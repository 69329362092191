import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { Preview } from '@mui/icons-material';
import {
  FormInputDate,
  FormInputText,
} from "../form";
import {
  CustomNoRowsOverlay,
} from "../datagrid";
import * as gclientActions from "../../store/gclient";
import * as dateUtils from '../../utils/dateUtils';
import * as formatters from '../../utils/formatters';
import { getGridHeight } from "../../constants/gridHeights";
import { MEMBERSHIP_TYPES, MEMBERSHIP_TYPE_LABELS } from "../../constants/gclientTypes";
import dayjs from 'dayjs';

const theme = createTheme();

const defaultValues = {
  searchName: "",
  partnerships: [''],
  memberships: [''],
  serviceDate: "",
  cmsDocumentPath: "",
  signYn: "",
  cmsYn: "",
  serviceMonth: "",
  startPaidEndDate: "",
  endPaidEndDate: "",
  };

const SettlementAssociation = ({ title, from }) => {

  const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues });

  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [rows, setRows] = useState([]);

  // 현재 화면에 맞는 Grid 높이 계산
  const GRID_HEIGHT = getGridHeight('ONE_LINE_LAYER', 0); // 0px 추가 여백

  // 현재 월 가져오기
 const currentMonth = dayjs().format('MM');  // '01' ~ '12' 형식

  const columnsBasic = [
    {
      field: 'cmsPayDay',
      headerName: 'CMS결제일자',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        if (!params.value) return '';
        const day = dateUtils.dateFormat(params.value, 'dd');
        return `${parseInt(day)}일`;  // 앞의 0을 제거하고 '일' 추가
      },
    },
    {
      field: 'name',
      headerName: '회사명',
      width: 180,
    },
    {
      field: 'membership',
      headerName: '멤버쉽',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          MEMBERSHIP_TYPE_LABELS[params.value]
        )
      }
    },
    {
      field: 'cmsAmount',
      headerName: 'CMS결제금액',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => formatters.formatKoreanCurrency(params.value),
    },
    {
      field: 'serviceDate',
      headerName: '서비스 시작일',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value ? dateUtils.dateFormat(params.value) : '',
    },
    {
      field: 'paidEndDate',
      headerName: '서비스 종료일',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value ? dateUtils.dateFormat(params.value) : '',
    },
  ];


  const sessionUser = useSelector(state => state.session.sessionUser);

  // CMS 정산 목록 조회
  const fetchCmsPayInfos = useCallback(async (month) => {
    try {
      setLoaded(false);
      // dispatch 없이 직접 API 호출
      const gclients = await gclientActions.selectCmsPayInfos(month);
      setRows(gclients);
    } catch (error) {
      console.error('CMS 결제 정보 조회 중 오류 발생:', error);
      setErrors(prev => [...prev, error.message]);
    } finally {
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
     fetchCmsPayInfos(currentMonth);
  }, [currentMonth, fetchCmsPayInfos]);

  const cmsPayMonthOptions = useMemo(() => {
    const options = Array.from({ length: 12 }, (_, i) => ({
      label: `${i + 1}월`,
      value: String(i + 1).padStart(2, '0')  // '01', '02', ... '12'
    }));

    // 컴포넌트 마운트 시 현재 월로 값 설정
   setValue("cmsPayMonth", currentMonth);
   
   return options;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {/* <Grid item xs={10}>
              <Typography variant="h5">
                {title}
              </Typography>
            </Grid> */}
            <Grid item xs={12} sm={10}>
              <FormInputText
                select
                name="cmsPayMonth"
                control={control}
                label={"CMS결제월"}
                options={cmsPayMonthOptions}
                onChange={async(e) => {
                  setValue("cmsPayMonth", e.target.value);
                  await fetchCmsPayInfos(e.target.value);
                }}
                sx={{
                  width: 150,
                  '& .MuiFormLabel-root': {
                    fontSize: '0.85rem',
                  },
                  '& .MuiSelect-select': {
                    fontSize: '0.85rem',
                  }
                }}
              />

            </Grid>
          </Grid>
          <div style={{ height: GRID_HEIGHT.FULL, width: '100%', marginTop: 10 }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={rows}
              columns={columnsBasic}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
            />
          </div>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SettlementAssociation;
