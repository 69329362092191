import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  Checkbox, 
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  Add,
  Assignment,
  Calculate,
  KeyboardArrowDown,
  KeyboardArrowUp,
  NoteAdd,
  PostAdd,
  Publish,
  Remove,
  Save,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import {
  changeArrayOrder,
} from "../../utils";
import { GlassUtils } from "../../utils";
import {
  FormInputCheckbox,
  FormInputText,
  FormInputMultipleSelect,
} from "../form";
import {
  AlertDialog,
  DialogTitleClose,
} from "../dialog";
import * as gclientActions from "../../store/gclient";
import * as gglassActions from "../../store/gglass";
import * as gtypeDetailActions from "../../store/gtypeDetail";
import * as gperfDataActions from "../../store/gperfData";
import * as gperformReportActions from "../../store/gperformReport";
import * as gglassPerformanceActions from "../../store/gglassPerformance";

const theme = createTheme();

const today = new Date();
let endDate = new Date();
endDate.setFullYear(today.getFullYear() + 2);

const defaultValues = {
  gclientName: "",
  site: "",
  siteAddress: "",
  comments: "",
}

const border = '1px solid rgba(224, 224, 224, 1)';
const headerBackgroundColor = '#f5f5f5';

const GPerformanceDataDialog = ({
  selectedRow,
  openDialog,
  setOpenDialog,
  modify,
  setModify,
  refresh,
}) => {
  const [selectedGlasses, setSelectedGlasses] = useState([]);
  const [alertInfo, setAlertInfo] = useState({});
  const [modifiedGlassesIndexArray, setModifiedGlassesIndexArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);  // 실제 수정 여부를 추적. 다이얼로그 그냥 닫았을 때 목록 재검색 방지
  const [glassesLoading, setGlassesLoading] = useState(false);

  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const dispatch = useDispatch();

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const gclients = useSelector((state) => state.gclient.gclients);
  const gglasses = useSelector((state) => state.gglass.gglasses);
  const g04docuGCertificationsProcess = useSelector((state) => state.g04docuGCertification.g04docuGCertificationsProcess);
  
  // TODO : 성능확인 데이터 미리 가져오기 (데이터 사이즈가 클 수 있으므로 추후 검토. 아래에서 즉석에서 api 호출하는 방식??? 현재 async 문제로 미리 가져오는 방식으로 구현)
  const gglassPerformances = useSelector((state) => state.gglassPerformance.gglassPerformances);
  const gtrans = useSelector((state) => state.gperfData.gtrans);
  
  const addGPerformReport = ({ gclientId, site, siteAddress, comments, selectedGlasses, pdfYn }) => dispatch(gperformReportActions.create({ gclientId, site, siteAddress, comments, selectedGlasses, pdfYn }))
  const modifyGPerformReport = ({ id, gclientId, site, siteAddress, comments, selectedGlasses, pdfYn }) => dispatch(gperformReportActions.modify({ id, gclientId, site, siteAddress, comments, selectedGlasses, pdfYn }))
  const selectAllGClients = (valid) => dispatch(gclientActions.selectAll(valid))
  const selectGlasses = () => dispatch(gglassActions.selectAllByQuery())
  const selectGTypeDetailsWithGComponentDirect = (gtypeId) => gtypeDetailActions.selectGTypeDetailsWithGComponentDirect(gtypeId)
  const selectGTrans = () => dispatch(gperfDataActions.selectGTrans())
  const selectGPerfData1 = ({ glass }) => gperfDataActions.selectGPerfData1({ glass });
  const selectGPerfData2 = ({ ex_glass, gas_thickness, gas_material, in_glass }) => gperfDataActions.selectGPerfData2({ ex_glass, gas_thickness, gas_material, in_glass });
  const selectGPerfData3 = ({ ex_glass, gas_thickness1, gas_material1, mid_glass, build_aspect, gas_thickness2, gas_material2, in_glass }) => gperfDataActions.selectGPerfData3({ ex_glass, gas_thickness1, gas_material1, mid_glass, build_aspect, gas_thickness2, gas_material2, in_glass });
  const selectAllGGlassPerformance = (code) => dispatch(gglassPerformanceActions.selectAll())
  
  useEffect(async () => {
    if (!openDialog) return;
    
    setGlassesLoading(true);

    try {
      // 공통으로 필요한 데이터 로드
      await selectAllGGlassPerformance();
      await selectAllGClients(true);
      await selectGTrans();
      await selectGlasses();
    
      console.log(selectedRow)

      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          setValue(item, selectedRow[item] || value);
        }

        // 기존 데이터 수정 시
        if (selectedRow.selectedGlasses && Array.isArray(selectedRow.selectedGlasses) && selectedRow.selectedGlasses.length > 0) {
          const updatedGlasses = await Promise.all(
            selectedRow.selectedGlasses.map(async (glass) => {
              const gtypeDetailsWithGComponent = await selectGTypeDetailsWithGComponentDirect(glass.gtypeId);
              return { ...glass, gtypeDetailsWithGComponent };
            })
          );
          console.log(updatedGlasses)
          setSelectedGlasses(updatedGlasses);
          setModifiedGlassesIndexArray(updatedGlasses.map(() => false));
        } else {
          setSelectedGlasses([{
            checked: false,
            no: "",
            specification: "",
            selectedGcomponentItems: [],
            appliedArea: "",
            aspect: "",
            performanceData: null
          }]);
          setModifiedGlassesIndexArray([false]);
        }
      } else {
        // 신규 등록 시
        const newGlass = {
          checked: false,
          no: "",
          specification: "",
          selectedGcomponentItems: [],
          appliedArea: "",
          aspect: "",
          performanceData: null,
        };
        setSelectedGlasses([newGlass]);
        setModifiedGlassesIndexArray([false]);
      }

    } finally {
      setGlassesLoading(false);
    }
  }, [openDialog, selectedRow]);

  const handleDialogClose = () => {
    setOpenDialog(false);

    if (modify && hasChanges) {
      refresh && refresh();
    }

    initDialog();
    setHasChanges(false);
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }
    setSelectedGlasses([]);
    setModifiedGlassesIndexArray([]);
  }

  const gcertificationObj = (types) => {
    const result = types.map(type => {
      const cert = g04docuGCertificationsProcess.find(gcertification => gcertification.code === type);
      if (cert) {
        const { id, name, code, order, selectedClassifications, classifications } = cert;
        return { id, name, code, order, selectedClassifications, classifications };
      }
      return null;
    }).filter(Boolean); // null 값 제거
  
    // 결과가 하나의 요소만 있으면 객체를 반환, 그렇지 않으면 배열을 반환
    return result.length === 1 ? result[0] : result;
  }
  /****************************************************************************************************************************************************************
   * 아래는 성적서와 성능계산을 할 때 조건이 되는 검색코드를 간략 설명한 것이다. 참고로 성능계산은 가공유리에만 적용한다.
   * 
   * 가공법	            성적서		                   예                                      성능확인서	            예
   * --------------------------------------------------------------------------------------------------------------------------------------------------------
   * 배강도/강화		     유리원판X                    5|H/S	                                  유리원판O	             5|CL|H/S
   * 접합		            유리원판X, 접합필름O          4+0.38|PVB+4	                          유리원판O, 접합필름O	  4|CL+0.38|PVB+4|CL
   * 복층		            유리원판O, 접합필름O          4|CL+12|A+4|CL	                        유리원판O, 접합필름O)	  4|CL+12|A+4|CL
   *                    접합이 있는 경우		         4|CL+0.38|PVB+4|CL+12|A+4|CL		                                4|CL+0.38|PVB+4|CL+12|A+4|CL

   ****************************************************************************************************************************************************************/
  const calcPerfData = async () => {
    console.log(selectedGlasses)
    console.log(modifiedGlassesIndexArray)

    const newSelectedGlasses = [];

    let idx = 0;
    for await (const glass of selectedGlasses) {
      const { selectedGcomponentItems, aspect } = glass;
      const modified = modifiedGlassesIndexArray[idx];

      if (!modified) {
        newSelectedGlasses.push(glass);
        console.log("There is no need to parse anymore.", glass)
        idx = idx + 1;
        continue;
      }

      const specificationElements = [];
      let fullSpecification = "";
      selectedGcomponentItems.forEach((gtypeDetails, i) => {
        let productName = "";
        
        gtypeDetails.forEach((item, j) => {
          if (j === 0) {
            return;
          }
          
          if (Array.isArray(item.value)) {
            item.value.forEach(i => {
              const { applyType, code } = i;
              if (applyType === 'productName') {
                productName += code ? `${code}|` : "";
                specificationElements.push({ code: i.code, value: i.value });
              }
            })
          } else {
            const itemCode = item.code;
            const { applyType, code } = item.value;
            if (applyType === 'productName') {
              productName += code ? `${GlassUtils.getMaterialItemCodeOrName(itemCode, code)}|` : "";
              specificationElements.push({ code: item.code, value: item.value });
            }
          }
        })
        productName = productName.substring(0, productName.length - 1);
        fullSpecification += (productName !== "" ? `${productName}+` : "");
      })
      
      fullSpecification = fullSpecification.substring(0, fullSpecification.length - 1);
      
      console.log(specificationElements);
      console.log(fullSpecification);
      
      const onePanes = [];
      const heats = [];
      const laminations = [];
      const insulations = [];
      const insulateLayers = [];
  
      let isInsulatedGlassUnit = false;
      let hasProcessing = false;
      let isMonolithic = true;

      let manufacturer = null;

      let i = 0;
      for await (const element of specificationElements) {
        const { code, value } = element;
        
        if (code === 'GLASS PRODUCT NAME' && value.gclients && Array.isArray(value.gclients) && value.gclients.length === 1) {
          const manufacturerId = value.gclients[0].id // TODO : 가공유리 안에서 원판제조사가 둘 이상인 경우 처리 필요 : 예를 들면 맨 앞 유리 또는 로이유리 등...
          manufacturer = gclients.find(gclient => gclient.id === manufacturerId);
        }

        if (code === 'GL HEAT TREATING' && (value.code === 'H/S' || value.code === 'F/T' || value.code === 'F/T HST')) {
          hasProcessing = true;

          let findIndex = -1;
          for(let j=i; j>=0; j--) {
            let targetCode = specificationElements[j].code;
            if (targetCode === 'GL THICKNESS') {
              findIndex = j;
              break;
            }
          }
  
          let type = "";
          let subType = "";
          const { code } = value;
          if (code === 'H/S') {
            type = 'HEAT_STRENGTHENED';
          } else if (code === 'F/T' || code === 'F/T HST') {
            type = 'TEMPERED';
            if (code === 'F/T HST') {
              subType = 'Heat_Soak_Test';
            }
          }
  
          const arrHeat = specificationElements.slice(findIndex === -1 ? i : findIndex, i+1).filter(ele => ele.code !== 'GLASS PRODUCT NAME');
          const arrHeatWithProductName = specificationElements.slice(findIndex === -1 ? i : findIndex, i+1);

          const specification = arrHeat.map(s => s.value.code).join("|");
          const specificationWithProductName = arrHeatWithProductName.map(s => GlassUtils.getMaterialItemCodeOrName(s.code, s.value.code)).join("|");
          
          heats.push({
            type,
            specification,
            specificationWithProductName,
            specificationElements,
          });
        }
  
        if (code === 'FILM THICKNESS') {
          isMonolithic = false;

          let frontIndex = -1;
          let rearIndex = -1;
          for(let j=i; j>=0; j--) {
            let targetCode = specificationElements[j].code;
            if (targetCode === 'GL THICKNESS') {
              frontIndex = j;
              break;
            }
          }
  
          const arrFront = specificationElements.slice(frontIndex, i);
  
          for(let j=i; j<specificationElements.length-1; j++) {
            if (j > i && specificationElements[j].code === 'SPACER THICKNESS') {
              rearIndex = j;
              break;
            }
          }
  
          const arrRearWithFilm = specificationElements.slice(i, rearIndex === -1 ? specificationElements.length : rearIndex);
          let rearSpecification = "";
          let rearSpecificationWithGlassProductName = "";
          arrRearWithFilm.forEach((s, i) => {
            if (i > 0 && s.code === 'GLASS PRODUCT NAME') {
              rearSpecificationWithGlassProductName = rearSpecificationWithGlassProductName + "|" + GlassUtils.getMaterialItemCodeOrName(s.code, s.value.code);
              return;
            }

            if (i === 0) {
              rearSpecification = s.value.code;
              rearSpecificationWithGlassProductName = s.value.code;
            } else {
              rearSpecification = rearSpecification + (s.code.indexOf('THICKNESS') >= 0 ? "+" : "|") + GlassUtils.getMaterialItemCodeOrName(s.code, s.value.code);
              rearSpecificationWithGlassProductName = rearSpecificationWithGlassProductName + (s.code.indexOf('THICKNESS') >= 0 ? "+" : "|") + GlassUtils.getMaterialItemCodeOrName(s.code, s.value.code);
            }
          })
          
          const specification = `${arrFront.filter(s => s.code !== 'GLASS PRODUCT NAME').map(s => s.value.code).join("|")}+${rearSpecification}`;
          const specificationWithGlassProductName = `${arrFront.map(s => GlassUtils.getMaterialItemCodeOrName(s.code, s.value.code)).join("|")}+${rearSpecificationWithGlassProductName}`;
          
          let type = 'LAMINATED';
          laminations.push({
            type,
            specification,
            specificationWithGlassProductName,
            specificationElements: arrFront.concat(arrRearWithFilm),
          });
        }

        if (code === 'SPACER THICKNESS') {
          isMonolithic = false;
          if (!isInsulatedGlassUnit) {
            const existGas = [];

            specificationElements.forEach((ele, i) => {
              if (ele.code === 'GAS MATERIAL' && ele.value.code !== 'A') {
                existGas.push(ele);
              } else if (ele.code === 'SPACER THICKNESS') {
                insulateLayers.push({ ele, i });
              }
            });

            let type = 'INSULATED_GLASS_UNIT';

            if (existGas.length > 0) {
              let type = 'INSULATED_GLAZING';
              insulations.push({
                type,
                specification: fullSpecification,
                specificationElements,
                g04docuGCertification: gcertificationObj([type]),
              });
            }

            insulations.push({
              type,
              specification: fullSpecification,
              specificationElements,
            });
          }

          isInsulatedGlassUnit = true;
        }
        i = i + 1;
      }

      if (isMonolithic && !hasProcessing) {
        const glassSpec = specificationElements
          .filter(ele => ele.code !== 'GLASS PRODUCT NAME')
          .map(s => s.value.code)
          .join("|");
        
        const glassSpecWithProductName = specificationElements
          .map(s => GlassUtils.getMaterialItemCodeOrName(s.code, s.value.code))
          .join("|");
        
        onePanes.push({
          type: 'ONE_PANE',
          specification: glassSpec,
          specificationWithProductName: glassSpecWithProductName,
          specificationElements,
        });
      }
  
      console.log({ onePanes, heats, laminations, insulations });
      
      const processes = [].concat(onePanes, heats, laminations, insulations);
      console.log(processes);

      let lastProcess;
      // 성능 데이터 계산 - 프로세스 배열의 마지막 요소에 대해서만 수행
      if (processes.length > 0) {
        lastProcess = processes[processes.length - 1];
        console.log(lastProcess)
        let perfData = {};

        if (manufacturer?.bizRegNumber === "369-85-01171") { // KCC
          if (lastProcess.type === 'ONE_PANE' || lastProcess.type === 'HEAT_STRENGTHENED' || lastProcess.type === 'TEMPERED') {
            const matched = gtrans.find(i => i.org_name === lastProcess.specificationWithProductName);
            console.log(matched)
            const foundWithProductName = matched && matched.replace_name && 
              (await selectGPerfData1({ glass: matched.replace_name }));
            
            if (foundWithProductName) {
              const { res_vlt, res_vlr_ex, res_st, res_sr_ex, res_u_value_ks_w, res_sc, res_shgc, res_rhg } = foundWithProductName;
              perfData = {
                name: lastProcess.specificationWithProductName.replaceAll("|", " "),
                vlt: res_vlt,
                vlr: null,
                vlr_ex: res_vlr_ex,
                vlr_in: null,
                st: res_st,
                sr_ex: res_sr_ex,
                sc: res_sc,
                shgc: res_shgc,
                u_value_ks: res_u_value_ks_w,
                rhg: res_rhg,
                manufacturer: { id: manufacturer.id, name: manufacturer.name, bizRegNumber: manufacturer.bizRegNumber },
              };
            }
          } else if (lastProcess.type === 'LAMINATED') {
            const layers = lastProcess.specificationWithGlassProductName.split("+");
            if (layers.length === 3) {
              const ex_glass = layers[0];
              const matched_ex_glass = gtrans.find(i => i.org_name === ex_glass);
              const in_glass = layers[2];
              const matched_in_glass = gtrans.find(i => i.org_name === in_glass);
              
              const foundWithProductName = matched_ex_glass && matched_ex_glass.replace_name && 
                matched_in_glass && matched_in_glass.replace_name && 
                (await selectGPerfData1({ glass: `${matched_ex_glass.replace_name}+${layers[1]}+${matched_in_glass.replace_name}`}));
              
              if (foundWithProductName) {
                const { res_vlt, res_vlr_ex, res_st, res_sr_ex, res_u_value_ks_w, res_sc, res_shgc, res_rhg } = foundWithProductName;
                perfData = {
                  name: lastProcess.specificationWithGlassProductName.replaceAll("|", " ").replaceAll("+", " + "),
                  vlt: res_vlt,
                  vlr: null,
                  vlr_ex: res_vlr_ex,
                  vlr_in: null,
                  st: res_st,
                  sr_ex: res_sr_ex,
                  sc: res_sc,
                  shgc: res_shgc,
                  u_value_ks: res_u_value_ks_w,
                  rhg: res_rhg,
                  manufacturer: { id: manufacturer.id, name: manufacturer.name, bizRegNumber: manufacturer.bizRegNumber },
                };
              }
            }
          } else if (lastProcess.type === 'INSULATED_GLASS_UNIT' || lastProcess.type === 'INSULATED_GLAZING') {
            const layers = insulateLayers.length;
            let foundWithProductName;
            
            if (layers === 1) { // 복층유리
              const spacerIdx = insulateLayers[0].i;
              const ex_glass = specificationElements.slice(0, spacerIdx);
              const spacer = specificationElements.slice(spacerIdx, spacerIdx+2);
              const in_glass = specificationElements.slice(spacerIdx+2);
              
              foundWithProductName = await selectGPerfData2({
                ex_glass: GlassUtils.getLayerGlassSpecificationNoParentheses(ex_glass, gtrans),
                gas_thickness: spacer && Array.isArray(spacer) && spacer.length > 0 && spacer[0].value.code,
                gas_material: spacer && Array.isArray(spacer) && spacer.length > 1 && spacer[1].value.code,
                in_glass: GlassUtils.getLayerGlassSpecificationNoParentheses(in_glass, gtrans),
              });
            } else if (layers === 2) { // 삼층복층유리
              const spacerIdx1 = insulateLayers[0].i;
              const spacerIdx2 = insulateLayers[1].i;
              const ex_glass = specificationElements.slice(0, spacerIdx1);
              const spacer1 = specificationElements.slice(spacerIdx1, spacerIdx1+2);
              const mid_glass = specificationElements.slice(spacerIdx1+2, spacerIdx2);
              const spacer2 = specificationElements.slice(spacerIdx2, spacerIdx2+2);
              const in_glass = specificationElements.slice(spacerIdx2+2);
              
              foundWithProductName = await selectGPerfData3({
                ex_glass: GlassUtils.getLayerGlassSpecificationNoParentheses(ex_glass, gtrans),
                gas_thickness1: spacer1 && Array.isArray(spacer1) && spacer1.length > 0 && spacer1[0]?.value.code,
                gas_material1: spacer1 && Array.isArray(spacer1) && spacer1.length > 1 && spacer1[1]?.value.code,
                mid_glass: GlassUtils.getLayerGlassSpecificationNoParentheses(mid_glass, gtrans),
                build_aspect: aspect,
                gas_thickness2: spacer2 && Array.isArray(spacer2) && spacer2.length > 0 && spacer2[0]?.value.code,
                gas_material2: spacer2 && Array.isArray(spacer2) && spacer2.length > 1 && spacer2[1]?.value.code,
                in_glass: GlassUtils.getLayerGlassSpecificationNoParentheses(in_glass, gtrans),
              });
            }
            
            if (foundWithProductName) {
              const { res_vlt, res_vlr_ex, res_st, res_sr_ex, res_u_value_ks_w, res_sc, res_shgc, res_rhg } = foundWithProductName;
              perfData = {
                name: fullSpecification.replaceAll("|", " ").replaceAll("+", " + "),
                vlt: res_vlt,
                vlr: null,
                vlr_ex: res_vlr_ex,
                vlr_in: null,
                st: res_st,
                sr_ex: res_sr_ex,
                sc: res_sc,
                shgc: res_shgc,
                u_value_ks: res_u_value_ks_w,
                rhg: res_rhg,
                manufacturer: { id: manufacturer.id, name: manufacturer.name, bizRegNumber: manufacturer.bizRegNumber },
              };
            }
          }
        } else if (manufacturer?.bizRegNumber === "542-88-01592") { // LX
          const found = gglassPerformances.find(performance => 
            performance.code === lastProcess.specification && 
            performance.gclientId === manufacturer.id
          );
          console.log({ found, gglassPerformances })
          if (found) {
            perfData = {
              ...found,
              name: lastProcess.specification.replaceAll("|", " ").replaceAll("+", " + "),
              manufacturer: { id: manufacturer.id, name: manufacturer.name, bizRegNumber: manufacturer.bizRegNumber },
            };
          } else {
            lastProcess.performanceData = null;
          }
        }

        if (perfData && Object.keys(perfData).length > 0) {
          lastProcess.performanceData = perfData;
        }
      }
      
      newSelectedGlasses.push({
        ...glass,
        performanceData: lastProcess?.performanceData || null,
      });

      console.log(processes);

      idx = idx + 1;
    }
    
    console.log(newSelectedGlasses);
    
    if (newSelectedGlasses.length > 0) {
      setSelectedGlasses(newSelectedGlasses);
    }
  }

  const handleChangeGGlass = async (e, i) => {
    const gglassId = e.target.value;
    const selGGlass = gglasses.find(gglass => gglass.id === gglassId);
    if (selGGlass) {
      console.log(selGGlass)
      const { name, gtypeId, gtypeCode, gtypeName, selectedG04docuGCertifications, selectedGcomponentItems } = selGGlass;
      const gtypeDetailsWithGComponent = await selectGTypeDetailsWithGComponentDirect(selGGlass.gtypeId);
      // gtypeDetails가 바뀌어었으므로 해당 구조로 selectedGcomponentItems 초기화 해야 함 => 초기화할 것이 아니라 품종에 적용한 기본 속성이 적용되어야 함
      const newSelectedGlasses = selectedGlasses.map((gglass, j) => i === j ? {
        ...gglass,
        gglassId,
        gglassName: name,
        gtypeId,
        gtypeCode,
        gtypeName,
        gtypeDetailsWithGComponent,
        selectedGcomponentItems,
        specification: GlassUtils.getSpecification(selectedGcomponentItems)[0],
        aspect: selGGlass?.gtypeCode === 'IGU_TRIPLE' ? "4면시공" : "",
        performanceData: null,
      } : gglass);

      // <<<주의 : 코드 하드코딩 사용>>>
      if (selGGlass.gtypeCode === 'IGU_TRIPLE' || selGGlass.gtypeName === '3중 복층유리') { // 삼중복층일 때
        newSelectedGlasses[i].aspect = "4면시공";
      }

      console.log(newSelectedGlasses)
      console.log(GlassUtils.getSpecification(selectedGcomponentItems))
      
      console.log(modifiedGlassesIndexArray);
      setSelectedGlasses(newSelectedGlasses);

      // TODO : modifiedGlassesIndexArray 수정
      const newModifiedGlassesIndexArray = [].concat(modifiedGlassesIndexArray);
      newModifiedGlassesIndexArray.splice(i, 1, true);
      console.log(newModifiedGlassesIndexArray)
      setModifiedGlassesIndexArray(newModifiedGlassesIndexArray);
    }
  }

  const handleGGlassUpDown = async (type, rownum, itemType) => {
    let newArr;
    
    let newRows = JSON.parse(JSON.stringify(selectedGlasses));
    
    const newModifiedGlassesIndexArray = [].concat(modifiedGlassesIndexArray);
    // up이면 rownum에서 -1한 행과 swap된 것이고, down이면 +1한 행과 swap된 것임
    if (type === "up") {
      if (rownum > 0) {
        newArr = changeArrayOrder(newRows, rownum, -1)

        // 행 변경에 따라 modifiedGlassesIndexArray내 요소를 swap 한다.
        /**
         * swap 예제코드
         * 
         * 1. temp 변수 사용
         * const arr = [1, 2, 3, 4, 5];
         * 
         * let temp = arr[1];
         * arr[1] = arr[2];
         * arr[2] = temp;
         * 
         * console.log(arr); // [1, 3, 2, 4, 5]
         * 
         * 2. 구조 분해 할당 사용
         * const arr = [1, 2, 3, 4, 5];
         * 
         * [arr[1], arr[2]] = [arr[2], arr[1]];
         * 
         * console.log(arr); // [1, 3, 2, 4, 5]
         */
        // [newModifiedGlassesIndexArray[rownum-1], newModifiedGlassesIndexArray[rownum]] = [newModifiedGlassesIndexArray[rownum], newModifiedGlassesIndexArray[rownum-1]]; // TODO : 오류발생. why?
        let temp = newModifiedGlassesIndexArray[rownum-1];
        newModifiedGlassesIndexArray[rownum-1] = newModifiedGlassesIndexArray[rownum];
        newModifiedGlassesIndexArray[rownum] = temp;
      } else {
        return;
      }
    } else if (type === "down") {
      if (rownum < newRows.length - 1) {
        newArr = changeArrayOrder(newRows, rownum, 1)
        
        // [newModifiedGlassesIndexArray[rownum], newModifiedGlassesIndexArray[rownum+1]] = [newModifiedGlassesIndexArray[rownum+1], newModifiedGlassesIndexArray[rownum]]; // TODO : 오류발생. why?
        let temp = newModifiedGlassesIndexArray[rownum+1];
        newModifiedGlassesIndexArray[rownum+1] = newModifiedGlassesIndexArray[rownum];
        newModifiedGlassesIndexArray[rownum] = temp;
      } else {
        return;
      }
    } else {
      newArr = newRows;
    }

    // console.log(newArr)
    
    setSelectedGlasses(newArr);

    console.log(newModifiedGlassesIndexArray)
    setModifiedGlassesIndexArray(newModifiedGlassesIndexArray);
  }

  const addGGlass = async (e, idx) => {
    // 14개 제한 체크 추가
    if (selectedGlasses.length >= 14) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: "유리는 최대 14개까지만 추가할 수 있습니다.",
        open: true,
      });
      return;
    }

    // 클릭한 바로 아래에 삽입하도록 한다.
    const newSelectedGlasses = JSON.parse(JSON.stringify(selectedGlasses));
    
    const selGGlass = gglasses.find(gglass => gglass.id === selectedGlasses[idx].gglassId);
    
    const selectedGcomponentItems = selectedGlasses[idx].selectedGcomponentItems;

    const newGlass = Object.assign({}, {
      ...selectedGlasses[idx],
      checked: false,
      id: uuidv4(),
      no: "",
      specification: "",
      // selectedProcessGClients: [],
      // 유리템플릿을 선택하지 않고 추가하면 유리템플릿이 복사되지 않으므로 오류가 발생하여 빈 배열 추가
      selectedGcomponentItems: selectedGcomponentItems && Array.isArray(selectedGcomponentItems) ? selectedGcomponentItems.map((layer, i) => (layer.map(item => ({ ...item, value: {} })))) : [],
      // 유리 데이터 구조변경(개발중이나 개선중. 여기서는 유리품종에서 인증규격 구조 변경)시 복사하지 않고 변경된 selectedG04docuGCertifications 를 새로 가지고 온다.
      // selectedG04docuGCertifications: (selGGlass && selGGlass.selectedG04docuGCertifications) ? selGGlass.selectedG04docuGCertifications : [],
      appliedArea: "",
      performanceData: null,
    });

    console.log(newGlass)

    // <<<주의 : 코드 하드코딩 사용>>>
    if (selGGlass?.gtypeCode === 'IGU_TRIPLE' || selGGlass?.gtypeName === '3중 복층유리') { // 삼중복층일 때
      newGlass.aspect = "4면시공";
    }

    // console.log(newGlass)
    newSelectedGlasses.splice(idx+1, 0, newGlass);
    
    // console.log(newSelectedGlasses)
    setSelectedGlasses(newSelectedGlasses);

    // 변경된 행 정보를 담고 있는 베열 - 배열요소 추가된 행을 나타내는 false 추가
    const newModifiedGlassesIndexArray = [].concat(modifiedGlassesIndexArray);
    newModifiedGlassesIndexArray.splice(idx+1, 0, false);

    // console.log(newModifiedGlassesIndexArray)
    setModifiedGlassesIndexArray(newModifiedGlassesIndexArray);
  }

  const removeGGlass = (e, idx) => {
    const newSelectedGlasses = JSON.parse(JSON.stringify(selectedGlasses));
    newSelectedGlasses.splice(idx, 1);
    
    setSelectedGlasses(newSelectedGlasses);

    // modifiedGlassesIndexArray와 selectedGlasses 통합하는 방법(selectedGlasses안에 수정여부 기록)을 고려했으나
    // selectedGlasses안에 수정여부를 기록했다가 저장시 다시 변경하는 번거로움이 있어 별도로 유지하기로 함
    const newModifiedGlassesIndexArray = [].concat(modifiedGlassesIndexArray);
    newModifiedGlassesIndexArray.splice(idx, 1);
    // console.log(newModifiedGlassesIndexArray)
    setModifiedGlassesIndexArray(newModifiedGlassesIndexArray);
  }

  const handleChangeMultiGcomponentItem = (value, item, gtypeDetailsOrder, gcomponentItemOrder, idx) => {
    // console.log({value, item, gtypeDetailsOrder, gcomponentItemOrder/*, gcomponent*/});
    const itemNew = {
      id: item.id,
      name: item.name,
      value: value.length > 0 ? value.map(v => JSON.parse(v)) : [], // 모두 선택해제되면 []로 설정
    };

    const newSelectedGlasses = selectedGlasses.map((glass, i) => {
      if (i === idx) {
        // return glass.selectedGcomponentItems[gtypeDetailsOrder][gcomponentItemOrder] = itemNew;
        glass.selectedGcomponentItems[gtypeDetailsOrder][gcomponentItemOrder] = itemNew;
        if (glass.selectedProcessGClients && Array.isArray(glass.selectedProcessGClients)) { // 유리템플릿을 변경했으므로 선택한 가공업체가 있다면 초기화한다.
          glass.selectedProcessGClients = glass.selectedProcessGClients.map(process => ({ ...process, selectedGClients: [] }));
        }

        return glass;
      }

      return glass;
    })    
            
    setSelectedGlasses(newSelectedGlasses);

    const newModifiedGlassesIndexArray = [].concat(modifiedGlassesIndexArray);
    newModifiedGlassesIndexArray.splice(idx, 1, true);
    // console.log(newModifiedGlassesIndexArray)
    setModifiedGlassesIndexArray(newModifiedGlassesIndexArray);
  }

  const handleChangeGcomponentItem = (e, item, gtypeDetailsOrder, gcomponentItemOrder, gcomponent, idx) => {
    console.log({ e, item, gtypeDetailsOrder, gcomponentItemOrder, gcomponent, idx })
    // "없음" 선택한 경우 해당 구성요소가 의존하고 있는 구성요소가 있을 경우 안내메시지 띄우고 "없음" 선택안되도록 함
    // properties가 0("없음")인 경우 selectedGcomponentItems[gtypeDetailsOrder]에서 dependentGcomponentItem가 있는 것 중 gcomponentId가 item.id인 것이 있으면 안내 메시지 출력 
    // console.log(selectedGcomponentItems);
    const properties = item.properties.filter(property => property.id === e.target.value);
    if (properties.length <= 0) { // "없음"일 경우
      const gcomponentItems = selectedGlasses[idx].selectedGcomponentItems[gtypeDetailsOrder];
      // console.log(gcomponentItems)
      const result = gcomponentItems.filter(gcomponentItem => {
        return gcomponentItem.value?.dependentGcomponentItem && JSON.parse(gcomponentItem.value?.dependentGcomponentItem).gcomponentId === item.id
      });
      // console.log(result)
      if (result.length > 0) {
        const { name, value } = result[0];
        const messageAlert = (
          <div>
            <span style={{ color: "#1976d2" }}>{`'${name}'`}</span>{`(이)가 '`}
            <span style={{ color: "#1976d2" }}>{`${value.name}`}</span>{`'(으)로 선택된 경우 `}
            <span style={{ color: "#1976d2" }}>{`'${item.name}'`}</span>{`(은)는 `}
            {/* <span style={{ color: "red" }}>{`'없음'`}</span>{`을 선택할 수 없고,`} */}
            <br/>
            <span style={{ color: "#d32f2f",  }}><i><u>{`반드시 값을 선택`}</u></i></span>
            {`해야 합니다.`}
          </div>
        );

        setAlertInfo({
          titleAlert: "안내",
          messageAlert,
          open: true,
        });

        return;
      }
    }
    
    let value = "";
    if (properties.length > 0) {
      value = properties[0];
      value.selectedGClientId = value.gclients && Array.isArray(value.gclients) && value.gclients.length === 1 && value.gclients[0].id || ""; // 원판 제조사 자동 설정 => 해당 원판의 제조사는 한군데라는 전제
    }
    
    // setValue(`${idx}_${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`, value?.id || value); // TODO : 안해도 값이 설정되네???
    // console.log(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`);

    const itemNew = {
      id: item.id,
      name: item.name,
      code: item.code, // TODO : 다른 곳도 필요시 코드 추가해야 함
      value,
    };

    const gcomponentItems = selectedGlasses[idx].selectedGcomponentItems;
    
    const newSelectedGlasses = selectedGlasses.map((glass, i) => {
      if (i === idx) {
        glass.selectedGcomponentItems[gtypeDetailsOrder][gcomponentItemOrder] = itemNew;
        // glass.specification = GlassUtils.getSpecification(gcomponentItems)[0]; // 0: 사양(specification), 1: 비고(otherSpecs)
        const [specification] = GlassUtils.getSpecification(gcomponentItems);
        glass.specification = specification;
        if (glass.selectedProcessGClients && Array.isArray(glass.selectedProcessGClients)) { // 유리제품 정보를 변경했으므로 선택한 가공업체가 있다면 초기화한다.
          glass.selectedProcessGClients = glass.selectedProcessGClients.map(process => ({ ...process, selectedGClients: [] }));
        }
      }

      return glass;
    })
    
    console.log(newSelectedGlasses)
    
    setSelectedGlasses(newSelectedGlasses);

    const newModifiedGlassesIndexArray = [].concat(modifiedGlassesIndexArray);
    newModifiedGlassesIndexArray.splice(idx, 1, true);
    console.log(newModifiedGlassesIndexArray)
    setModifiedGlassesIndexArray(newModifiedGlassesIndexArray);
  }

  // <<<주의 : 코드 하드코딩 사용>>>
  const getWidthByCode = (code) => {
    let width = 100;
    if (code === 'GLASS PRODUCT NAME') {
      width = 150;
    } else if (code === 'GL THICKNESS') {
      width = 98;
    } else if (code === 'GL HEAT TREATING') {
      width = 87;
    } else if (code === 'GAS MATERIAL') {
      width = 98;
    } else if (code === 'FILM TYPE') {
      width = 90;
    } else if (code === 'FILM THICKNESS') {
      width = 94;
    } else if (code === 'SPACER THICKNESS') {
      width = 114;
    }

    return width;
  }

  const generateGGlassByRow = (gtypeDetailsWithGComponent, gcomponentItems, idx) => {
    return gtypeDetailsWithGComponent?.map((gtypeDetail, i) => {
      return (
        <>
        {
          gtypeDetail.map((gcomponent, j) => {
            const { id, name, code, type, properties, multipleYN } = gcomponent;
            // TODO : 같은 레벨의 properties안에서도 applyType이 productName/etc 섞여 있음
            if (type === 'property' &&
                properties &&
                properties.length &&
                properties.length > 0 &&
                properties.filter(property => property.applyType === 'productName').length === properties.length // && // TODO : applyType이 "productName"이 조건이 아니라 별도의 속성이 추가해야 할 수도 있음(2023-03-31)
                // gcomponent.properties.filter(property => property.applyType === 'productName').length > 0
              ) {
              // console.log(gcomponent);
              return (
                <>
                  {
                    multipleYN ? (<FormInputMultipleSelect
                      id="multiple-type-select"                        
                      name={`${idx}_${i}_${j}_${id}`}
                      control={control}
                      label={name}
                      setValue={setValue}
                      onChangeItem={(value) => handleChangeMultiGcomponentItem(value, gcomponent, i, j, gtypeDetail, idx)}
                      dynamicItems={{ selectedGcomponentItems: gcomponentItems, gtypeDetailsIndex: i, gcomponentItemIndex: j, gcomponentItem: gcomponent }} // selectedGcomponentItems[gtypeDetailsIndex][gcomponentItemIndex] 넘기면 값이 고정되므로 안됨
                      options={properties}
                      chipSize={"small"}
                      sx={{ width: 140, ml: 1, mt: 2 }}
                    />) : (<FormInputText
                      select
                      name={`${idx}_${i}_${j}_${id}`}
                      control={control}
                      label={name}
                      onChange={(e) => handleChangeGcomponentItem(e, gcomponent, i, j, gtypeDetail, idx)}
                      options={
                        [{ label: '없음', value: "" }].concat(properties.map(property => { // TODO : 추후 value: {} 했을 때 문제 없는지 체크할 것
                          return {
                            label: property.name,
                            value: property.id,
                          }
                        }))
                      }
                      sx={{
                        width: getWidthByCode(code),
                        ml: 1,
                        // bgcolor: (code === 'SPACER THICKNESS' || code === 'GAS MATERIAL') ? '#e3f2fd' : (code === 'FILM THICKNESS' || code === 'FILM TYPE' ? "#f9fbe7" : ""),
                        bgcolor: (code === 'SPACER THICKNESS' || code === 'GAS MATERIAL'|| code === 'FILM THICKNESS' || code === 'FILM TYPE' ? "" : "#E8F3FF"),
                      }}
                      // 아래에서 속성값이 설정되지 않은 경우 selectedGlasses[idx].selectedGcomponentItems[i][j].value = {} 이므로 selectedGlasses[idx].selectedGcomponentItems[i][j].value.id는 undefined임
                      // 이럴 경우 값이 설정된 후 정렬시 행이동에도 불구하고 값이 이동하지 않음. 참고로 ""가 아닌 {}, null일 경우 동작이 차이가 있음
                      value={
                        selectedGlasses[idx] &&
                        selectedGlasses[idx].selectedGcomponentItems[i] &&
                        selectedGlasses[idx].selectedGcomponentItems[i][j] &&
                        selectedGlasses[idx].selectedGcomponentItems[i][j].value &&
                        selectedGlasses[idx].selectedGcomponentItems[i][j].value.id ?
                          selectedGlasses[idx].selectedGcomponentItems[i][j].value.id :
                          ""
                      }
                      inputProps={{
                        sx: {
                          height: "23px",
                          fontSize: "0.9rem"
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          // color: "#518eb9",
                          fontSize: "0.9rem",
                          // fontWeight: 1000,
                          "&.MuiOutlinedInput-notchedOutline": { fontSize: "0.9rem" }
                        }
                      }}
                    />)
                  }
                </>
              )
            }
          })
        }
        </>
      )
    })
  }

  const handleChangeNo = (e, idx) => {
    const { value } = e.target;
    const newSelectedGlasses = selectedGlasses.map((gglass, i) => (i === idx ? { ...gglass, no: value } : gglass));
    setSelectedGlasses(newSelectedGlasses);
  }

  const handleChangeAppliedArea = (e, idx) => {
    const { value } = e.target;
    const newSelectedGlasses = selectedGlasses.map((gglass, i) => (i === idx ? { ...gglass, appliedArea: value } : gglass));
    setSelectedGlasses(newSelectedGlasses);
  }

  const handleChangeAspect = (e, checked, idx) => {
    const newSelectedGlasses = selectedGlasses.map((gglass, i) => (i === idx ? { ...gglass, aspect: checked ? '3면시공' : '4면시공' } : gglass));
    newSelectedGlasses[idx].selectedProcessGClients = newSelectedGlasses[idx].selectedProcessGClients.map(process => ({ ...process, selectedGClients: [] }));

    setSelectedGlasses(newSelectedGlasses);

    const newModifiedGlassesIndexArray = [].concat(modifiedGlassesIndexArray);
    newModifiedGlassesIndexArray.splice(idx, 1, true);
    console.log(newModifiedGlassesIndexArray)
    setModifiedGlassesIndexArray(newModifiedGlassesIndexArray);
  }

  const generateGGlasses = () => {
    return (
      <>
        <div>
        {
          selectedGlasses?.map((selectedGlass, idx) => {
            const { id, no, gprojectId, gglassId, gtypeId, gtypeName, gtypeCode, selectedGcomponentItems, gtypeDetailsWithGComponent, appliedArea, aspect, specification, otherSpecs } = selectedGlass;
            console.log(gglassId)
            return (
              <Box sx={ selectedGlasses.length-1 === idx ? {} : { pb: 2, /*borderBottom: 1, borderColor: '#DCDCDC'*/ }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: 24,
                      height: 24,
                      fontSize: 12,
                      mr: 2,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {idx+1}
                  </Avatar>
                  
                  <IconButton
                    sx={{ color: theme.palette.grey[600] }}
                    size="small"
                    onClick={(e) => addGGlass(e, idx)}
                  >
                    <Add fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    sx={{ color: theme.palette.grey[600] }}
                    size="small"
                    onClick={(e) => removeGGlass(e, idx)}
                    disabled={selectedGlasses.length <= 1 ? true : false}
                  >
                    <Remove fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    sx={{ color: theme.palette.grey[600] }}
                    size="small"
                    onClick={(e) => handleGGlassUpDown('up', idx)}
                    disabled={idx > 0 ? false : true}
                  >
                    <KeyboardArrowUp fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    sx={{ color: theme.palette.grey[600] }}
                    size="small"
                    onClick={(e) => handleGGlassUpDown('down', idx)}
                    disabled={idx === selectedGlasses.length - 1 ? true : false}
                  >
                    <KeyboardArrowDown fontSize="inherit" />
                  </IconButton>
                  <FormInputText
                    control={control}
                    label={"품번"}
                    value={no}
                    sx={{ width: /*120*/80, ml: 1, mr: 1 }}
                    onChange={(e) => handleChangeNo(e, idx)}
                    inputProps={{
                      sx: {
                        height: "23px",
                        fontSize: "0.8rem"
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        // color: "#518eb9",
                        fontSize: "0.8rem",
                        // fontWeight: 1000,
                        "&.MuiOutlinedInput-notchedOutline": { fontSize: "0.8rem" },
                        "&.MuiInputLabel-outlined": { fontSize: "0.9rem" },
                      }
                    }}
                  />
                  <FormInputText
                    select
                    control={control}
                    label={"유리 템플릿"}
                    options={gglasses.map(gglass => {
                      return {
                        label: gglass.name,
                        value: gglass.id,
                      }
                    })}
                    onChange={(e) => handleChangeGGlass(e, idx)}
                    value={gglassId}
                    sx={{ width: /*240*/200, mr: 2/*, bgcolor: '#e3f2fd'*/ }}
                    inputProps={{
                      sx: {
                        // height: "14px",
                        fontSize: "0.9rem"
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        // color: "#518eb9",
                        fontSize: "0.9rem",
                        // fontWeight: 1000,
                        "&.MuiOutlinedInput-notchedOutline": { fontSize: "0.9rem" }
                      }
                    }}
                  />
                  { generateGGlassByRow(gtypeDetailsWithGComponent, selectedGcomponentItems, idx) }
                  <FormInputText
                    control={control}
                    label={"사양"}
                    value={specification}
                    sx={{ width: 200, ml: 1, display: 'none' }}
                  />
                  <FormInputText
                    control={control}
                    label={"적용부위"}
                    value={appliedArea}
                    sx={{ width: /*120*/300, ml: 1, mr: 1 }}
                    onChange={(e) => handleChangeAppliedArea(e, idx)}
                    inputProps={{
                      sx: {
                        height: "23px",
                        fontSize: "0.8rem"
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        // color: "#518eb9",
                        fontSize: "0.8rem",
                        // fontWeight: 1000,
                        "&.MuiOutlinedInput-notchedOutline": { fontSize: "0.8rem" },
                        "&.MuiInputLabel-outlined": { fontSize: "0.9rem" },
                      }
                    }}
                  />
                  {
                    (gtypeCode === 'IGU_TRIPLE' || gtypeName === '3중 복층유리') /* gtypeName 비교논리는 기존에 면시공 기능 추가되기전 3중 복층유리에도 적용하기 위해서임 */&& (
                      <Stack direction="row" display="flex" alignItems="center" sx={{ fontSize: "0.9rem", mt: 1.5 }}>
                        <FormInputCheckbox
                          control={control}
                          onChangeCheckValue={(e, checked) => handleChangeAspect(e, checked, idx)}
                          checked={aspect && aspect === '3면시공' ? true : false}
                        />
                        {"3면시공"}
                      </Stack>
                    )
                  }
                </Box>
              </Box>
            )
          })
        }
        </div>
      </>
    );
  }

  const handleClickCalcPerfData = () => {
    console.log('성능데이터 계산');
    calcPerfData();
  }

  const getDisplaySpecification = (glass) => {
    const formattedSpec = glass.specification.replaceAll("|", " ").replaceAll("+", " + ");
    // 성능데이터가 있고 이름이 다른 경우에만 성능데이터의 이름을 표시
    if (glass.performanceData?.name && glass.performanceData.name === formattedSpec) {
      return glass.performanceData.name;
    }
    return formattedSpec;
  };
  
  const generatePerformanceTable = () => {
    if (glassesLoading) {
      return (
        <></>
      );
    }
    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ width: '20px', fontWeight: 'bold', border, backgroundColor: headerBackgroundColor }} rowSpan={2}>
                <Checkbox
                  checked={selectedGlasses.length > 0 && selectedGlasses.every(glass => glass.checked)}
                  indeterminate={selectedGlasses.some(glass => glass.checked) && !selectedGlasses.every(glass => glass.checked)}
                  onChange={handleCheckAll}
                />
              </TableCell>
              <TableCell align="center" sx={{ width: '50px', border, backgroundColor: headerBackgroundColor }} rowSpan={2}>No.</TableCell>
              <TableCell align="center" sx={{ width: '120px', border, backgroundColor: headerBackgroundColor }} rowSpan={2}>품번</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }} rowSpan={2}>유리 사양</TableCell>
              <TableCell align="center" sx={{ width: '200px', border, backgroundColor: headerBackgroundColor }} rowSpan={2}>원판제조사</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }} colSpan={2}>가시광선(%)</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }} colSpan={2}>태양열선(%)</TableCell>
              <TableCell align="center" sx={{ width: '120px', border, backgroundColor: headerBackgroundColor }} rowSpan={2}>열관류율(KS)<br/>(W/m²K)</TableCell>
              <TableCell align="center" sx={{ width: '120px', border, backgroundColor: headerBackgroundColor }} rowSpan={2}>차폐계수<br/>(SC)</TableCell>
              <TableCell align="center" sx={{ width: '120px', border, backgroundColor: headerBackgroundColor }} rowSpan={2}>태양열취득률<br/>(SHGC)</TableCell>
              <TableCell align="center" sx={{ width: '120px', border, backgroundColor: headerBackgroundColor }} rowSpan={2}>취득총열량<br/>(W/m²)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" sx={{ width: '120px', border, backgroundColor: headerBackgroundColor }}>투과율</TableCell>
              <TableCell align="center" sx={{ width: '120px', border, backgroundColor: headerBackgroundColor }}>반사율(실외)</TableCell>
              <TableCell align="center" sx={{ width: '120px', border, backgroundColor: headerBackgroundColor }}>투과율</TableCell>
              <TableCell align="center" sx={{ width: '120px', border, backgroundColor: headerBackgroundColor }}>반사율(실외)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedGlasses.map((glass, index) => (
              <TableRow key={glass.id}>
                <TableCell align="center" sx={{ border }}>
                  <Checkbox
                    checked={Boolean(glass.checked)}
                    onChange={() => handleCheckItem(index)}
                  />
                </TableCell>
                <TableCell align="right" sx={{ border }}>{index + 1}</TableCell>
                <TableCell sx={{ border }}>{glass.no}</TableCell>
                {/* <TableCell sx={{ border }}>{glass.performanceData?.name || glass.specification.replaceAll("|", " ").replaceAll("+", " + ")}</TableCell> */}
                <TableCell sx={{ border }}>
                  {getDisplaySpecification(glass)}
                </TableCell>
                <TableCell sx={{ border }}>{glass.performanceData?.manufacturer?.name || '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.vlt ? glass.performanceData.vlt : '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.vlr_ex ? glass.performanceData.vlr_ex : '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.st ? glass.performanceData.st : '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.sr_ex ? glass.performanceData.sr_ex : '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.u_value_ks ? glass.performanceData.u_value_ks.toFixed(2) : '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.sc ? glass.performanceData.sc.toFixed(2) : '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.shgc ? glass.performanceData.shgc.toFixed(2) : '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.rhg ? glass.performanceData.rhg.toFixed(0) : '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // 체크박스 전체 선택/해제 핸들러
  const handleCheckAll = (event) => {
    const checked = event.target.checked;
    const newSelectedGlasses = selectedGlasses.map(glass => ({
      ...glass,
      checked: checked
    }));
    setSelectedGlasses(newSelectedGlasses);
  };

  // 개별 체크박스 핸들러
  const handleCheckItem = (index) => {
    const newSelectedGlasses = selectedGlasses.map((glass, i) => 
      i === index ? { ...glass, checked: !glass.checked } : glass
    );
    setSelectedGlasses(newSelectedGlasses);
  };

  // 발급 버튼용 성능 데이터 유효성 검사 함수
  const isPublishEnabled = () => {
    const checkedGlasses = selectedGlasses.filter(glass => glass.checked);
    if (checkedGlasses.length === 0) return false;
    
    return checkedGlasses.every(glass => 
      // 품번검사는 여기서 하지 않음. 하게되면 사용자에게 알리지 않으므로 품번을 안넣어서 그런지 알 수 없음
      // glass.no?.trim() && // 품번 검사
      // glass.specification?.trim() && // 사양 검사
      glass.performanceData && 
      glass.performanceData.vlt !== undefined &&
      glass.performanceData.vlr_ex !== undefined &&
      glass.performanceData.st !== undefined &&
      glass.performanceData.sr_ex !== undefined &&
      glass.performanceData.u_value_ks !== undefined &&
      glass.performanceData.sc !== undefined &&
      glass.performanceData.shgc !== undefined &&
      glass.performanceData.rhg !== undefined
    );
  };

  // 저장 버튼용 성능 데이터 유효성 검사 함수
  const isSaveEnabled = () => {
    return selectedGlasses.every(glass => 
      // glass.no?.trim() && // 품번 검사
      // glass.specification?.trim() && // 사양 검사
      glass.performanceData && 
      glass.performanceData.vlt !== undefined &&
      glass.performanceData.vlr_ex !== undefined &&
      glass.performanceData.st !== undefined &&
      glass.performanceData.sr_ex !== undefined &&
      glass.performanceData.u_value_ks !== undefined &&
      glass.performanceData.sc !== undefined &&
      glass.performanceData.shgc !== undefined &&
      glass.performanceData.rhg !== undefined
    );
  };

  const onSubmit = async ({ id, site, siteAddress, comments }, mode) => {
    // 유효성 검사 먼저 수행.
    // 단, 모든 데이터를 점검하지 않고 현장명, 현장주소, 품번만 체크한다.
    // 왜냐하면 그 전에 사양이나 성능확인데이터가 없으면 저장(발급)버튼이 비활성화되어 누를 수 없기 때문이다.
    const validationErrors = [];
    
    // 필수 입력 필드 검사
    if (!site?.trim()) {
      validationErrors.push(<><span style={{ color: "#1976d2" }}>{"현장명"}</span>을 입력해주세요.</>);
    }
    if (!siteAddress?.trim()) {
      validationErrors.push(<><span style={{ color: "#1976d2" }}>{"현장주소"}</span>를 입력해주세요.</>);
    }

    // 유리 데이터 검사
    selectedGlasses.forEach((glass, index) => {
      if (!glass.no?.trim()) {
        validationErrors.push(<>{index + 1}번 유리의 <span style={{ color: "#1976d2" }}>품번</span>를 입력해주세요.</>);
      }
    });

    // 유효성 검사 실패 시 처리
    if (validationErrors.length > 0) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: (
          <div>
            {validationErrors.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </div>
        ),
        open: true,
      });
      return;
    }

    const newSelectedGlasses = selectedGlasses.map(glass => {
      const { checked, no, specification, appliedArea, aspect, gglassId, gtypeId, selectedGcomponentItems, comments, performanceData } = glass;
      let newPerformanceData = null;
      if (performanceData) {
        const { name, vlt, vlr_ex, st, sr_ex, u_value_ks, sc, shgc, rhg, vlr_in, vlr, sr, manufacturer } = performanceData;
        newPerformanceData = {
          name,
          vlt,
          vlr_ex,
          st,
          sr_ex,
          u_value_ks,
          sc,
          shgc,
          rhg,
          vlr_in,
          vlr,
          sr,
          manufacturer,
        };
      }
      
      return {
        checked,
        no,
        specification,
        appliedArea,
        aspect,
        gglassId,
        gtypeId,
        selectedGcomponentItems,
        comments,
        performanceData: newPerformanceData,
      };
    });

    console.log({
      gprojectId: selectedRow?.id,
      gclientId: sessionUser.id,
      site,
      siteAddress,
      comments,
      selectedGlasses: newSelectedGlasses, mode,
    })

    // TODO : 유리 및 성능확인데이터 유효성 체크 (API 필수입력) 필요
    
    // return;

    let func;
    let params = {
      gclientId: sessionUser.id,
      site,
      siteAddress,
      comments,
      selectedGlasses : newSelectedGlasses,
      pdfYn: mode === 'saveAndPublish' ? true : false,
    };
    if (modify) {
      func = modifyGPerformReport;
      params.id = selectedRow.id;

    } else {
      func = addGPerformReport;
    }

    if (mode === 'save') {
      setLoading(true);
    } else if (mode === 'saveAndPublish') {
      setLoadingPublish(true);
    }

    try {
      const res = await func(params);
      if (mode === 'save') {
        setTimeout(() => {
          alert("저장되었습니다.");
          setLoading(false);
        }, 1000);
      } else if (mode === 'saveAndPublish') {
        setTimeout(() => {
          alert("발급되었습니다.");
          setLoadingPublish(false);
        }, 1000);
      }

      setModify(true);
      setHasChanges(true);  // 저장/발급 성공 시 변경사항 있음으로 설정
      
      // add 이후 첫 modify 시에만 gprojectId 추가
      if (modify && !selectedRow?.id) {
        const gprojectId = res.gproject.id;
        newSelectedGlasses.forEach(glass => {
          glass.gprojectId = gprojectId;
        });
        setSelectedGlasses(newSelectedGlasses);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        fullScreen={true}
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
        scroll="body"
        disableEscapeKeyDown={true}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          fullScreen={true}
          color="white"
          style={{ backgroundColor: "#1976d2" }}
        >
          <div id="dialog-position">
            {"성능확인서 발급"}
          </div>
        </DialogTitleClose>
        <DialogContent>
          <Card sx={{ mt: 3, p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  {
                    sessionUser.type === 'ADMIN' && (
                      <FormInputText
                        name={"gclientName"}
                        control={control}
                        label={"의뢰처"}
                        sx={{ width: { /*xs: 330, */sm: 330 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                        disabled={true}
                        value={selectedRow?.gclientName || sessionUser.name}
                      />
                    )
                  }
                  <FormInputText
                    name={"site"}
                    control={control}
                    label={"현장명"}
                    sx={{ width: { /*xs: 330, */sm: 330 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                  />
                  <FormInputText
                    name={"siteAddress"}
                    control={control}
                    label={"현장주소"}
                    sx={{ width: { /*xs: 330, */sm: 508 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  <FormInputText
                    name={"comments"}
                    control={control}
                    label={"설명"}
                    sx={{ width: { /*xs: 330, */sm: 846 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                  />
                </Stack>
              </Grid>
            </Grid>
          </Card>
          <Card sx={{ p: 2, mt: 2 }}>
            <Grid container spacing={2}>
              {
                glassesLoading ? (  
                  <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px' }}>
                    {/* <CircularProgress size={40} /> */}
                    <LinearProgress sx={{ width: '100%', height: 4 }} /> {/* CircularProgress를 LinearProgress로 변경하고 스타일 적용 */}
                  </Grid>
                ) : (
                  <>
                    <Grid item sm={12}>
                      {generateGGlasses()}
                    </Grid>
                    <Grid item display="flex" justifyContent="flex-end" sm={12}>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleClickCalcPerfData} 
                        startIcon={<Calculate />}
                        sx={{ mr: 1 }}
                      > 
                        성능데이터 계산
                      </Button>
                      <LoadingButton 
                        variant="contained" 
                        color="primary" 
                        onClick={() => handleSubmit(onSubmit)('save')}
                        startIcon={<Save />}
                        loading={loading}
                        loadingPosition="start"
                        disabled={!isSaveEnabled()} // 저장 버튼용 유효성 검사 함수 사용
                        sx={{ mr: 1 }}
                      > 
                        저장
                      </LoadingButton>
                      <LoadingButton 
                        variant="contained" 
                        color="primary" 
                        onClick={() => handleSubmit(onSubmit)('saveAndPublish')}
                        startIcon={<NoteAdd />}
                        loading={loadingPublish}
                        loadingPosition="start"
                        disabled={!isPublishEnabled()} // 발급 버튼용 유효성 검사 함수 사용
                      >
                        발급
                      </LoadingButton>
                    </Grid>
                    <Grid item sm={12}>
                      {generatePerformanceTable()}
                    </Grid>
                  </>
                )
              }
            </Grid>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
    </ThemeProvider>
  );
};

export default GPerformanceDataDialog;
