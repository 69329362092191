import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Add } from "@mui/icons-material";

import {
  FormInputText,
  FormInputDate,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import { CustomNoRowsOverlay,} from "../datagrid";
import { AlertDialog } from "../dialog";
import * as gclientActions from "../../store/gclient";
import dayjs from 'dayjs'; 


const defaultValues = {
  gclientId: "",
  docType: "",
};
const docTypeMap = {
  'GEN': '일반서류',
  'CERT': '인증서',
  'TEST': '성적서',
  'ETC': '기타'
};
const GClientDocumentDialog = ({
  open,
  setOpen,
  params,
}) => {

  const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues });
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [loaded, setLoaded] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [alertInfo, setAlertInfo] = useState({ open: false, titleAlert: '오류', messageAlert: '' });

  const [docType, setDocType] = useState(null); // docType를 상태로 관리

  const handleDialogClose = () => {
    setOpen(false);    
  };

  const initDialog = () => {
    
    if (params?.row) {
      console.log('initDialog => ', params);
      setValue("gclientId", params.row.gclientId);
      searchDocuments();
    }
  }

  useEffect(() => {
    if (open) {
      initDialog();      
    }
  }, [open, params]);


  const columns = [
    { field: 'docType', headerName: '문서종류', width: 100, 
        valueFormatter: (params) => {
          return docTypeMap[params.value] || params.value || '';
        }
    },
    { field: 'docName', headerName: '문서명', flex: 1, minWidth: 350 },
    { field: 'documentPath', headerName: '등록유무', width: 80, align: 'center',
      valueFormatter: (params) => {
        return params.value ? '등록' : '미등록';
      }
    },
    { field: 'endDate', headerName: '만료일', width: 120, 
      valueFormatter: (params) => {
        return params.value ? dayjs(params.value).format('YYYY-MM-DD') : '';
      }
    },
  ]; 

  const handleChangeType = (e) => {
    const { value } = e.target;
    setValue("docType", value); // onChange 이벤트가 추가되면 반드시 setValue 해줘야 한다.

    searchDocuments();
  }

  const searchDocuments = async () => {

    try {
      console.log("gclientId:", getValues("gclientId"), "; docType:", getValues("docType"));
      const response = await gclientActions.selectGClientDocumentsDetail(getValues("gclientId"), getValues("docType"));
      console.log("response:", response);
      const rowsWithId = response.map((row, index) => ({
        ...row,
        _id: `${row.gclientId}_${row.docType}_${row.docName}_${index}`  // 고유 ID 생성
      }));
      setRows(rowsWithId);
    } catch (error) {
      console.error("문서 조회 중 오류 발생:", error);
      // 에러 처리 - 예: 에러 메시지 표시
      setRows([]);  // 에러 발생 시 빈 배열로 초기화
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose} 
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="md"
        fullWidth
        scroll="body"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          {params?.row ? `${params.row.gclientName} 문서 현황` : "문서 현황"}
        </DialogTitleClose>
        <DialogContent dividers>
          <Box sx={{ mt: 0 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <FormInputText
                  select
                  name="docType"
                  control={control}
                  label={"문서종류"}
                  sx={{ width: 300 }}
                  options={
                    [
                      { label: '전체', value: '' },
                      ...Object.entries(docTypeMap).map(([value, label]) => ({
                        label,
                        value
                      }))
                    ]
                  }
                  onChange={handleChangeType}
                />
              </Grid>
              <Grid item xs={12} sm={6} container justifyContent="flex-end">
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={searchDocuments}
                >
                  {"검색"}
                </Button>
              </Grid>
            </Grid>
            <div style={{ width: '100%', height: 400, marginTop: '20px'}}>
              <DataGridPro
                localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                initialState={{ pinnedColumns: { right: ['actions'] } }}
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                  loadingOverlay: LinearProgress,
                }}
                columnHeaderHeight={38}
                rowHeight={34}
                getRowId={(row) => row._id}
                rows={rows}
                loading={!loaded}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 50, 100]}
                pagination
              />
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />

    </>
  );
};

export default GClientDocumentDialog;
