import React from 'react';
import { Route } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import { GProjectManagement } from "../components/GProject";
import { GSupplyConfirmManagement } from "../components/GProject";
import { G04docuMaterialApprovalManagement } from "../components/G04docuMaterialApproval";

// 납품확인서 관리
const SupplyConfirmRoute = ({sessionUser}) => {

    return (
        <>
            {/* 요청하기 */}
            <Route path="/gsupplyConfirmRequest" element={
                <RequireAuth sessionUser={sessionUser}>
                    <GProjectManagement />
                </RequireAuth>
            } />
            {/* 요청목록 */}
            <Route path="/gsupplyConfirmRequestList" element={
                <RequireAuth sessionUser={sessionUser}>
                    <GSupplyConfirmManagement />
                </RequireAuth>
            } />
            {/* 발행하기 */}
            <Route path="/gsupplyConfirmPublish" element={
                <RequireAuth sessionUser={sessionUser}>
                    <GSupplyConfirmManagement />
                </RequireAuth>
            } />
            {/* 발행목록 */}
            <Route path="/gsupplyConfirmPublishList" element={
                <RequireAuth sessionUser={sessionUser}>
                    <GSupplyConfirmManagement />
                </RequireAuth>
            } />
            {/* 발급하기 */}
            <Route path="/gsupplyConfirmGenerateFile" element={
                <RequireAuth sessionUser={sessionUser}>
                    <GProjectManagement />
                </RequireAuth>
            } />
            {/* 발급목록 */}
            <Route path="/gsupplyConfirmGenerateFileList" element={
                <RequireAuth sessionUser={sessionUser}>
                    <G04docuMaterialApprovalManagement />
                </RequireAuth>
            } />
        </>
    );
};

export default SupplyConfirmRoute;

