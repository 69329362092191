import { format, parse, endOfMonth, startOfMonth, isValid, addMonths } from 'date-fns';

/**
 * 전월, 당월, 익월에 대한 옵션을 반환하는 함수
 * @returns {Array<{value: string, label: string}>} 날짜 옵션 배열
 * @example
 * // 현재가 2023년 11월인 경우
 * // [
 * //   { value: '2023-10', label: '전월(10월)' },
 * //   { value: '2023-11', label: '당월(11월)' },
 * //   { value: '2023-12', label: '익월(12월)' }
 * // ]
 */
export const getServiceDateOptions = () => {
  const today = new Date();
  const currentMonth = today.getMonth(); // 0-11

  // 전월, 당월, 익월 날짜 생성
  const prevMonth = new Date(today.getFullYear(), currentMonth - 1);
  const currentMonthDate = new Date(today.getFullYear(), currentMonth); 
  const nextMonth = new Date(today.getFullYear(), currentMonth + 1);

  return [
    {
      value: format(prevMonth, 'yyyy-MM'),
      label: `전월(${format(prevMonth, 'M')}월)`
    },
    {
      value: format(currentMonthDate, 'yyyy-MM'), 
      label: `당월(${format(currentMonthDate, 'M')}월)`
    },
    {
      value: format(nextMonth, 'yyyy-MM'),
      label: `익월(${format(nextMonth, 'M')}월)`
    }
  ];
};

/**
 * 특정 날짜의 전월, 당월, 익월에 대한 옵션을 반환하는 함수
 * @param {Date} baseDate - 기준 날짜
 * @returns {Array<{value: string, label: string}>} 날짜 옵션 배열
 */
export const getServiceDateOptionsFromDate = (baseDate) => {
// baseDate가 빈 문자열이면 전체만 반환
// if (baseDate === '') {
//   return [
//     {
//       value: "",
//       label: "전체"
//      }
//    ];
// }

  // baseDate가 문자열인 경우 Date 객체로 변환
  let date;
  if (typeof baseDate === 'string' && baseDate) {
    try {
      date = parse(baseDate, 'yyyy-MM', new Date());
    } catch (error) {
      console.warn('Invalid date string:', baseDate);
      date = new Date();
    }
  } else {
    date = baseDate || new Date();
  }

  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  const prevMonth = new Date(currentYear, currentMonth - 1);
  const currentMonthDate = new Date(currentYear, currentMonth);
  const nextMonth = new Date(currentYear, currentMonth + 1);

  return [
    // {
    //   value: "",  // 전체 옵션 추가
    //   label: "전체"
    // },
    {
      value: format(prevMonth, 'yyyy-MM'),
      label: `전월(${format(prevMonth, 'M')}월)`
    },
    {
      value: format(currentMonthDate, 'yyyy-MM'),
      label: `당월(${format(currentMonthDate, 'M')}월)`
    },
    {
      value: format(nextMonth, 'yyyy-MM'),
      label: `익월(${format(nextMonth, 'M')}월)`
    }
  ];
};

/**
 * 서비스 월에 대한 과금 시작일과 종료일을 반환하는 함수
 * @param {string} serviceMonth - 'YYYY-MM' 형식의 서비스 월
 * @returns {{startPaidEndDate: string, endPaidEndDate: string}} 과금 시작일과 종료일
 * @example
* // getPaidEndDateRange('2024-11')
* // returns {
* //   startPaidEndDate: '2024-11-01 00:00:00',
* //   endPaidEndDate: '2024-11-30 23:59:59'
* // }
* // getPaidEndDateRange('2024-11', 'M')
* // returns {
* //   startPaidEndDate: '2024-11-01 00:00:00',
* //   endPaidEndDate: '2024-12-01 00:00:00'
* // }
 */
export const getPaidEndDateRange = (serviceMonth, option) => {
  try {
    // 전체 선택인 경우 (빈 문자열)
    if (serviceMonth === '') {
      return {
        startPaidEndDate: null,
        endPaidEndDate: null
      };
    }

    // serviceMonth가 유효한 형식인지 검사
    if (!serviceMonth || typeof serviceMonth !== 'string' || !serviceMonth.match(/^\d{4}-\d{2}$/)) {
      console.warn('Invalid service month format. Expected: YYYY-MM, Received:', serviceMonth);
      return {
        startPaidEndDate: null,
        endPaidEndDate: null
      };
    }

    // serviceMonth를 Date 객체로 파싱
    const [year, month] = serviceMonth.split('-').map(Number);
    const serviceDate = new Date(year, month - 1); // month는 0-based

    // 유효한 날짜인지 확인
    if (!isValid(serviceDate)) {
      console.warn('Invalid date created from service month:', serviceMonth);
      return {
        startPaidEndDate: null,
        endPaidEndDate: null
      };
    }

    // 시작일: 해당 월의 1일
    const startDate = startOfMonth(serviceDate);
    
    // 종료일: 옵션에 따라 다르게 설정
   let endDate;
   if (option === 'M') {
     // 다음 달 1일 00:00:00
     endDate = startOfMonth(addMonths(serviceDate, 1));
     return {
       startPaidEndDate: format(startDate, 'yyyy-MM-dd HH:mm:ss'),
       endPaidEndDate: format(endDate, 'yyyy-MM-dd HH:mm:ss')
     };
   } else {
     // 해당 월 말일 23:59:59
     endDate = endOfMonth(serviceDate);
     return {
       startPaidEndDate: format(startDate, 'yyyy-MM-dd HH:mm:ss'),
       endPaidEndDate: format(endDate, 'yyyy-MM-dd 23:59:59')
     };
   }
  } catch (error) {
    console.error('Error in getPaidEndDateRange:', error);
    return {
      startPaidEndDate: null,
      endPaidEndDate: null
    };
  }
};

/**
* 날짜를 지정된 형식으로 포맷팅하는 함수
* @param {string|Date} date - 포맷팅할 날짜
* @param {string} formatStr - 포맷 문자열 (기본값: 'yyyy-MM-dd')
* @returns {string} 포맷팅된 날짜 문자열
* @example
* // returns '2024-01-01'
* dateFormat('2024-01-01T00:00:00')
* // returns '2024-01'
* dateFormat('2024-01-01T00:00:00', 'yyyy-MM')
*/
export const dateFormat = (date, formatStr = 'yyyy-MM-dd') => {
 if (!date) return '';
 try {
   const dateObj = typeof date === 'string' ? new Date(date) : date;
   if (!isValid(dateObj)) return '';
   return format(dateObj, formatStr);
 } catch (error) {
   console.error('Date formatting error:', error);
   return '';
 }
};
