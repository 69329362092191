export const PARTNERSHIP_TYPES = [
    '이사회원사',
    '명예회원사',
    '특별회원사',
    '일반회원사',
    '비회원사'
];

export const MEMBERSHIP_TYPES = ['DEFAULT', 'OPTION01'];

export const PARTNERSHIP_TYPE = {
    이사회원사: PARTNERSHIP_TYPES[0],
    명예회원사: PARTNERSHIP_TYPES[1],
    특별회원사: PARTNERSHIP_TYPES[2],
    일반회원사: PARTNERSHIP_TYPES[3],
    비회원사: PARTNERSHIP_TYPES[4]
};

export const MEMBERSHIP_TYPE = {
    DEFAULT: MEMBERSHIP_TYPES[0],
    OPTION01: MEMBERSHIP_TYPES[1]
};

export const PARTNERSHIP_TYPE_LABELS = {
    이사회원사: '이사회원사',
    명예회원사: '명예회원사',
    특별회원사: '특별회원사',
    일반회원사: '일반회원사',
    비회원사: '비회원사'
};

export const MEMBERSHIP_TYPE_LABELS = {
    DEFAULT: '자재승인서',
    OPTION01: '자재승인서+납품확인서'
};

export const getMembershipTypeOptions = () => 
    MEMBERSHIP_TYPES.map(type => ({
        label: MEMBERSHIP_TYPE_LABELS[type] || type,
        value: type
    }));

export const getPartnershipTypeOptions = () => 
    PARTNERSHIP_TYPES.map(type => ({
        label: PARTNERSHIP_TYPE_LABELS[type] || type,
        value: type
    }));
