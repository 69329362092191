import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGridPro, koKR } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
} from '@mui/material';
import {
  CustomNoRowsOverlay,
} from "../datagrid";
import * as securityActions from "../../store/security";
import * as securityRequestActions from "../../store/securityRequest";

const theme = createTheme();

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const defaultValues = {
  gclient: {},
};

const SecurityRequestApproval = () => {
  const [loadedApproval, setLoadedApproval] = useState(false);
  const [pageSizeApproval, setPageSizeApproval] = useState(100);
  const [pageApproval, setPageApproval] = useState(0);
  const [selectionModelApproval, setSelectionModelApproval] = useState([]);
  const [selectedRowsApproval, setSelectedRowsApproval] = useState([]);

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const rowsToApproval = useSelector((state) => state.securityRequest.securityToApprovals);

  const dispatch = useDispatch();
  
  const selecteSecurityToApprovalsByQuery = (receiverId) => dispatch(securityRequestActions.selecteSecurityToApprovalsByQuery(receiverId))
  const modifySecurityRequestStatus = ({ id, status }) => dispatch(securityRequestActions.modifyStatus({ id, status }))
  const addSecurityOpenGClientList = ({ service, gclientId, list }) => dispatch(securityActions.addSecurityOpenGClientList({ service, gclientId, list }))

  const columnsApproval = [
    {
      field: 'funcions',
      headerName: '상태/기능',
      width: 140,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { status } = params.row;
        if (status) {
          if (status === '요청') {
            return (
              <>
                <Button
                  onClick={(e) => handleClickApproval(e, params)}
                  sx={{ borderRadius: 30, maxWidth: '60px', maxHeight: '60px', minWidth: '60px', minHeight: '60px', backgroundImage: 'url("/approve.png")', backgroundSize: "60px 60px" }}
                >             
                </Button>
                <Button
                  onClick={(e) => handleClickDisaproval(e, params)}
                  sx={{ borderRadius: 30, maxWidth: '60px', maxHeight: '60px', minWidth: '60px', minHeight: '60px', backgroundImage: 'url("/disaprove.png")', backgroundSize: "60px 60px", ml: 1 }}
                >             
                </Button>
              </>
            )
          } else if (status === '비공개') {
            return (
              <Button
                onClick={(e) => handleClickApproval(e, params)}
                sx={{ borderRadius: 30, maxWidth: '60px', maxHeight: '60px', minWidth: '60px', minHeight: '60px', backgroundImage: 'url("/approve.png")', backgroundSize: "60px 60px" }}
              >             
              </Button>
            )
          }
        }
      },
    },
    {
      field: 'bizRegNumber',
      headerName: '사업자등록번호',
      width: 160,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'name',
      headerName: '거래선명',
      width: 240,
    },
    {
      field: 'ceo',
      headerName: '대표자',
      width: 240,
    },
    {
      field: 'phone',
      headerName: '대표전화번호',
      width: 160,
    },
    {
      field: 'email',
      headerName: '회사대표메일',
      width: 200,
    },
    {
      field: 'address',
      headerName: '주소',
      width: 240,
    },
  ];

  useEffect(
    async () => {
      selectToApprovals();
    }, [dispatch]
  );

  const handleClickApproval = async (e, params) => {
    e.stopPropagation();

    // console.log(params.row)
    await addSecurityOpenGClientList({
      service: '04docu',
      gclientId: sessionUser.id,
      list: [{ ...params.row, id: params.row.gclientId }]
    });

    selectToApprovals();
  }

  const handleClickDisaproval = async (e, params) => {
    e.stopPropagation();

    const { id } = params.row;
    await modifySecurityRequestStatus({ id, status: '비공개' });

    selectToApprovals();
  }

  const handleClickApprovalSelected = async () => {
    // console.log(selectedRowsApproval)
    await addSecurityOpenGClientList({
      service: '04docu',
      gclientId: sessionUser.id,
      list: selectedRowsApproval.map(row => ({ ...row, id: row.gclientId }),
    )});

    selectToApprovals();
  }

  const selectToApprovals = async () => {
    await selecteSecurityToApprovalsByQuery(sessionUser.id);
    
    setTimeout(() => setLoadedApproval(true), 300);
  }
  
  const handlePageChangeApproval = (newPage) => {
    setPageApproval(newPage);
  }

  return (
    <Paper sx={{ p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
            <Grid container>
              <Grid item xs={6} display="flex" alignItems="center">
                {"열람승인"}
              </Grid>
              <Grid item xs={6} display="flex" justifyContent={"flex-end"} alignItems="center">
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleClickApprovalSelected}
                  disabled={selectionModelApproval.length === 0}
                >
                  {"승인"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: 400, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              // density="standard"
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              headerHeight={64}
              rowHeight={64}
              loading={!loadedApproval}
              rows={rowsToApproval}
              columns={columnsApproval}
              pageSize={pageSizeApproval}
              onPageSizeChange={(newPageSize) => setPageSizeApproval(newPageSize)}
              rowsPerPageOptions={[1, 10, 20, 50, 100]}
              onPageChange={handlePageChangeApproval}
              page={pageApproval}
              pagination
              disableColumnMenu
              checkboxSelection
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                setSelectionModelApproval(ids);
                
                const selected = rowsToApproval.filter((row) => selectedIDs.has(row.id));
                setSelectedRowsApproval(selected);
              }}
              selectionModel={selectionModelApproval}
            />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SecurityRequestApproval;