import React, { useState, useRef, useEffect } from 'react';
import { useForm } from "react-hook-form";
import * as sessionActions from "../../store/session";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useSearchParams, Link as RouterLink } from "react-router-dom";
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Typography,
  Button,
  Link,
} from '@mui/material';
import { Home } from '@mui/icons-material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const theme = createTheme();

const StyledGClientLoginButton = styled((props) => (
  <Button {...props}/>
))(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#25529E',
  },
}));

const StyledGUserLoginButton = styled((props) => (
  <Button {...props}/>
))(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#E83E35',
  },
}));

const GLogin = () => {

  const location = useLocation();

  const sessionUser = useSelector((state) => state.session.sessionUser);

  // 로그인되어 있다면 이 페이지가 뜨지 않고 root로 이동시킴
  if (sessionUser) {
    console.log("LoginFormPage/index sessionUser exists.");
    console.log(location);
    return <Navigate to={location.state && location.state.from ? `${location.state.from}` : "/glass/dashboard"} />; // TODO : state 필요없는지 확인 필요
  } else {
    console.log("LoginFormPage/index no sessionUser exists.");
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            minHeight: 'calc(100vh - 282px)',  // 헤더 높이를 제외한 전체 높이
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',  // 수직 중앙 정렬
            //py: 4  // 상하 패딩
          }}
        >
          <Box sx={{ mt: 20, mb: 20 }}>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} display="flex" justifyContent={"center"} alignItems={"center"}>
                <Box sx={{ width: '320px', height: '200px' }} >
                  <StyledGClientLoginButton component={RouterLink} to="/glass/signin/gclient" variant="contained" sx={{ width: '100%', height: '100%', fontSize: '1.4rem', fontWeight: 'bold', bgcolor: '#3372B7' }}>
                    {"회원사 관리자 로그인"}
                  </StyledGClientLoginButton>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} display="flex" justifyContent={"center"} alignItems={"center"}>
                <Box sx={{ width: '320px', height: '200px' }} >
                  <StyledGUserLoginButton component={RouterLink} to="/glass/signin/guser" variant="contained" sx={{ width: '100%', height: '100%', fontSize: '1.4rem', fontWeight: 'bold', bgcolor: '#F59036' }}>
                    {"회원사 사용자 로그인"}
                  </StyledGUserLoginButton>
                </Box>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent={"center"} alignItems={"center"}>
                <Link href={"/"}><Button sx={{ mt: 3, mb: 2, fontSize: "1.2rem" }} startIcon={<Home />}>{"홈으로"}</Button></Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default GLogin;