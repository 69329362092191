import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR, GridToolbar  } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  CssBaseline,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from '@mui/material';
import  {
  Add,
  ContentPaste,
  Delete,
  Edit,
  Search,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
  DragIndicator,
} from '@mui/icons-material';
import {
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  FormInputAutoComplete,
  FormInputText
} from "../form";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import * as gcomponentItemActions from "../../store/gcomponentItem";
import GComponentItemDialog from "./GComponentItemDialog";
import { GClientManagement } from "../GClient";
import { getMaterialItemTypeOptions, MATERIAL_ITEM_TYPE, getPreferenceItemTypeOptions, PREFERENCE_ITEM_TYPE } from '../../constants/itemTypes';
import { getGridHeight } from '../../constants/gridHeights';

const theme = createTheme();

const defaultValues = {
  itemType: "",
  itemTypeList: "",
};

const GComponentItemManagement = () => {
  const [crudMode, setCrudMode] = useState('');
  // const [paste, setPaste] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(true);
  // const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [pageSize, setPageSize] = useState(100);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selManufacturers, setSelManufacturers] = useState([]);
  const [rows, setRows] = useState([]);

  // itemTypeOptions 상태 추가
  const [itemTypeOptions, setItemTypeOptions] = useState([]);
  const [itemTypeListOptions, setItemTypeListOptions] = useState([]);
  
  const [selectedItemType, setSelectedItemType] = useState('');
  const [selectedItemTypeListId, setSelectedItemTypeListId] = useState('');

  const [applyTypeToggleState, setApplyTypeToggleState] = useState({});
  const [madeByToggleState, setMadeByToggleState] = useState({});
  const [validSwitchState, setValidSwitchState] = useState({});

  const { control, setValue } = useForm({ defaultValues: defaultValues });

  // 현재 화면에 맞는 Grid 높이 계산
  const GRID_HEIGHT = getGridHeight('ONE_LINE_LAYER', -17); // 0px 추가 여백

  const handleSelect = async ({ type, params }) => {
    const { id } = params;

    setOpenBackdrop(true);

    if (type === "detail") {
      // await select(id);
      await selectByQuery(id)
      setCrudMode('R')
      // setPaste(false);
      setOpen(true);
    } else if (type === "edit") {
      // TODO : await 재검토 필요. 대부분 await 가 필요
      // await select(id);
      await selectByQuery(id)
      setCrudMode('U')
      // setPaste(false);
      setOpen(true);
    } else if (type === "paste") {
      // await select(id);
      await selectByQuery(id)
      setCrudMode('C')
      // setPaste(true);
      setOpen(true);
    } else if (type === "delete") {
      setRemoveId(id);
      setParams(params);
      setConfirmOpen(true);
    }

    setOpenBackdrop(false);
  }

  const remove = (removeId) => {
    dispatch(gcomponentItemActions.remove(removeId))
    //.then(res => selectAllByQuery())
    .then(res => refreshGrid())
    .catch(async (res) => {
      const data = await res.json();
      if (data && data.errors) setErrors(data.errors);
    });
  }

  // 유리사양적용 변경
  const handleChangeToggleApplyType = async ({ applyType, params, e }) => {
    e.stopPropagation();
    const { id } = params;

    setLoaded(false);

    try {
      await modifyGComponentItemApplyType({ id, applyType });
      setApplyTypeToggleState(prev => ({ ...prev, [id]: applyType }));
    } catch (error) {
      console.error("Error updating ApplyType:", error.serverResponse.message, error);
    } finally {
      setLoaded(true);
    }
  };

  // ON/OFF 변경
  const handleChangeToggleValid = async ({ params, e }) => {
    e.stopPropagation();
    const { id } = params;
    const valid = e.target.checked;
    
    setLoaded(false);

    try {
      await modifyGComponentItemValid({ id, valid });
      setValidSwitchState(prev => ({ ...prev, [id]: valid }));
    } catch (error) {
      console.error("Error updating ON/OFF:", error.serverResponse.message, error);
      // 실패 시 원래 상태로 되돌리기
      //setValidSwitchState(prev => ({ ...prev, [id]: !valid }));
    } finally {
      setLoaded(true);
    }
  }

  // 자재구분 변경 (NOT USED)
  const handleChangeToggleItemType = async ({ itemType, params, e }) => {
    e.stopPropagation();

    await modifyGComponentItemItemType({ id: params.id, itemType })

    setLoaded(false);
    await selectAllByQuery();
    setTimeout(() => setLoaded(true), 300);
  }

  // 생산지구분 변경
  const handleChangeToggleMadeBy = async ({ madeBy, params, e }) => {
    e.stopPropagation();
    const { id } = params;

    setLoaded(false);

    await modifyGComponentItemMadeBy({ id: id, madeBy })

    setMadeByToggleState(prev => ({ ...prev, [id]: madeBy }));

    setLoaded(true);
    //await selectAllByQuery();
    //setTimeout(() => setLoaded(true), 300);
  }

  // 검색버튼
  const handleClickSearch = async () => {
    refreshGrid();
  }

  // 저장/수정 다이얼로그
  const handleClickOpen = () => {
    // initGComponentItem();
    setCrudMode('C');
    // setPaste(false);
    setOpen(true);
  };


  const handleViewManufacturers = (manufacturers) => {
    setSelManufacturers(manufacturers);
    console.log(manufacturers);
    setDrawerOpen(true);
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelect({ type: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ContentPaste />}
        label={"복사하여 등록"}
        onClick={() => handleSelect({ type: 'paste', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
    ];

    return arrActions;
  }

  const columns = [
    // {
    //   field: 'id',
    //   headerName: '아이디',
    //   width: 300,
    //   hide: true,
    // },
    {
      field: 'name',
      headerName: '이름',
      width: 200,
      // editable: true,
    },
    {
      field: 'code',
      headerName: '코드',
      width: 200,
      // editable: true,
    },
    {
      // field: 'gcomponentName',
      field: 'gname',
      headerName: '분류',
      width: 150,
      // editable: true,
    },
    {
      field: 'applyType',
      headerName: '유리사양적용',
      width: 180,
      // hide: true,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
          /**
           * 2023-05-10 : GComponentItems의 type을 GComponents로 옮길 계획이었으나 아래의 이유로 철회
           * 같은 속성(GComponents에서의 type이 property. 하위에 GComponentItems를 가지고 있음)이더라도 적용범위(이름, 비고 등)가 다를 수 있음
           * 예)
           * "표면가공" 속성에는 Sand Blast, Frit, Etching, Back Paint, Print, 모따기, Hole Ø : 6 mm 등이 있을 수 있는데
           * Sand Blast ~ Print 까지는 유리사양 중 "이름", 모따기, Hole Ø : 6 mm 등은 "비고"로 같은 속성이더라도 다를 수 있음
           */
          <ToggleButtonGroup
            color="primary"
            value={applyTypeToggleState[params.id] ?? params.value}
            exclusive
            onChange={(e, newValue) => handleChangeToggleApplyType({ applyType: newValue, params, e })}
            size={"small"}
          >
            <ToggleButton value="productName" sx={{ fontSize: '0.7rem', fontWeight: 'bold', width: 40, height: 20, borderRadius: 0 }} >{"이름"}</ToggleButton>
            <ToggleButton value="etc" sx={{ fontSize: '0.7rem', fontWeight: 'bold', width: 40, height: 20, borderRadius: 0 }} >{"비고"}</ToggleButton>
            <ToggleButton value="none" sx={{ fontSize: '0.7rem', fontWeight: 'bold', width: 40, height: 20, borderRadius: 0 }} >{"안함"}</ToggleButton>
          </ToggleButtonGroup>
      ),
    },
    {
      field: 'dependentGcomponentItem',
      headerName: '의존성',
      width: 200,
      // editable: true,
      valueGetter: (params) => {
        if (params.value) {
          return JSON.parse(params.value).name;
        }

        return "";
      },
    },
    {
      field: 'g04docu',
      // headerName: '규격',
      renderHeader: () => (<Stack direction="row" spacing={1} alignItems="center">
        <Chip label={"표준번호"} size="small" sx={{ mr: 1 }}></Chip><span>{"인증규격명"}</span>
      </Stack>),
      width: 300,
      // editable: true,
      // valueGetter: (params) => {
      //   if (params.row.g04docuName && params.row.gstandardNo) {
      //     return `${params.row.g04docuName} ( ${params.row.gstandardNo} )`
      //   }

      //   return "";
      // },
      renderCell: (params) => {
        if (params.row.g04docuGCertificationName && params.row.gstandardNo) {
          return <><Chip label={params.row.gstandardNo} size="small" sx={{ mr: 1 }}></Chip>{params.row.g04docuGCertificationName}</>;
        } else {
          return "";
        }
      }
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    {
      field: 'valid',
      headerName: 'ON/OFF',
      headerAlign: 'center',
      align: 'center',
      width: 120,
      renderCell: (params) => (
          <Switch
            // defaultChecked를 사용하면 목록에서의 변경이 아닌 수정다이얼로그에서 변경 후 목록 갱신시 안됨
            // 반대로 checked를 사용하면 실제 switch 클릭시 변경이 일어나지 않고 재검색 후 일어남(그러나 마치 switch 누른 후 변경된 것처럼 보임)
            // defaultChecked={doneYN}
            checked={validSwitchState[params.id] ?? Boolean(params.value)}
            onChange={(e) => handleChangeToggleValid({ params, e })}
          />
      )
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세/수정/복사하여 등록/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];
  
  // const location = useLocation();
  const [searchParams] = useSearchParams();
  const queryStrings = Object.fromEntries([...searchParams]);
  const searchItemType = queryStrings["searchItemType"];

  // useEffect를 사용하여 searchItemType이 변경될 때마다 itemTypeOptions 업데이트
  useEffect(() => {
    // URL 파라미터가 변경될 때마다 상태 초기화
    setSelectedItemType('');
    setSelectedItemTypeListId('');
    setValue("itemType", "");
    setValue("itemTypeList", "");
    setItemTypeListOptions([]);
    setRows([]);

    if (searchItemType === 'materials') {
      setItemTypeOptions(getMaterialItemTypeOptions());
      setItemTypeListOptions([]);
    } else if (searchItemType === 'preferences') {
      setItemTypeOptions(getPreferenceItemTypeOptions());
      setItemTypeListOptions([]);
    } else {
      setItemTypeOptions([]); // 다른 경우에는 빈 배열로 설정
      setItemTypeListOptions([]);
    }
  }, [searchItemType, setValue]);

  const itemTypeCol = {
    field: 'itemType',
    headerName: '자재구분',
    width: 250,
    // hide: true,
    // editable: true,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      let itemType = params.row.itemType;
      // if (params.row.itemType === "") {
      //   itemType = 'NONE';
      // }
      return (
        <ToggleButtonGroup
          color="primary"
          value={itemType}
          exclusive
          size={"small"}
        >
          <ToggleButton value="RAW_MATERIAL" sx={{ fontSize: '0.7rem', fontWeight: 'bold', width: 30, height: 20, borderRadius: 0 }} onClick={(e) => handleChangeToggleItemType({ itemType: 'RAW_MATERIAL', params, e })}>
            <Tooltip title={"원자재"} followCursor>
              <Box>
                {"원재"}
              </Box>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="SUB_MATERIAL_PROCESS" sx={{ fontSize: '0.7rem', fontWeight: 'bold', width: 30, height: 20, borderRadius: 0 }} onClick={(e) => handleChangeToggleItemType({ itemType: 'SUB_MATERIAL_PROCESS', params, e })}>
            <Tooltip title={"가공부자재"} followCursor>
              <Box>
                {"가부"}
              </Box>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="SUB_MATERIAL_BUILD" sx={{ fontSize: '0.7rem', fontWeight: 'bold', width: 30, height: 20, borderRadius: 0 }} onClick={(e) => handleChangeToggleItemType({ itemType: 'SUB_MATERIAL_BUILD', params, e })}>
            <Tooltip title={"시공부자재"} followCursor>
              <Box>
                {"시부"}
              </Box>
            </Tooltip>
          </ToggleButton>
          {/* <ToggleButton value="NONE" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggleItemType({ itemType: 'NONE', params, e })}>{"자재아님"}</ToggleButton> */}
        </ToggleButtonGroup>
      )
    },
  };

  const madeByCol = {
    field: 'madeBy',
    headerName: '생산지구분',
    width: 240,
    // hide: true,
    // editable: true,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      let { madeBy, itemType } = params.row;
      if (itemType === 'RAW_MATERIAL') {
        return (

          <ToggleButtonGroup
            color="primary"
            exclusive
            onChange={(e, newValue) => handleChangeToggleMadeBy({ madeBy: newValue, params, e })}
            size={"small"}
            //disabled={loaded}
            //value={madeBy ? madeBy : "국내산"}
            value={madeByToggleState[params.id] ?? params.value}
          >
            <ToggleButton value="국내산" sx={{ fontSize: '0.7rem', fontWeight: 'bold', width: 30, height: 20, borderRadius: 0 }} >
              <Tooltip title={"국내산"} followCursor>
                <Box>
                  {"국"}
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="국내산(로이)" sx={{ fontSize: '0.7rem', fontWeight: 'bold', width: 58, height: 20, borderRadius: 0 }} >
              <Tooltip title={"국내산(로이)"} followCursor>
                <Box>
                  {"국(로이)"}
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="수입산" sx={{ fontSize: '0.7rem', fontWeight: 'bold', width: 30, height: 20, borderRadius: 0 }} >
              <Tooltip title={"수입산"} followCursor>
                <Box>
                  {"수"}
                </Box>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="수입산(로이)" sx={{ fontSize: '0.7rem', fontWeight: 'bold', width: 58, height: 20, borderRadius: 0 }} onClick={(e) => handleChangeToggleMadeBy({ madeBy: '수입산(로이)', params, e })}>
              <Tooltip title={"수입산(로이)"} followCursor>
                <Box>
                  {"수(로이)"}
                </Box>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        )
      } else {
        return <></>
      }
      
    },
  };

  const manufacturerCol = {
    field: 'gclients',
    headerName: '제조사',
    width: 150,
    // editable: true,
    // valueGetter: (params) => params.value?.name,
    renderCell: (params) => {
      if (params.row.name === "Clear") {
        console.log(params)
      }
      
      if (params.value && Array.isArray(params.value) && params.value.length > 0) {
        //console.log(params.value)
        return params.value.length === 1
          ?
            // (<Button variant="text" sx={{ textTransform: 'none' }} onClick={() => handleViewManufacturers([params.value[0].gclient])}>{params.value[0].gclient.name}</Button>)
            (<Button variant="text" sx={{ textTransform: 'none' }} onClick={() => handleViewManufacturers([params.value[0]])}>{params.value[0].name}</Button>)
          :
            // (<Button variant="text" sx={{ textTransform: 'none' }} onClick={() => handleViewManufacturers(params.value.map(val => val.gclient))}>{"2+"}</Button>)
            (<Button variant="text" sx={{ textTransform: 'none' }} onClick={() => handleViewManufacturers(params.value)}>{"2+"}</Button>)
      };
    }
  };

  

  // [자재구분] 필드 삭제
  //searchItemType === 'materials' && columns.splice(5, 0, itemTypeCol) && columns.splice(6, 0, madeByCol) && columns.splice(7, 0, manufacturerCol);
  searchItemType === 'materials' && columns.splice(6, 0, madeByCol) && columns.splice(7, 0, manufacturerCol);

  //const rows = useSelector((state) => state.gcomponentItem.gcomponentItems);
  const selectedRow = useSelector((state) => state.gcomponentItem.gcomponentItem);
  
  // 데이터 관리
  const dispatch = useDispatch();
  
  // NOT USED
  const selectAllByQuery = () => dispatch(gcomponentItemActions.selectAllByQuery())
  const selectAllByItemTypesByQuery = (itemTypes) => dispatch(gcomponentItemActions.selectAllByItemTypesByQuery(itemTypes))
  const selectByQuery = (id) => dispatch(gcomponentItemActions.selectByQuery(id))
  const selectItemTypeComboData = (itemType) => dispatch(gcomponentItemActions.selectItemTypeComboData(itemType))
  const selectAllItemTypeListByItemType = (itemType, gcomponentId, g04docuGCertificationId) => dispatch(gcomponentItemActions.selectAllItemTypeListByItemType(itemType, gcomponentId, g04docuGCertificationId))
  const modifyGComponentItemApplyType = ({ id, applyType }) => dispatch(gcomponentItemActions.modifyApplyType({ id, applyType }));
  const modifyGComponentItemItemType = ({ id, itemType }) => dispatch(gcomponentItemActions.modifyItemType({ id, itemType }))
  const modifyGComponentItemMadeBy = ({ id, madeBy }) => dispatch(gcomponentItemActions.modifyMadeBy({ id, madeBy }))
  const modifyGComponentItemValid = ({ id, valid }) => dispatch(gcomponentItemActions.modifyGComponentItemValid({ id, valid }))
  const modifyRowOrder = ({ id, type, toOrder, itemType, itemId }) => gcomponentItemActions.modifyRowOrder({ id, type, toOrder, itemType, itemId })


  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // console.log({ ...state, [anchor]: open });
    setDrawerOpen(open);
  };

  // Drag & Drop 순서 변경
  const handleRowOrderChange = async (params) => {
    
    const { oldIndex, targetIndex } = params;
    if (oldIndex === targetIndex) return;

    const newRows = [...rows];
    const [movedRow] = newRows.splice(oldIndex, 1);
    newRows.splice(targetIndex, 0, movedRow);
    
    setOpenBackdrop(true);
    try {
      const moveType = oldIndex > targetIndex ? 'up' : 'down';
      // 새로운 순서 정보를 생성
      const movedRow = rows[oldIndex];
      
      let result = await modifyRowOrder({
        id: movedRow.id,
        type: 'to',
        toOrder: targetIndex+1,
        itemType: selectedItemType,
        itemId: selectedItemTypeListId
      });

      refreshGrid();

    } catch (error) {
      console.error("Error row order:", error.serverResponse.message, error);
    }

    setOpenBackdrop(false);
  };

  // 자재구분 선택
  const handleChangeItemType = async (e) => {
    const { value: itemType } = e.target;
    setSelectedItemType(itemType);
    setValue("itemType", itemType);
    setValue("itemTypeList", "");

    if (itemType) {
      try {
        // 서버에서 분류 목록을 조회하는 API 호출
        const response = await selectItemTypeComboData(itemType);
        let itemTypeList = [];
        
        //console.log(response.itemList);
        
        if (response && response.itemList && Array.isArray(response.itemList)) {
          if (itemType === MATERIAL_ITEM_TYPE.RAW_MATERIAL || 
              itemType === MATERIAL_ITEM_TYPE.SUB_MATERIAL_PROCESS ||
              itemType === PREFERENCE_ITEM_TYPE.NONE) {
            itemTypeList = response.itemList.map(item => ({
              label: item.gname,
              value: item.gcomponentId
            }));
          } else if (itemType === MATERIAL_ITEM_TYPE.SUB_MATERIAL_BUILD) {
            itemTypeList = response.itemList.map(item => ({
              label: item.g04docuGCertificationName,
              value: item.g04docuGCertificationId
            }));
          }
        }
        
        // "없음" 옵션 추가
        const optionsWithNone = [          
          ...itemTypeList,
          { label: "없음", value: "NONE" },
        ];

        // 분류 선택 옵션 설정
        setItemTypeListOptions(optionsWithNone);
      } catch (error) {
        console.error("분류 목록 조회 중 오류 발생:", error);
      }
    } 
  }

  // 분류 선택
  const handleChangeItemTypeList = async (e) => {
    const { value: itemId } = e.target;
    setValue("itemTypeList", itemId);
    setSelectedItemTypeListId(itemId);

    setLoaded(false);

    if (itemId && selectedItemType) {
      try {
        let gcomponentId = null;
        let g04docuGCertificationId = null;

        // itemOption에 따라 적절한 ID를 설정
        if (selectedItemType === MATERIAL_ITEM_TYPE.RAW_MATERIAL || 
            selectedItemType === MATERIAL_ITEM_TYPE.SUB_MATERIAL_PROCESS ||
            selectedItemType === PREFERENCE_ITEM_TYPE.NONE) {
          gcomponentId = itemId;  
        } else if (selectedItemType === MATERIAL_ITEM_TYPE.SUB_MATERIAL_BUILD) {
          g04docuGCertificationId = itemId;   
        }

//        console.log(selectedItemType, gcomponentId, g04docuGCertificationId);

        const response = await selectAllItemTypeListByItemType(
          selectedItemType,
          gcomponentId,
          g04docuGCertificationId
        );

        if (response && response.gcomponentItems && Array.isArray(response.gcomponentItems)) {
          setRows(response.gcomponentItems);
        } 
        /*else {
          console.warn("예상치 못한 응답 형식:", response);
          setItemList([{ label: "없음", value: "" }]);
        }*/
        
        // 데이터 로딩 완료
        setLoaded(true);
      } catch (error) {
        console.error("항목 목록 조회 중 오류 발생:", error);
        setRows([]);
        setLoaded(true);
      }
    } else {
      //setRows([]);
      setLoaded(true);
    }
  }

  // 등록/수정/삭제 시 목록 갱신
  const refreshGrid = async () => {
    
    if (selectedItemType && selectedItemTypeListId) {
      setLoaded(false);
      try {
        let gcomponentId = null;
        let g04docuGCertificationId = null;

        // itemOption에 따라 적절한 ID를 설정
        if (selectedItemType === MATERIAL_ITEM_TYPE.RAW_MATERIAL || 
            selectedItemType === MATERIAL_ITEM_TYPE.SUB_MATERIAL_PROCESS) {
          gcomponentId = selectedItemTypeListId;  
        } else if (selectedItemType === MATERIAL_ITEM_TYPE.SUB_MATERIAL_BUILD) {
          g04docuGCertificationId = selectedItemTypeListId;   
        }

        //console.log(selectedItemType, gcomponentId, g04docuGCertificationId);

        const response = await selectAllItemTypeListByItemType(
          selectedItemType,
          gcomponentId,
          g04docuGCertificationId
        );

        if (response && response.gcomponentItems && Array.isArray(response.gcomponentItems)) {
          setRows(response.gcomponentItems);
        } 
        /*else {
          console.warn("예상치 못한 응답 형식:", response);
          setItemList([{ label: "없음", value: "" }]);
        }*/
       
       setLoaded(true);
      } catch (error) {
        console.error("항목 목록 조회 중 오류 발생:", error);
        setLoaded(true);
      }

    } 
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <GComponentItemDialog
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          // paste={paste}
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
          refresh={refreshGrid}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <FormInputText
                select
                name="itemType"
                control={control}
                label={"자재구분 선택"}
                options={itemTypeOptions}
                onChange={handleChangeItemType}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <FormInputText
                select
                name="itemTypeList"
                control={control}
                label={"분류 선택"}
                options={itemTypeListOptions}
                onChange={handleChangeItemTypeList}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={6} container justifyContent="flex-end">
              <Button
                variant="contained"
                startIcon={<Search />}
                onClick={handleClickSearch}
                sx={{ ml: 5, mr: 1 }}
              >
                {"검색"}
              </Button>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={handleClickOpen}
              >
                {"등록하기"}
              </Button>
            </Grid>
          </Grid>
          {/* 순서변경 기능을 Drag & Drop으로 변경
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={checked}
                    onChange={handleChangeOrder}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={"순서"}
              />
            </Grid>
          </Grid>
          */}
          <div style={{ height: GRID_HEIGHT.REDUCED, width: '100%', marginTop: '20px' }}>
            {/* TODO : Skeleton 처리를 하는게 보기 좋은지 아닌지 의견 수렴 필요 */}
            {/* <Skeleton variant="rounded" height={show ? 0 : 900} /> */}
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              // sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              // 순서 변경 처리
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              rowReordering
              onRowOrderChange={handleRowOrderChange}
              // 순서 변경 처리 끝
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={rows}
              columns={columns}
              // pageSize={5}
              // rowsPerPageOptions={[5]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              // checkboxSelection
              // disableSelectionOnClick
              // onRowSelectionModelChange={(ids) => {
              //   const selectedIDs = new Set(ids);
              //   const selectedRows = rows.filter((row) =>
              //     selectedIDs.has(row.id),
              //   );
      
              //   setSelectedRows(selectedRows);
              // }}
              onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
            />
          </div>
        </Box>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
      </Container>
      <Drawer
        anchor={"bottom"} // TODO : 추후 사용자가 환경설정에서 위치 설정하면 전체 반영하는 방법 강구
        open={drawerOpen}
        PaperProps={{
          sx: { width: "100%" },
        }}
        onClose={toggleDrawer(false)}
      >
        <Grid display="flex" justifyContent={"center"} alignItems="center" sx={{ backgroundColor: "#f3f3f3" }}>
          <Tooltip title={"닫기"}>
            <IconButton aria-label="close drawer" size="small" component="span" onClick={toggleDrawer(false)}>
              <KeyboardArrowDown />
            </IconButton>
          </Tooltip>
        </Grid>
        {/* TODO : 아래 props를 하나로 넘겼을 때랑 아래처럼 따로 각각 넘겼을 때(setState를 각각 하고 있으므로) render 횟수 체크해 볼 것 */}
        <GClientManagement
          title={`거래선 정보`}
          from={
            {
              source: "GComponentItemManagement",
              params: {
                selManufacturers,
              }
            }
          }
          // statusWorkOrder={statusWorkOrder}
          // originRefresh={() => selectAllByStatusReceptionByQuery(['PRE-RECEIVE', 'RECEIVE', 'RETURN'])}
        />
      </Drawer>
    </ThemeProvider>
  );
};

export default GComponentItemManagement;
