import { csrfFetch } from "./csrf.js";
import { projectServerUrl } from '../config.js';

// Action Types
const SET_GSUPPLY_CONFIRM = 'gsupplyConfirm/setGSupplyConfirm';
const REMOVE_GSUPPLY_CONFIRM = "gsupplyConfirm/removeGSupplyConfirm";
const SET_GSUPPLY_CONFIRMS = 'gsupplyConfirm/setGSupplyConfirms';

// Action Creators
const setGSupplyConfirm = (gsupplyConfirm) => ({
  type: SET_GSUPPLY_CONFIRM,
  payload: gsupplyConfirm,
});

const removeGSupplyConfirm = () => ({
  type: REMOVE_GSUPPLY_CONFIRM,
});

const setGSupplyConfirms = (gsupplyConfirms) => ({
  type: SET_GSUPPLY_CONFIRMS,
  payload: gsupplyConfirms,
});

// Thunk Actions
export const create = (data) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gsupplyConfirms`, {
    method: "POST",
    body: JSON.stringify(data),
  });
  const responseData = await response.json();
  dispatch(setGSupplyConfirm(responseData.gsupplyConfirm));
  return response;
};

export const createBulk = (dataList) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gsupplyConfirms/bulk`, {
    method: "POST",
    body: JSON.stringify(dataList),
  });
  return response;
};

export const selectAll = ({ searchType, constructionCompanyName, site, startDate, endDate, completionDate, requestAt, confirmAt, status }) => async dispatch => {
  const params = [];

  if (searchType) {
    params.push(`searchType=${encodeURIComponent(searchType)}`);
  }
  if (constructionCompanyName) {
    params.push(`constructionCompanyName=${encodeURIComponent(constructionCompanyName)}`);
  }
  if (site) {
    // params.push(`searchSite=${searchSite}`);
    params.push(`site=${encodeURIComponent(site)}`);
  }
  if (startDate) {
    params.push(`startDate=${encodeURIComponent(startDate)}`);
  }
  if (endDate) {
    params.push(`endDate=${encodeURIComponent(endDate)}`);
  }
  if (completionDate) {
    params.push(`completionDate=${encodeURIComponent(completionDate)}`);
  }
  if (requestAt) {
    params.push(`requestAt=${encodeURIComponent(requestAt)}`);
  }
  if (confirmAt) {
    params.push(`confirmAt=${encodeURIComponent(confirmAt)}`);
  }
  if (status && Array.isArray(status) && status.length > 0) {
    status.forEach(s => {
      params.push(`status=${encodeURIComponent(s)}`);
    });
  }

  let querString = params.join("&");

  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gsupplyConfirms${querString ? `?${querString}` : ""}`);
  const data = await response.json();
  dispatch(setGSupplyConfirms(data.gsupplyConfirms));
  return response;
};

export const selectAllDirect = async ({ searchType, gprojectId, constructionCompanyName, site, startDate, endDate, completionDate, requestAt, confirmAt, status }) => {
  const params = [];

  if (searchType) {
    params.push(`searchType=${encodeURIComponent(searchType)}`);
  }
  if (gprojectId) {
    params.push(`gprojectId=${encodeURIComponent(gprojectId)}`);
  }
  if (constructionCompanyName) {
    params.push(`constructionCompanyName=${encodeURIComponent(constructionCompanyName)}`);
  }
  if (site) {
    // params.push(`searchSite=${searchSite}`);
    params.push(`site=${encodeURIComponent(site)}`);
  }
  if (startDate) {
    params.push(`startDate=${encodeURIComponent(startDate)}`);
  }
  if (endDate) {
    params.push(`endDate=${encodeURIComponent(endDate)}`);
  }
  if (completionDate) {
    params.push(`completionDate=${encodeURIComponent(completionDate)}`);
  }
  if (requestAt) {
    params.push(`requestAt=${encodeURIComponent(requestAt)}`);
  }
  if (confirmAt) {
    params.push(`confirmAt=${encodeURIComponent(confirmAt)}`);
  }
  if (status && Array.isArray(status) && status.length > 0) {
    status.forEach(s => {
      params.push(`status=${encodeURIComponent(s)}`);
    });
  }

  let querString = params.join("&");

  const response = await csrfFetch(null, `${projectServerUrl}/api/gsupplyConfirms${querString ? `?${querString}` : ""}`);
  const data = await response.json();
  return data.gsupplyConfirms;
};

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gsupplyConfirms/id/${id}`);
  const data = await response.json();
  dispatch(setGSupplyConfirm(data.gsupplyConfirm));
  return response;
};

export const selectDirect = async (id) => {
  const response = await csrfFetch(null, `${projectServerUrl}/api/gsupplyConfirms/id/${id}`);
  const data = await response.json();
  return data.gsupplyConfirm;
};

export const modify = (data) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gsupplyConfirms/id/${data.id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  const responseData = await response.json();
  dispatch(setGSupplyConfirm(responseData.gsupplyConfirm));
  return response;
};

export const updateStatus = (data) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gsupplyConfirms/updateState`, {
    method: "PUT",
    body: JSON.stringify(data),
  });
  const responseData = await response.json();
  dispatch(setGSupplyConfirm(responseData.gsupplyConfirm));
  return response;
};

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gsupplyConfirms/id/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGSupplyConfirm());
  return response;
};

// Initial State
const initialState = {
  gsupplyConfirm: null,
  gsupplyConfirms: [],
};

// Reducer
function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GSUPPLY_CONFIRM:
      newState = Object.assign({}, state, { gsupplyConfirm: action.payload });
      return newState;
    case REMOVE_GSUPPLY_CONFIRM:
      newState = Object.assign({}, state, { gsupplyConfirm: null });
      return newState;
    case SET_GSUPPLY_CONFIRMS:
      newState = Object.assign({}, state, { gsupplyConfirms: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;