import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  ContentPaste,
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
  Filter1,
} from '@mui/icons-material';
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import { dateFormat } from "../../utils";
import * as gcomponentItemActions from "../../store/gcomponentItem";
import * as gcomponentActions from "../../store/gcomponent";
import * as datagridActions from "../../store/components/datagrid";
import * as dialogActions from "../../store/components/dialog";
import * as confirmDialogActions from "../../store/components/confirmDialog";
import GComponentChildManagement from "./GComponentChildManagement";
import GComponentDialog from "./GComponentDialog";
import GComponentDeleteConfirmDialog from "./GComponentDeleteConfirmDialog";

// React.getInnerText = function(obj) {
//   var buf = '';
//   if( obj ) {
//     var type = typeof(obj);
//     if( type === 'string' || type === 'number' ) {
//       buf += obj;
//     } else if( type === 'object' ) {
//       var children = null;
//       if( Array.isArray(obj) ) {
//         children = obj;
//       } else {
//         var props = obj.props;
//         if( props ) {
//           children = props.children;
//         }
//       }
//       if( children ) {
//         if( Array.isArray(children) ) {
//           children.forEach(function(o) {
//             buf += React.getInnerText(o);
//           });
//         } else {
//           buf += React.getInnerText(children);
//         }
//       }
//     }
//   }
//   return buf;
// };

const theme = createTheme();

const GComponentManagement = () => {

  const [loaded, setLoaded] = useState(false);
  const [checked, setChecked] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  
  const handleSelect = async ({ type, params }) => {
    const { id } = params;

    if (type === "detail") {
      await select(id);
      dispatch(dialogActions.setOptions({ open: true, crudMode: 'R' }));
    } else if (type === "edit") {
      /**
       * 1. 데이터 sync 조회(await) 후 다이얼로그 열기
       *  => 데이터가 조회된 상태에서 다이얼로그가 나타나므로 데이터가 나타나면서 일어나는 UI의 변화가 적지만 다소 다이얼로그가 늦게 나타날 수 있음
       * 2. 다이얼로그 열기 후 데이터 조회
       *  => 다이얼로그는 빨리 나타나지만 나타난 후 UI의 변화가 눈에 보임. 이것을 마치 의도된 로딩처럼 생각하여 보기에 따라 더 좋을 수 있으나 그렇지 않을 수도 있음
       */
      await select(id);
      dispatch(dialogActions.setOptions({ open: true, crudMode: 'U' }));
    } else if (type === "paste") {
      await select(id);
      dispatch(dialogActions.setOptions({ open: true, crudMode: 'C' }));
    } else if (type === "delete") {
      dispatch(confirmDialogActions.setOptions({ open: true, params, action: remove }));
    } else if (type === "addChild") {
      dispatch(dialogActions.setOptions({ open: true, crudMode: 'C', values: { pid: id, pname: params.row.name } }));
    }
  }
  
  const handleSelectUpDown = async ({ type, id }) => {
    await reorder({ type, id });
    
    setLoaded(false);
    await selectAllByQuery();
    setLoaded(true);

    /**
     * 하위 구성요소의 순서를 바꿀때 상위 구성요소 목록에서
     *   #1. 현재 선택한 구성요소가 아닌 다른 요소의 하위 구성요소의 순서를 바꾸는 경우
     *   #2. 현재 선택한 구성요소의 하위 구성요소의 순서를 바꾸는 경우
     * 선택한 구성요소의 하위 구성요소가 하위 구성요소 목록에 조회되어 있으므로
     *   #1의 경우는 하위 구성요소 목록을 그대로 두어야 하고
     *   #2의 경우는 하위 구성요소 목록을 재구성해야 하는데...
     * 현재까지는 선택여부를 알 수 없으므로 순서 변경 후 상위 구성요소 목록을 재구성하므로 하위 구성요소 목록을 초기화하는 방법으로 함
     */
    initializeGComponentsChild();
  }

  const remove = (params, selectedRows) => {
    const { id: removeId } = params;
    dispatch(gcomponentActions.remove(removeId))
      .then(async res => {
        await selectAllByQuery();
        
        // 상위 구성요소 목록에서 구성요소가 선택되어 있고 선택되어 있는 구성요소의 하위의 구성요소를 삭제하면
        // 선택되어 있는 구성요소의 하위 구성요소를 아래의 하위 구성요소 목록을 재구성한다.
        if (selectedRows.length === 1) {
          selectByPid(selectedRows[0].id);
        }
      })
      .catch(async (res) => {
        const data = await res.json();
      });
  }

  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const handleChangeToggle = async ({ type, params, e }) => {
    e.stopPropagation();

    await modifyGComponentType({ id: params.id, type })

    setLoaded(false);
    await selectAllByQuery();
    setLoaded(true);

    /**
     * 하위 구성요소의 "구분" 속성을 바꿀때 상위 구성요소 목록에서
     *   #1. 현재 선택한 구성요소가 아닌 다른 요소의 하위 구성요소의 "구분" 속성을 바꾸는 경우
     *   #2. 현재 선택한 구성요소의 하위 구성요소의 "구분" 속성을 바꾸는 경우
     * 선택한 구성요소의 하위 구성요소가 하위 구성요소 목록에 조회되어 있으므로
     *   #1의 경우는 하위 구성요소 목록을 그대로 두어야 하고
     *   #2의 경우는 하위 구성요소 목록을 재구성해야 하는데...
     * 현재까지는 선택여부를 알 수 없으므로 순서 변경 후 상위 구성요소 목록을 재구성하므로 하위 구성요소 목록을 초기화하는 방법으로 함
     */
    initializeGComponentsChild();
  }

  // 등록 다이얼로그
  const handleClickOpen = () => {
    dispatch(dialogActions.setOptions({ open: true, crudMode: 'C' }));
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelect({ type: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ContentPaste />}
        label={"복사하여 등록"}
        onClick={() => handleSelect({ type: 'paste', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Add />}
        label={"하위등록"}
        onClick={() => handleSelect({ type: 'addChild', params })}
        showInMenu
      />,
    ];

    if (checked) {
      arrActions = arrActions.concat([
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowUp />}
            label={"맨위로"}
            onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowUp />}
            label={"위로"}
            onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowDown />}
            label={"아래로"}
            onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />,
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowDown />}
            label={"맨아래로"}
            onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />
        ]
      );
    }

    return arrActions;
  }

  const columns = useMemo(() => [
      {
        field: 'type',
        headerName: '구분',
        width: 180,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          let type = params.row.type;
          if (params.row.type === 'item') return "";
          if (params.row.type === "") {
            type = 'none';
          }
          
          return (
            <ToggleButtonGroup
              color="primary"
              value={type}
              exclusive
              size={"small"}
            >
              <ToggleButton value="none" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'none', params, e })}>{"없음"}</ToggleButton>
              <ToggleButton value="group" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'group', params, e })}>{"그룹"}</ToggleButton>
              <ToggleButton value="property" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'property', params, e })}>{"속성"}</ToggleButton>
            </ToggleButtonGroup>
          )
        },
      },
      {
        field: 'code',
        headerName: '코드',
        width: 200,
      },
      {
        field: 'multipleYN',
        headerName: '다중선택',
        headerAlign: 'center',
        align: 'center',
        width: 100,
        renderCell: (params) => {
          if (params.row.type === "property") {
            let multipleYN = params.row.multipleYN;

            return (
              <Switch
                // defaultChecked를 사용하면 목록에서의 변경이 아닌 수정다이얼로그에서 변경 후 목록 갱신시 안됨
                // 반대로 checked를 사용하면 실제 switch 클릭시 변경이 일어나지 않고 재검색 후 일어남(그러나 마치 switch 누른 후 변경된 것처럼 보임)
                // TODO : 추후 useState([]) 사용하는 방법 강구
                // defaultChecked={doneYN}
                checked={multipleYN}
                onChange={(e) => handleChangeMultipleYNToggle({ params, e })}
              />
            )
          } else {
            return "";
          }
        },
      },
      {
        field: 'comments',
        headerName: '설명',
        width: 280,
      },
      {
        field: 'createdAt',
        headerName: '생성일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => dateFormat(params.value),
      },
      {
        field: 'updatedAt',
        headerName: '수정일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => dateFormat(params.value),
      },
      {
        field: 'actions',
        headerName: <Tooltip title={"상세/수정/복사하여 등록/삭제/하위등록"} followCursor><Box>{"기능"}</Box></Tooltip>,
        width: checked ? 200 : 70,
        // description: "수정/삭제/하위등록",
        // headerClassName: 'super-app-theme--header',
        // cellClassName: 'super-app-theme--cell',
        type: 'actions',
        getActions: (params) => generateActions(params),
      },
    ], [handleSelect]
  );

  const handleChangeMultipleYNToggle = async ({ params, e }) => {
    e.stopPropagation();

    await modifyGComponentMultipleYN({ id: params.id, multipleYN: e.target.checked });

    setLoaded(false);
    await selectAllByQuery();
    setLoaded(true);

    initializeGComponentsChild();
  }
  
  const rows = useSelector((state) => state.gcomponent.gcomponentsTree);
  
  const dispatch = useDispatch();

  // dispatch
  const select = (id) => dispatch(gcomponentActions.select(id))
  const selectByPid = (pid) => dispatch(gcomponentActions.selectByPid(pid))
  const selectAllByQuery = () => dispatch(gcomponentActions.selectAllByQuery())
  const modifyGComponentType = ({ id, type }) => dispatch(gcomponentActions.modifyType({ id, type }))
  const initializeGComponentItems = ()=> dispatch(gcomponentItemActions.initializeGComponentItems())
  const modifyGComponentMultipleYN = ({ id, multipleYN }) => dispatch(gcomponentActions.modifyMultipleYN({ id, multipleYN }))
  const initializeGComponentsChild = () => dispatch(gcomponentActions.initializeGComponentsChild())
  const selectAllGComponentItemsByGComponentId = (gcomponentId) => dispatch(gcomponentItemActions.selectAllByGcomponentId(gcomponentId))

  // no dispatch
  const reorder = ({ type, id }) => gcomponentActions.reorder({ type, id })
  
  // setTimeout(() => hideWatermark(), 300); // 각종 검색할 때가 아닌 여기서 처리??? => 라이센스 구입으로 필요없어졌지만 주석으로 남겨둠

  useEffect(
    async () => {
      initializeGComponentsChild();
      initializeGComponentItems();
      
      await selectAllByQuery();
      setLoaded(true);
    }, [dispatch]
  );

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <GComponentDialog refresh={selectAllByQuery} />
        <Box
          sx={{
            mt: 3,
            // '& .super-app-theme--header': {
            //   backgroundColor: 'rgba(255, 7, 0, 0.55)',
            // },
            // '& .super-app-theme--cell' : {
            //   backgroundColor: 'rgba(255, 7, 0, 0.55)',
            // },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={10} display="flex" alignItems="center">
              <Filter1 />
              <Typography variant="h6" sx={{ mt: 2, mb: 2, ml: 2 }}>{"상위 구성요소"}</Typography>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Add />}
                onClick={handleClickOpen}
              >
                {"등록하기"}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={checked}
                    onChange={handleChangeOrder}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={"순서"}
              />
            </Grid>
          </Grid>
          <div style={{ height: 400, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              treeData
              groupingColDef={{ headerName : "이름 (하위요소 개수)", width: 300 }}
              getTreeDataPath={(row) => row.path}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              disableMultipleRowSelection={true}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              loading={!loaded}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              columnHeaderHeight={38}
              rowHeight={34}
              // 상위 요소 선택시 하위 요소 및 아이템 목록 표시
              onRowSelectionModelChange={ids => {
                console.log(ids)
                const selectedIDs = new Set(ids);
                const selRows = rows.filter((row) => selectedIDs.has(row.id));
                
                if (selRows.length !== 1) return; // 현재는 단일 선택 기능만 있음. disableMultipleRowSelection={true}이므로 이 코드는 사실 현재는 필요없음

                const { id, type } = selRows[0];

                // 속성 하위는 GComponentItems에서 조회하고 속성이 아닌 경우는 GComponents에서 조회
                type === "property" ? selectAllGComponentItemsByGComponentId(id) : selectByPid(id)

                dispatch(datagridActions.setSelectedRows(selRows));
              }}
              onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
            />
          </div>
        </Box>
        <GComponentChildManagement /> {/* 하위 구성요소 목록 */}
        <GComponentDeleteConfirmDialog /> {/* 삭제 예/아니오 다이얼로그 */}
      </Container>
    </ThemeProvider>
  );
};

export default GComponentManagement;
