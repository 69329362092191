import React from 'react';
import { Route } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import { GClientAssociation, SettlementAssociation } from "../components/Association";

// 납품확인서 관리
const AssociationRoute = ({sessionUser}) => {

    return (
        <>
            {/* 요청하기 */}
            <Route path="/association/gclients" element={
                <RequireAuth sessionUser={sessionUser}>
                    <GClientAssociation />
                </RequireAuth>
            } />
            <Route path="/association/settlements" element={
                <RequireAuth sessionUser={sessionUser}>
                    <SettlementAssociation />
                </RequireAuth>
            } />
        </>
    );
};

export default AssociationRoute;

