import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, HelpOutline as HelpOutlineIcon, Help } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormInputText } from "../form";
import { DialogTitleClose, PaperComponent, AlertDialog, } from "../dialog";
import { Accordion, AccordionSummary, AccordionDetails, } from "../accordion";
import { mode } from "../../config";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { fontModules, fontFormats, smallStyle, registerFonts } from "../../utils/QuillConfig";

// import TextMaskCustom from "../form/inputProps/TextMaskCustom"
import * as sessionActions from "../../store/session";
import * as mailActions from "../../store/mail";
import * as errorActions from "../../store/error";

// 아래 form components의 name과 연계
const defaultValues = {
  service: "",
  credential: "",
  email: "",
  password: "",
  signYN: false,
  signContent: ""
};

let appPasswordInputRef = null;

const services = [
  {
    label: "네이버", value: "NAVER", ico: "/naver.ico", host: "smtp.naver.com", port: 465, secure: true, help: "/공사다큐_메일 세팅방법_네이버.pdf",
    steps: [
      { name: "1. 메일 계정 로그인", url: "https://nid.naver.com/nidlogin.login", tip: "먼저 네이버 메일에 로그인합니다." },
      { name: "2. 메일 서버 설정", url: "https://mail.naver.com/v2/settings/smtp", tip: "보내는 메일을 설정합니다." },
      { name: "3. 앱 비밀번호 생성", url: "https://nid.naver.com/user2/help/myInfoV2?m=viewSecurity&lang=ko_KR", tip: "2단계 인증과 앱 비밀번호를 생성합니다." },
      { name: "4. 앱 비밀번호 입력", url: "", tip: "앱 비밀번호를 입력합니다." },
    ]
  },
  {
    label: "다음", value: "DAUM", ico: "/daum.ico", host: "smtp.daum.net", port: 465, secure: true, help: "/공사다큐_메일 세팅방법_다음, 카카오.pdf",
    steps: [
      { name: "1. 보안 인증 설정", url: "https://accounts.kakao.com/weblogin/account/security/two_step_verification", tip: "다음 2단계 인증을 설정합니다." },
      { name: "2. 앱 비밀번호 생성", url: "https://accounts.kakao.com/weblogin/account/security/two_step_verification", tip: "2단계 인증과 앱 비밀번호를 생성합니다." },
      { name: "3. 메일 서버 설정", url: "https://mail.daum.net/setting/POP3IMAP", tip: "보내는 메일을 설정합니다." },
      { name: "4. 앱 비밀번호 입력", url: "", tip: "앱 비밀번호를 입력합니다." },
    ]
  },
  {
    label: "카카오", value: "KAKAO", ico: "/kakao.ico", host: "smtp.kakao.com", port: 465, secure: true, help: "/공사다큐_메일 세팅방법_다음, 카카오.pdf",
    steps: [
      { name: "1. 보안 인증 설정", url: "https://accounts.kakao.com/weblogin/account/security/two_step_verification", tip: "다음 2단계 인증을 설정합니다." },
      { name: "2. 앱 비밀번호 생성", url: "https://accounts.kakao.com/weblogin/account/security/two_step_verification", tip: "2단계 인증과 앱 비밀번호를 생성합니다." },
      { name: "3. 메일 서버 설정", url: "https://mail.daum.net/setting/POP3IMAP", tip: "보내는 메일을 설정합니다." },
      { name: "4. 앱 비밀번호 입력", url: "", tip: "앱 비밀번호를 입력합니다." },
    ]
  },
  {
    label: "지메일", value: "GMAIL", ico: "/gmail.ico", host: "smtp.gmail.com", port: 465, secure: true, help: "/공사다큐_메일 세팅방법_지메일.pdf",
    steps: [
      { name: "1. 보안 인증 설정", url: "https://myaccount.google.com/signinoptions/twosv", tip: "2단계 인증을 설정합니다." },
      { name: "2. 앱 비밀번호 생성", url: "https://myaccount.google.com/apppasswords", tip: "앱 비밀번호를 생성합니다." },
      { name: "3. 앱 비밀번호 입력", url: "", tip: "앱 비밀번호를 입력합니다." },
    ]
  },
  {
    label: "네이버웍스", value: "NAVER_WORKS", ico: "/naver_works.ico", host: "smtp.worksmobile.com", port: 465, secure: true, help: "/공사다큐_메일 세팅방법_네이버웍스.pdf",
    steps: [
      { name: "1. 네이버웍스 로그인", url: "https://common.worksmobile.com/proxy/my", tip: "로그인합니다." },
      { name: "2. 앱 비밀번호 생성", url: "https://common.worksmobile.com/security-settings/app-password", tip: "앱 비밀번호를 생성합니다." },
      { name: "3. 앱 비밀번호 입력", url: "", tip: "앱 비밀번호를 입력합니다." },
    ]
  },
  {
    label: "네이트", value: "NATE", ico: "/nate.ico", host: "smtp.mail.nate.com", port: 465, secure: true, help: "/공사다큐_메일 세팅방법_네이트.pdf",
    steps: [
      { name: "1. 네이트 로그인", url: "https://home.mail.nate.com/login/login.html", tip: "로그인합니다." },
      { name: "2. 메일 서버 설정", url: "https://mail3.nate.com/app/option/out_pop3/", tip: "보내는 메일을 설정합니다." },
      { name: "3. 앱 비밀번호 입력", url: "", tip: "앱 비밀번호를 입력합니다." },
    ]
  },
  // {
  //   label: "직접 설정하기", value: "MANUAL",
  // },
];

const EmailSettingDialog = ({
  type,
  open,
  setOpen,
  credential,
  email
}) => {
  const setAppPasswordInputRef = element => {
    appPasswordInputRef = element;
  };

  const [errors, setErrors] = useState([]);
  const [alertInfo, setAlertInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingTest, setLoadingTest] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [manual, setManual] = useState(false);
  const [mailExpanded, setMailExpanded] = useState(true);
  const [signExpanded, setSignExpanded] = useState(true);
  const [signYN, setSignYN] = useState(false);
  const [signContent, setSignContent] = useState('');

  const sessionUser = useSelector((state) => state.session.sessionUser);
  // const sessionUserLoginType = useSelector((state) => state.session.sessionUser.sessionUserLoginType);
  // const sessionUserRole = useSelector((state) => state.session.sessionUser.sessionUserRole);

  const handleDialogClose = () => {
    setOpen(false);

    initDialog();
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    setLoading(false);
    setLoadingTest(false);
  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, getValues, setValue } = useForm({ defaultValues: defaultValues });
  
  // 데이터 관리
  const dispatch = useDispatch();

  // TODO : 추후 type (GLIENT, ADMIN, USER...) 대응 필요
  const setEmailDirect = ({ type, credential, mailSettings }) => sessionActions.setEmailDirect({ type, credential, mailSettings })
  const sendMailTestDrect = ({ mailSettings }) => mailActions.sendMailTestDrect({ mailSettings })

  const onSubmit = async ({ service, credential, email, password }) => {
    setErrors([]);
    
    if (service === 'MANUAL') {
      
      return;
    } else {
      // TODO : 추후 yup 등 사용할 것
      // console.log({ service, credential, email, password });
      if (!service) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: "서비스를 선택해주세요.",
          open: true,
        });

        return;
      }

      if (!email) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: "메일계정을 입력해주세요.",
          open: true,
        });

        return;
      }

      if (!password) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: "앱 비밀번호를 입력해주세요.",
          open: true,
        });

        return;
      }
    }

    let { host, port, secure } = services.find(item => item.value === service);
    
    const mailSettings = {
      host,
      port,
      secure,
      auth: {
        user: email,
        pass: password,
      },
      footer: {
        useYn: signYN,
        message: signContent
      }
    };

    try {
      setLoading(true);
      
      const res = await sendMailTestDrect({ mailSettings });
      if (mode.indexOf('java') > 0) {
        if (res?.info) {
          await setEmailDirect({ type, credential, mailSettings }); // TODO : 추후 type (GLIENT, ADMIN, USER...) 대응 필요
              
          await dispatch(sessionActions.restoreSessionUser());
          
          setAlertInfo({
            titleAlert: "안내",
            messageAlert: "메일 설정이 완료되었습니다.",
            open: true,
          });

          setTimeout(() => {
            setLoading(false);
            handleDialogClose();
          }, 1000);
        } else {
          setLoading(false);
          dispatch(errorActions.occurError({ response: null, serverResponse: res })); // 오류 처리 방식 4
        }
      } else {
        if (res && res.info) {
          const { accepted, response } = res.info;
          if (accepted && Array.isArray(accepted) && accepted.length === 1) {
            if (accepted[0] === email/* && response.indexOf()*/) { // TODO : response 메시지는 추후 서비스별로 체크해 볼 것
              await setEmailDirect({ type, credential, mailSettings }); // TODO : 추후 type (GLIENT, ADMIN, USER...) 대응 필요
              
              await dispatch(sessionActions.restoreSessionUser());
              
              setAlertInfo({
                titleAlert: "안내",
                messageAlert: "메일 설정이 완료되었습니다.",
                open: true,
              });
  
              setTimeout(() => {
                setLoading(false);
                handleDialogClose();
              }, 1000);
            }
          } else {
            // setAlertInfo({
            //   titleAlert: "안내",
            //   messageAlert: "메일 설정 테스트가 완료되지 않았습니다. 관리자에게 문의해주세요.",
            //   open: true,
            // });
            setLoading(false);
            dispatch(errorActions.occurError({ response: null, serverResponse: res })); // 오류 처리 방식 4
          }
        }
      }
      
      // await setEmailDirect({ type, credential, mailSettings }); // TODO : 추후 type (GLIENT, ADMIN, USER...) 대응 필요
      
      // await dispatch(sessionActions.restoreSessionUser());

      // setTimeout(() => {
      //   setLoading(false);
      //   handleDialogClose();
      // }, 1000);
      
    } catch (e) {
      setLoading(false);
      setAlertInfo({
        titleAlert: e.title,
        messageAlert: e.message,
        open: true,
      });
    }
  }

  const onSignSubmit = async () => {
    setErrors([]);

    try {
      setLoading(true);
      
      if (!signContent || signContent === '<p><br></p>') {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: "서명 내용을 입력해주세요.",
          open: true,
        });
        return;
      }

      console.log(sessionUser);

      if (!sessionUser?.mailSettings) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: "보내는 메일 설정을 완료해 주세요.",
          open: true,
        });
        return;
      }

      // 현재 메일 설정에서 footer를 제외한 나머지 설정 가져오기
      const { footer: _, ...currentMailSettings } = sessionUser.mailSettings;

      const mailSettingsWithSignature = {
        ...currentMailSettings,
        footer: {
          useYn: signYN,
          message: signContent
        }
      };

      console.log(mailSettingsWithSignature);

      await setEmailDirect({ type, credential, mailSettings:mailSettingsWithSignature });
            
      await dispatch(sessionActions.restoreSessionUser());
        
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: "서명 설정이 완료되었습니다.",
        open: true,
      });
    } catch (e) {
      console.error('서명 저장 실패:', e);
      const message = e.serverResponse?.message || '서명 저장에 실패했습니다.';
      setAlertInfo({
        open: true,
        titleAlert: '설정 오류',
        messageAlert: message
      });
    } finally {
      setLoading(false);
    }
  }
  // Quill 폰트 등록
  useEffect(() => {
    registerFonts(ReactQuill.Quill);
  }, []);

  useEffect(
    () => {
      setValue("credential", credential);
      setValue("email", email);

      console.log(sessionUser)
      
      if (type === "GCLIENT") {
        const service = services.find(item => item.host === sessionUser.mailSettings?.host);
        setValue("service", service ? service.value : "");
        setSelectedService(service);
      } else if (type === "USER") {
        const { user } = sessionUser;
        if (user) {
          const service = services.find(item => item.host === user.mailSettings?.host);
          setValue("service", service ? service.value : "");
          setSelectedService(service);
        }
      }

      // 서명 설정 초기화
      if (sessionUser?.mailSettings) {
        const footer = sessionUser.mailSettings.footer || {};
        if (footer.useYn) {
          setSignYN(footer.useYn);
        }
        setSignContent(footer.message ?? ""); // message가 undefined일 경우 빈 문자열로 설정
      }
    }, [open, sessionUser]
  )

  useEffect(
    () => {
      if (selectedService?.value === 'MANUAL') {
        setManual(true);
      } else {
        setManual(false);
      }
    }, [selectedService]
  )


  // 메일 서비스(네이버, 다음....) 변경
  const handleChangeType = (e) => {
    const selService = e.target.value;
    setValue("service", selService);
    
    setSelectedService(services.find(item => item.value === selService));
  }

  // 서명 사용 여부 변경
  const handleChangeSignYN = (e) => {
    const checked = e.target.checked;
    setSignYN(checked);
  }
  
  // 서명 내용 변경
  const handleSignContentChange = (value) => {
    setSignContent(value);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          {"메일 설정"}
        </DialogTitleClose>
        <DialogContent dividers>
          <Box sx={{ mt: 0 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Accordion expanded={mailExpanded} onChange={() => setMailExpanded(!mailExpanded)}>
                  <AccordionSummary aria-controls="mail-content" id="mail-header">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="div">{"보내는 메일 설정"}</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {
                      selectedService && selectedService.help && selectedService.steps && (
                        <Grid item xs={12} display="flex" alignItems="center">
                          <Stack direction="row">
                            <Link
                              href={selectedService.help}
                              underline="none"
                              target="_blank"
                              rel="noopener"
                            >
                              <Typography variant="subtitle2" display="block">
                                {
                                  selectedService.label ? (
                                    <Tooltip title="보내는 메일을 설정하는 방법을 보시려면 링크를 클릭해주세요.">
                                      <Button variant="contained">{"설정 가이드"}</Button>
                                    </Tooltip>
                                  ) : ""
                                }
                              </Typography>
                            </Link>
                            {
                              Array.isArray(selectedService.steps) && (
                                selectedService.steps.map((step, index) => {
                                  const { name, url, tip } = step;
                                  if (url) {
                                    return (
                                      <Link
                                        key={index}
                                        href={url}
                                        underline="none"
                                        target="_blank"
                                        rel="noopener"
                                      >
                                        <Typography key={index} variant="subtitle2" display="block"><Tooltip title={tip}>
                                          <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                                            <Button variant="outlined">
                                              {`${name}`}
                                            </Button>
                                          </span>
                                        </Tooltip></Typography>
                                      </Link>
                                    )
                                  } else {
                                    return (
                                      <Typography key={index} variant="subtitle2" display="block"><Tooltip title={tip}>
                                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                                          <Button variant="outlined" style={{textTransform: 'none'}} onClick={() => {
                                            appPasswordInputRef.focus();
                                          }}>
                                            {`${name}`}
                                          </Button>
                                        </span>
                                      </Tooltip></Typography>
                                    )
                                  }
                                })
                              )
                            }
                          </Stack>
                        </Grid>
                      )
                      }
                      <Grid item xs={12}>
                        <FormInputText
                          required
                          select
                          name={"service"}
                          control={control}
                          label={"서비스"}
                          options={services}
                          onChange={handleChangeType}
                          // value={details && details.length > i && details[i]}
                        />
                      </Grid>
                      {
                        manual === true ? (
                          <>
                            <Grid item xs={12}>
                              <FormInputText
                                // margin="normal"
                                required
                                fullWidth
                                name={"host"}
                                control={control}
                                label={"SMTP 서버"}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormInputText
                                // margin="normal"
                                required
                                fullWidth
                                name={"port"}
                                control={control}
                                label={"포트"}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormInputText
                                required
                                select
                                name={"secure"}
                                control={control}
                                label={"보안"}
                                options={[{ label: "TRUE", value: true }, { label: "FALSE", value: false }]}
                                // onChange={handleChangeType}
                                // value={details && details.length > i && details[i]}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormInputText
                                // margin="normal"
                                required
                                fullWidth
                                name={"email"}
                                control={control}
                                label={"메일계정"}
                                disabled
                              />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12}>
                              <FormInputText
                                // margin="normal"
                                required
                                fullWidth
                                name={"email"}
                                control={control}
                                label={"메일계정"}
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormInputText
                                // margin="normal"
                                required
                                fullWidth
                                name={"password"}
                                control={control}
                                label={"앱 비밀번호"}
                                type="password"
                                inputProps={{ // InputProps와는 다름
                                  ref: setAppPasswordInputRef,
                                }}
                              />
                            </Grid>
                          </>
                        )
                      }
                      <Grid item xs={12}>
                        <LoadingButton loading={loading} onClick={handleSubmit(onSubmit)} fullWidth variant="contained" >
                          {"메일 설정 하기"}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                    
                  </AccordionDetails>
                </Accordion>  
              </Grid>
              <Grid item xs={12}>
                <Accordion expanded={signExpanded} onChange={() => setSignExpanded(!signExpanded)}>
                  <AccordionSummary aria-controls="sign-content" id="sign-header">
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography variant="h6" component="div">{"서명 설정"}</Typography>
                          <Tooltip arrow placement="top-start"
                            title="메일을 보낼 때, 꼬릿말처럼 메일 내용 끝에 항상 첨부되는 내용입니다." 
                          >
                            <Help fontSize="small" color="action" sx={{ ml: 1 }} />
                          </Tooltip>
                        </Stack>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {/* 서명 사용 */}
                      <Grid item xs={12}>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <FormLabel component="legend" sx={{ color: 'black', fontSize: '16px' }}>서명 사용</FormLabel>
                            
                            <FormControlLabel
                              control={
                              <Switch
                                checked={signYN}
                                onChange={handleChangeSignYN}
                                />
                            }
                              label={signYN ? "사용" : "사용안함"}
                            />
                          </Stack>
                      </Grid>
                      {/* 서명 내용 */}
                      <Grid item xs={12}>
                          <Box sx={smallStyle}>
                            <ReactQuill
                              value={signContent}
                              onChange={handleSignContentChange}
                              modules={fontModules}
                              formats={fontFormats}
                              placeholder="서명 내용을 입력하세요..."
                              theme="snow"
                              style={{ 
                                height: '150px',    // 전체 높이
                                marginBottom: '20px'
                              }}
                            />
                          </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <LoadingButton loading={loading} onClick={handleSubmit(onSignSubmit)} fullWidth variant="contained" >
                          {"서명 설정 하기"}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {/* <LoadingButton loading={loading} onClick={handleSubmit(onSubmit)}>{"메일 설정"}</LoadingButton> */}
          {/* <LoadingButton loading={loadingTest} onClick={handleClickTest}>{"메일 설정"}</LoadingButton> */}
          {/* <LoadingButton loading={loading} onClick={handleSubmit(onSubmit)}>{"설정"}</LoadingButton> */}
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
      {/* <ConfirmDialog
        removeId={null}
        title={"메일 설정"}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={setMailSettings}
        onCancel={() => {}}
      >
        {"메일 설정 테스트가 완료되었습니다. 이어서 메일 설정까지 완료하시겠습니까?"}
      </ConfirmDialog> */}
    </>
  );
};

export default EmailSettingDialog;
