// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { projectServerUrl } from '../config';

const SET_GPROJECT = 'gproject/setGProject';
const REMOVE_GPROJECT = "gproject/removeGProject";
const SET_GPROJECTS = 'gproject/setGProjects';

const setGProject = (gproject) => ({
  type: SET_GPROJECT,
  payload: gproject,
});

const removeGProject = () => ({
  type: REMOVE_GPROJECT,
});

const setGProjects = (gprojects) => ({
  type: SET_GPROJECTS,
  payload: gprojects,
});

export const create = ({
  id,
  site,
  siteAddress,
  startDate,
  endDate,
  gclientDetails,
  comments,
  doneYN,
  constructionWorkTypeId,
  selectedGlasses,
  selectedSubMaterialProcessItems,
  selectedSubMaterialBuildItems,
  docu04=false,
}) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojects`, {
    method: "POST",
    body: JSON.stringify({
      id,
      site,
      siteAddress,
      startDate,
      endDate,
      gclientDetails,
      comments,
      doneYN,
      constructionWorkTypeId,
      selectedGlasses,
      selectedSubMaterialProcessItems,
      selectedSubMaterialBuildItems,
      docu04,
    }),
  });
  const data = await response.json();

  dispatch(setGProject(data.gproject));

  return response;
};

export const createWithResult = async ({
  id,
  site,
  siteAddress,
  startDate,
  endDate,
  completionDate,
  gclientDetails,
  constructionCompanyName,
  comments,
  doneYN,
  constructionWorkTypeId,
  selectedGlasses,
  selectedSubMaterialProcessItems,
  selectedSubMaterialBuildItems,
  docu04=false,
  gdomesticAuths,
}) => {
  const response = await csrfFetch(null, `${projectServerUrl}/api/gprojects`, {
    method: "POST",
    body: JSON.stringify({
      id,
      site,
      siteAddress,
      startDate,
      endDate,
      completionDate,
      gclientDetails,
      constructionCompanyName,
      comments,
      doneYN,
      constructionWorkTypeId,
      selectedGlasses,
      selectedSubMaterialProcessItems,
      selectedSubMaterialBuildItems,
      docu04,
      gdomesticAuths,
    }),
  });
  const data = await response.json();
  return data;
};

export const modify = ({
  id,
  site,
  siteAddress,
  startDate,
  endDate,
  completionDate,
  gclientDetails,
  constructionCompanyName,
  comments,
  doneYN,
  constructionWorkTypeId,
  selectedGlasses,
  selectedSubMaterialProcessItems,
  selectedSubMaterialBuildItems,
  docu04=false,
  gdomesticAuths,
}) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojects`, {
    method: "PUT",
    body: JSON.stringify({
      id,
      site,
      siteAddress,
      startDate,
      endDate,
      completionDate,
      gclientDetails,
      constructionCompanyName,
      comments,
      doneYN,
      constructionWorkTypeId,
      selectedGlasses,
      selectedSubMaterialProcessItems,
      selectedSubMaterialBuildItems,
      docu04,
      gdomesticAuths,
    }),
  });
  const data = await response.json();

  dispatch(setGProject(data.gproject));

  return response;
};

export const modifyWithResult = async ({
  id,
  site,
  siteAddress,
  startDate,
  endDate,
  completionDate,
  gclientDetails,
  constructionCompanyName,
  comments,
  doneYN,
  constructionWorkTypeId,
  selectedGlasses,
  selectedSubMaterialProcessItems,
  selectedSubMaterialBuildItems,
  docu04=false,
  gdomesticAuths,
  ownerId,
}) => {
  // Wed Dec 07 2022 00:00:00 GMT+0900 (한국 표준시)
  // JSON.stringify하면 2022-12-06T15:00:00.000Z 형태로 바뀜
  const response = await csrfFetch(null, `${projectServerUrl}/api/gprojects`, {
    method: "PUT",
    body: JSON.stringify({
      id,
      site,
      siteAddress,
      startDate,
      endDate,
      completionDate,
      gclientDetails,
      constructionCompanyName,
      comments,
      doneYN,
      constructionWorkTypeId,
      selectedGlasses,
      selectedSubMaterialProcessItems,
      selectedSubMaterialBuildItems,
      docu04,
      gdomesticAuths,
      ownerId,
    }),
  });
  const data = await response.json();
  return data;
};

export const modifyDoneYN = ({ id, doneYN }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojects/doneYN`, {
    method: "PUT",
    body: JSON.stringify({ id, doneYN }),
  });
  const data = await response.json();
  dispatch(setGProject(data.gproject));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojects`);
  const data = await response.json();
  // console.log(data.gprojects)
  dispatch(setGProjects(data.gprojects));
  return response;
}

export const selectAllByOwnerByQuery = (ownerId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojects/query/selectAll/owner/${ownerId}`);
  const data = await response.json();
  // console.log(data.gprojects)
  dispatch(setGProjects(data.gprojects));
  return response;
}

export const selectAllProjectsOr4docuProjects = (docu04, searchName, searchSite, startDate, endDate, completionDate) => async dispatch => {
  const params = [];
  if (searchName) {
    params.push(`searchName=${searchName}`);
  }
  if (searchSite) {
    params.push(`searchSite=${searchSite}`);
  }
  if (startDate) {
    params.push(`startDate=${startDate}`);
  }
  if (endDate) {
    params.push(`endDate=${endDate}`);
  }
  if (completionDate) {
    params.push(`completionDate=${completionDate}`);
  }

  let querString = params.join("&");

  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojects/docu04/${docu04}${querString ? `?${querString}` : ""}`);
  const data = await response.json(); 
  // console.log(data.gprojects)
  dispatch(setGProjects(data.gprojects));
  return response;
}

export const selectProjectsOr4docuProjects = (docu04, gclientId, searchName, searchSite, startDate, endDate, completionDate) => async dispatch => { // TODO : 위의 selectAllByOwnerByQuery와 용어(owner or gclient) 관련 검토
  const params = [];
  if (searchName) {
    params.push(`searchName=${searchName}`);
  }
  if (searchSite) {
    params.push(`searchSite=${searchSite}`);
  }
  if (startDate) {
    params.push(`startDate=${startDate}`);
  }
  if (endDate) {
    params.push(`endDate=${endDate}`);
  }
  if (completionDate) {
    params.push(`completionDate=${completionDate}`);
  }

  let querString = params.join("&");

  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojects/docu04/${docu04}/gclient/${gclientId}${querString ? `?${querString}` : ""}`);
  const data = await response.json();
  // console.log(data.gprojects)
  dispatch(setGProjects(data.gprojects));
  return response;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojects/${id}`);
  const data = await response.json();
  // console.log(data.gproject)
  dispatch(setGProject(data.gproject));
  return response;
}

export const selectDirect = async (id) => {
  const response = await csrfFetch(null, `${projectServerUrl}/api/gprojects/${id}`);
  const data = await response.json();
  // console.log(data.gproject)
  return data.gproject;
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojects/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGProject());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${projectServerUrl}/api/gprojects/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  gproject: null,
  gprojects: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GPROJECT:
      newState = Object.assign({}, state, { gproject: action.payload });
      return newState;
    case REMOVE_GPROJECT:
      newState = Object.assign({}, state, { gproject: null });
      return newState;
    case SET_GPROJECTS:
      newState = Object.assign({}, state, { gprojects: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
