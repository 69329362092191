import { csrfFetch } from "./csrf.js";
import { authServerUrl } from '../config';

const SET_ADDRESS_BOOK = 'addressBook/setAddressBook';
const REMOVE_ADDRESS_BOOK = "addressBook/removeAddressBook";
const SET_ADDRESS_BOOKS = 'addressBook/setAddressBooks';

const setAddressBook = (addressBook) => ({
  type: SET_ADDRESS_BOOK,
  payload: addressBook,
});

const removeAddressBook = () => ({
  type: REMOVE_ADDRESS_BOOK,
});

const setAddressBooks = (data) => ({
  type: SET_ADDRESS_BOOKS,
  payload: data,
});

export const create = ({ name, email, phone, company, duties, publicYN }) => async dispatch => {
  console.log('create => ', { name, email, phone, company, duties, publicYN });
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/addressbooks`, {
    method: "POST",
    body: JSON.stringify({ name, email, phone, company, duties, publicYN }),
  });
  const data = await response.json();
  dispatch(setAddressBook(data.addressbook));
  return response;
};

export const modify = ({ seq, name, email, phone, company, duties, publicYN=null }) => async dispatch => {
  console.log('modify => ', { seq, name, email, phone, company, duties, publicYN });
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/addressbooks/seq/${seq}`, {
    method: "PUT",
    body: JSON.stringify({ seq, name, email, phone, company, duties, publicYN }),
  });
  const data = await response.json();
  console.log('modify(response) => ', data);
  dispatch(setAddressBook(data.addressbook));
  return response;
};

export const remove = (seq) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/addressbooks/seq/${seq}`, {
    method: "DELETE",
  });
  dispatch(removeAddressBook());
  return response;
};

export const selectAll = (searchWord, sort, initConst) => async dispatch => {
  let url = `${authServerUrl}/api/addressbooks?`;
  if (searchWord) url += `searchWord=${encodeURIComponent(searchWord)}&`;
  if (sort) url += sort.map(s => `sort=${s.field},${s.sort}`).join('&');
  if (initConst) url += `initConst=${encodeURIComponent(initConst)}&`;
  
  const response = await csrfFetch(dispatch, url);
  const data = await response.json();
  dispatch(setAddressBooks(data));
  return response;
}

export const select = (seq) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/addressbooks/seq/${seq}`);
  const data = await response.json();
  dispatch(setAddressBook(data.addressbook));
  return response;
}  

const initialState = {
  addressBook: null,
  addressBooks: [],
  count: 0,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_ADDRESS_BOOK:
      console.log('SET_ADDRESS_BOOK action received:', action.payload);
      newState = Object.assign({}, state, { addressBook: action.payload });
      return newState;
    case REMOVE_ADDRESS_BOOK:
      newState = Object.assign({}, state, { addressBook: null });
      return newState;
    case SET_ADDRESS_BOOKS:
      newState = Object.assign({}, state, { addressBooks: action.payload.addressbooks, count: action.payload.count });
      return newState;
    default:
      return state;
  }
}

export default reducer;