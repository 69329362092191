import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Badge,
  Chip,
  CircularProgress,
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Switch,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  styled,
} from '@mui/material';
import  {
  Add,
  Attachment,
  Search,
  Delete,
  Edit,
  FilePresent,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
  DoNotTouch,
  Preview,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputAutoComplete,
  FormInputText
} from "../form";
import {
  AlertDialog,
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import {
  StringAvatar,
} from "../avatar";
import { StyledTooltip } from "../tooltip";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import { dateFormat } from "../../utils";
import { uploadFilePath, fileServerUrl, mode } from '../../config';
import * as gclientActions from "../../store/gclient";
import * as securityActions from "../../store/security";
import * as g04docuGCertificationActions from "../../store/g04docuGCertification";
import * as g04docuGTestActions from "../../store/g04docuGTest";
import * as g04docuFileActions from "../../store/g04docuFile";
import * as G04docuGTestSubstituteActions from "../../store/g04docuGTestSubstitute";
import * as gclientG04docuGTestMapActions from "../../store/gclientG04docuGTestMap";
import * as gcomponentItemActions from "../../store/gcomponentItem";
import * as g04docuSearchActions from "../../store/g04docuSearch";
import * as gsendBasketActions from "../../store/gsendBasket";
import G04docuSearchDialog from "./G04docuSearchDialog";

import { filePreviewServerUrl } from '../../config';
import { getGridHeight } from '../../constants/gridHeights';
import * as securityRequestActions from "../../store/securityRequest";
import { tr } from "date-fns/locale";

const theme = createTheme();

const defaultValues = {
  // gclientId: "",
  // division: '',
  type: 'NONE',
  searchWord: "",
  name: "",
  classification: "",
};

// const ITEM_HEIGHT = 48;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -10,
    top: 20,
    // border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: "white",
    backgroundColor: "#9e9e9e",
  },
}));

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const G04docuSearchManagement = ({
  title,
  from,
}) => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const [gclientId, setGClientId] = useState("");
  const [gclients, setGClients] = useState([]);
  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState({
    title: "",
    content: "",
    onConfirm: () => {},
    onCancel: () => {}
  });
  const [searchConfirm, setSearchConfirm] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [classificationTypes, setClassificationTypes] = useState([]);
  const [display, setDisplay] = useState('none');
  const [hideGClientNameColumn, setHideGClientNameColumn] = useState(true);
  const [g04docuNames, setG04docuNames] = useState([]);
  // const [rowCount, setRowCount] = useState(100);
  
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedGComponentItemName, setSelectedGComponentItemName] = useState("");
  const [gstandardId, setGStandardId] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [alertInfo, setAlertInfo] = useState({});

  const [opened, setOpened] = useState(true);

  // const openFunctionMenu = Boolean(anchorEl);
  
  // 기능버튼 메뉴
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // 현재 화면에 맞는 Grid 높이 계산
  const GRID_HEIGHT = getGridHeight('TWO_LINE_BUTTON_LAYER', -9); // 0px 추가 여백

  const handleSelect = async ({ type, params }) => {
    if (!opened) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: "비공개 거래선의 문서이므로 전송바구니에 추가할 수 없습니다.",
        open: true,
      });
      return;
    }

    // setAnchorEl(null);
    try {
      console.log(params)
      setOpenBackdrop(true);
      // const id = selectedRows[0].id;
      const { g04docuId, gclientId, documentType, typeAlias, division, g04docuGCertificationName, name, gcomponentItemName, selectedClassifications, validYN, valid } = params.row;
      
      const ownerId = sessionUser.id; // 전송바구니를 사용하는 현재 접속계정(거래선)의 아이디
      if (type === "addToSendBasket") {
        // TODO : 화면에 나타난 이름을 저장할 필요가 있음. (다시 데이터베이스 검색으로 만들기에는 쿼리가 복잡해짐)
        const gclient = gclients.find(gclient => gclient.id === gclientId);

        const res = await putInGSendBasketDirect({ id: uuidv4(), g04docuId, gclientId, gclient, documentType, ownerId, name: { type: typeAlias, division, g04docuGCertificationName, name, gcomponentItemName, selectedClassifications, validYN, valid } });
        if(res.duplicated) {
          setAlertInfo({
            titleAlert: "안내",
            // messageAlert: "전송바구니에 이미 추가되어 있습니다.",
            messageAlert: (<div><span style={{ color: "#ED6C02" }}>{"전송바구니에 이미 추가되어 있습니다."}</span></div>),
            open: true,
          });
        } else {
          const { id, sessionUserLoginType } = sessionUser;
          let creatorId = "";
          if (sessionUserLoginType === 'GCLIENT') {
            creatorId = id;
          } else if (sessionUserLoginType === 'USER') {
            if (sessionUser.user) {
              creatorId = sessionUser.user.id;
            }
          }

          await selectGSendBasketsByCreatorByQuery(creatorId);

          setAlertInfo({
            titleAlert: "안내",
            messageAlert: "전송바구니에 추가되었습니다.",
            open: true,
          });
        }
      }
    } catch (e) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: e.message,
        open: true,
      });
    } finally {
      setOpenBackdrop(false);
    }
  }

  // const options = [
  //   {
  //     text: '수정',
  //     icon: <Edit fontSize="small" />,
  //     type: 'edit',
  //   },
  //   {
  //     text: '삭제',
  //     icon: <Delete fontSize="small" />,
  //     type: 'delete',
  //   },
  // ];
  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<Add />}
        label={"전송바구니 담기"}
        onClick={() => handleSelect({ type: 'addToSendBasket', params })}
        showInMenu
        disabled={!opened}
      />,
    ];

    return arrActions;
  }

  const comparator = (v1, v2) => {
    console.log({ v1, v2 })
  }

  const columns = [
    // column id는 문제가 없음. 데이터행(rows에 id가 있고 id만 고유하면 됨)
    // {
    //   field: 'id',
    //   headerName: '아이디',
    //   width: 200,
    //   hide: true,
    // },
    // {
    //   field: 'g04docuId',
    //   headerName: '문서아이디',
    //   width: 200,
    //   // editable: true,
    //   hide: true,
    // },
    {
      field: 'gclient',
      headerName: '거래선명',
      width: 200,
      // editable: true,
      hide: hideGClientNameColumn,
      // valueGetter: (params) => {
      //   return params.row.gclient.name;
      // }
      renderCell: (params) => {
        const { gclient, validYN, valid } = params.row;
        return (
          <Grid sx={{ color: (validYN || valid) ? '1976d2' : '#ed6c02' }}>
            {gclient?.name}
          </Grid>
        )
      }
    },
    // {
    //   field: 'type',
    //   headerName: '종류',
    //   width: 120,
    // },
    {
      field: 'typeAlias',
      headerName: '문서종류',
      width: 140,
      renderCell: (params) => {
        const { typeAlias, gstandardTypeName, validYN, valid } = params.row;
        let value = "";
        typeAlias === "성적서" ? value = gstandardTypeName : value = typeAlias;
        return (<Grid sx={{ color: (validYN || valid) ? '1976d2' : '#ed6c02' }}>
          {value}
        </Grid>)
      }
    },
    {
      field: 'name',
      headerName: '이름',
      width: 500,
      // renderHeader: (params) => (
      //   <Grid container>
      //     <Grid item xs={3}>
      //       {"이름"}
      //     </Grid>
      //     <Grid item xs={9} display="flex" justifyContent={"flex-end"}>
      //       <Stack direction="row" spacing={1} alignItems="center">
      //         <StringAvatar name={"원"} width={20} height={20} fontSize={"small"} color={"#64b5f6"} />{"자재"}
      //         <StringAvatar name={"가"} width={20} height={20} fontSize={"small"} color={"#a5d6a7"} />{"공부자재"}
      //         <StringAvatar name={"시"} width={20} height={20} fontSize={"small"} color={"#ffb74d"} />{"공부자재"}
      //         <StringAvatar name={"공"} width={20} height={20} fontSize={"small"} color={"#ce93d8"} />{"정"}
      //       </Stack>
      //     </Grid>
      //   </Grid>
      // ),
      renderCell: (params) => {
        const { /*division, */name, validYN, valid } = params.row;
        // let ele;
        // if (division === 'RAW_MATERIAL') {
        //   ele = <StringAvatar name={"원"} width={20} height={20} fontSize={"small"} color={"#64b5f6"} />;
        // } else if (division === 'SUB_MATERIAL_PROCESS') {
        //   ele = <StringAvatar name={"가"} width={20} height={20} fontSize={"small"} color={"#a5d6a7"} />;
        // } else if (division === 'SUB_MATERIAL_BUILD') {
        //   ele = <StringAvatar name={"시"} width={20} height={20} fontSize={"small"} color={"#ffb74d"} />;
        // } else if (division === 'PROCESS') {
        //   ele = <StringAvatar name={"공"} width={20} height={20} fontSize={"small"} color={"#ce93d8"} />;
        // }
        // if (ele) {
        //   return (
        //     <Grid container>
        //       <Grid item xs={11} sx={{ color: (validYN || valid) ? '1976d2' : '#ed6c02' }}>
        //         {name}
        //       </Grid>
        //       <Grid item xs={1} display="flex" justifyContent={"flex-end"}>
        //         {ele}
        //       </Grid>
        //     </Grid>
        //   )
        // }

        return (
          <Grid container>
            <Grid item xs={12} sx={{ color: (validYN || valid) ? '' : '#ed6c02' }}>
              {name}
            </Grid>
          </Grid>
        )
      }
    },
    {
      field: 'selectedClassifications',
      headerName: '비고',
      width: 260,
      renderCell: (params) => {
        const { selectedClassifications, validYN, valid } = params.row;
        if (selectedClassifications && Array.isArray(selectedClassifications)) {
          return selectedClassifications
                  .filter(value => value.checked === true)
                  .map(value=> <Chip
                    style={{ borderColor: '#ed6c02' }} // TODO : 추후 styling 방법 통일할 것
                    variant={(validYN || valid) ? "" : "outlined"}
                    label={value.type}
                    size="small"
                    sx={{ ml: 1, fontSize: '0.85em', color: (validYN || valid) ? '' : '#ed6c02' }}>
                  </Chip>);
        }
      }
    },
    {
      field: 'g04docuGCertificationName',
      headerName: '인증규격',
      width: 220,
      // sortComparator: comparator, // localeCompare, toUpperCase, toUpperCase
      renderCell: (params) => {
        const { g04docuGCertificationName, validYN, valid } = params.row;
        return (
          <Grid sx={{ color: (validYN || valid) ? '' : '#ed6c02' }}>
            {g04docuGCertificationName}
          </Grid>
        )
      }
    },
    {
      field: 'division',
      headerName: '구분',
      width: 200,
      hide: true,
    },
    {
      field: 'documentPath',
      headerName: '자재승인서류',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { documentPath, validYN, valid } = params.row;
        // isPublicAboutMe(params.row);
        if (opened) {
          return (
            <StyledTooltip
              title={<Stack direction="row" display="flex" alignItems="center">
                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                  {`미리보기`}
                </Box>
                <Typography variant="subtitle2">{documentPath?.split("/")[documentPath?.split("/")?.length-1]}</Typography>
              </Stack>}
              placement="right"
              sx={tooltipTop}
            >
              <IconButton
                color="primary"
                aria-label="file"
                onClick={(e) => handleClickViewEachDoc(e, params, documentPath)}
                sx={{ color: (validYN || valid) ? "#1976D2" : "#ED6C02" }}
              >
                <Preview />
              </IconButton>
            </StyledTooltip>
          )
        } else {
          return (
            <Tooltip title={"비공개 거래선의 문서는 열람할 수 없습니다."}>
              <IconButton
                color="primary"
                aria-label="file"
                edge="end"
                // disabled
                sx={{ color: "#BDBDBD"}}
              >
                <DoNotTouch />
              </IconButton>
            </Tooltip>
          )
        }
      }
    },
    {
      field: 'endDate',
      headerName: '만료일',
      width: 140,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { validYN, valid } = params.row;
        if (validYN) {
          return (
            <span style={{ /*fontWeight: 'bold', color: '#1976d2'*/ }}>
              {"만료일 없음"}
            </span>
          );
        } else {
          if (valid) {
            return (
              <span style={{ /*fontWeight: 'bold', color: '#1976d2'*/ }}>
                {params.value && dateFormat(params.value, 'yyyy-MM-dd')}
              </span>
            );
            
          } else {
            return (
              <span style={{ /*fontWeight: 'bold', */color: '#ed6c02' }}>
                {params.value && dateFormat(params.value, 'yyyy-MM-dd')}
              </span>
            );
          }
        }
      },
    },    
    {
      field: 'actions',
      headerName: <Tooltip title={"전송바구니 담기"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];

  const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const sessionUser = useSelector(state => state.session.sessionUser);
  // TODO : 아래 rows와 rowCount 따로 받아서 두번 렌더링 되는지 확인해볼 것. 그렇다면 하나의 객체로 통합할 것
  const rows = useSelector((state) => state.g04docuGTest.g04docuGTests);
  const classifications = useSelector((state) => state.g04docuGCertification.classificationsDistinct);
  const rowsG04docuSearch = useSelector((state) => state.g04docuSearch.g04docuSearch);

  const securityOpenGClients = useSelector((state) => state.security.securityOpenGClients);
  const securityCloseGClients = useSelector((state) => state.security.securityCloseGClients);
  // const securityOpenedGClientsAboutMe = useSelector((state) => state.security.securityOpenedGClientsAboutMe);
  
  // 데이터 관리
  const dispatch = useDispatch();

  const selectAllGLThickness = (gcomponentCode) => dispatch(gcomponentItemActions.selectAllByGcomponentCode(gcomponentCode));
  const selectG04docuGTestDirect = (id) => gclientG04docuGTestMapActions.selectByQueryDirect(id)
  const selectAllByQuery = ({ gclientId, type, division, searchWord, classifications, gtestNameFilter }) => dispatch(g04docuSearchActions.selectAllByQuery({ gclientId, type, division, searchWord, classifications, gtestNameFilter }))
  const select = (id) => dispatch(g04docuGTestActions.select(id))
  const initializeG04docuGTests = () => dispatch(g04docuGTestActions.initializeG04docuGTests())
  const reorder = ({ type, id }) => g04docuGTestActions.reorder({ type, id })
  const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)
  const selectAllGClientDirect = () => gclientActions.selectAllDirect()
  // const selectAllGClientDirect = () => gclientActions.selectValidAllDirect()
  const selectClassificationsDistinct = () => dispatch(g04docuGCertificationActions.selectClassificationsDistinct())
  const createSubstitute = ({
    id,
    division,
    name,
    code,
    substituteInformation,
    gcomponentItemId,
    g04docuGCertificationId,
    gstandardTypeId,
    gclientId,
    gclient,
    // supplierId,
    // supplier,
    gprojectId,
    site,
    documentPath,
    documentType,
    startDate,
    endDate,
  }) => dispatch(G04docuGTestSubstituteActions.createWithDoc({
    id,
    division,
    name,
    code,
    substituteInformation,
    gcomponentItemId,
    g04docuGCertificationId,
    gstandardTypeId,
    gclientId,
    gclient,
    // supplierId,
    // supplier,
    gprojectId,
    site,
    documentPath,
    documentType,
    startDate,
    endDate,
  }))
  const selectGSendBasketsByCreatorByQuery = (creatorId) => dispatch(gsendBasketActions.selectAllByCreatorByQuery(creatorId))
  const putInGSendBasketDirect = ({ id, g04docuId, gclientId, gclient, documentType, ownerId, name }) => gsendBasketActions.createDirect({ id, g04docuId, gclientId, gclient, documentType, ownerId, name })
  const putInGSendBasketsDirect = (gsendBaskets) => gsendBasketActions.createBulkDirect(gsendBaskets)

  const selectAllNamesByQueryDirect = ({ gclientId, name }) => g04docuSearchActions.selectAllNamesByQueryDirect({ gclientId, name })
  const selectOpenedAboutMeGClientListDirect = (service, gclientId) => securityActions.selectOpenedAboutMeGClientListDirect(service, gclientId)

  // 거래선 문서 전송 요청
  const createSecurityRequest = ({ id, senderId, receiverId, service, status }) => dispatch(securityRequestActions.create({ id, senderId, receiverId, service, status }))
  // 거래선 문서 전송 요청 상태 조회
  const selectGClientsBySecurityRequestStatusByQueryDirect = ({ senderId, receiverIds }) => securityRequestActions.selectGClientsBySecurityRequestStatusByQueryDirect({ senderId, receiverIds });

  const queryPaging = useMemo(
    () => ({
      page,
      pageSize,
    }), [page]
  );

  console.log("queryPaging")
  console.log(queryPaging)

  useEffect(
    async () => {
      const resGClients = await selectAllGClientDirect();
      console.log(resGClients)
      setGClients(resGClients);

      selectClassificationsDistinct();
      // // TODO : 아래 코드를 selectPaging으로 바꾸고 페이지 변경시 호출. 단점은 hideWatermark가 매번 호출됨
      // if (from?.params) {
      //   const paramGClientId = from?.params?.gclientId;
      //   const paramDivision = from?.params?.division;
      //   const paramItemOrCertificationName = from?.params?.itemOrCertificationName;

      //   console.log({ gclientId: paramGClientId, division: paramDivision, itemOrCertificationName: paramItemOrCertificationName });
        
      //   setGClientId(from?.params?.gclientId);
      //   setValue("division", from?.params?.division);
      //   setValue("itemOrCertificationName", paramItemOrCertificationName);
      //   await selectAllByQuery({
      //     gclientId: paramGClientId,
      //     division: paramDivision,
      //     itemOrCertificationName: paramItemOrCertificationName,
      //   });
      // } else {
      //   // 거래선과 종류(원자재/가공부자재/시공부자재/공정), 제품 또는 공정명 검색 필드를 보이도록 한다.
        setDisplay('');

        const { id: gclientId, type } = sessionUser;
        // TODO : 우신복층유리 1차 오픈을 위해 다른 업체 문서도 검색하도록 허용
        // if (type === 'ADMIN') {
          setHideGClientNameColumn(false);
        // } else {
        //   selectG04docus({ gclientId });
        // }
      //   await initializeG04docuGTests();
      // }
      
      setLoaded(true);
    }, [dispatch]
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  }

  const handleClickFileDownload = async (e, documentPath) => {
    e.stopPropagation();

    const arr = documentPath.split("/");
    const fileName = arr[arr.length - 1];

    const res = await downloadG04docuFile(documentPath);
    const blob = await res.blob(); // res.blob는 promise를 리턴함???
    
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));            
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  // const handleClickViewEachDoc = (e, params, documentPath) => {
  //   // 이벤트 전파 중지
  //   e.stopPropagation();
  //   // d6cdfae6-f7d3-475d-afed-9176029812a8/a54064ee-52d7-4f46-97c0-93d0fb89d75d
  //   // /home/warpcore/project/g04docu/금강글라스(주)(a54064ee-52d7-4f46-97c0-93d0fb89d75d)/TEST/복층유리(bc096321-bf2f-46f3-9528-dce275826ef1)/5 CL + 12 A + 5 CL(d6cdfae6-f7d3-475d-afed-9176029812a8)/금강글라스_23년A종22T.pdf
  //   const id = params.id;
  //   const [g04docuId, gclientId] = id.split("/");
    
  //   let path = "";
  //   if (sessionUser.type === 'ADMIN' || sessionUser.id === gclientId) {
  //     const randNumber = Math.floor(Math.random()*99); // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
  //     const encodedPath = encodeURIComponent(documentPath.replace(uploadFilePath, ''));
  //     path = `${fileServerUrl}${encodedPath}?q=cat&${randNumber}`;
  //   } else {
  //     path = `${filePreviewServerUrl}${encodeURIComponent(documentPath)}`;
  //   }

  //   console.log('문서 ID:', g04docuId, ', 거래선 ID:', gclientId, ', 세션 사용자 ID:', sessionUser.id);

  //   //window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  //   window.open(`${path}`, "미리보기", 'target="_self"');
  // }
  const handleClickViewEachDoc = (e, params, documentPath) => {
    // 이벤트 전파 중지
    e.stopPropagation();
    // d6cdfae6-f7d3-475d-afed-9176029812a8/a54064ee-52d7-4f46-97c0-93d0fb89d75d
    // /home/warpcore/project/g04docu/금강글라스(주)(a54064ee-52d7-4f46-97c0-93d0fb89d75d)/TEST/복층유리(bc096321-bf2f-46f3-9528-dce275826ef1)/5 CL + 12 A + 5 CL(d6cdfae6-f7d3-475d-afed-9176029812a8)/금강글라스_23년A종22T.pdf
    const id = params.id;
    const [g04docuId, gclientId] = id.split("/");
    
    let path = "";
    if (sessionUser.type === 'ADMIN' || sessionUser.id === gclientId) {
      const randNumber = Math.floor(Math.random()*99); // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
      const encodedPath = documentPath
                        .replace(uploadFilePath, '')
                        .split('/')
                        .map(part => encodeURIComponent(part))
                        .join('/');

      path = `${fileServerUrl}${encodedPath}?q=cat&${randNumber}`;
    } else {
      const encodedPath = documentPath
                          .split('/')
                          .map(part => encodeURIComponent(part))
                          .join('/');
      path = `${filePreviewServerUrl}${encodedPath}`;
    }

    console.log('문서 ID:', g04docuId, ', 거래선 ID:', gclientId, ', 세션 사용자 ID:', sessionUser.id);

    //window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
    window.open(`${path}`, "미리보기", 'target="_self"');
  }

  const selectG04docus = async ({ gclientId, searchWord, type, classification, gtestNameFilter }) => {
    // TODO : 현재 우신복층유리에서는 자사의 문서 외에 다른 회사의 성적서도 관리하고 송부하고 있음. 따라서 우선 우신복층유리 1차오픈에서는 아래 구분 삭제
    // const paramGClientId = sessionUser.type === 'ADMIN' ? gclientId : sessionUser.id;
    const paramGClientId = gclientId;
    const paramSearchWord = searchWord ? searchWord : getValues("searchWord");
    const paramType = type ? type : getValues("type");
    const paramClassification = classification ? classification : getValues("classification");
    const paramGTestNameFilter = gtestNameFilter;
    let paramClassifications = [];
    if (paramClassification) {
      const selectedClassificationTypes = classificationTypes.filter(c => c.indexOf(paramClassification) >= 0);
      paramClassifications = selectedClassificationTypes.length > 0 ? selectedClassificationTypes : [paramClassification];
    }
    // if (paramSearchWord) {
    //   const selectedClassificationTypes = classificationTypes.filter(c => c.indexOf(paramSearchWord) >= 0);
    //   console.log(selectedClassificationTypes)
    //   paramClassifications = selectedClassificationTypes.length > 0 ? selectedClassificationTypes : [];
    // }

    console.log({
      gclientId: paramGClientId,
      type: paramType,
      searchWord: paramSearchWord,
      classificationTypes,
      classifications: paramClassifications,
      gtestNameFilter: paramGTestNameFilter,
    })
    // return;
    await selectAllByQuery({
      gclientId: paramGClientId,
      type: paramType,
      searchWord: paramSearchWord,
      classifications: paramClassifications,
      gtestNameFilter: paramGTestNameFilter,
    });
  }

  // 거래선명으로 검색
  const handleChangeGClient = async (e, value) => {
    if (value) {
      const { id: gclientId, label: gclientName } = value;
      selectG04docus({ gclientId });
      setGClientId(gclientId);

      const service = '04docu';
      const securityOpenedGClientsAboutMe = await selectOpenedAboutMeGClientListDirect(service, sessionUser.id);
      console.log({ securityCloseGClients, securityOpenGClients, securityOpenedGClientsAboutMe })

      const openedGClients = securityOpenedGClientsAboutMe.filter(gclient => gclient.id === gclientId);
      if (openedGClients.length > 0) {
        setOpened(true);
        setSearchConfirm(false);
      } else {
        setOpened(false);

        const closedAboutMeWithStatus = await selectGClientsBySecurityRequestStatusByQueryDirect({ senderId: sessionUser.id, receiverIds: [gclientId] });
        console.log('closedAboutMeWithStatus = ' , { closedAboutMeWithStatus })
        if (closedAboutMeWithStatus && closedAboutMeWithStatus.length > 0) {
          const status = closedAboutMeWithStatus[0].status;
          if (status === '요청') {
            setConfirmOpen(false);
            setAlertInfo({
              titleAlert: "안내",
              messageAlert: (
                <span>
                  <span style={{ color: '#1976d2' }}>{gclientName}</span>에 자재승인서류 열람승인이 진행중 상태입니다.
                </span>
              ),
              open: true,
            });
          } else {
            setConfirmOpen(true);
            handleSetConfirmInfo(gclientName);
          }
        } else {
          setConfirmOpen(true);
          handleSetConfirmInfo(gclientName);
        }
      }
    } else {
      setGClientId("");
    }
  }

  const handleSetConfirmInfo = (gclientName) => {
    setConfirmInfo({
      title: "안내",
      content: (
        <div>
          <p>귀사에 자재승인서류 열람을 허가하지 않은 거래선입니다.</p>
          <p><span style={{ color: '#1976d2' }}>{gclientName}</span>에 열람을 요청하시겠습니까?</p>
        </div>
      ),
      onConfirm: async () => {
        try {
          await createSecurityRequest({ 
            id: uuidv4(), 
            senderId: sessionUser.id, 
            receiverId: gclientId, 
            service: '04docu', 
            status: '요청' 
          });
          console.log("열람 요청이 성공적으로 전송되었습니다.");
        } catch (error) {
          console.error("열람 요청 중 오류가 발생했습니다:", error);
        }
      },
      onCancel: () => {}
    });
  };

  const handleChangeNameFilter = (e, value) => {
    if (value) {
      const { name } = value;
      console.log(name)
      selectG04docus({ gclientId, gtestNameFilter: name });
    } else {
      selectG04docus({ gclientId });
    }
  }

  // 일반서류/인증서/성적서/기타
  const handleChangeType = async (e) => {
    const { value: type } = e.target;
    selectG04docus({ gclientId, type });
    setValue("type", type);

    if (type === "1. 일반서류" || type === "4. 기타") {
      setValue("classification", "");
    } else if (type === "3. 성적서") { // TODO : 성적서 상세 FormInputAutoComplete 구성
      const g04docuSearchNames = await selectAllNamesByQueryDirect({ gclientId, name: getValues("searchWord")});
      setG04docuNames(g04docuSearchNames);
    }
  }
  
  var timer;
  // 원부자재 및 공정의 이름으로 검색
  const handleChangeSearchWord = async (e) => {
    // 과도한 API 호출 방지를 위한 debouncing 코드. TODO : 라이브러리 활용 검토
    if (timer) {
      clearTimeout(timer);
    }
    // 타이머 설정
    timer = setTimeout(() => {
      const searchWord = e.target.value;
      selectG04docus({ gclientId, searchWord });

      if (getValues("type") === "3. 성적서") {
        const selectNameFilter = async () => {
          const g04docuSearchNames = await selectAllNamesByQueryDirect({ gclientId, name: searchWord });
          // console.log(g04docuSearchNames)
          setG04docuNames(g04docuSearchNames);
        }

        selectNameFilter();
      }
    }, 500);
  };

  // 비고(종류·등급·호칭 또는 모델)로 검색
  const handleChangeClassification = async (e) => {
    // 과도한 API 호출 방지를 위한 debouncing 코드. TODO : 라이브러리 활용 검토
    if (timer) {
      clearTimeout(timer);
    }

    // 타이머 설정
    timer = setTimeout(() => {
      const name = e.target.value;
      selectG04docus({ gclientId, classification: name });
    }, 500);
  };

  // 검색버튼
  const handleClickSearch = async () => {
    if (gclientId === "" || gclientId === "NONE") {
      return;
    }

    const type = getValues("type");
    const searchWord = getValues("searchWord");
    const gtestNameFilter = getValues("gtestNameFilter"); // 성적서 이름 상세
    const classification = getValues("classification");

    let paramClassifications = [];
    if (classification) {
      const selectedClassificationTypes = classificationTypes.filter(c => c.indexOf(classification) >= 0);
      paramClassifications = selectedClassificationTypes.length > 0 ? selectedClassificationTypes : [classification];
    }

    await selectAllByQuery({
      gclientId: gclientId,
      type,
      searchWord,
      gtestNameFilter,
      classifications: paramClassifications,
    });
  };

  useEffect(
    () => {
      if (classifications.length > 0) {
        // console.log(classifications)
        const types = mode.indexOf('java') > 0 ? classifications.map(c1 => [...c1.map(c2 => c2.type)]) : classifications.map(c1 => [...c1.classifications.map(c2 => c2.type)]);
        // console.log(types)
        const distincts = types.reduce((acc, cur) => acc.concat(cur));
        setClassificationTypes([...new Set(distincts)]);
      }
    }, [classifications]
  )

  const handleClickAddToSendBasket = async () => {
    console.log(selectedRows)
    const ownerId = sessionUser.id;

    // TODO : 화면에 나타난 이름을 저장할 필요가 있음. (다시 데이터베이스 검색으로 만들기에는 쿼리가 복잡해짐)
    const gsendBaskets = selectedRows.map(row => {
      const { g04docuId, gclientId, gclient, documentType, typeAlias, division, g04docuGCertificationName, name, gcomponentItemName, selectedClassifications, validYN, valid } = row;
      
      return {
        id: uuidv4(),
        g04docuId,
        gclientId,
        gclient,
        documentType,
        ownerId,
        name: { type: typeAlias, division, g04docuGCertificationName, name, gcomponentItemName, selectedClassifications, validYN, valid }
      };
    });

    // console.log(gsendBaskets)
    // return;
    // return 값이 추가된 항목
    const res = await putInGSendBasketsDirect(gsendBaskets);
    // TODO : 추가된 내용, 이미 추가되어 있는 내용
    // setAlertInfo({
    //   titleAlert: "안내",
    //   messageAlert: "전송바구니에 이미 추가되어 있습니다.",
    //   open: true,
    // });
    console.log(res)

    const { id, sessionUserLoginType } = sessionUser;
    let creatorId = "";
    if (sessionUserLoginType === 'GCLIENT') {
      creatorId = id;
    } else if (sessionUserLoginType === 'USER') {
      if (sessionUser.user) {
        creatorId = sessionUser.user.id;
      }
    }
    selectGSendBasketsByCreatorByQuery(creatorId);

    const added = selectedRows.filter(gsendBasket => {
      /**
       * 아래에서 res 자체가 새로 추가한 목록(서버에서 해당 사용자별로 구분된 목록)이므로 ownerId로 조회하는 것은 불필요해 보임
       * 참고로 g04docuId & gclientId 인 이유는 일반문서나 인증서는 g04docuId가 동일하고 gclientId로 구분되므로 아래와 같이 조회
       * 사실 성적서나 기타 문서는 g04docuId로만 비교해도 되나 굳이 그렇게 하지 않음
       */
      const exist = res.find(i => i.g04docuId === gsendBasket.g04docuId && i.gclientId === gsendBasket.gclientId && i.ownerId === gsendBasket.ownerId); // TODO : ownerId로 비교하는 것은 불필요해 보임
      return exist ? true : false;
    });

    const excluded = selectedRows.filter(gsendBasket => {
      const exist = res.find(i => i.g04docuId === gsendBasket.g04docuId && i.gclientId === gsendBasket.gclientId && i.ownerId === gsendBasket.ownerId); // TODO : ownerId로 비교하는 것은 불필요해 보임
      return exist ? false : true;
    });

    // console.log({ added, excluded })
    
    const messageAlert = (
      <div>
        <List
          sx={{
            width: '500px',
            maxWidth: 800,
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 500,
            '& ul': { padding: 0 },
          }}
          subheader={<li />}
        >
          {
            added.length > 0 && (<li key={`section-added`}><ul>
              <ListSubheader sx={{ fontSize: 16, color: "#4caf50" }}><StyledBadge badgeContent={added.length}>{`추가한 목록`}</StyledBadge></ListSubheader>
              {
                added.map((add) => {
                  const { id, documentType, name, gcomponentItemName } = add;
                  return (<ListItem key={`item-added-${id}`} sx={{ fontSize : 16 }}>
                    {/* 아래에서 gcomponentItemName가 null인 null이 스트링으로 출력됨 */}
                    {"-"}<ListItemText sx={{ ml: 2 }} primary={documentType === 'TEST' ? (gcomponentItemName ? `${gcomponentItemName} ` : "") + name : name} />
                  </ListItem>)
                })
              }
            </ul></li>)
          }
          {
            excluded.length > 0 && (<li key={`section-excluded`}><ul>
              <ListSubheader sx={{ fontSize: 16, color: "#f44336" }}><StyledBadge badgeContent={excluded.length}>{`이미 추가되어 있는 목록`}</StyledBadge></ListSubheader>
              {
                excluded.map((ex) => {
                  const { id, documentType, name, gcomponentItemName } = ex;
                  return (<ListItem key={`item-excluded-${id}`}>
                    {"-"}<ListItemText sx={{ ml: 2 }} primary={documentType === 'TEST' ? (gcomponentItemName ? `${gcomponentItemName} ` : "") + name : name} />
                  </ListItem>)
                })
              }
            </ul></li>)
          }
        </List>
      </div>
    );

    setAlertInfo({
      titleAlert: "안내",
      messageAlert,
      open: true,
    });

    setSelectedRows([]);
    setSelectionModel([]);
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <G04docuSearchDialog
          open={open}
          setOpen={setOpen}
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          selectedRow={selectedRow}
          selectedGComponentItemName={selectedGComponentItemName}
          gstandardId={gstandardId}
          // gclientId={selectedGClientId}
          // gclient={selectedGClient}
          // refresh={selectAllByGComponentItemOrProcessByQuery}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            {
              title && (<Grid item xs={12}>
                <Typography variant="h5">{title}</Typography>
              </Grid>)
            }
            {
              /*sessionUser.type === 'ADMIN' && */(<Grid item xs={12} sm={2} display="flex" justifyContent="flex-start" alignItems="center" sx={{ display }}>
                <FormInputAutoComplete
                  control={control}
                  label={"거래선명"}
                  noOptionsText={'해당 거래선이 없습니다.'}
                  // TODO : 우신복층유리 1차 오픈 위한 코드
                  // options={[{ label: "전체", id: "NONE" }].concat(gclients.map(gclient => ({ label: gclient.name, id: gclient.id })))}
                  options={gclients.map(gclient => ({ label: gclient.name, id: gclient.id }))}
                  setValue={setValue}
                  onCustomChange={handleChangeGClient}
                />
              </Grid>)
            }
            <Grid item xs={12} sm={2} display="flex" justifyContent="flex-start" alignItems="center" sx={{ display }}>
              <FormInputText
                select
                name={"type"}
                control={control}
                label={"문서종류"}
                options={[
                  { label: "전체", value: "NONE" },
                  { label: "일반서류", value: "1. 일반서류" },
                  { label: "인증서", value: "2. 인증서" },
                  { label: "성적서", value: "3. 성적서" },
                  { label: "기타", value: "4. 기타" },
                ]}
                onChange={handleChangeType}
                // value={details && details.length > i && details[i]}
              />
            </Grid>
            <Grid
              item xs={12}
              sm={
                // 전체 : 거래선/문서종류/이름/비고 (2262)
                // 일반서류 : 거래선/문서종류/이름 (228)
                // 인증서 : 거래선/문서종류/이름/비고 (2262)
                // 성적서 : 거래선/문서종류/이름/성적서 이름 상세/비고 (22332)
                // 기타 : 거래선/문서종류/이름 (228)
                getValues("type") === "3. 성적서" ? 3 : ((getValues("type") === "NONE" || getValues("type") === "2. 인증서") ? 6 : 8)
              }
              display="flex" justifyContent="flex-start" alignItems="center"
            >
              <FormInputText
                name={"searchWord"}
                control={control}
                label={"이름"}
                onCustomChange={handleChangeSearchWord}
              />
            </Grid>
            {
              getValues("type") === "3. 성적서" && (<Grid item xs={12} sm={3} display="flex" justifyContent="flex-start" alignItems="center" sx={{ display }}>
                <FormInputAutoComplete
                  control={control}
                  label={"성적서 이름 상세"}
                  options={g04docuNames.map(g04docu => ({ label: g04docu, name: g04docu }))}
                  setValue={setValue}
                  onCustomChange={handleChangeNameFilter}
                />
              </Grid>)
            }
            {
              (
                getValues("type") === "NONE" ||
                getValues("type") === "3. 성적서" ||
                getValues("type") === "2. 인증서"
              ) && (<Grid item xs={12} sm={2} display="flex" justifyContent="flex-start" alignItems="center">
                <FormInputText
                  name={"classification"}
                  control={control}
                  label={"비고"}
                  onCustomChange={handleChangeClassification}
                />
              </Grid>)
            }
            <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                variant="contained"
                startIcon={<Search />}
                onClick={handleClickSearch}
                sx={{ ml: 5, mr: 1 }}
                disabled={gclientId ? false : true} // TODO : 우신복층유리 1차 오픈
              >
                {"검색"}
              </Button>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={handleClickAddToSendBasket}
                disabled={selectedRows.length <= 0}
              >
                {`담기(${selectedRows.length})`}
              </Button>
            </Grid>
          </Grid>
          {/* <div style={{ height: from?.params ? 500 : 900, width: '100%' }}> */}
          <div style={{ height: GRID_HEIGHT.REDUCED, width: '100%', marginTop: '20px' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              // density="standard"
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={rowsG04docuSearch}
              columns={columns}
              // pageSize={5}
              // rowsPerPageOptions={[5]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[1, 10, 20, 50, 100]}
              onPageChange={handlePageChange}
              page={page}
              // rowCount={rowCount}
              pagination
              disableColumnMenu
              onRowDoubleClick={(params) => handleSelect({ type: 'addToSendBasket', params })}
              checkboxSelection
              // checkboxSelection
              // disableSelectionOnClick
              onRowSelectionModelChange={(ids) => {
                if (!opened) {
                  return;
                }

                const selectedIDs = new Set(ids);
                // console.log(selectedIDs)
                const selected = rowsG04docuSearch.filter((row) =>
                  selectedIDs.has(row.id),
                );
      
                setSelectionModel(ids);
                
                const newSelected = selected.map(select => ({ ...select, ownerId: sessionUser.id }));
                console.log(newSelected)
                setSelectedRows(newSelected);
              }}
              selectionModel={selectionModel}
            />
          </div>
        </Box>
        {/* <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog> */}
        <AlertDialog
          alertInfo={alertInfo}
          setAlertInfo={setAlertInfo}
        />
        <ConfirmDialog
          title={confirmInfo.title}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={confirmInfo.onConfirm}
          onCancel={confirmInfo.onCancel}
        >
          {confirmInfo.content}
        </ConfirmDialog>
      </Container>
    </ThemeProvider>
  );
};

export default G04docuSearchManagement;
