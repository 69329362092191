import React from 'react';
import { alpha, styled } from '@mui/material/styles';
// TODO : 최신버전은 TreeView가 @mui/lab에서 @mui/x-tree-view로 이동했으나 기존 개발버전이 작동하지 않아 올드버전 사용
// -import { TreeView, TreeItem } from '@mui/lab';
// +import { TreeView, TreeItem } from '@mui/x-tree-view';
import MuiTreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import SvgIcon from '@mui/material/SvgIcon';
// import * as MuiTreeItem from '@mui/x-tree-view/TreeItem';
import Collapse from '@mui/material/Collapse';
import { useSpring, animated } from '@react-spring/web';

import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import  {
  Article,
  CorporateFare,
  FilePresent,
  FormatListBulleted,
  Grain,
  Preview,
  Circle,
  Clear,
  Warning,
  Block,
  ChromeReaderMode,
} from '@mui/icons-material';
import {
  grey,
  red,
  deepOrange,
} from '@mui/material/colors';
import {
  dateFormat,
} from "../../utils";
import { StyledTooltip } from "../tooltip";
import * as g04docuFileActions from "../../store/g04docuFile";

function EndSquare(props) {
  return (
    <SvgIcon
      fontSize="inherit" 
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* 문서 외곽선 - 접힌 모서리 반영 */}
      <path 
        d="M4 2h12 L22 8 L22 22 L4 22 L4 2" 
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      {/* 문서 내부 선 */}
      <path 
        d="M6 10h14M6 14h14M6 18h14" 
        stroke="currentColor" 
        strokeWidth="1.2" 
        fill="none"
      />
      {/* 우측 상단 접힘 표시 삼각형 */}
      <path
        d="M16 2l6 6h-6z"
        fill="#7e7e7e"
      />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props) => (
  <MuiTreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, /*0.4*/0)}`, // 확대점선 삭제
  },
  // 모든 계층의 트리 아이템에 분리선 추가
  [`& .${treeItemClasses.content}`]: {
    borderBottom: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
  },
  // 각 그룹의 마지막 아이템은 분리선 제거
  [`& .${treeItemClasses.group} > .${treeItemClasses.content}:last-child`]: {
    borderBottom: 'none'
  }
}));

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const TreeItem = ({
  items,
  selected,
  onSelect,
  disableMultiParentSelection,
  handleClickViewEachDoc,
  handleClickFileDownload,
  setSelectedRow,
  setSelectedRowEtc,
  setSelectedGComponentItemId,
  setSelectedGComponentItemName,
  setSelectedGComponentItemType,
  setSelectedDocuFileType,
  setRemoveFileObject,
  setConfirmOpenRemoveFile,
  setOpen,
  setOpenEtc,
  setEtcType,
}) => {
  const tree = React.useMemo(() => flattenTree({ items }), [items]);
  const marksUncheckedRef = React.useRef(
    createMarksUnchecked({ tree, items, selected }),
  );
  
  const activeParentRef = React.useRef('');

  React.useEffect(() => {
    // marksUncheckedRef.current = createMarksUnchecked({ tree, items, selected });
    // console.log('marksUnchecked', marksUncheckedRef.current);
    // console.clear();
    // console.log(JSON.stringify(marksUncheckedRef.current, null, 2));
    // console.log('activeParent', activeParentRef.current);
  }, [items, selected, tree]);

  const handleChange = ({ event, parents = [] }) => {
    const {
      target: { value, checked },
    } = event;
    let newSelect = selected.slice();

    if (checked) {
      newSelect = [...parents, value].reverse().reduce(
        (prev, curr, index) => {
          const node = curr;
          let newSelectSoFar = prev;

          if (index === 0) {
            const childNodes = getTreeNodes({ tree, node });
            const childNodesLength = childNodes.length;

            if (childNodesLength > 0) {
              newSelectSoFar = [
                ...newSelectSoFar.filter(
                  (select) => !childNodes.includes(select),
                ),
              ];

              marksUncheckedRef.current = marksUncheckedRef.current.filter(
                (marksUnchecked) =>
                  ![...childNodes, node].includes(marksUnchecked),
              );
            }
          } else {
            // const childNodes = getTreeNodes({ tree, node, depth: 1 });
            const childNodes = getTreeNodes({ tree, node });
            const childNodesLength = childNodes.length;

            if (childNodesLength > 0) {
              const isEveryChildrenExist = childNodes.every((childNode) =>
                newSelectSoFar.includes(childNode),
              );

              if (isEveryChildrenExist) {
                newSelectSoFar = [
                  ...newSelectSoFar.filter(
                    (select) => !childNodes.includes(select),
                  ),
                  node,
                ];

                marksUncheckedRef.current = marksUncheckedRef.current.filter(
                  (marksUnchecked) =>
                    ![...childNodes, node].includes(marksUnchecked),
                );
              }
            }
          }

          marksUncheckedRef.current = marksUncheckedRef.current.filter(
            (marksUnchecked) => !newSelectSoFar.includes(marksUnchecked),
          );

          return newSelectSoFar;
        },
        [...newSelect, value],
      );
    } else if (!checked && !newSelect.includes(value)) {
      let toExclude = value;
      newSelect = parents
        .slice()
        .reverse()
        .reduce(
          (prev, curr, index) => {
            const node = curr;
            let newSelectSoFar = prev;
            let childNodes;

            marksUncheckedRef.current = [
              ...new Set([...marksUncheckedRef.current, toExclude]),
            ];

            if (index === 0) {
              childNodes = getTreeNodes({ tree, node, depth: 1 });
              console.log(
                toExclude,
                childNodes,
                childNodes.filter((childNode) => childNode !== toExclude),
              );
              newSelectSoFar = [
                ...newSelectSoFar,
                ...childNodes.filter((childNode) => childNode !== toExclude),
              ];
            } else {
              childNodes = getTreeNodes({ tree, node, depth: 1 }).filter(
                (childNode) => childNode !== toExclude,
              );
              console.log(
                toExclude,
                childNodes,
                childNodes.filter((childNode) => childNode !== toExclude),
              );
              newSelectSoFar = [
                ...newSelectSoFar.filter(
                  (select) => !childNodes.includes(select),
                ),
                ...childNodes.filter(
                  (childNode) => !marksUncheckedRef.current.includes(childNode),
                ),
              ];
            }

            toExclude = curr;

            return newSelectSoFar;
          },
          newSelect.filter((select) => !parents.includes(select)),
        );
    } else {
      [...parents, value]
        .slice()
        .reverse()
        .forEach((item) => {
          const node = item;
          const childNodes = getTreeNodes({ tree, node, depth: 1 });

          if (childNodes.length > 0) {
            marksUncheckedRef.current = [
              ...new Set([...marksUncheckedRef.current, ...childNodes]),
            ];
          } else {
            marksUncheckedRef.current = [
              ...new Set([...marksUncheckedRef.current, node]),
            ];
          }
        });
      newSelect = newSelect.filter((select) => select !== value);
    }

    if (disableMultiParentSelection) {
      if (checked) {
        activeParentRef.current = parents.length > 0 ? parents[0] : value;
      } else {
        const childNodes = getTreeNodes({
          tree,
          node: parents.length > 0 ? parents[0] : value,
        });

        if (!childNodes.some((childNode) => newSelect.includes(childNode))) {
          activeParentRef.current = '';
        }
      }
    }

    onSelect(newSelect);
  };

  const renderTreeItem = ({ nodes, parents = [], level = 0 }) => {
    return nodes.map((node) => {
      // TODO : node에 필요정보가 다 있음
      // console.log(node)
      const { id: value, label, type, data, children } = node;
      const checked =
        selected.includes(value) ||
        parents.some((parent) => selected.includes(parent));
      let disabled = activeParentRef.current
        ? !parents.includes(activeParentRef.current)
        : false;

      if (activeParentRef.current === value) {
        disabled = false;
      }

      const isDocType = type?.substring(0, 3) === 'DOC';
      console.log(isDocType)
      // 문서 타입일 때만 EndSquare 아이콘 사용
      const treeItemProps = {
        ...(isDocType && {
          expandIcon: <EndSquare />,
          collapseIcon: <EndSquare />,
          endIcon: <EndSquare />  // endIcon도 추가
        })
      };

      if (children && children.length > 0) {
        const indeterminate = isIndeterminate({ tree, selected, node: value });
        const treeItemLabel = createTreeItemLabel({
          // formControlLabelProps: { label },
          nodeData: { label, type, data },
          checkboxProps: {
            value,
            checked,
            indeterminate,
            disabled,
            onChange: (event) => {
              handleChange({ event, parents });
            },
          },
          handleClickViewEachDoc,
          handleClickFileDownload,
          setSelectedRow,
          setSelectedRowEtc,
          setSelectedGComponentItemId,
          setSelectedGComponentItemName,
          setSelectedGComponentItemType,
          setSelectedDocuFileType,
          setRemoveFileObject,
          setConfirmOpenRemoveFile,
          setOpen,
          setOpenEtc,
          setEtcType,
        });

        return (
          <StyledTreeItem
            key={value}
            nodeId={value}
            label={treeItemLabel}
            {...treeItemProps}
          >
            {renderTreeItem({
              nodes: children,
              parents: [...parents, value],
              level: level + 1,
            })}
          </StyledTreeItem>
        );
      }

      const treeItemLabel = createTreeItemLabel({
        // formControlLabelProps: { label },
        nodeData: { label, type, data },
        checkboxProps: {
          value,
          checked,
          disabled,
          onChange: (event) => {
            handleChange({ event, parents });
          },
        },
        handleClickViewEachDoc,
        handleClickFileDownload,
        setSelectedRow,
        setSelectedRowEtc,
        setSelectedGComponentItemId,
        setSelectedGComponentItemName,
        setSelectedGComponentItemType,
        setSelectedDocuFileType,
        setRemoveFileObject,
        setConfirmOpenRemoveFile,
        setOpen,
        setOpenEtc,
        setEtcType,
      });

      return (
        <StyledTreeItem
          key={value}
          nodeId={value}
          label={treeItemLabel}
          {...treeItemProps}
        />
      );
    });
  };

  return renderTreeItem({ nodes: items });
};

export default TreeItem;

function flattenTree({ items, parent = 'root', depth = 0 }) {
  return items.reduce((prev, curr) => {
    Object.assign(prev, { [parent]: [...(prev[parent] || []), curr.id] });

    if (curr.children && curr.children.length > 0) {
      return {
        ...prev,
        ...flattenTree({
          items: curr.children,
          depth: depth + 1,
          parent: curr.id,
        }),
      };
    }

    return prev;
  }, {});
}

function createMarksUnchecked({ tree, items, selected }) {
  return items.reduce((prev, { id: node }) => {
    return [...prev, node, ...getTreeNodes({ tree, node })];
  }, []);
}

// const handleClickFileDownload = async (documentPath) => {
//   const arr = documentPath.split("/");
//   const fileName = arr[arr.length - 1];

//   const res = await downloadG04docuFile(documentPath);
//   const blob = await res.blob(); // res.blob는 promise를 리턴함???
  
//   // 2. Create blob link to download
//   const url = window.URL.createObjectURL(new Blob([blob]));
//   const link = document.createElement('a');
//   link.href = url;
//   link.setAttribute('download', fileName);
//   // 3. Append to html page
//   document.body.appendChild(link);
//   // 4. Force download
//   link.click();
//   // 5. Clean up and remove the link
//   link.parentNode.removeChild(link);
// }

const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)

function createTreeItemLabel({
  // formControlLabelProps = {},
  nodeData = {},
  checkboxProps = {},
  handleClickViewEachDoc,
  handleClickFileDownload,
  setSelectedRow,
  setSelectedRowEtc,
  setSelectedGComponentItemId,
  setSelectedGComponentItemName,
  setSelectedGComponentItemType,
  setSelectedDocuFileType,
  setRemoveFileObject,
  setConfirmOpenRemoveFile,
  setOpen,
  setOpenEtc,
  setEtcType,
}) {
  // console.log(formControlLabelProps);
  const handleClickGTest = (e, gtest, mode) => {
    // console.log(gtest);
    if (gtest.type === 'ETC') {
      const { id, code, name, gclientId, division, documentPath, startDate, endDate, valid, validYN } = gtest;
      if (mode === 'attachDocument') {
        setSelectedRowEtc({
          g04docuEtcId: id,
          documentType: code,
          title: name,
          documentPath,
          startDate,
          endDate,
          valid,
          validYN,
        });
    
        setSelectedGComponentItemType(division);
        setEtcType('TEST');
        setOpenEtc(true);
      } else if (mode === 'deleteDocument') {
        if (documentPath) {
          setSelectedGComponentItemType(division); // TODO : division과 동일한 것 같음. 추후 정리할 것
          setEtcType('TEST');
          setRemoveFileObject({ gclientId, g04docuEtcId: id, documentPath, division, type: 'ETC' });
          setConfirmOpenRemoveFile(true);
        }
      }
    } else {
      const { id, name, gclientId, g04docuGCertificationId, g04docuGCertificationName, gcomponentItemId, gcomponentItemName, division, documentPath, startDate, endDate, valid, validYN } = gtest;
      if (mode === 'attachDocument') {
        setSelectedGComponentItemId(gcomponentItemId);
        setSelectedGComponentItemName(gcomponentItemName);
        setSelectedGComponentItemType(division);
        
        setSelectedRow({
          division,
          g04docuGTestId : id,
          g04docuGTestName : name,
          g04docuGCertificationId,
          g04docuGCertificationName,
          documentPath,
          startDate,
          endDate,
          valid,
          validYN,
        });
    
        setSelectedDocuFileType('TEST');
        setOpen(true);
      } else if (mode === 'deleteDocument') {
        if (documentPath) {
          setSelectedGComponentItemType(division);
          setSelectedDocuFileType('TEST');
          setRemoveFileObject({ gclientId, g04docuGTestId: id, division, documentPath, type: 'TEST' });
          setConfirmOpenRemoveFile(true);
        }
      }
    }
  }
  
  const { label, type, data } = nodeData;
  // console.log(data)
  return (
    // <FormControlLabel
    //   style={{ width: '100%', marginLeft: 0 }}
    //   onClick={(event) => {
    //     event.stopPropagation();
    //   }}
    //   control={<Checkbox {...checkboxProps} />}
    //   {...formControlLabelProps}
    // />
    // TODO : 아래에서 grey.300 은 되는데 red.300 은 안됨???
    <Stack
      direction="row"
      spacing={1}
      sx={{
        // border: 1,
        // borderBottom: 1,
        // color: data?.valid === false && red[700],
        color: type?.substring(0, 3) !== 'DOC' ? "#000000" : (!data?.documentPath ? red[700] : ((data?.validYN || data?.valid) ? "#000000" : "#ED6C02")),
        borderColor: grey[300],
      }}
    >
      { type?.substring(0, 3) === 'DOC' && data.documentPath && (data.valid || data.validYN) && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#4caf50' }}><Circle sx={{ width: 14, height: 14 }} /></Grid> }
      {/* { type?.substring(0, 3) === 'DOC' && data.documentPath && !data.valid && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ED6C02' }}><Warning /></Grid> } */}
      { type?.substring(0, 3) === 'DOC' && data.documentPath && (!data.valid && !data.validYN) && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ED6C02' }}><Circle sx={{ width: 14, height: 14 }} /></Grid> }
      {/* { type?.substring(0, 3) === 'DOC' && !data.documentPath && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ef5350' }}><Block /></Grid> } */}
      { type?.substring(0, 3) === 'DOC' && !data.documentPath && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ef5350' }}><Circle sx={{ width: 14, height: 14 }} /></Grid> }
      <Grid container sx={{ height: 40 }}>
        <Grid item sm={4} display="flex" alignItems="center">
          <Typography variant="subtitle1" sx={{ ml: 0.5 }}>{label}</Typography>
        </Grid>
        <Grid item sm={2} display="flex" justifyContent="center" alignItems="center" sx={{ ml: -6 }}>
          {
            type?.substring(0, 3) === 'DOC' && data && data.documentPath && (
              // <Grid item sm={2} display="flex" justifyContent="center" alignItems="center">
                <>
                  <StyledTooltip
                    title={<Stack direction="row" display="flex" alignItems="center">
                      <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                        {`성적서 미리보기`}
                      </Box>
                      <Typography variant="subtitle2">{data.documentPath?.split("/")[data.documentPath?.split("/")?.length-1]}</Typography>
                    </Stack>}
                    placement="left"
                    sx={tooltipTop}
                  >
                    <IconButton
                      color="primary"
                      aria-label="file"
                      onClick={() => handleClickViewEachDoc(data.documentPath)}
                      sx={{ color: (data.validYN || data.valid) ? "#1976D2" : "#ED6C02" }}
                    >
                      <Preview />
                    </IconButton>
                  </StyledTooltip>
                  {
                    type === 'DOCTEST' && data.gtestComparisonDocumentPath && (
                      <StyledTooltip
                        title={<Stack direction="row" display="flex" alignItems="center">
                          <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                            {`시험성과대비표 미리보기`}
                          </Box>
                          <Typography variant="subtitle2">{data.gtestComparisonDocumentPath?.split("/")[data.gtestComparisonDocumentPath?.split("/")?.length-1]}</Typography>
                        </Stack>}
                        placement="right"
                        sx={tooltipTop}
                      >
                        <IconButton
                          color="primary"
                          aria-label="file"
                          onClick={() => handleClickViewEachDoc(data.gtestComparisonDocumentPath)}
                          sx={{ color: (data.validYN || data.valid) ? "#1976D2" : "#ED6C02" }}
                        >
                          <Preview />
                        </IconButton>
                      </StyledTooltip>
                    )
                  }
                </>
              // </Grid>
            )
          }
          {
            type?.substring(0, 3) === 'DOC' && data && !data.documentPath && (
              <Grid item sm={2} display="flex" justifyContent="center" alignItems="center">
                <Tooltip title={"서류없음"} placement="right">
                  <IconButton
                    // color={data?.valid === false ? "error" : "primary"}
                    aria-label="download"
                    // edge="end"
                    sx={{ /*ml: 1, */color: '#ef5350' }}
                  >
                    <Block />
                  </IconButton>
                </Tooltip>
              </Grid>
            )
          }
        </Grid>
        <Grid item sm={2} display="flex" justifyContent="center" alignItems="center" sx={{ ml: 2.1 }}>
          {
            type?.substring(0, 3) === 'DOC' && data && /*data.endDate && */(
              data.endDate ? (
                <Tooltip title={"만료일"} placement="right">
                  <Chip label={` ~ ${dateFormat(data.endDate, 'yyyy-MM-dd')}`} color={data?.valid === false ? "warning" : "primary"} variant={data?.valid === false ? "" : "outlined"}></Chip>
                </Tooltip>
              ) : (data?.validYN ? "만료일 없음" : "")
            )
          }
        </Grid>
        <Grid item sm={2} display="flex" justifyContent="center" alignItems="center" sx={{ ml: 2.2 }}>
          {
            type?.substring(0, 3) === 'DOC' && data && data.mapUpdatedAt && (
              data.mapUpdatedAt ? (
                <Tooltip title={"최종수정일시"} placement="right">
                  <Chip label={dateFormat(data.mapUpdatedAt)}></Chip>
                </Tooltip>
              ) : ""
            )
          }
        </Grid>
        <Grid item sm={2} display="flex" justifyContent="center" alignItems="center" sx={{ ml: 0.5 }}>
          {
            type === 'DOCTEST' &&  (
              <>
                <Button
                  color="primary"
                  aria-label="edit"
                  onClick={(e) => handleClickGTest(e, data, 'attachDocument')}
                  sx={{ color: '#1976d2' }}
                >
                  {/* {"등록/수정"} */}
                  {"첨부"}
                </Button>
                <Button
                  color="primary"
                  aria-label="edit"
                  onClick={(e) => handleClickGTest(e, data, 'deleteDocument')}
                  sx={{ color: '#1976d2' }}
                  disabled={!data.documentPath}
                >
                  {"삭제"}
                </Button>
              </>
            )
          }
        </Grid>
      </Grid>
    </Stack>
  );
}

function getTreeNodes({ tree, node = 'root', depth, currentDepth = 1 }) {
  const branches = tree[node];

  if (!branches) {
    return [];
  }

  return branches.reduce((prev, curr) => {
    let newPrev = [...prev, curr];

    if (tree[curr] && (typeof depth === 'undefined' || depth > currentDepth)) {
      newPrev = [
        ...newPrev,
        ...getTreeNodes({
          tree,
          node: curr,
          depth,
          currentDepth: currentDepth + 1,
        }),
      ];
    }

    return newPrev;
  }, []);
}

function isIndeterminate({ tree, node: value, selected }) {
  return getTreeNodes({ tree, node: value }).some((node) =>
    selected.includes(node),
  );
}
