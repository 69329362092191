import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  FormInputText,
} from "../form";
import  {
  OpenInNew,
} from '@mui/icons-material';
import {
  CustomNoRowsOverlay,
} from "../datagrid";
import 'dayjs/locale/ko';  // 한국어 로케일 추가
import * as gclientActions from "../../store/gclient";
import * as mailActions from "../../store/mail";
import { getGridHeight } from "../../constants/gridHeights";
import { MEMBERSHIP_TYPES, MEMBERSHIP_TYPE_LABELS } from "../../constants/gclientTypes";
import { uploadFilePath, fileServerUrl, mode } from '../../config';
import { filePreviewServerUrl } from '../../config';
import debounce from 'lodash/debounce';
import GClientMailTestUserDialog from "./GClientMailTestUserDialog";

const theme = createTheme();

const defaultValues = {
  searchName: "",
  partnerships: [''],
  memberships: [''],
  serviceDate: "",
  cmsDocumentPath: "",
  signYn: "",
  cmsYn: "",
  serviceMonth: "",
  startPaidEndDate: "",
  endPaidEndDate: "",
  };

const GClientManagement = ({ title, from }) => {

  const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues });
  
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  
  const searchInputRef = useRef("");

  // 현재 화면에 맞는 Grid 높이 계산
  const GRID_HEIGHT = getGridHeight('TWO_LINE_BUTTON_LAYER', 0); // 0px 추가 여백

  const handleSelect = async ({ type, params }) => {
    const { id } = params;

    setOpenBackdrop(true);

    if (type === "userMailTest") {
      await select(id);
      setOpen(true);
    }

    setOpenBackdrop(false);
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"사용자 메일 테스트"}
        onClick={() => handleSelect({ type: 'userMailTest', params })}
        showInMenu
      />,
    ];

    return arrActions;
  }

  const columns = [
    {
      field: 'name',
      headerName: '거래선명',
      width: 220,
    },
    {
      field: 'bizRegNumber',
      headerName: '사업자등록번호',
      width: 160,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'ceo',
      headerName: '대표자',
      width: 150,
    },
    {
      field: 'phone',
      headerName: '연락처',
      width: 150,
    },
    {
      field: 'address',
      headerName: '주소',
      width: 500,
    },
    {
      field: 'email',
      headerName: '회사대표메일',
      width: 250,
    },
    {
      field: 'mailTest',
      headerName: '메일테스트',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          disabled={!params.row.mailSettings}
          onClick={(e) => {
            e.stopPropagation();
            handleClickMailTest({ mailSettings: params.row.mailSettings });
          }}
        >
          {params.row.mailSettings ? '테스트' : '미설정'}
        </Button>
      ),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세/수정/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      type: 'actions',
      getActions: (params) => generateActions(params),
    }
  ];
  
  const rows = useSelector((state) => state.gclient.gclients);
  const selectedRow = useSelector((state) => state.gclient.gclient);
  
  // 데이터 관리
  const dispatch = useDispatch();
  
  const selectAllByParams = (params) => dispatch(gclientActions.selectAllByParams(params))
  const select = (id) => dispatch(gclientActions.select(id))
  const selectAllWithFields = (valid, fields) => dispatch(gclientActions.selectAllWithFields(valid, fields))
  const sendMailTestDrect = ({ mailSettings }) => mailActions.sendMailTestDrect({ mailSettings })

  // debounce된 검색 함수 생성
  const debouncedSearch = useCallback(
    debounce((searchName) => {
      setValue("searchName", searchName);
      handleClickSearch();
    }, 500),  // 500ms 딜레이
    [] // 컴포넌트가 마운트될 때만 생성
  );

  const handleClickMailTest = async ({ mailSettings }) => {
    try {
      const res = await sendMailTestDrect({ mailSettings });
      if (res?.info) {
        alert("메일이 정상으로 동작합니다.");
      } else {
        alert("메일 설정을 확인하세요.");
      }
    } catch (error) {
      alert(error.message);
    }
  }

  const handleChangeSearchName = useCallback((e) => {
    const searchName = e.target.value;
    setValue("searchName", searchName);
   
    debouncedSearch(searchName);
  }, [debouncedSearch]);

  // 컴포넌트 언마운트 시 debounce 취소
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // 검색 버튼 클릭 시 호출
  const handleClickSearch = () => {
    const params = {
      name: getValues("searchName"),
    };
    
    console.log("Search Params:", params);
    
    selectAllByParams(params);
  };
  
  useEffect(
    async () => {
      await selectAllWithFields(undefined, ['id', 'bizRegNumber', 'name', 'ceo', 'phone', 'address', 'email', 'mailSettings']);
      
      setLoaded(true);
    }, []
  );

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <GClientMailTestUserDialog
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5">
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name="searchName"
                control={control}
                label={"거래선명 또는 사업자번호로 검색하세요."}
                onCustomChange={handleChangeSearchName}
                inputRef={searchInputRef}
                sx={{
                  width: 500,
                  '& .MuiFormLabel-root' : {
                    fontSize: '0.85rem',
                  },
                  input: { fontSize: '0.85rem' }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ height: GRID_HEIGHT.FULL, width: '100%', marginTop: 10 }}>
                <DataGridPro
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                  sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                  initialState={{ pinnedColumns: { right: ['actions'] } }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: LinearProgress,
                  }}
                  columnHeaderHeight={38}
                  rowHeight={34}
                  loading={!loaded}
                  rows={rows}
                  columns={columns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  pagination
                  onRowDoubleClick={(params) => handleSelect({ type: 'userMailTest', params })}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default GClientManagement;
