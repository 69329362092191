import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl, projectServerUrl } from '../config.js';

// 납품실적 목록, IF-SUPRELT-001
export const selectListDirect = async (supplyGClientId, params) => {
  // 유효한 파라미터만 필터링
  const filteredParams = {supplyGClientId};
  Object.keys(params).forEach(key => {
    if (params[key] !== '' && params[key] !== null && params[key] !== undefined) {
      filteredParams[key] = params[key];
    }
  });

  // 쿼리스트링 생성
  const queryString = new URLSearchParams(filteredParams).toString();

  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsupplyResults?${queryString}`);
  const responseData = await response.json();

  let formattedData = {};
  if (responseData.gsupplyResults.length > 0) {
    formattedData = {
      list: responseData.gsupplyResults.map(result => ({
        ...result,
        id: result.seq // seq를 id로 사용
      })),  
      count: responseData.totalElements
    };
  }

  console.log('formattedData => ', formattedData);
  
  return formattedData;
};

// 납품실적 등록, IF-SUPRELT-003
export const createDirect = async (supplyGClientId, params) => {
  // 유효한 파라미터만 필터링
  const filteredParams = {supplyGClientId};
  Object.keys(params).forEach(key => {
    if (params[key] !== '' && params[key] !== null && params[key] !== undefined) {
      filteredParams[key] = params[key];
    }
  });

  console.log('filteredParams => ', filteredParams);

  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsupplyResults`, {
    method: "POST",
    body: JSON.stringify(filteredParams),
  });

  const data = await response.json();

  return data;
};

// 납품실적 일괄등록, IF-SUPRELT-004
export const createBulkDirect = async (supplyGClientId, arrayParams) => {
  // 유효한 파라미터만 필터링
  const filteredArrayParams = arrayParams.map(params => {
    const filteredParams = {};
    Object.keys(params).forEach(key => {
      if (params[key] !== '' && params[key] !== null && params[key] !== undefined) {
        filteredParams[key] = params[key];
      }
    });
    filteredParams['checked'] = true;
    return filteredParams;
  });

  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsupplyResults/bulk`, {
    method: "POST",
    body: JSON.stringify({
      supplyGClientId,
      list: filteredArrayParams
    }),
  });

  return response;
};

// 납품실적 수정, IF-SUPRELT-005
export const updateDirect = async (seq, params) => {
  const updatedParams = {
    ...params,
    seq: seq  // seq 추가
  };
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsupplyResults/seq/${seq}`, {
    method: "PUT",
    body: JSON.stringify(updatedParams),
  });

  return response;
};

// 납품실적 PDF 파일 생성, IF-SUPRELT-007
export const generatePdf = async (supplyGClientId) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsupplyResults/generatePdf`, {
    method: "POST",
    body: JSON.stringify({ supplyGClientId }),
  });

  const data = await response.json();
  
  return data;
};

// 납품실적 선택 상태 변경, IF-SUPRELT-008
export const updateCheckedDirect = async (seq, checked) => {

  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsupplyResults/updateChecked/seq/${seq}`, {
    method: "PUT",
    body: JSON.stringify({seq, checked}),
  });

  return response;
};

// 납품실적 삭제, IF-SUPRELT-010
export const removeDirect = async (seq) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsupplyResults/seq/${seq}`, {
    method: "DELETE",
  });

  return response;
};


const initialState = {
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
