import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  TableContainer,
  Table,  
  TableCell,
  TableBody,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import  {
  Clear,
  Delete,
  FilePresent,
  Send,
  Image,
  PictureAsPdf,
  TrafficRounded,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReactMultiEmail } from "react-multi-email";
// import "react-multi-email/dist/style.css";
import "./react-multi-email.css";
import { FilePond } from 'react-filepond';
import uploadLabels from '../../FildPondLabel';
import usePrevious from "../hook/usePrevious";
import { gcoreServerUrl } from '../../config';
import {
  FormInputAutoComplete,
  FormInputMultipleSelect,
  FormInputText,
} from "../form";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  AlertDialog,
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";

import * as gclientActions from "../../store/gclient";
import * as userActions from "../../store/user";
import * as dialogActions from "../../store/components/dialog";
import * as g04docuMaterialApprovalActions from "../../store/g04docuMaterialApproval";
import * as errorActions from "../../store/error";

// 아래 form components의 name과 연계
const defaultValues = {
  // to: "",
  // cc: "",
  // ccInternal: "",
  subject: "",
  html: "",
  recipientLabel: "제출처",
  recipient: "",
  siteLabel: "현장명",
  site: "",
};

const G04docuMaterialApprovalEmailDialog = ({

}) => {
  const [gclients, setGClients] = useState([]);
  const [gclientId, setGClientId] = useState("");
  const [emailOptionsTo, setEmailOptionsTo] = useState([]);
  const [emailOptionsCc, setEmailOptionsCc] = useState([]);
  const [emailOptionsCcInternal, setEmailOptionsCcInternal] = useState([]);
  const [mailTos, setMailTos] = useState([]);
  const [mailCcs, setMailCcs] = useState([]);
  const [mailCcInternals, setMailCcInternals] = useState([]);
  const [mailDirect, setMailDirect] = useState(false);
  const [mailToDirect, setMailToDirect ] = useState([]);
  const [mailCcDirect, setMailCcDirect ] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertInfo, setAlertInfo] = useState({});
  const [signOption, setSignOption] = useState("NONE");
  const [URLThumbnail, setURLThumbnail] = useState("");
  const [emptyRecipient, setEmptyRecipient] = useState(false);
  const [emptySite, setEmptySite] = useState(false);
  const [addedFiles, setAddedFiles] = useState([]);
  const [checked, setChecked] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const options = useSelector((state) => state.dialog.options);

  const dispatch = useDispatch();

  const selectAllGClientDirect = () => gclientActions.selectAllDirect()
  const selectAllByGClientDirect = (gclientId) => userActions.selectAllByGClientDirect(gclientId)
  const sendWithLocalFileDirect = (formData) => g04docuMaterialApprovalActions.sendWithLocalFileDirect(formData)

  useEffect(
    async () => {
      if (options?.open) {
        const resGClients = await selectAllGClientDirect();
        setGClients(resGClients);

        // 내부참조
        const { id, email } = sessionUser; // sessionUser의 id는 회원사 관리자 id임 (접속한 계정이 회원사 사용자이더라도...)
        const users = await selectAllByGClientDirect(id);
        const emailList = [];
        
        if (email) {
          emailList.push({ /*code*/id: email, name: `회사대표메일 <${email}>` }); // code가 아니라 id임에 유의
        }

        if (users.length > 0) {
          users.forEach(user => {
            const { name, userId } = user;
            emailList.push({ id: `${name} <${userId}>`, name: `${name} <${userId}>` });
          })
        }

        // console.log(emailList)
        if (emailList.length > 0) {
          setEmailOptionsCcInternal(emailList);
        } else {
          setEmailOptionsCcInternal([]);
        }

        setValue("supplierLabel", "제공사");
        setValue("supplier", "해당 문서의 제공사가 표시됩니다.");
        setValue("phrase", "해당 문서의 제공사의 안내문구가 표시됩니다.");

        // 현장명과 제출처 정보 설정 
        if (options?.value) {
          const { recipient, site } = options.value;
          setValue("recipient", recipient);
          setValue("site", site);
        }
      }
    }, [options]
  )

  const handleDialogClose = () => {
    dispatch(dialogActions.setOptions({ open: false, crudMode: '' }));

    initDialog()
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    // TODO : 설정되어 있는 메일쓰기 정보 초기화할 것
    setGClients([]);
    setGClientId("");
    setEmailOptionsTo([]);
    setEmailOptionsCc([]);
    setEmailOptionsCcInternal([]);
    // 아래 두개의 초기화는 useEffect([gclientId])안에서 이루어짐
    // setMailTos([]);
    // setMailCcs([]);
    setMailCcInternals([]);
    setMailDirect(false);

    setEmptyRecipient(false);
    setEmptySite(false);

    setAddedFiles([]);
    setChecked([]);
    setCheckedAll(false);
  }

  const handleChangeDirect = (e) => {
    const { checked } = e.target;
    
    setMailDirect(checked);
    if (checked) {
      setMailToDirect([]);
      setMailCcDirect([]);
    } else {
      setMailTos([]);
      setMailCcs([]);
      setMailCcInternals([]);
    }

    // setGClients([]); // TODO : 왜 초기화했지???
    setGClientId("");
  }

  const handleChangeGClient = (e, value) => {
    // console.log(value)
    // if (!value) {
    //   setValue("recipient", "");
    //   return;
    // }

    const { id: gclientId, label } = value;
    setGClientId(gclientId);

    // setValue("recipient", label);
  }

  useEffect(
    () => {
      if (addedFiles.length === 0) {
        setSignOption("NONE");
      }
    }, [addedFiles]
  )

  const handleFileChange = (e) => {
    console.log({ files: e.target.files, addedFiles })
    setAddedFiles(addedFiles.concat([...e.target.files])) // e.target.files는 배열이 아니고 객체임에 주의
    setCheckedAll(false);
    
    document.getElementById('input_files').value = "";
  }

  const handleDeleteCheckedFiles = (e) => {
    const newAddedFiles = addedFiles.filter((file, i) => checked.indexOf(i) > -1 ? false : true);
    setAddedFiles(newAddedFiles);
    setChecked([]);
  }

  const handleDeleteAddedFile = (e, idx) => {
    console.log(addedFiles)
    const newAddedFiles = addedFiles.filter((file, i) => idx !== i)
    setAddedFiles(newAddedFiles);
    setCheckedAll(false);

    const newChecked = checked.map(i => {
      if (i === idx) {
        return null;
      } else if (i >= idx) {
        return i-1;
      } else {
        return i;
      }
    });
    setChecked(newChecked.filter(i => i !== null));
  }

  const handleToggleAll = (e) => {
    console.log(e.target.checked)
    setCheckedAll(e.target.checked);
    if (e.target.checked) {
      const newChecked = addedFiles.map((file, i) => i);
      setChecked(newChecked);
    } else {
      setChecked([]);
    }
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    console.log(newChecked)
    setChecked(newChecked);

    console.log(newChecked.length)
    console.log(addedFiles.length)
    if (newChecked.length === addedFiles.length) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  };

  const prevGClientId = usePrevious(gclientId);

  useEffect(
    async () => {
      if (prevGClientId !== gclientId) {
        setMailTos([]);
        setMailCcs([]);
      }

      // console.log({ prevGClientId, gclientId })
      const selectedGClient = gclients.filter(gclient => gclient.id === gclientId);
      console.log(selectedGClient)
      if (selectedGClient.length === 1) {
        const { id, email, inCharges } = selectedGClient[0];

        const emailList = [];
        if (email) {
          emailList.push({ id: email, name: `회사대표메일 <${email}>` });
        }

        // if (inCharges && Array.isArray(inCharges)) {
        //   inCharges.forEach(inCharge => {
        //     const { inChargeName, inChargeEmail } = inCharge;
        //     emailList.push({ id: `${inChargeName} <${inChargeEmail}>`, name: `${inChargeName} <${inChargeEmail}>` });
        //   })
        // }
        const users = await selectAllByGClientDirect(id);
        if (users.length > 0) {
          users.forEach(user => {
            const { name, userId } = user;
            emailList.push({ id: `${name} <${userId}>`, name: `${name} <${userId}>` });
          })
        }

        // TODO : inCharges 사용하지 않으므로 바꾸어야 함
        if (emailList.length > 0) {
          setEmailOptionsTo(emailList);
          setEmailOptionsCc(emailList);
        } else {
          setEmailOptionsTo([]);
          setEmailOptionsCc([]);
        }
      }
    }, [gclientId]
  )
  
  // const traverseObj = (obj) => {
  //   for (const key in obj) {
  //       if (typeof obj[key] === "object") {
  //           traverseObj(obj[key]);
  //       } else {
  //         // values.push(obj[key]);
  //         objValues.push({ key, value: obj[key] });
  //       }
  //   }
  // }
  
  // console.log(objValues);
  const onSubmit = async ({ mailTo, mailCc, mailCcInternal, subject, html, recipient, site }) => {
    // console.log({ mailTo, mailCc, mailCcInternal, subject, html, recipient, site, signOption });
    // return;

    if (addedFiles.length > 0 && signOption === 'CERTIFIED_TRUE_COPY_AND_SIGN') {
      if (!recipient) {
        setEmptyRecipient(true);
      }

      if (!site) {
        setEmptySite(true);
      }

      if (!recipient || !site) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: `제출처나 현장명을 모두 입력해주세요.`,
          open: true,
        });
        return;
      }
    }

    const objMailSettingsValues = [];
    const objUserMailSettingsValues = [];
    let userMailSettings;
    const { sessionUserLoginType, mailSettings } = sessionUser;
    
    // sessionUserLoginType === 'USER'여도 사용자 메일설정이 안되어 있으면 관리자 메일설정으로도 보낼 수 있는 기능 때문에
    // 관리자 메일설정은 항상 체크해야 함

    const traverseObj = (obj) => {
      for (const key in obj) {
          if (typeof obj[key] === "object") {
              traverseObj(obj[key]);
          } else {
            objMailSettingsValues.push({ key, value: obj[key].toString() });
          }
      }
    }

    traverseObj(mailSettings);

    if (sessionUserLoginType === 'USER') {
      userMailSettings = sessionUser.user.mailSettings;

      const traverseObj = (obj) => {
        for (const key in obj) {
            if (typeof obj[key] === "object") {
                traverseObj(obj[key]);
            } else {
              objUserMailSettingsValues.push({ key, value: obj[key] });
            }
        }
      }

      traverseObj(userMailSettings);
    }

    const emptyMailSettingsResults = objMailSettingsValues.filter(obj => obj.value ? false : true);
    const emptyUserMailSettingsResults = objUserMailSettingsValues.filter(obj => obj.value ? false : true);

    // 관리자 메일 설정이 안되어 있는 경우
    const isNotMailSet = objMailSettingsValues.length === 0 || emptyMailSettingsResults.length > 0;
    if (sessionUserLoginType === 'GCLIENT' && isNotMailSet) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: (<>
          <span>{`메일정보 설정이 되어 있지 않습니다.`}</span><br />
          <span style={{ color: "#1976d2" }}>{`내정보`}</span>
          <span>{`에서 메일정보를 설정해주세요.`}</span>
        </>),
        open: true,
      });
      return;
    }

    // 사용자 메일 설정이 안되어 있는 경우
    const isNotUserMailSet = objUserMailSettingsValues.length === 0 || emptyUserMailSettingsResults.length > 0;
    if (sessionUserLoginType === 'USER' && isNotUserMailSet) {
      // 1. 관리자 메일이 설정되어 있다면 관리자 메일로 보낼 것(내 메일은 참조로...)인지 선택
      if (!isNotMailSet) {
        setConfirmOpen(true);
        return;
      } else { // 2. 관리자 메일도 사용자 메일도 설정되어 있지 않은 경우
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: (<>
            <span>{`메일정보 설정이 되어 있지 않습니다.`}</span><br />
            <span style={{ color: "#1976d2" }}>{`내정보`}</span>
            <span>{`에서 메일정보를 설정해주세요.`}</span>
          </>),
          open: true,
        });
        return;
      }
    }
    
    await sendMail({ mailTo, mailCc, mailCcInternal, subject, html, recipient, site });
  }

  const sendMail = async ({ mailTo, mailCc, mailCcInternal, subject, html, recipient, site }, sendReplace) => {
    // console.log({ mailTo, mailCc, mailCcInternal, subject, html, recipient, site, sendReplace })
    // return;

    // 메일 보내기 전 유효성 체크, TODO : 더 체크할게 있는지 검토할 것
    const mailToArr = mailDirect ? mailToDirect.map(item => ({ id: item, name: item })) : (mailTo ? mailTo : []);
    console.log(mailToArr)
    if (mailToArr) {
      if (mailToArr.length <= 0) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: `받는 사람을 입력해주세요.`,
          open: true,
        });
        return;
      }
    }

    try {
      // console.log(addedFiles)
      
      setLoading(true);

      const selectedGClient = gclients.filter(gclient => gclient.id === gclientId); // 직접쓰기하면 gclientId = "" => selectedGClient = []

      // console.log({
      //   gclientTo: selectedGClient.length === 1 ? selectedGClient[0] : {}, // TODO : 데이터베이스에 gclient 정보 전체를 저장하는데 그럴 필요가 있나 검토할 것(DB저장정보보다는 데이터전송 사이즈 때문에...)
      //   mailTo: mailToArr,
      //   mailCc: mailDirect ? mailCcDirect.map(item => ({ id: item, name: item })) : (mailCc ? mailCc : []),
      //   mailCcInternal,
      //   subject,
      //   html,
      //   mailDirect,
      //   g04docuMaterialApproval: options?.value,
      // });
      // setLoading(false);
      // return;

      const addedFileNames = [];

      const formData = new FormData();

      // if (sendReplace) {
        formData.append("sendReplace", sendReplace);
      // }

      formData.append("mailData", JSON.stringify({
        gclientTo: selectedGClient.length === 1 ? selectedGClient[0] : {},
        mailTo: mailToArr,
        mailCc: mailDirect ? mailCcDirect.map(item => ({ id: item, name: item })) : (mailCc ? mailCc : []),
        mailCcInternal,
        subject,
        html,
        g04docuMaterialApproval: options?.value,
        options: { signOption : addedFiles.length > 0 ? signOption : "NONE" },
        recipient,
        site,
      }));
      
      // addedFiles는 배열이 아니고 객체이므로 아래와 같이 배열로 변환
      console.log(addedFiles)
      // if (addedFiles && !objectEmptyCheck(addedFiles) && Array.isArray([...addedFiles])) {
      //   [...addedFiles].forEach((file) => { // formData.append('addedFiles', addedFiles); 이렇게 넘기면 안됨
      //     formData.append("addedFiles", file);
      //     addedFileNames.push(file.name)
      //   });
      // }
      
      addedFiles.forEach((file) => { // formData.append('addedFiles', addedFiles); 이렇게 넘기면 안됨
        formData.append("addedFiles", file);
        addedFileNames.push(file.name)
      });
      
      // console.log(addedFileNames);
      formData.append("addedFileNames", JSON.stringify(addedFileNames));

      const res = await sendWithLocalFileDirect(formData);
      // console.log(res)
      if (res) {
        if (res.gsendG04docu) {
          setLoading(false);
          if (res.gsendG04docu?.id) { // TODO : 추후 res.gsendG04docu.results를 보고 판단하도록 할 것. (현재 서버에서 작업이 안된 상태)
            setAlertInfo({
              titleAlert: "안내",
              messageAlert: `메일이 전송되었습니다.`,
              open: true,
            });

            setTimeout(() => handleDialogClose(false), 500);
          }
        } else {
          console.log(res)
          dispatch(errorActions.occurError({ response: null, serverResponse: res })); // 오류 처리 방식 4
        }
      }
      
      setLoading(false);
    } catch (e) {
      console.log(e)
      setLoading(false);

      // TODO : 에러 메시지
    }
  }
  
  const sendMailByGClient = async () => {
    const mailTo = getValues("mailTo");
    const mailCc = getValues("mailCc");
    const mailCcInternal = getValues("mailCcInternal");
    const subject = getValues("subject");
    const html = getValues("html");
    const recipient = getValues("recipient");
    const site = getValues("site");
    console.log({ mailTo, mailCc, mailCcInternal, subject, html, recipient, site })

    await sendMail({ mailTo, mailCc, mailCcInternal, subject, html, recipient, site }, true/*sendReplace*/);
  }

  const handleChangeSignOption = (e) => {
    const { value } = e.target;
    setSignOption(value);
  }

  // const handleChangeRecipient = (e) => {
  //   const { value } = e.target;
  //   // console.log(value)
  //   if (value) {
  //     setEmptyRecipient(false);
  //   } else {
  //     setEmptyRecipient(true);
  //   }

  //   setValue("recipient", value);
  // }

  // const handleChangeSite = (e) => {
  //   const { value } = e.target;
  //   // console.log(value)
  //   if (value) {
  //     setEmptySite(false);
  //   } else {
  //     setEmptySite(true);
  //   }

  //   setValue("site", value);
  // }

  return (
    <>
    <Dialog
        open={options?.open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          {"전송"}
        </DialogTitleClose>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  titleTypographyProps={{ variant: 'subtitle1' }}
                  title={"메일 쓰기"}
                  sx={{ bgcolor: "#eaeaea" }}
                  action={
                    <FormGroup>
                      <FormControlLabel control={<Checkbox onChange={handleChangeDirect}/>} label="직접쓰기" />
                    </FormGroup>
                  }
                />
                <CardContent>
                  <Grid container spacing={2}>
                    {
                      mailDirect === false ? (
                        <>
                          <Grid item xs={12}>
                            <FormInputAutoComplete
                              control={control}
                              label={"거래선명"}
                              options={gclients.map(gclient => ({ label: gclient.name, id: gclient.id }))}
                              setValue={setValue}
                              onCustomChange={handleChangeGClient}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormInputMultipleSelect
                              id="multiple-mail-to-select"
                              name="mailTo"
                              label={"받는 사람"}
                              control={control}
                              setValue={setValue}
                              dynamicItemsBasic={mailTos}
                              onChangeItem={(value) => setMailTos(value.map(i => ({ ...JSON.parse(i) })))}
                              options={emailOptionsTo}
                              chipSize={"small"}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormInputMultipleSelect
                              id="multiple-mail-cc-select"
                              name="mailCc"
                              label={"참조"}
                              control={control}
                              setValue={setValue}
                              dynamicItemsBasic={mailCcs}
                              onChangeItem={(value) => setMailCcs(value.map(i => ({ ...JSON.parse(i) })))}
                              options={emailOptionsCc}
                              chipSize={"small"}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormInputMultipleSelect
                              id="multiple-mail-cc-internal-select"
                              name="mailCcInternal"
                              label={"내부참조"}
                              control={control}
                              setValue={setValue}
                              dynamicItemsBasic={mailCcInternals}
                              onChangeItem={(value) => setMailCcInternals(value.map(i => ({ ...JSON.parse(i) })))}
                              options={emailOptionsCcInternal}
                              chipSize={"small"}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <ReactMultiEmail
                              placeholder="받는 사람"
                              emails={mailToDirect}
                              onChange={(_emails) => setMailToDirect(_emails)}
                              getLabel={( email, index, removeEmail ) => {
                                return (<div data-tag key={index}>
                                  {email}
                                  <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                </div>);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ReactMultiEmail
                              placeholder="참조"
                              emails={mailCcDirect}
                              onChange={(_emails) => setMailCcDirect(_emails)}
                              getLabel={( email, index, removeEmail) => {
                                return (<div data-tag key={index}>
                                  {email}
                                  <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                </div>);
                              }}
                            />
                          </Grid>
                        </>
                      )
                    }
                    <Grid item xs={12}>
                      <FormInputText
                        name={"subject"}
                        control={control}
                        label={"제목"}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"html"}
                        control={control}
                        label={"메시지"}
                        multiline
                        maxRows={5}
                      />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="flex-end" sx={{ color: 'white', fontSize: '0.9em' }}>
                      <label
                        id="input_files_label"
                        htmlFor={"input_files"}
                        /*ref={uploadFilesBoxRef}*/
                        style={{ cursor: 'pointer', borderRadius: '4px', backgroundColor: '#1976D2' }}
                      >
                        <div className="input-label" style={{ padding: '8px' }}>
                          {"추가첨부"}
                        </div>
                      </label>
                      <input hidden type="file" multiple accept="image/*,.pdf" id={"input_files"}/* ref={inputFilesRef}*/onChange={handleFileChange} />
                    </Grid>
                    {
                      addedFiles.length > 0 && (
                        <Grid item xs={12}>
                          <List dense={true}>
                            <ListItem
                              disablePadding>
                              <ListItemButton>
                                <Checkbox
                                  size="small"
                                  checked={checkedAll}
                                  onChange={handleToggleAll}
                                />
                                <Button size="small" disabled={checked.length > 0 ? false : TrafficRounded} onClick={(e) => handleDeleteCheckedFiles(e)}>
                                  {"삭제"}
                                </Button>
                              </ListItemButton>
                            </ListItem>
                            {
                              addedFiles.map((file, idx) => {
                                // console.log(file)
                                const { name } = file;
                                const pos = name.lastIndexOf(".");
                                let ext = "";
                                if (pos > -1) {
                                  ext = name.substring(pos+1);
                                }
    
                                return (
                                  <ListItem
                                    secondaryAction={
                                      <IconButton size="small" onClick={(e) => handleDeleteAddedFile(e, idx)}>
                                        <Clear  sx={{ width: 14, height: 14 }} />
                                      </IconButton>
                                    }
                                    disablePadding>
                                    <ListItemButton>
                                      <Checkbox
                                        onChange={handleToggle(idx)}
                                        checked={checked.indexOf(idx) !== -1}
                                        inputProps={{ 'aria-labelledby': idx }}
                                        size="small"
                                      />
                                      <ListItemIcon>
                                        { ext.toLowerCase() === 'pdf' ? <PictureAsPdf /> : <Image /> }
                                      </ListItemIcon>
                                      <ListItemText primary={name} />
                                    </ListItemButton>
                                  </ListItem>
                                )
                              })
                            }
                          </List>
                        </Grid>
                      )
                    }
                    {
                      addedFiles.length > 0 && (
                        <>
                          <Grid item xs={12}>
                            {"날인정보 "}
                            <span style={{ fontWeight: 'bold', color: '#1976d2' }}>{"원본대조필과 인감, 현장정보"}</span>{" 를 "}
                            <span style={{ fontWeight: 'bold', color: '#1976d2' }}>{"추가첨부 문서"}</span>{"에 날인합니다."}
                          </Grid>
                          <Grid item xs={12}>
                            <ToggleButtonGroup
                              color="primary"
                              value={signOption}
                              exclusive
                              // size="small"
                              onChange={handleChangeSignOption}
                            >
                              <ToggleButton value="CERTIFIED_TRUE_COPY_AND_SIGN">{"날인합니다."}</ToggleButton>
                              <ToggleButton value="NONE">{"날인하지 않습니다."}</ToggleButton>
                            </ToggleButtonGroup>  
                          </Grid>
                          <Grid item xs={12} sx={{ display: signOption === 'NONE' ? 'none' : "" }}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid xs={12} display="flex" justifyContent={"center"} alignItems="center">
                                  <TableContainer>
                                    <Table aria-label="날일정보" sx={{ width: 400 }} size="small">
                                      <TableBody>
                                        <TableRow>
                                          <TableCell colspan={2} sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, width: '90%', fontSize: '20px', color: '#664AA0' }}>
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                              {"원본대조필 (인)"}
                                            </Box>
                                          </TableCell>
                                          <TableCell sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, color: '#664AA0', p: 0 }}>
                                            {
                                              URLThumbnail && (<Box sx={{ width: 60, height: 60 }} display="flex" justifyContent={"center"} alignItems={"center"}>
                                                <img src={URLThumbnail} alt="이미지 미리보기" style={{ maxWidth: '58px' }} />
                                              </Box>)
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, color: '#664AA0', width: '30%' }}>
                                            <Grid container>
                                              <Grid item xs={10}>
                                                <FormInputText
                                                  autoComplete="supplierLabel"
                                                  name={"supplierLabel"}
                                                  control={control}
                                                  required
                                                  fullWidth
                                                  errorStyle={{ message: false, border: true }}
                                                  variant="standard"
                                                  InputProps={{
                                                    disableUnderline: true,
                                                    readOnly: true,
                                                  }}
                                                  inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                                />
                                              </Grid>
                                              <Grid item xs={2}>
                                                {" : "}
                                              </Grid>
                                            </Grid>
                                          </TableCell>
                                          <TableCell colSpan={2} sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, color: '#664AA0' }}>
                                            <FormInputText
                                              autoComplete="supplier"
                                              name={"supplier"}
                                              control={control}
                                              required
                                              fullWidth
                                              errorStyle={{ message: false, border: true }}
                                              variant="standard"
                                              inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                              InputProps={{
                                                disableUnderline: true,
                                                readOnly: true,
                                              }}
                                            />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, width: '30%', fontSize: '14px', color: '#664AA0' }}>
                                            <Grid container>
                                              <Grid item xs={10}>
                                                <FormInputText
                                                  name={"recipientLabel"}
                                                  control={control}
                                                  variant="standard"
                                                  inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                                  InputProps={{
                                                    disableUnderline: true,
                                                    readOnly: true,
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item xs={2}>
                                                {" : "}
                                              </Grid>
                                            </Grid>
                                          </TableCell>
                                          <TableCell colSpan={2} sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, fontSize: '14px', color: '#664AA0' }}>
                                            <FormInputText
                                              name={"recipient"}
                                              control={control}
                                              variant="standard"
                                              inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                              error={emptyRecipient}
                                              errorStyle={{ message: false, border: true }}
                                              // onChange={handleChangeRecipient}
                                              InputProps={{
                                                readOnly: true,
                                                disableUnderline: true
                                              }}
                                            />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, width: '30%', fontSize: '14px', color: '#664AA0' }}>
                                            <Grid container>
                                              <Grid item xs={10}>
                                                <FormInputText
                                                  name={"siteLabel"}
                                                  control={control}
                                                  variant="standard"
                                                  inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                                  InputProps={{
                                                    disableUnderline: true,
                                                    readOnly: true,
                                                  }}
                                                />
                                              </Grid>
                                              <Grid item xs={2}>
                                                {" : "}
                                              </Grid>
                                            </Grid>
                                          </TableCell>
                                          <TableCell colSpan={2} sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, fontSize: '14px', color: '#664AA0' }}>
                                            <FormInputText
                                              name={"site"}
                                              control={control}
                                              variant="standard"
                                              inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                              error={emptySite}
                                              errorStyle={{ message: false, border: true }}
                                              // onChange={handleChangeSite}
                                              InputProps={{
                                                readOnly: true,
                                                disableUnderline: true
                                              }}
                                            />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell colSpan={3} sx={{ border: 1, borderColor: '#664AA0' }}>
                                            <FormInputText
                                              name={"phrase"}
                                              control={control}
                                              // label={"문구"}
                                              // fullWidth
                                              multiline
                                              maxRows={5}
                                              // onChange={handleChangePhrase}
                                              variant="standard"
                                              InputProps={{
                                                disableUnderline: true,
                                                readOnly: true,
                                              }}
                                              inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}

                                            />
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        </>
                      )
                    }
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pt: 2, pb: 2, pr: 3 }}>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            // onClick={files.length > 0 ? () => sendMail() : () => handleSubmit(onSubmit)()}
            loading={loading}
          >
            {"보내기"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
      <ConfirmDialog
        removeId={""}
        title={"안내"}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={sendMailByGClient}
        onCancel={() => {}}
        maxWidth="sm"
      >
        <>
          <span>{`메일설정이 되어 있지 않습니다. 내정보에서 메일설정을 해주세요.`}</span><br /><br />
          <span>{`나중에 설정하고 지금은 아래 관리자 메일로 보내시겠습니까?`}</span><br />
          <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#1976d2', p: 2, mt: 1, fontSize: '1em' }}>
            <span>{`보내는 사람 ${sessionUser.email}`}</span><br />
            <span>{`참조 ${sessionUser && sessionUser.user ? sessionUser.user.userId : ""}`}</span>
          </Box>
        </>
        {/* {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`} */}
      </ConfirmDialog>
    </>
  )
}

export default G04docuMaterialApprovalEmailDialog;