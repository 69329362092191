import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { DataGridPro, koKR } from '@mui/x-data-grid-pro';
import {
  Chip,
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import {
  FormInputText
} from "../form";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import * as securityActions from "../../store/security";
import { getGridHeight } from "../../constants/gridHeights";
const GRID_HEIGHT = getGridHeight('ONE_LINE_LAYER', 190); // 0px 추가 여백

const defaultValues = {
  searchCloseGClients: "",
  searchOpenGClients: "",
}

const Security = ({
  from,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [loadedLeft, setLoadedLeft] = useState(false);
  const [pageSizeLeft, setPageSizeLeft] = useState(100);
  const [pageLeft, setPageLeft] = useState(0);
  const [selectionModelLeft, setSelectionModelLeft] = useState([]);

  const [loadedRight, setLoadedRight] = useState(false);
  const [pageSizeRight, setPageSizeRight] = useState(100);
  const [pageRight, setPageRight] = useState(0);
  const [selectionModelRight, setSelectionModelRight] = useState([]);

  const [selectedRowsLeft, setSelectedRowsLeft] = useState([]);
  const [selectedRowsRight, setSelectedRowsRight] = useState([]);

  const [rowsRight, setRowsRight] = useState([]);

  const dispatch = useDispatch();

  const { control, setValue, getValues } = useForm({ defaultValues });

  const sessionUser = useSelector(state => state.session.sessionUser);
  const sessionUserRole = useSelector((state) => state.session.sessionUser.role);
  const rowsLeft = useSelector((state) => state.security.securityCloseGClients);
  const securityOpenGClients = useSelector((state) => state.security.securityOpenGClients);

  const selectSecurityCloseGClientList = (service, gclientId) => dispatch(securityActions.selectSecurityCloseGClientList(service, gclientId))
  const selectSecurityCloseGClientListByBizRegNumberOrName = (service, gclientId, search) => dispatch(securityActions.selectSecurityCloseGClientListByBizRegNumberOrName(service, gclientId, search))
  const selectSecurityOpenGClientList = (service, gclientId) => dispatch(securityActions.selectSecurityOpenGClientList(service, gclientId))
  const selectSecurityOpenGClientListByBizRegNumberOrName = (service, gclientId, search) => dispatch(securityActions.selectSecurityOpenGClientListByBizRegNumberOrName(service, gclientId, search))

  const addSecurityOpenGClientList = ({ service, gclientId, list }) => dispatch(securityActions.addSecurityOpenGClientList({ service, gclientId, list }))
  const removeSecurityOpenGClientList = ({ service, gclientId, list }) => dispatch(securityActions.removeSecurityOpenGClientList({ service, gclientId, list }))

  useEffect(
    () => {
      console.log(securityOpenGClients)
      if (securityOpenGClients && securityOpenGClients.openList && Array.isArray(securityOpenGClients.openList) && securityOpenGClients.openList.length > 0) {
        setRowsRight(securityOpenGClients.openList);
      } else {
        setRowsRight([]);
      }
    }, [securityOpenGClients]
  )

  const columns = [
    {
      field: 'bizRegNumber',
      headerName: '사업자등록번호',
      width: 150,
    },
    {
      field: 'name',
      headerName: '회원사명',
      width: 200,
      renderCell: (params) => {
        const { name, status } = params.row;
        return (<>
          { (status === '요청' || status === '비공개') && (<Chip color="secondary" label={status} size="small" sx={{ mr: 1 }}></Chip>) }
          {name}
        </>)
      }
    },
    {
      field: 'ceo',
      headerName: '대표자',
      width: 150,
    },
  ]

  const handlePageChangeLeft = (newPage) => {
    setPageLeft(newPage);
  }

  const handlePageChangeRight = (newPage) => {
    setPageRight(newPage);
  }

  useEffect(
    async () => {
      setTimeout(() => setLoadedLeft(true), 300);
      setTimeout(() => setLoadedRight(true), 300);
    }, 
  )

  useEffect(
    async () => {
      if (from) {
        return;
      }
      
      if (sessionUser && sessionUser.id) {
        await selectSecurityOpenGClientList('04docu', sessionUser.id);
        await selectSecurityCloseGClientList('04docu', sessionUser.id);
      }
    }, [dispatch]
  )

  // 관리자가 해당 거래선의 문서 보안 설정을 대리로 설정하기 위해 추가
  useEffect(
    async () => {
      if (from && from.source === 'GSecurityByGClientManagement') {
        if (from.params && from.params.gclientId) {
          // TODO : 현재 2번 들어옴. 추후 수정해야 함
          await selectSecurityOpenGClientList('04docu', from.params.gclientId);
          await selectSecurityCloseGClientList('04docu', from.params.gclientId);
        }
      }
    }, [from]
  )

  const handleClickRight = async () => {
    setValue("searchCloseGClients", "");
    setValue("searchOpenGClients", "");
    
    let gclientId = "";
    if (from && from.params && from.params.gclientId) {
      gclientId = from.params.gclientId;
    } else {
      gclientId = sessionUser.id;
    }
    
    // addSecurityOpenGClientList 내부에서 openList를 조회해서 store에서 openList 구성 => 아래에서 selectSecurityCloseGClientList 함수만 호출하는 이유임
    await addSecurityOpenGClientList({ service: '04docu', gclientId, list: selectedRowsLeft })

    setSelectionModelLeft([]);
    setSelectedRowsLeft([]);
    
    await selectSecurityCloseGClientList('04docu', gclientId);
  }

  const handleClickLeft = async () => {
    setValue("searchCloseGClients", "");
    setValue("searchOpenGClients", "");
    
    let gclientId = "";
    if (from && from.params && from.params.gclientId) {
      gclientId = from.params.gclientId;
    } else {
      gclientId = sessionUser.id;
    }

    // removeSecurityOpenGClientList 내부에서 openList를 조회해서 store에서 openList 구성 => 아래에서 selectSecurityCloseGClientList 함수만 호출하는 이유임
    await removeSecurityOpenGClientList({ service: '04docu', gclientId, list: selectedRowsRight })

    setSelectionModelRight([]);
    setSelectedRowsRight([]);
    
    await selectSecurityCloseGClientList('04docu', gclientId);
  }

  var timer;
  const handleChangeCloseGClients = async (e) => {
    let gclientId = "";
    if (from && from.params && from.params.gclientId) {
      gclientId = from.params.gclientId;
    } else {
      gclientId = sessionUser.id;
    }
    
    // 과도한 API 호출 방지를 위한 debouncing 코드. TODO : 라이브러리 활용 검토
    if (timer) {
      clearTimeout(timer);
    }
    // 타이머 설정
    timer = setTimeout(() => {
      const searchGClients = e.target.value;
      selectSecurityCloseGClientListByBizRegNumberOrName('04docu', gclientId, searchGClients);
    }, 500);
  };

  const handleChangeOpenGClients = async (e) => {
    let gclientId = "";
    if (from && from.params && from.params.gclientId) {
      gclientId = from.params.gclientId;
    } else {
      gclientId = sessionUser.id;
    }
    
    // 과도한 API 호출 방지를 위한 debouncing 코드. TODO : 라이브러리 활용 검토
    if (timer) {
      clearTimeout(timer);
    }
    // 타이머 설정
    timer = setTimeout(() => {
      const searchGClients = e.target.value;
      // 이미 가져온 정보를 화면에서 필터링한다. 검색조건이 사라지면 다시 서버에서 전체를 가져온다.
      if (searchGClients.length > 0) {
        if (securityOpenGClients && securityOpenGClients.openList && Array.isArray(securityOpenGClients.openList) && securityOpenGClients.openList.length > 0) {
          setRowsRight(securityOpenGClients.openList.filter(gclient => {
            const { name, bizRegNumber } = gclient;
            if (name.indexOf(searchGClients) >= 0 || bizRegNumber.indexOf(searchGClients) >= 0) {
              return true;
            } else {
              return false;
            }
          }))
        }
      } else {
        selectSecurityOpenGClientListByBizRegNumberOrName('04docu', gclientId, searchGClients);
      }      
    }, 500);
  };

  return (
    <>
      {
        sessionUserRole === 'ADMIN_ROLE' && (
          <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary aria-controls="security-content" id="security-header">
              <Typography variant="h6" component="div">{"보안"}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {
                      sessionUserRole === 'ADMIN_ROLE' && (
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={5}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>{"비공개"}</Box>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormInputText
                                    name={"searchCloseGClients"}
                                    control={control}
                                    label={"사업자등록번호 또는 회원사명으로 검색하세요."}
                                    // placeholder="사업자등록번호 또는 회원사명으로 검색하세요."
                                    onCustomChange={handleChangeCloseGClients}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <DataGridPro
                                    localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                                    // density="standard"
                                    sx={{ cursor: 'pointer', fontSize: '0.85em', height: from && from.params && from.params.gclientId ? GRID_HEIGHT.REDUCED : 400, width: '100%' }}
                                    slots={{
                                      noRowsOverlay: CustomNoRowsOverlay,
                                      loadingOverlay: LinearProgress,
                                    }}
                                    columnHeaderHeight={38}
                                    rowHeight={34}
                                    loading={!loadedLeft}
                                    rows={Array.isArray(rowsLeft) ? rowsLeft : []}
                                    columns={columns}
                                    pageSize={pageSizeLeft}
                                    onPageSizeChange={(newPageSize) => setPageSizeLeft(newPageSize)}
                                    rowsPerPageOptions={[1, 10, 20, 50, 100]}
                                    onPageChange={handlePageChangeLeft}
                                    page={pageLeft}
                                    pagination
                                    disableColumnMenu
                                    checkboxSelection
                                    onRowSelectionModelChange={(ids) => {
                                      const selectedIDs = new Set(ids);
                                      setSelectionModelLeft(ids);
                                      
                                      const selected = rowsLeft.filter((row) => selectedIDs.has(row.id));
                                      setSelectedRowsLeft(selected);
                                    }}
                                    selectionModel={selectionModelLeft}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={2} display="flex" alignItems={"center"}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} display="flex" justifyContent={"center"}>
                                  <Button variant={"contained"} sx={{ width: 100 }} startIcon={<KeyboardArrowRight />} onClick={handleClickRight}>
                                    {"공개"}
                                  </Button>
                                </Grid>
                                <Grid item xs={12} display="flex" justifyContent={"center"}>
                                  <Button variant={"outlined"} sx={{ width: 100 }} startIcon={<KeyboardArrowLeft />} onClick={handleClickLeft}>
                                    {"비공개"}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>{"공개"}</Box>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormInputText
                                    name={"searchOpenGClients"}
                                    control={control}
                                    label={"사업자등록번호 또는 회원사명으로 검색하세요."}
                                    onCustomChange={handleChangeOpenGClients}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <DataGridPro
                                    localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                                    sx={{ cursor: 'pointer', fontSize: '0.85em', height: from && from.params && from.params.gclientId ? GRID_HEIGHT.REDUCED : 400, width: '100%' }}
                                    slots={{
                                      noRowsOverlay: CustomNoRowsOverlay,
                                      loadingOverlay: LinearProgress,
                                    }}
                                    columnHeaderHeight={38}
                                    rowHeight={34}
                                    loading={!loadedRight}
                                    rows={Array.isArray(rowsRight) ? rowsRight : []}
                                    columns={columns}
                                    pageSize={pageSizeRight}
                                    onPageSizeChange={(newPageSize) => setPageSizeLeft(newPageSize)}
                                    rowsPerPageOptions={[1, 10, 20, 50, 100]}
                                    onPageChange={handlePageChangeRight}
                                    page={pageRight}
                                    pagination
                                    disableColumnMenu
                                    checkboxSelection
                                    onRowSelectionModelChange={(ids) => {
                                      const selectedIDs = new Set(ids);
                                      setSelectionModelRight(ids);

                                      const selected = rowsRight.filter((row) => selectedIDs.has(row.id));
                                      setSelectedRowsRight(selected);
                                    }}
                                    selectionModel={selectionModelRight}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )
      }
    </>
  )
}

export default Security;