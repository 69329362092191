// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { projectServerUrl } from '../config';

const SET_GPERFORM_REPORT = 'gperformReport/setGPerformReport';
const SET_GPERFORM_REPORTS = 'gperformReport/setGPerformReports';
const REMOVE_GPERFORM_REPORT = 'gperformReport/removeGPerformReport';

const setGPerformReport = (gperformReport) => ({
  type: SET_GPERFORM_REPORT,
  payload: gperformReport,
});

const setGPerformReports = (gperformReports) => ({
  type: SET_GPERFORM_REPORTS,
  payload: gperformReports,
});

const removeGPerformReport = () => ({
  type: REMOVE_GPERFORM_REPORT,
});

export const create = ({ gclientId, site, siteAddress, comments, selectedGlasses, pdfYn }) => async dispatch => {
  const params = new URLSearchParams(); // URLSearchParams는 브라우저 내장 객체
  if (pdfYn) params.append('pdfYn', pdfYn);
  const queryString = params.toString();

  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gperformReport?${queryString}`, {
    method: 'POST',
    body: JSON.stringify({ gclientId, site, siteAddress, comments, selectedGlasses }),
  });
  const data = await response.json();
  dispatch(setGPerformReport(data.gproject));
  return data;
}

export const modify = ({ id, gclientId, site, siteAddress, comments, selectedGlasses, pdfYn }) => async dispatch => {
  const params = new URLSearchParams(); // URLSearchParams는 브라우저 내장 객체
  if (pdfYn) params.append('pdfYn', pdfYn);
  const queryString = params.toString();

  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gperformReport/gprojectId/${id}?${queryString}`, {
    method: 'PUT',
    body: JSON.stringify({ id, gclientId, site, siteAddress, comments, selectedGlasses }),
  });
  const data = await response.json();
  dispatch(setGPerformReport(data.gproject));
  return data;
}

export const select = (gprojectId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gperformReport/gprojectId/${gprojectId}`);
  const data = await response.json();
  dispatch(setGPerformReport(data.gproject));
  return response;
}

export const selectDirect = async (gprojectId) => {
  const response = await csrfFetch(null, `${projectServerUrl}/api/gperformReport/gprojectId/${gprojectId}`);
  const data = await response.json();
  return data.gproject;
}

export const selectAll = (searchGClient, searchSite) => async dispatch => {
  const params = new URLSearchParams();
  if (searchGClient) params.append('searchGClient', searchGClient);
  if (searchSite) params.append('searchSite', searchSite);
  const queryString = params.toString();
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gperformReport?${queryString}`);
  const data = await response.json();
  dispatch(setGPerformReports(data.gprojects));
  return response;
}

export const remove = (gprojectId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gperformReport/gprojectId/${gprojectId}`, {
    method: 'DELETE',
  });
  dispatch(removeGPerformReport());
  return response;
}

const initialState = {
  gperformReports: [],
  gperformReport: {},
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GPERFORM_REPORT:
      newState = Object.assign({}, state, { gperformReport: action.payload });
      return newState;
    case SET_GPERFORM_REPORTS:
      newState = Object.assign({}, state, { gperformReports: action.payload });
      return newState;
    case REMOVE_GPERFORM_REPORT:
      newState = Object.assign({}, state, { gperformReport: {} });
      return newState;
    default:
      return state;
  }
}

export default reducer;
