// TODO : 추후 G04docuFile의 G04docuFileDialog와 통합 필요
/**
 * https://pqina.nl/filepond/docs/api/instance/properties/
Labels
PROPERTY	DEFAULT VALUE	DESCRIPTION
labelDecimalSeparator	
undefined
The decimal separator used to render numbers. By default this is determined automatically.
labelThousandsSeparator	
undefined
The thousdands separator used to render numbers. By default this is determined automatically.
labelIdle	
'Drag & Drop your files or <span class="filepond--label-action"> Browse </span>'
Default label shown to indicate this is a drop area. FilePond will automatically bind browse file events to the element with CSS class .filepond--label-action
labelInvalidField	
'Field contains invalid files'
Label shown when the field contains invalid files and is validated by the parent form.
labelFileWaitingForSize	
'Waiting for size'
Label used while waiting for file size information
labelFileSizeNotAvailable	
'Size not available'
Label used when no file size information was received
labelFileLoading	
'Loading'
Label used while loading a file
labelFileLoadError	
'Error during load'
Label used when file load failed
labelFileProcessing	
'Uploading'
Label used when uploading a file
labelFileProcessingComplete	
'Upload complete'
Label used when file upload has completed
labelFileProcessingAborted	
'Upload cancelled'
Label used when upload was cancelled
labelFileProcessingError	
'Error during upload'
Label used when something went wrong during file upload
labelFileProcessingRevertError	
'Error during revert'
Label used when something went wrong during reverting the file upload
labelFileRemoveError	
'Error during remove'
Label used to indicate something went wrong when removing the file
labelTapToCancel	
'tap to cancel'
Label used to indicate to the user that an action can be cancelled.
labelTapToRetry	
'tap to retry'
Label used to indicate to the user that an action can be retried.
labelTapToUndo	
'tap to undo'
Label used to indicate to the user that an action can be undone.
labelButtonRemoveItem	
'Remove'
Label used for remove button
labelButtonAbortItemLoad	
'Abort'
Label used for abort load button
labelButtonRetryItemLoad	
'Retry'
Label used for retry load button
labelButtonAbortItemProcessing	
'Cancel'
Label used for abort upload button
labelButtonUndoItemProcessing	
'Undo'
Label used for undo upload button
labelButtonRetryItemProcessing	
'Retry'
Label used for retry upload button
labelButtonProcessItem	
'Upload'
Label used for upload button
 */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  koKR,
  GridCellModes,
} from '@mui/x-data-grid-pro';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  LinearProgress,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import  {
  Add,
  Autorenew,
  CommentBank,
  Delete,
  ManageSearch,
  Refresh,
  Remove,
  Save,
  Undo,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { v4 as uuidv4 } from 'uuid';
import { FilePond } from 'react-filepond';
import { gcoreServerUrl, fileServerUrl, uploadFilePath, mode } from '../../config';
import uploadLabels from '../../FildPondLabel';
import {
  FormInputCheckbox,
  FormInputDate,
} from "../form";
import {
  AlertDialog,
  ConfirmDialog,
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import { GlassUtils, dateFormat } from "../../utils";

import * as gclientG04docuGCertificationMapActions from "../../store/gclientG04docuGCertificationMap";
import * as gclientG04docuGTestMapActions from "../../store/gclientG04docuGTestMap";
import * as g04docuFileActions from "../../store/g04docuFile";
import * as gtestComparisonActions from "../../store/gtestComparison";
import * as g04docuGTestComparisonActions from "../../store/g04docuGTestComparison";

const today = new Date();
// let endDate = new Date();
// endDate.setFullYear(today.getFullYear() + 2);

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  no: "",
  name: "",
  code: "",
  // certification: [],
  // testCertification: [],
  validYN: false,
  startDate: today.getDateWithStartHours(),
  endDate: null,
  comments: "",
};

let pond;

const G04docuFileDialog = ({
  open,
  setOpen,
  selectedRow,
  selectedGClientId,
  selectedGClient,
  selectedGComponentItemId,
  selectedGComponentItemName,
  selectedGComponentItemType,
  setSelectedGComponentItemId,
  setSelectedGComponentItemName,
  setSelectedGComponentItemType,
  selectedDocuFileType,
  refresh,
  from,
}) => {
  const handleClick = () => {
    const id = uuidv4();
    
    setRowsG04docuGTestComparison((oldRows) => [...oldRows, { id, item: '시험항목', unit: '단위', criterion: '기준', value: '', result: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.View, fieldToFocus: 'item' },
    }));
  };

  const handleClickG04docuGTestComparison = async () => {
    setLoaded(false);

    await createG04docuGTestComparisonsBulk({
      g04docuGTestId,
      g04docuGTestName,
      division,
      g04docuGTestComparisons: rowsG04docuGTestComparison.map(row => {
        delete row.g04docuGCertificationId;
        return row;
      }),
      gclientId: selectedGClientId,
      gcomponentItemId: selectedGComponentItemId,
      gcomponentItemName: selectedGComponentItemName,
      g04docuGCertificationId,
      g04docuGCertificationName,
      gclient: selectedGClient,
    });

    setAlertInfo({
      titleAlert: "안내",
      messageAlert: (
        <div>
          <span style={{ color: "#1976d2" }}>{"시험성과대비표"}</span><span>{"가 작성되었습니다."}</span>
          <br /><br />
          <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
            { `제품명(성적서명) : ${selectedGComponentItemName} ${g04docuGTestName}` }
          </Box>
        </div>
      ),
      open: true,
    });

    setTimeout(() => setLoaded(true), 300);

    if (from === 'PROFILE') {
      division === 'PROCESS' ? refresh(selectedGClientId) : refresh(selectedGClientId, division);
    } else {
      refresh(selectedGComponentItemType === 'PROCESS' ? g04docuGCertificationId : selectedGComponentItemId, selectedGComponentItemType, selectedGClientId);
    }
  }

  const handleClickInitG04docuGTestComparison = () => {
    const { g04docuGCertificationId } = selectedRow;
    if (g04docuGCertificationId) {
      selectGTestComparisions(g04docuGCertificationId)
    }
  }

  const handleClickSearchG04docuGTestComparison = () => {
    const { g04docuGTestId } = selectedRow;
    if (g04docuGTestId) {
      selectAllByG04docuGTests(g04docuGTestId)
    }
  }
  
  // DataGridPro 내 툴바 사용할 경우 셀 수정 후 포커스를 잃어야 버튼이 동작한다. 따라서 EditToolbar 사용하지 않고 그리드 밖에 버튼을 만들어 사용
  const EditToolbar = (props) => {
    const { setRows, setRowModesModel, rows } = props;
  
    const handleClick = () => {
      const id = uuidv4();
      
      setRows((oldRows) => [...oldRows, { id, item: '시험항목', unit: '단위', criterion: '기준', value: '', result: '', isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'item' },
      }));
    };
  
    const handleClickG04docuGTestComparison = async () => {
      setLoaded(false);
      await createG04docuGTestComparisonsBulk({
        g04docuGTestId,
        g04docuGTestName,
        division,
        g04docuGTestComparisons: rowsG04docuGTestComparison,
        gclientId: selectedGClientId,
        gcomponentItemId: selectedGComponentItemId,
        gcomponentItemName: selectedGComponentItemName,
        g04docuGCertificationId,
        g04docuGCertificationName,
        gclient: selectedGClient,
      });

      setAlertInfo({
        titleAlert: "안내",
        messageAlert: (
          <div>
            <span style={{ color: "#1976d2" }}>{"시험성과대비표"}</span><span>{"가 작성되었습니다."}</span>
            <br /><br />
            <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
              { `제품명 : ${selectedGComponentItemName} ${g04docuGTestName}` }
            </Box>
          </div>
        ),
        open: true,
      });

      setTimeout(() => setLoaded(true), 300);

      if (from === 'PROFILE') {
        division === 'PROCESS' ? refresh(selectedGClientId) : refresh(selectedGClientId, division);
      } else {
        refresh(selectedGComponentItemType === 'PROCESS' ? g04docuGCertificationId : selectedGComponentItemId, selectedGComponentItemType, selectedGClientId);
      }
    }

    const handleClickInitG04docuGTestComparison = () => {
      const { g04docuGCertificationId } = selectedRow;
      if (g04docuGCertificationId) {
        selectGTestComparisions(g04docuGCertificationId)
      }
    }

    const handleClickSearchG04docuGTestComparison = () => {
      const { g04docuGTestId } = selectedRow;
      if (g04docuGTestId) {
        selectAllByG04docuGTests(g04docuGTestId)
      }
    }
  
    const { gstandardNo, guideLink } = selectedRow;
    const GuideLink = guideLink ? (
      <Tooltip title={guideLink}>
        <Button
          startIcon={<CommentBank />}
          >
          <Link
            href={guideLink}
            underline="none"
            target="_blank"
            rel="noopener"
          > 
            {`${gstandardNo} 규격집`}
          </Link>
        </Button>
      </Tooltip>
    ) : <></>;

    return (
      <GridToolbarContainer>
        <Tooltip title="시험항목을 추가합니다.">
          <Button color="primary" startIcon={<Add />} onClick={handleClick}>
            {"추가"}
          </Button>
        </Tooltip>
        <Tooltip title="시험결과를 저장하고 시험성과대비표를 작성합니다.">
          <Button color="primary" startIcon={<Save />} onClick={handleClickG04docuGTestComparison}>
            {"저장/작성"}
          </Button>
        </Tooltip>
        <Tooltip title="표준 시험항목을 불러옵니다.">
          <Button color="primary" startIcon={<Undo />} onClick={handleClickInitG04docuGTestComparison}>
            {"초기화"}
          </Button>
        </Tooltip>
        <Tooltip title="마지막으로 작성한 시험성과대비표를 불러옵니다.">
          <Button color="primary" startIcon={<Refresh />} onClick={handleClickSearchG04docuGTestComparison}>
            {"다시불러오기"}
          </Button>
        </Tooltip>
        {GuideLink}
      </GridToolbarContainer>
    );
  }

  const { division, g04docuGCertificationId, g04docuGCertificationName, g04docuGTestId, g04docuGTestName, documentPath/*, startDate, endDate*/ } = selectedRow;

  // const [errors, setErrors] = useState([]);
  const [files, setFiles] = useState(
    []
    // [{
    //   source: "https://www.google.com/logos/doodles/2018/baba-amtes-104th-birthday-6729609885253632-s.png",
    //   options:{
    //         type: 'remote'
    //   }
    // }],
  );
  // const [loading, setLoading] = useState(false);
  const [alertInfo, setAlertInfo] = useState({});
  const [uploadedDocumentPath, setUploadedDocumentPath] = useState("");
  const [fullScreen, setFullScreen] = useState(false);
  const [gtestComparisonShow, setGTestComparisonShow] = useState(false);
  const [rowsG04docuGTestComparison, setRowsG04docuGTestComparison] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [cellModesModel, setCellModesModel] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [fromGTestComparison, setFromGTestComparison] = useState(false);
  // const [endDate, setEndDate] = useState(null);

  // const [rows, setRows] = React.useState(initialRows);
  // const [disabled, setDisabled] = useState(true);
  // const [disabledRemoveButton, setDisabledRemoveButton] = useState(true);
  // const [confirmOpen, setConfirmOpen] = useState(false);

  // const handleRowModesModelChange = (newRowModesModel) => {
  //   setRowModesModel(newRowModesModel);
  // };

  // const handleRowEditStop = (params, event) => {
  //   if (params.reason === GridRowEditStopReasons.rowFocusOut) {
  //     event.defaultMuiPrevented = true;

  //     const { id } = params;
  //     setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  //   }
  // };

  const handleCellClick = useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (event.target.nodeType === 1 && !event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => {      
      const newModel = {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
      return newModel;
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    console.log("handleCellModesModelChange");
    console.log(newModel)
    setCellModesModel(newModel);
  }, []);

  // const handleEditClick = (id) => () => {
  //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  // };

  // const handleSaveClick = (id) => () => {
  //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  // };

  const handleDeleteClick = (id) => () => {
    setRowsG04docuGTestComparison(rowsG04docuGTestComparison.filter((row) => row.id !== id));
  };

  // const handleCancelClick = (id) => () => {
  //   setRowModesModel({
  //     ...rowModesModel,
  //     [id]: { mode: GridRowModes.View, ignoreModifications: true },
  //   });

  //   const editedRow = rowsG04docuGTestComparison.find((row) => row.id === id);
  //   if (editedRow.isNew) {
  //     setRowsG04docuGTestComparison(rowsG04docuGTestComparison.filter((row) => row.id !== id));
  //   }
  // };
  const updateRowPosition = (initialIndex, newIndex, rows) => {
    return new Promise((resolve) => {
      setTimeout(
        () => {
          const rowsClone = [...rows];
          const row = rowsClone.splice(initialIndex, 1)[0];
          rowsClone.splice(newIndex, 0, row);
          resolve(rowsClone);
        }, 300,
      );
    });
  }

  const handleRowOrderChange = async (params) => {
    // setLoading(true);
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rowsG04docuGTestComparison,
    );

    // setRowsG04docuGTestComparison(newRows.map(row => {
    //   return {
    //     ...row,
    //     id: uuidv4(),
    //   }
    // }));
    setRowsG04docuGTestComparison(newRows);
    // setLoading(false);
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRowsG04docuGTestComparison(rowsG04docuGTestComparison.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleDialogClose = () => {
    setOpen(false);

    initDialog();
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    setFiles([]);
    setUploadedDocumentPath("");
    setFullScreen(false);
    setGTestComparisonShow(false);
    setRowsG04docuGTestComparison([]);
    setFromGTestComparison(false);
    // setDisabled(true);
    // setDisabledRemoveButton(true);
    // setSelectedDocumentPath("");
    // console.log({ selectedGComponentItemId, selectedGComponentItemName, selectedGComponentItemType })
    // setSelectedGComponentItemId("");
    // setSelectedGComponentItemName("");
    // setSelectedGComponentItemType("");
  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { control, setValue, getValues } = useForm({ defaultValues: defaultValues });
  
  const gtestComparisons = useSelector((state) => state.gtestComparison.gtestComparisons);
  const g04docuGTestComparisons = useSelector((state) => state.g04docuGTestComparison.g04docuGTestComparisons);

  // 데이터 관리
  const dispatch = useDispatch();

  const createGClientG04docuGCertificationMap = ({ gclientId, g04docuGCertificationId, gcomponentItemId, gclient, documentPath, documentType, validYN, startDate, endDate }) => dispatch(gclientG04docuGCertificationMapActions.create({ gclientId, g04docuGCertificationId, gcomponentItemId, gclient, documentPath, documentType, validYN, startDate, endDate }))
  const updateGClientG04docuGCertificationMap = ({ gclientId, g04docuGCertificationId, documentPath, validYN, startDate, endDate }) => dispatch(gclientG04docuGCertificationMapActions.modifyDate({ gclientId, g04docuGCertificationId, documentPath, validYN, startDate, endDate }))
  const removeGClientG04docuGCertificationMap = ({ gclientId, g04docuGCertificationId, documentPath }) => dispatch(gclientG04docuGCertificationMapActions.remove({ gclientId, g04docuGCertificationId, documentPath }))

  const createGClientG04docuGTestMap = ({ gclientId, g04docuGTestId, gcomponentItemId, g04docuGCertificationId, gclient, documentPath, documentType, validYN, startDate, endDate }) => dispatch(gclientG04docuGTestMapActions.create({ gclientId, g04docuGTestId, gcomponentItemId, g04docuGCertificationId, gclient, documentPath, documentType, validYN, startDate, endDate }))
  const updateGClientG04docuGTestMap = ({ gclientId, g04docuGTestId, documentPath, validYN, startDate, endDate }) => dispatch(gclientG04docuGTestMapActions.modifyDate({ gclientId, g04docuGTestId, documentPath, validYN, startDate, endDate }))
  const removeGClientG04docuGTestMap = ({ gclientId, g04docuGTestId, documentPath }) => dispatch(gclientG04docuGTestMapActions.remove({ gclientId, g04docuGTestId, documentPath }))

  const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)
  
  const selectGTestComparisions = (g04docuGCertificationId) => dispatch(gtestComparisonActions.selectAllByG04docuGCertifications(g04docuGCertificationId))
  const selectAllByG04docuGTests = (g04docuGTestId) => dispatch(g04docuGTestComparisonActions.selectAllByG04docuGTests(g04docuGTestId))
  const createG04docuGTestComparisonsBulk = ({
    g04docuGTestId,
    g04docuGTestName,
    division,
    g04docuGTestComparisons,
    gclientId,
    gcomponentItemId,
    gcomponentItemName,
    g04docuGCertificationId,
    g04docuGCertificationName,
    gclient,
  }) => dispatch(g04docuGTestComparisonActions.createG04docuGTestComparisonsBulk({
    g04docuGTestId,
    g04docuGTestName,
    division,
    g04docuGTestComparisons,
    gclientId,
    gcomponentItemId,
    gcomponentItemName,
    g04docuGCertificationId,
    g04docuGCertificationName,
    gclient,
  }))
  // const onSubmit = ({ id, no, name, code, /*certifications, testCertifications,*/ comments }) => {
  //   setErrors([]);
    
  //   // console.log({ id, no, name, code, certifications, testCertifications, comments });
  //   // return;
  //   let func;
  //   if (crudMode === 'C') {
  //     func = addGStandard;
  //   } else if ((crudMode === 'U')) {
  //     func = modifyGStandard;
  //   }
  //   func({ id, no, name, code, certifications, testCertifications, comments })
  //     .then (res => {
  //       handleDialogClose();
  //       refresh(queryPaging);
  //     })
  //     .catch (async (res) => {
  //       const data = await res.json();
  //       if (data && data.errors) setErrors(data.errors);
  //     });
  // }
  const columnsGTestComparison = [ // 정렬없음
    {
      field: 'item',
      headerName: '구분',
      editable: true,
      width: 160,
    },
    {
      field: 'unit',
      headerName: '단위',
      editable: true,
      width: 100,
    },
    {
      field: 'criterion',
      headerName: '기준',
      editable: true,
      width: 300,
    },
    {
      field: 'value',
      headerName: '시험결과',
      editable: true,
      width: 120,
    },
    {
      field: 'result',
      headerName: '판정',
      width: 120,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['합격', '불합격', '참조값'],
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'method',
      headerName: '시험방법',
      editable: true,
      width: 120,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '기능',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    // {
    //   field: 'functions',
    //   headerName: '기능',
    //   headerAlign: 'center',
    //   align: 'center',
    //   renderCell: (params) => {          
    //     const buttons = [
    //       // <IconButton size="small" ><OpenInNew /></IconButton>,
    //       <IconButton size="small" onClick={(e) => handleSelectGTestComparison(e, { type: 'edit', params })}><Edit /></IconButton>,
    //       <IconButton size="small" onClick={(e) => handleSelectGTestComparison(e, { type: 'delete', params })}><Delete /></IconButton>,
    //     ];
        
    //     return buttons;
    //   }
    // },
  ];

  useEffect(
    () => {
      if (selectedRow) {
        console.log({ documentPath, uploadedDocumentPath });
        for (const [item, value] of Object.entries(defaultValues)) {
          if (item === "startDate" || item === "endDate") {
            console.log(typeof selectedRow[item]) // DB에서 받은 값은 string 타입임
            const newValue = selectedRow[item] ? new Date(selectedRow[item]) : value;
            setValue(item, newValue);
            // if (item === "endDate") {
            //   setEndDate(newValue);
            // }
          } else {
            setValue(item, selectedRow[item] || value);
          }
        }

        // 그외 설정할 것들은 여기서 한다.
        // selectedRow["endDate"] && documentPath && setDisabled(false); // 인증서는 유효기간이 반드시 있으므로 "만료일 없음" 관련 기능 삭제
        // gtestComparisons
        
        const { g04docuGCertificationId, g04docuGTestId } = selectedRow;
        if (g04docuGTestId) {
          selectAllByG04docuGTests(g04docuGTestId);
        }
      }
    }, [selectedRow]
  );

  useEffect(
    () => {
      console.log(selectedRow)
      const { g04docuGTestId, division, code, selectedClassifications, sample, gstandardNo } = selectedRow;
      
      let type = "";
      if (selectedClassifications && Array.isArray(selectedClassifications)) {
        const selectedClassification = selectedClassifications.filter(c => c.checked === true);
        if (selectedClassification.length > 0) {
          type = selectedClassification[0].type;
        }
      }

      const newRowsGTestComparison = gtestComparisons.map(row => {
        const { criterion } = row;
        const selectedCriterion = GlassUtils.selectCriterion(criterion, code, type, division, sample, gstandardNo);
        return {
          ...row,
          id: uuidv4(),
          g04docuGTestId: g04docuGTestId,
          criterion: selectedCriterion,
        }
      })

      if (newRowsGTestComparison.length > 0) {
        setLoaded(false);
        setRowsG04docuGTestComparison(newRowsGTestComparison);
      } else {
        setRowsG04docuGTestComparison([]);
      }

      setLoaded(true);
    }, [gtestComparisons]
  )

  useEffect(
    () => {
      if (g04docuGTestComparisons.length === 0) {
        const { g04docuGCertificationId } = selectedRow;
        if (g04docuGCertificationId) {
          setFromGTestComparison(true);
          selectGTestComparisions(g04docuGCertificationId);
          
        }
      } else {
        setFromGTestComparison(false);
        setRowsG04docuGTestComparison(g04docuGTestComparisons);
      }
    }, [g04docuGTestComparisons]
  )

  useEffect(
    () => {
      // console.log(rowsG04docuGTestComparison)
    }, [rowsG04docuGTestComparison]
  )

  useEffect(
    async () => {
      if (open && documentPath) {
        const arr = documentPath.split("/");
        const fileName = arr[arr.length - 1];

        // const res = await downloadG04docuFile(documentPath);
        // const blob = await res.blob();
        // console.log(blob)
        setFiles(
          [{
            // source: blob,
            // source: JSON.stringify({ file: documentPath }),
            options: {
              type: 'limbo',
              file: {
                name: fileName,
                // size: blob.size,
                // type: blob.type,
              },
            }
          }]
        )
        // setDisabledRemoveButton(false);
      }
    }, [open]
  )

  useEffect(
    async () => {
      // 다이얼로그 풀스크린하면 FilePond를 다시 로딩함. 이때 파일첨부 부분 표시문제로 여기서 초기화하고 useEffect([fullScreen])에서 다시 setFiles 실행
      if (documentPath) {
        const arr = documentPath.split("/");
        const fileName = arr[arr.length - 1];

        const file = {
          options: {
            type: 'limbo',
            file: { name: fileName },
          }
        };

        setFiles([file]);
      }
    }, [fullScreen]
  )

  // const handleClickUpload = () => {
  //   console.log(files)
  // }

  // return 값에 유의. true => 값 변경, false => 현재값 유지
  const handleChangePeriod = (value, name) => {
    console.log({ name, value })
    console.log({ files, documentPath, uploadedDocumentPath });
    // if ((documentPath || uploadedDocumentPath) && (name === "endDate" ? value : getValues("endDate"))) {
    //   setDisabled(false);
    // } else {
    //   setDisabled(true);
    // }
    setValue(name, value);
    setValue("validYN", false);
    // if (name === "endDate") {
    //   setEndDate(value);
    // }
    return true;
  }

  const updateDate = async () => {
    // setLoading(true);
    const startDate = getValues("startDate");
    let endDate = getValues("endDate");
    // console.log({ "obj": "startDate", type: typeof startDate, "Date object": startDate instanceof Date, startDate })
    // console.log({ "obj": "endDate", type: typeof endDate, "Date object": endDate instanceof Date, endDate })
    if (endDate && typeof endDate === 'string') { // 사실 endDate는 Date 객체임 => dispatch([selectedRow] 코드에서 Date 객체로 저장)
      endDate = new Date(endDate)
    }

    // 최초 등록 후 파일다이얼로그를 닫지 않고 유효기간만 수정하면 selectedRow의 documentPath는 없는 상태임. 따라서 최초 등록시 파일경로를 저장해두고 documentPath가 없을 경우 사용
    if (selectedDocuFileType === 'CERTIFICATION') {
      await updateGClientG04docuGCertificationMap({
        gclientId: selectedGClientId,
        g04docuGCertificationId,
        documentPath: documentPath || uploadedDocumentPath,
        validYN: getValues("validYN"),
        startDate: startDate ? dateFormat(startDate.getDateWithStartHours()) : null,
        endDate: endDate ? dateFormat(endDate.getDateWithEndHours()) : null,
      });
    } else if (selectedDocuFileType === 'TEST') {
      await updateGClientG04docuGTestMap({
        gclientId: selectedGClientId,
        g04docuGTestId,
        documentPath: documentPath || uploadedDocumentPath,
        validYN: getValues("validYN"),
        startDate: getValues("startDate"),
        startDate: startDate ? dateFormat(startDate.getDateWithStartHours()) : null,
        endDate: endDate ? dateFormat(endDate.getDateWithEndHours()) : null,
      });
    }

    // setTimeout(() => setLoading(false), 1000);

    if (selectedDocuFileType === 'CERTIFICATION') {
      refresh(selectedGClientId);
    } else if (selectedDocuFileType === 'TEST') {
      if (from === 'PROFILE') {
        division === 'PROCESS' ? refresh(selectedGClientId) : refresh(selectedGClientId, division);
      } else {
        refresh(selectedGComponentItemType === 'PROCESS' ? g04docuGCertificationId : selectedGComponentItemId, selectedGComponentItemType, selectedGClientId);
      }
    }

    return true;
  }

  const handleChangeCheckButton = (event, checked, name) => {
    console.log({ event, checked, name });
    if (checked) {
      setValue("endDate", null);
      // setEndDate(null);
    }
  }

  const save = (mode) => {
    // // document.getElementsByClassName("filepond--file-action-button filepond--action-process-item")[0].click();
    // // document.getElementsByClassName("filepond--file-action-button filepond--action-revert-item-processing")[0].click();
    
    // const processElems = document.getElementsByClassName("filepond--file-action-button filepond--action-process-item"); // process(upload) 버튼
    // const retryElems = document.getElementsByClassName("filepond--file-action-button filepond--action-retry-item-processing"); // 재시도 버튼
    // let upload = false;
    // if (processElems.length > 0) {
    //   const styles = window.getComputedStyle(processElems[0]);
    //   if (styles.visibility === "visible") {
    //     upload = true;
    //   }
    // }

    // if (retryElems.length > 0) {
    //   const styles = window.getComputedStyle(retryElems[0]);
    //   if (styles.visibility === "visible") {
    //     upload = true;
    //   }
    // }

    // // process(upload) 버튼 또는 재시도 버튼이 보이면 업로드
    // if (upload) {
    //   pond.processFiles(); // server option의 process 시작됨 (창닫기는 server option의 process 안에서 실행됨)
    // }

    // const revertElems = document.getElementsByClassName("filepond--file-action-button filepond--action-revert-item-processing"); // 서버에서 삭제 버튼
    // if (revertElems.length > 0) {
    //   const styles = window.getComputedStyle(revertElems[0]);
    //   // console.log(styles.display)
    //   // console.log(styles.visibility)
    //   if (styles.visibility === "visible") { // 삭제 버튼이 보일 때 저장하면 만료일 관련 설정 저장하고 창 닫음
    //     handleClickUpdateDate();
    //     handleDialogClose();
    //   }
    // }

    // // handleDialogClose();
    // // pond.removeFiles();
    if (files.length === 0) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: (
          <div>
            <span>{"파일을 첨부해주세요."}</span>
          </div>
        ),
        open: true,
      });

      return;
    }

    // server option의 process 시작됨. 단, 상태에 따라 process 실행여부를 자동으로 판단하므로 파일이 첨부되어 있는 경우 server option의 process 실행안함
    pond.processFiles().then(async (res) => {
      console.log({ res })
      let result = true;
      if (res.length === 0) { // 이미 파일이 첨부되어 있는 상태에서는 만료일 관련 정보만 업데이트 한다.
        result = await updateDate();
      }
  
      if (mode === 'close' && result) {
        setTimeout(() => handleDialogClose(), 300);
      }
    });
  }

  // const comfirmRemove = () => {
  //   setConfirmOpen(true);
  // }

  // const removeFile = () => {
  //   pond.removeFile({ revert: true });
  // }

  const handleClickG04docuTestComparison = () => {
    setFiles([]); // 다이얼로그 풀스크린하면 FilePond를 다시 로딩함. 이때 파일첨부 부분 표시문제로 여기서 초기화하고 useEffect([fullScreen])에서 다시 setFiles 실행
    setGTestComparisonShow(!gtestComparisonShow);
    setFullScreen(!fullScreen);
  }
  
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleDialogClose();
          }
        }}
        PaperComponent={!fullScreen && PaperComponent} // fullScreen일때는 드래그 허용하지 않음
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        scroll="body"
        maxWidth="sm"
        disableEscapeKeyDown={true}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleDialogClose();
            }
          }}
          fullScreen={fullScreen}
          color={fullScreen ? "white" : ""}
          // style={{ cursor: 'move' }}
          style={{ cursor: fullScreen ? '' : 'move', backgroundColor: fullScreen ? "#1976d2" : "" }}
        >
          {/* {"자재승인서류 첨부"} */}
          {selectedDocuFileType === 'CERTIFICATION' ? `${g04docuGCertificationName} 인증서 첨부` : `${g04docuGTestName} 성적서 첨부`}
        </DialogTitleClose>
        <DialogContent dividers>
          {/* TODO : 추후 에러메시지 노출 어떻게 할지 검토 */}
          {/* <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                {/* <Grid item xs={4}>
                  <FormInputDate
                    name="startDate"
                    control={control}
                    label={"유효기간 시작"}
                    onChangePeriodValue={(value) => handleChangePeriod(value, "startDate")}
                    value={getValues("startDate")}
                    setValue={setValue}
                    // onEdit={checkEdit}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <FormInputDate
                    name="endDate"
                    control={control}
                    label={"만료일"}
                    onChangePeriodValue={handleChangePeriod}
                    value={getValues("endDate")}
                    // value={endDate}
                    setValue={setValue}
                    getValues={getValues}
                    inputHeight={38}
                    // onEdit={checkEdit}
                  />
                </Grid>
                <Grid item xs={6} display="flex" justifyContent={"flex-start"} alignItems={"center"} sx={{ display: 'none' }}>
                  {/* <LoadingButton
                    variant={"contained"}
                    onClick={handleClickUpdateDate}
                    loading={loading}
                    disabled={disabled}
                  >
                    {"만료일 변경"}
                  </LoadingButton> */}
                  <FormInputCheckbox
                    name="validYN"
                    control={control}
                    setValue={setValue}
                    onChangeCheckValue={handleChangeCheckButton}
                  />
                  <Typography>{"만료일 없음"}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* {
              selectedDocuFileType === 'CERTIFICATION' && (
                <Grid item xs={12}>
                  {JSON.stringify()}
                </Grid>
              )
            } */}
            
            <Grid item xs={12}>
              <FilePond
                  ref={ref => pond = ref}
                  files={files}
                  credits={false}
                  // allowDownloadByUrl={false} // by default downloading by URL disabled
                  allowMultiple={false}
                  allowReplace={false}
                  allowProcess={false}
                  allowRevert={false}
                  instantUpload={false}
                  acceptedFileTypes={['image/*', 'application/pdf']}
                  onupdatefiles={setFiles} // onupdatefiles(files)
                  labelIdle={uploadLabels.labelIdle}
                  labelInvalidField={uploadLabels.labelInvalidField}
                  labelFileWaitingForSize={uploadLabels.labelFileWaitingForSize}
                  labelFileSizeNotAvailable={uploadLabels.labelFileSizeNotAvailable}
                  labelFileLoading={uploadLabels.labelFileLoading}
                  labelFileLoadError={uploadLabels.labelFileLoadError}
                  labelFileProcessing={uploadLabels.labelFileProcessing}
                  labelFileProcessingComplete={uploadLabels.labelFileProcessingComplete}
                  labelFileProcessingAborted={uploadLabels.labelFileProcessingAborted}
                  labelFileProcessingError={uploadLabels.labelFileProcessingError}
                  labelFileProcessingRevertError={uploadLabels.labelFileProcessingRevertError}
                  labelFileRemoveError={uploadLabels.labelFileRemoveError}
                  labelTapToCancel={uploadLabels.labelTapToCancel}
                  labelTapToRetry={uploadLabels.labelTapToRetry}
                  labelTapToUndo={uploadLabels.labelTapToUndo}
                  labelButtonRemoveItem={uploadLabels.labelButtonRemoveItem}
                  labelButtonAbortItemLoad={uploadLabels.labelButtonAbortItemLoad}
                  labelButtonRetryItemLoad={uploadLabels.labelButtonRetryItemLoad}
                  labelButtonAbortItemProcessing={uploadLabels.labelButtonAbortItemProcessing}
                  labelButtonUndoItemProcessing={uploadLabels.labelButtonUndoItemProcessing}
                  labelButtonRetryItemProcessing={uploadLabels.labelButtonRetryItemProcessing}
                  labelButtonProcessItem={uploadLabels.labelButtonProcessItem}
                  // 다이얼로그 열기 : oninit
                  // 파일 첨부 : beforeAddFile -> oninitfile -> onaddfilestart -> onaddfile
                  // 서버로 업로드 : server:process:ondata -> onpreparefile -> server:process:onload -> onprocessfilestart -> onprocessfileprogress -> onprocessfile -> onprocessfiles
                  // 서버에서 삭제 : onprocessfilerevert -> server:revert:ondata
                  // 파일삭제나 다이얼로그 닫기 : beforeRemoveFile -> onremovefile
                  // Hooks
                  beforeDropFile={(file) => { // FilePond is about to allow this item to be dropped, it can be a URL or a File object. Return true or false depending on if you want to allow the item to be dropped.
                    // alert("beforeDropFile");
                    console.log("beforeDropFile");
                    console.log(file);
                  }}
                  beforeAddFile={(item) => { // FilePond is about to add this file, return false to prevent adding it, or return a Promise and resolve with true or false.
                    // alert("beforeAddFile");
                    console.log("beforeAddFile");
                    console.log(item);
                    // 첨부 전 필요한 체크를 한다면 여기서 할 수 있음
                    // return false;
                  }}
                  beforeRemoveFile={(item) => { // FilePond is about to remove this file, return false to prevent removal, or return a Promise and resolve with true or false.
                    // alert("beforeRemoveFile");
                    console.log("beforeRemoveFile");
                    console.log(item);
                  }}
                  // Callbacks
                  oninit={() => { //	FilePond instance has been created and is ready.
                    // alert("oninit");
                    console.log("oninit");
                  }}
                  onwarning={(error, file, status) => { //	FilePond instance throws a warning. For instance when the maximum amount of files has been reached. Optionally receives file if error is related to a file object.
                    // alert("onwarning");
                    console.log("onwarning");
                    console.log(error, file, status);
                  }}
                  onerror={(error, file, status) => { //	FilePond instance throws an error. Optionally receives file if error is related to a file object.
                    // alert("onerror");
                    console.log("onerror");
                    console.log(error, file, status);
                  }}
                  oninitfile={(file) => { //	Create file item
                    // alert("oninitfile");
                    console.log("oninitfile");
                    console.log(file);
                  }}
                  onaddfilestart={(file) => { //	Started file load
                    // alert("onaddfilestart");
                    console.log("onaddfilestart");
                    console.log(file);
                  }}
                  onaddfileprogress={(file, progress) => { //	Made progress loading a file
                    // alert("onaddfileprogress");
                    console.log("onaddfileprogress");
                    console.log(file, progress);
                  }}
                  onaddfile={(error, file) => { //	File has been loaded, if the detail object contains an error property, something went wrong
                    // alert("onaddfile");
                    console.log("onaddfile");
                    console.log(error, file);
                    console.log(files);
                  }}
                  onprocessfilestart={(file) => { //	Started processing a file
                    // alert("onprocessfilestart");
                    console.log("onprocessfilestart");
                    console.log(file);
                  }}
                  onprocessfileprogress={(file, progress) => { //	Made progress processing a file
                    // alert("onprocessfileprogress");
                    console.log("onprocessfileprogress");
                    console.log(file, progress);
                  }}
                  onprocessfileabort={(file) => { //	Aborted processing of a file
                    // alert("onprocessfileabort");
                    console.log("onprocessfileabort");
                    console.log(file);
                  }}
                  onprocessfilerevert={(file) => { //	Processing of a file has been reverted
                    // alert("onprocessfilerevert");
                    console.log("onprocessfilerevert");
                    console.log(file);
                  }}
                  onprocessfile={(error, file) => { //	Finished processing a file, if the detail object contains an error property, something went wrong
                    // alert("onprocessfile");
                    console.log("onprocessfile");
                    console.log(error, file);
                  }}
                  onprocessfiles={() => { //	All files in the list have been processed
                    // alert("onprocessfiles");
                    console.log("onprocessfiles");
                  }}
                  onremovefile={(error, file) => { //	File has been removed
                    // alert("onremovefile");
                    console.log("onremovefile");
                    console.log(error, file);
                  }}
                  onpreparefile={(file, output) => { //	File has been transformed by the transform plugin or another plugin subscribing to the prepare_output filter. It receives the file item and the output data.
                    // alert("onpreparefile");
                    console.log("onpreparefile");
                    console.log(file, output);
                  }}
                  // onupdatefiles={(error, file) => { //	A file has been added or removed
                  //   // alert("onupdatefiles");
                  //   console.log("onupdatefiles");
                  //   console.log(error, file);
                  // }}
                  onactivatefile={(file) => { //	Fired when a file is clicked or tapped
                    // alert("onactivatefile");
                    console.log("onactivatefile");
                    console.log(file);
                  }}
                  onreorderfiles={(files, origin, target) => { //
                    // alert("onreorderfiles");
                    console.log("onreorderfiles");
                    console.log(files, origin, target);
                  }}
                  server={{
                    url: gcoreServerUrl,
                    timeout: 700000,
                    // TODO : 아마도 서버에 업로드된 파일 연결은 load로 할 것 같음
                    // load: {
                    //   url: `/api/g04docuFiles/loadFile`,
                    // },
                    // fetch: {
                    //   url: `/api/g04docuFiles/fetchFile`,
                    //   onload: (res) => {
                    //     alert("server:load:onload")
                    //     console.log(res)
                    //   },
                    //   onerror: (res) => {
                    //     alert("server:load:onerror")
                    //     console.log(res)
                    //   },
                    //   ondata: (formData) => {
                    //     alert("server:load:ondata")
                    //     formData.append('Hello', 'World');
                    //     return formData;
                    //   },
                    // },
                    // load: (source, load, error, progress, abort, headers) => {
                    //   alert("load")
                    // },
                    // fetch: (url, load, error, progress, abort, headers) => {
                    //   alert("111")
                    // },
                    process: {
                        // url: '/api/g04docuFiles/uploadFile',
                        url: `/api/g04docuFiles/uploadFile`,
                        headers: {
                          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                        },
                        // method: 'POST',
                        // headers: this.headers, // TODO : 추후 header 정보 추가 필요
                        ondata: (formData) => {
                          // alert("server:process:ondata");
                          console.log("server:process:ondata");
                          // TODO : 이렇게 body로 데이터를 넘길지 header를 사용할지 추후 검토. 우선은 body 사용
                          formData.append('gclientId', selectedGClientId);
                          formData.append('gclientName', selectedGClient?.name);
                          formData.append('division', division);

                          formData.append('g04docuGCertificationId', g04docuGCertificationId);
                          formData.append('g04docuGCertificationName', g04docuGCertificationName);
                          /*if (selectedDocuFileType === 'CERTIFICATION') {
                            formData.append('g04docuGCertificationId', g04docuGCertificationId);
                            formData.append('g04docuGCertificationName', g04docuGCertificationName);
                          } else */if (selectedDocuFileType === 'TEST') {
                            formData.append('gcomponentItemId', selectedGComponentItemId)
                            formData.append('gcomponentItemName', selectedGComponentItemName)
                            formData.append('g04docuGTestId', g04docuGTestId)
                            formData.append('g04docuGTestName', g04docuGTestName)
                          }
                          formData.append('docuFileType', selectedDocuFileType);
                          // 주의 : build하면 files가 []임. 원인파악 안됨. 확인결과 pond.getFiles()는 사용가능하여 이 코드로 대체
                          // formData.append('fileName', files[0].filename); // TODO : 한글 파일명인 경우 filepond를 그대로 쓸 경우 서버에서 인코딩 문제가 발생. 현재 해결 못하여 fileName을 설정함 => filepond 문제가 아닌 거 같음
                          formData.append('fileName', (pond.getFiles())[0].filename); // TODO : 한글 파일명인 경우 filepond를 그대로 쓸 경우 서버에서 인코딩 문제가 발생. 현재 해결 못하여 fileName을 설정함
                          formData.append('validYN', getValues('validYN'));
                          // formData.append('endDate', getValues('endDate')); // 이렇게 보내면 null이 string으로 전달됨
                          let newEndDate = getValues('endDate');
                          newEndDate = newEndDate ? dateFormat(newEndDate.getDateWithEndHours()) : null;
                          if (newEndDate) {
                            formData.append('endDate', newEndDate);
                          }

                          // console.log(formData);
                          return formData;
                        },
                        onerror: (res) => {
                          console.log({ res, type: typeof res });
                          // node 서버 : {"msg":"invalid endDate"}
                          // java api 서버 : {"code":43001,"title":"Upload File Error.","message":"invalid endDate"}
                          if (typeof res === 'string') {
                            let needAlert = false;
                            const jsonRes = JSON.parse(res);
                            if (mode.indexOf('java') > 0) {
                              console.log(jsonRes)
                              if (jsonRes.code === 43001 || jsonRes.code === '43001') {
                                needAlert = true;
                              }
                            } else {
                              if (jsonRes.msg === 'invalid endDate') {
                                needAlert = true;
                              }
                            }

                            if (needAlert) {
                              setAlertInfo({
                                titleAlert: "안내",
                                messageAlert: (
                                  <div>
                                    <span style={{ color: "#d32f2f" }}><i><u>{"만료일"}</u></i></span>
                                    <span>{"이 유효하지 않습니다."}</span>
                                  </div>
                                ),
                                open: true,
                              });
                            }
                          }
                        },
                        onload: (res) => { // 서버에서 api 동작 후 리턴되는 callback
                          const startDate = getValues("startDate");
                          const endDate = getValues("endDate");

                          if (selectedDocuFileType === 'CERTIFICATION') {
                            createGClientG04docuGCertificationMap({
                              gclientId: selectedGClientId,
                              g04docuGCertificationId, gcomponentItemId: null,
                              gclient: selectedGClient,
                              documentPath: JSON.parse(res).file,
                              documentType: selectedDocuFileType,
                              validYN: getValues("validYN"),
                              startDate: startDate ? dateFormat(startDate.getDateWithStartHours()) : null,
                              endDate: endDate ? dateFormat(endDate.getDateWithEndHours()) : null,
                            })
                            .then(response => {
                              refresh(selectedGClientId);
                              setUploadedDocumentPath(JSON.parse(res).file);
                            });
                          } else if (selectedDocuFileType === 'TEST') {
                            createGClientG04docuGTestMap({
                              gclientId: selectedGClientId,
                              g04docuGTestId,
                              gcomponentItemId: selectedGComponentItemId,
                              g04docuGCertificationId,
                              gclient: selectedGClient,
                              documentPath: JSON.parse(res).file,
                              documentType: selectedDocuFileType,
                              validYN: getValues("validYN"),
                              startDate: startDate ? dateFormat(startDate.getDateWithStartHours()) : null,
                              endDate: endDate ? dateFormat(endDate.getDateWithEndHours()) : null,
                            })
                            .then(response => {
                              if (from === 'PROFILE') {
                                division === 'PROCESS' ? refresh(selectedGClientId) : refresh(selectedGClientId, division);
                              } else {
                                refresh(selectedGComponentItemType === 'PROCESS' ? g04docuGCertificationId : selectedGComponentItemId, selectedGComponentItemType, selectedGClientId);
                              }
                              
                              setUploadedDocumentPath(JSON.parse(res).file);
                            });
                          }
                          
                          return res;
                        },
                    },
                    // custom
                    // process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                    //   const formData = new FormData();
                    //   formData.append(fieldName, file, file.name);
                    //   formData.append('gclientId', selectedGClientId);
                    //   formData.append('docuFileType', docuFileType);

                    //   // store 사용
                    //   window.fetch(`${gcoreServerUrl}/api/g04docuFiles/uploadFile`, {
                    //     method: 'POST',
                    //     body: formData,
                    //   })
                    // },
                    // fetch: null,
                    fetch: {
                      url: `/api/g04docuFiles/fetchFile/`,
                      method: 'GET',
                      // 중요: 아래 헤더가 없으면 backend에서 req.body를 얻지 못함
                      headers: {
                        "Content-Type": "application/json"
                      },
                    },
                    revert: {
                      url: `/api/g04docuFiles/deleteFile`,
                      method: 'DELETE',
                      // 중요: 아래 헤더가 없으면 backend에서 req.body를 얻지 못함
                      headers: {
                        "Content-Type": "application/json"
                      },
                      onload: /*async*/ (res) => { // 서버에서 api 동작 후 리턴되는 callback
                        // console.log("server:revert:ondata1");
                        // console.log(res);
                        // console.log(JSON.parse(res).file);
                        if (selectedDocuFileType === 'CERTIFICATION') {
                          removeGClientG04docuGCertificationMap({ gclientId: selectedGClientId, g04docuGCertificationId, documentPath: JSON.parse(res).file })
                          .then(response => {
                            setValue("endDate", null);
                            setUploadedDocumentPath("");
                            // setDisabled(true);
                            // setDisabledRemoveButton(true);
                            
                            refresh(selectedGClientId);
                          });
                        } else if (selectedDocuFileType === 'TEST') {
                          removeGClientG04docuGTestMap({ gclientId: selectedGClientId, g04docuGTestId, documentPath: JSON.parse(res).file })
                          // .then(res => refresh(selectedGComponentItemType === 'PROCESS' ? g04docuGCertificationId : selectedGComponentItemId, selectedGComponentItemType, selectedGClientId));
                          .then(response => {
                            setValue("endDate", null);
                            setUploadedDocumentPath("");
                            // setDisabled(true);
                            // setDisabledRemoveButton(true);
                            
                            if (from === 'PROFILE') {
                              division === 'PROCESS' ? refresh(selectedGClientId) : refresh(selectedGClientId, division);
                            } else {
                              refresh(selectedGComponentItemType === 'PROCESS' ? g04docuGCertificationId : selectedGComponentItemId, selectedGComponentItemType, selectedGClientId);
                            }
                          });
                        }
                        
                        return res;
                      },
                    },        
                  }}
                >
                </FilePond>
            </Grid>
            {
              selectedDocuFileType === 'TEST' && (
                <Grid item xs={12} sx={{ color: '#1976d2', fontSize: '0.8rem' }}>
                  &#8251;&nbsp;{"시험성과대비표를 만드시려면 성적서를 먼저 첨부해주세요."}
                </Grid>
              )
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button
            onClick={handleClickUpload}
            disabled={files?.length === 0}
          >
            {"업로드"}
          </Button> */}
          {
            (selectedDocuFileType === 'TEST' && (documentPath || uploadedDocumentPath)) && (
              <Button onClick={handleClickG04docuTestComparison}>{gtestComparisonShow ? "시험성과대비표 접기" : (fromGTestComparison ? "시험성과대비표 만들기" : "시험성과대비표 보기" )}</Button>
            )
          }
          {
            !gtestComparisonShow && (
              <>
                <Button onClick={save}>{"저장"}</Button>
                <Button onClick={() => save('close')}>{"저장 후 닫기"}</Button>
                {/* <Button onClick={comfirmRemove} disabled={disabledRemoveButton}>{"삭제"}</Button> */}
              </>
            )
          }
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
        {
          gtestComparisonShow && (
            <Card sx={{ m: 3, p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Grid container>
                    <Grid item xs={12} display="flex" alignItems="center">
                      <Typography variant="subtitle1" sx={{ ml: 1 }}>{"성적서"}</Typography>
                    </Grid>
                  </Grid>
                  <div style={{ height: 700, width: '100%', marginTop: '8px' }}>
                    <iframe src={documentPath?.replace(uploadFilePath, fileServerUrl) || uploadedDocumentPath?.replace(uploadFilePath, fileServerUrl) } width="100%" height="100%"></iframe>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container>
                    <Grid item xs={2} display="flex" justifyContent="flex-start" alignItems="center">
                      <Typography variant="subtitle1" sx={{ ml: 1 }}>{"시험성과대비표"}</Typography>
                    </Grid>
                    <Grid item xs={10} display="flex" justifyContent="flex-end" alignItems="center">
                      <Tooltip title="시험항목을 추가합니다.">
                        <Button color="primary" startIcon={<Add />} onClick={handleClick}>
                          {"추가"}
                        </Button>
                      </Tooltip>
                      <Tooltip title="시험결과를 저장하고 시험성과대비표를 작성합니다.">
                        <Button color="primary" startIcon={<Save />} onClick={handleClickG04docuGTestComparison}>
                          {"저장/작성"}
                        </Button>
                      </Tooltip>
                      <Tooltip title="표준 시험항목을 불러옵니다.">
                        <Button color="primary" startIcon={<Undo />} onClick={handleClickInitG04docuGTestComparison}>
                          {"초기화"}
                        </Button>
                      </Tooltip>
                      <Tooltip title="마지막으로 작성한 시험성과대비표를 불러옵니다.">
                        <Button color="primary" startIcon={<Refresh />} onClick={handleClickSearchG04docuGTestComparison}>
                          {"다시불러오기"}
                        </Button>
                      </Tooltip>
                      {
                        selectedRow.guideLink && (
                          <Tooltip title={selectedRow.guideLink}>
                            <Button
                              startIcon={<CommentBank />}
                              >
                              <Link
                                href={selectedRow.guideLink}
                                underline="none"
                                target="_blank"
                                rel="noopener"
                              > 
                                {`${selectedRow.gstandardNo} 규격집`}
                              </Link>
                            </Button>
                          </Tooltip>
                        )
                      }
                    </Grid>
                  </Grid>
                  <div style={{ height: 700, width: '100%' }}>
                    <DataGridPro
                      localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                      sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                      slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: LinearProgress,
                        // toolbar: EditToolbar,
                      }}
                      columnHeaderHeight={38}
                      rowHeight={34}
                      rows={rowsG04docuGTestComparison}
                      columns={columnsGTestComparison}
                      loading={!loaded}
                      disableColumnMenu
                      rowReordering
                      // editMode="row"
                      editMode="cell"
                      rowModesModel={rowModesModel}
                      // onRowModesModelChange={handleRowModesModelChange}
                      // onRowEditStop={handleRowEditStop}
                      processRowUpdate={processRowUpdate}
                      onRowOrderChange={handleRowOrderChange}
                      // slotProps={{
                      //   toolbar: { setRows: setRowsG04docuGTestComparison, setRowModesModel },
                      // }}
                      cellModesModel={cellModesModel}
                      onCellModesModelChange={handleCellModesModelChange}
                      onCellClick={handleCellClick}
                    />
                  </div>
                </Grid>
              </Grid>
            </Card>
          )
        }
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
      {/* <ConfirmDialog
        removeId={""}
        title={"삭제"}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={removeFile}
        onCancel={() => {}}
      >
        {"첨부된 서류를 삭제하시겠습니까?"}
      </ConfirmDialog> */}
    </>
  );
};

export default G04docuFileDialog;
