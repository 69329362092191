import React, { useEffect, useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useSearchParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Fade,
  Link,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  AccountCircle,
  Business,
  ImportContacts,
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import * as sessionActions from "../../store/session";
import { FormInputText } from "../../components/form";
import { AlertDialog } from "../../components/dialog";
import { objectEmptyCheck } from "../../utils";
import TextMaskCustom from "../../components/form/inputProps/TextMaskCustom";
import CopyrightKFGWA from "../layout/CopyrightKFGWA";
import ConsentDialog from '../../ConsentDialog';
import { styled } from '@mui/material/styles';
import { Visibility, VisibilityOff, HelpOutline as HelpOutlineIcon, Check as CheckIcon } from '@mui/icons-material';
import { EMAIL_DOMAINS } from "../../constants/emails";

// 아래 form components의 name과 연계
const defaultValues = {
  id: uuidv4(),
  bizRegNumber: "",
  name: "",
  ceo: "",
  phone: "",
  address: "",
  email: "",
  emailId: "",
  emailDomain: "",
  password: "",
  confirmPassword: "",
};

const validateFields = {
  bizRegNumber: "사업자번호",
  name: "회사명",
  ceo: "대표자",
  phone: "대표전화번호",
  address: "주소",
  email: "회사대표메일",
  emailId: "이메일 아이디",
  emailDomain: "이메일 도메인",
  password: "비밀번호",
  confirmPassword: "비밀번호 확인",
};

const theme = createTheme();

const requiredMessage = "을(를) 입력해주세요.";
const emailMessage = "을(를) 올바른 이메일 형태로 입력해주세요.";

const schema = yup.object({
  bizRegNumber: yup.string().required(requiredMessage),
  name: yup.string().required(requiredMessage),
  ceo: yup.string().required(requiredMessage),
  phone: yup.string().required(requiredMessage),
  address: yup.string().required(requiredMessage),
  //email: yup.string().email(emailMessage).required(requiredMessage),
  emailId: yup.string().required(requiredMessage),
  emailDomain: yup.string().required(requiredMessage),
  password: yup.string().required(requiredMessage),
  confirmPassword: yup.string().required(requiredMessage),
}).required();

const SignupGClient = () => {
  const { handleSubmit, getValues, setValue, reset, control, formState: { errors } } = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema) });

  const dispatch = useDispatch();
  
  const sessionUser = useSelector((state) => state.session.sessionUser);

  const [phoneMask, setPhoneMask] = useState("000-0000-0000");
  const [alertInfo, setAlertInfo] = useState({});
  const [error, setError] = useState(null);
  const [fadeIn, setFadeIn] = useState(false);
  const [open, setOpen] = useState(false);
  const [consented, setConsented ] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState({
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const bizRegNumberRef = useRef(null);
  const [isManualDomain, setIsManualDomain] = useState(false);


  // 아래 if 문 위에 useEffect가 있어야 한다.
  useEffect(() => {

    if (!objectEmptyCheck(errors)) {
      // console.log(errors)
      let error;
      for (const [key, value] of Object.entries(validateFields)) {
        // console.log(`${key}: ${value}`);
        if (errors[key]) {
          error = errors[key];
          error.fieldName = value;
          break;
        }
      }
      // console.log(error)
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: `${error.fieldName}${error.message}`,
        open: true,
        success: false,
      });
    }

  }, [errors]);

  useEffect(() => {

    if (alertInfo.open && alertInfo.onClose) {
      alertInfo.onClose();
    }
  }, [alertInfo]);

  // 비밀번호 강도 체크
  useEffect(() => {
      setPasswordStrength({
        uppercase: /[A-Z]/.test(password),
        lowercase: /[a-z]/.test(password),
        number: /[0-9]/.test(password),
        special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        length: password.length >= 8,
      });
  }, [password]);

  if (sessionUser) return <Navigate  to="/glass/dashboard" />;

  const onSubmit = ({
    id,
    name,
    ceo,
    bizRegNumber,
    phone,
    address,
    emailId, emailDomain, manualDomain,
    password,
    confirmPassword,
  }) => {
    
    const finalDomain = emailDomain === "MANUAL" ? manualDomain : emailDomain;
    const email = `${emailId}@${finalDomain}`;

    console.log(email);

    // 도메인 직접입력 검증
    if (emailDomain === "MANUAL") {
      if (!manualDomain) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: "도메인을 입력해주세요.",
          open: true,
        });
        return;
      }
      
      // 도메인 형식 검증 
      // 허용되는 최상위 도메인: .com, .net, .org, .kr, .co.kr, .go.kr, .or.kr, .ac.kr, .edu 등
      const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9](\.[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])*\.(com|net|org|kr|co\.kr|go\.kr|or\.kr|ac\.kr|edu|xyz|info)$/;
      if (!domainRegex.test(manualDomain)) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: "올바른 도메인 형식이 아닙니다. (예: example.co.kr)",
          open: true,
        });
        return;
      }
    }

    if (!consented) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: (<>
          <span style={{ color: "#1976d2" }}>{"정보수집 및 활용 동의"}</span>
          <span>{" 버튼을 눌러 해당 내용에 동의해주세요."}</span>
        </>),
        open: true,
      });
      return;
    }

    if (bizRegNumber.replace(/-/g, '').length !== 10) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: "사업자번호는 10자리 숫자로 입력해주세요.",
        open: true,
        onClose: () => {
          if (bizRegNumberRef.current) {
            bizRegNumberRef.current.focus();
          }
        }
      });
      return;
    }

    if (!password) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: "비밀번호를 입력해주세요.",
        open: true,
        onClose: () => {
          if (passwordRef.current) {
            passwordRef.current.focus();
          }
        }
      });
      return;
    }

    const passwordStrength = checkPasswordStrength(password);
    const isPasswordValid = Object.values(passwordStrength).every(Boolean);
   
    if (!isPasswordValid) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: "비밀번호는 영문자, 숫자, 특수문자를 포함하여 8자 이상이어야 합니다.",
        open: true,
        onClose: () => {
          if (passwordRef.current) {
            passwordRef.current.focus();
          }
        }
      });
      return;
    }

    if (password === confirmPassword) {

      return dispatch(sessionActions.signupGClient({
        id,
        name,
        ceo,
        bizRegNumber,
        phone,
        address,
        email,
        password,
        consented,
      }))
        .catch(e => {
            let res = e.serverResponse;
            console.log(res)
            setError(res);
            setFadeIn(true);
            setTimeout(() => setFadeIn(false), 5000);
        });
    } else {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: "비밀번호와 확인을 위해 입력한 비밀번호가 일치하지 않습니다.",
        open: true,
        success: false,
        onClose: () => {
          if (confirmPasswordRef.current) {
            confirmPasswordRef.current.focus();
          }
        }
      });
    }
  };

  // const handleKeyDownPhone = (e) => {
  //   console.log(e.key)
  // }

  const handleChangePhone = (e) => {
    const { value } = e.target;

    const areaCode02 = "02";
    const compareCode = value.substring(0, 2);
    if (compareCode === areaCode02) {
      if (value.length < 12) {
        setPhoneMask("00-000-00000"); // 한자리 여분을 두어야 함. 00-000-0000하면 더 이상 입력이 안됨
      } else {
        setPhoneMask("00-0000-0000");
      }
    } else {
      if(value.length < 13) {
        setPhoneMask("000-000-00000"); // 한자리 여분을 두어야 함
      } else {
        setPhoneMask("000-0000-0000");
      }
    }
    
    setValue('phone', value);
  }

  const handleClickConsent = () => {
    setOpen(true);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setValue('password', e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const checkPasswordStrength = (password) => {
    if (!password) {
      return {
        // uppercase: false,
        // lowercase: false,
        letter: false,
        number: false,
        special: false,
        length: false,
      };
    }
    return {
      // uppercase: /[A-Z]/.test(password),
      // lowercase: /[a-z]/.test(password),
      letter: /[a-zA-Z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*(),.]/.test(password),
      length: password.length >= 8,
    };
  };
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))`
    & .MuiTooltip-tooltip {
      max-width: 400px;
      width: 400px;
    }
  `;
  const passwordRules = (
    <div style={{ fontSize: '0.875rem' }}>
      <p style={{ fontWeight: 'bold', marginBottom: '8px', fontSize: '1rem', textAlign: 'center' }}>
        [ 비밀번호 생성규칙 ]
      </p>
      <ul style={{ paddingLeft: '20px', paddingRight: '10px', paddingBottom: '20px', margin: 0 }}>
        <li>최소길이 : 8자 이상</li>
        <li>최대길이 : 20자 이하</li>
        <li>필수문자 : 영문자, 숫자, 특수문자(!@#$%^&*(),.) 중 3 가지 조합</li>
        <li>사용금지 : 자판 배열상 연속되어 4자 이상 <br/>(예) 1234, !@#$, ASDF 등</li>
        <li>사용금지 : 연속 문자 4자이상<br/>(예) 1111, aaaa, DDDD, !!!! 등</li>
      </ul>
    </div>
  );

  // 도메인 선택 변경 핸들러 추가
  const handleDomainChange = (e) => {
    const value = e.target.value;
    setValue("emailDomain", value);
    setIsManualDomain(value === "MANUAL");
    
    // 직접입력이 아닌 경우, 수동 입력 필드 값 초기화
    if (value !== "MANUAL") {
      setValue("manualDomain", "");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}><Business /></Avatar>
          <Typography component="h1" variant="h5">{"회원사 가입"}</Typography>
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                <Grid item xs={12} display="flex" alignItems="end" sx={{ height: 40 }}>
                  <Fade in={fadeIn}>
                    <Typography sx={{ color: "#ff5722" }}>
                      { error?.message }
                    </Typography>
                  </Fade>
                </Grid>
                  <Grid item xs={12} sx={{ display: 'none' }}>
                    <FormInputText
                      autoComplete="id"
                      name={"id"}
                      control={control}
                      required
                      fullWidth
                      label={"아이디"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      autoComplete="bizRegNumber"
                      name={"bizRegNumber"}
                      control={control}
                      required
                      fullWidth
                      label={"사업자번호"}
                      placeholder={"사업자번호를 입력해주세요. - 는 자동입력됩니다."}
                      autoFocus
                      InputProps={{
                        inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                        inputProps: {
                          style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                            mask: "000-00-00000",
                            definitions: {
                              '#': /[1-9]/,
                            },
                          },
                        }
                      }}
                      errorStyle={{ message: false, border: true }}
                      inputRef={bizRegNumberRef}
                    />
                  </Grid>
                  {/* TODO : 추후 유효한 사업자번호인지 체크 필요 */}
                  {/* <Grid item xs={2} display="flex" alignItems="center">
                      <Button
                        fullWidth
                        variant="outlined"
                        // sx={{ mt: 3, mb: 2 }}
                      >
                        {"확인"}
                      </Button>
                  </Grid> */}
                  <Grid item xs={12}>
                    <FormInputText
                      autoComplete="name"
                      name={"name"}
                      control={control}
                      required
                      fullWidth
                      label={"회사명"}
                      errorStyle={{ message: false, border: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      autoComplete="ceo"
                      name={"ceo"}
                      control={control}
                      required
                      fullWidth
                      label={"대표자"}
                      errorStyle={{ message: false, border: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      autoComplete="phone"
                      name={"phone"}
                      control={control}
                      required
                      fullWidth
                      label={"대표전화번호"}
                      placeholder={"대표전화번호를 입력해주세요. - 는 자동입력됩니다."}
                      InputProps={{
                        inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                        inputProps: {
                          style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                            // mask: "*00-*000-0000",
                            mask: phoneMask,
                            definitions: {
                              '#': /[1-9]/,
                            },
                          },
                        }
                      }}
                      errorStyle={{ message: false, border: true }}
                      onChange={handleChangePhone}
                      // onKeyDown={handleKeyDownPhone}
                      // onBlur={handleBlurPhone}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      autoComplete="address"
                      name={"address"}
                      control={control}
                      required
                      fullWidth
                      label={"주소"}
                      errorStyle={{ message: false, border: true }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInputText
                      autoComplete="email"
                      name={"emailId"}
                      control={control}
                      required
                      fullWidth
                      label={"회사대표메일"}
                      errorStyle={{ message: false, border: true }}
                    />
                  </Grid>
                  <Grid item xs={0.5} sx={{ textAlign: 'center' }}>
                    <Typography variant="h6">@</Typography>
                  </Grid>
                  {isManualDomain && (
                    <Grid item xs={4}>
                    <FormInputText
                      required
                      name={"manualDomain"}
                      control={control}
                      label={"도메인을 입력해주세요."}
                      fullWidth
                      errorStyle={{ message: false, border: true }}
                    />
                  </Grid>
                  )}
                  <Grid item xs={isManualDomain ? 3.5 : 7.5}>
                    <FormInputText
                      required
                      select
                      name={"emailDomain"}
                      control={control}
                      label={"도메인 선택"}
                      options={EMAIL_DOMAINS}
                      fullWidth
                      onChange={handleDomainChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      required
                      fullWidth
                      name={"password"}
                      control={control}
                      label={"비밀번호"}
                      type={showPassword ? "text" : "password"}
                      autoComplete="new-password"
                      errorStyle={{ message: false, border: true }}
                      inputRef={passwordRef}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <StyledTooltip title={passwordRules}>
                              <span>
                                <IconButton edge="start">
                                  <HelpOutlineIcon />
                                </IconButton>
                              </span>
                            </StyledTooltip>
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="start"
                              placement="right"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={handlePasswordChange} // 비밀번호 강도 체크를 위한 함수
                    />
                    <Box sx={{ mt: 1, display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap' }}>
                      {[
                        // { label: '대문자', condition: passwordStrength.uppercase },
                        // { label: '소문자', condition: passwordStrength.lowercase },
                        { label: '영문자', condition: passwordStrength.uppercase || passwordStrength.lowercase },
                        { label: '숫자', condition: passwordStrength.number },
                        { label: '특수문자', condition: passwordStrength.special },
                        { label: '8자 이상', condition: passwordStrength.length },
                      ].map(({ label, condition }) => (
                        <Box
                          key={label}
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            padding: '4px 8px',
                            borderRadius: '16px',
                            backgroundColor: condition ? '#e3f2fd' : '#f5f5f5',
                          }}
                        >
                          {condition && <CheckIcon sx={{ mr: 0.5, fontSize: '0.875rem', color: '#1976d2' }} />}
                          <Typography
                            variant="caption"
                            sx={{
                              color: condition ? '#1976d2' : '#9e9e9e',
                              fontWeight: condition ? 'bold' : 'normal',
                            }}
                          >
                            {label}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText
                      required
                      fullWidth
                      name={"confirmPassword"}
                      control={control}
                      label={"비밀번호 확인"}
                      type={showConfirmPassword ? "text" : "password"}
                      autoComplete="new-confirm-password"
                      errorStyle={{ message: false, border: true }}
                      inputRef={confirmPasswordRef}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle confirm password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="start"
                              placement="right"
                            >
                              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Button
              onClick={handleClickConsent}
              fullWidth
              variant="outlined"
              startIcon={<ImportContacts />}
              sx={{ mt: 2, fontWeight: 'bold' }}
            >
              {"정보수집 및 활용 동의"}
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2, fontWeight: 'bold' }}
            >
              {"가입하기"}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/glass/signin/gclient" variant="body2">
                  {"계정이 있으십니까? 로그인해주세요."}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <AlertDialog
          alertInfo={alertInfo}
          setAlertInfo={setAlertInfo}
        />
        <ConsentDialog
          open={open}
          setOpen={setOpen}
          setConsented={setConsented}
          from={{ source: "SignupGClient" }}
        />
      </Container>
      {/* <CopyrightKFGWA /> */}
    </ThemeProvider>
  );
}

export default SignupGClient;