import { csrfFetch } from "./csrf.js";
import { projectServerUrl } from '../config';

const SET_MANAGE_STATUS = 'manageStatus/setManageStatus';

const setManageStatus = (manageStatus) => ({
  type: SET_MANAGE_STATUS,
  payload: manageStatus,
});

export const selectManageStatus = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/common/manageStatus`);
  const data = await response.json();
  dispatch(setManageStatus(data.manageStatus));
  return response;
};

const initialState = {
  manageStatus: null,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_MANAGE_STATUS:
      newState = Object.assign({}, state, { manageStatus: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
