import { adminMenus, gclientMenus, kfgwaMenus, userMenus } from '../menus';

export const getMenusByUserType = (sessionUser) => {
  if (!sessionUser) return userMenus;
  
  if (sessionUser.type === 'ADMIN') return adminMenus;
  
  if (sessionUser.type === 'GCLIENT') {
    return sessionUser.bizRegNumber === '220-82-07388' 
      ? kfgwaMenus 
      : gclientMenus;
  }
  
  return userMenus;
};