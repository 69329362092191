import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from "react-hook-form";
import {
  Backdrop,
  Button,
  CircularProgress,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  ArrowDropDown,
} from '@mui/icons-material';
import _ from 'lodash';
import {
  FormInputDate,
  FormInputSwitch,
  FormInputText,
} from "../form";
import {
  AlertDialog,
  DialogTitleClose,
} from "../dialog";
import * as constructionWorkTypeActions from "../../store/constructionWorkType";
import * as gprojectActions from "../../store/gproject";
import * as gsupplyConfirmActions from "../../store/gsupplyConfirm";
import GProjectG04Dialog from './GProjectG04Dialog';

const theme = createTheme();

const defaultValues = {
  startDate: "",
  endDate: "",
  completionDate: "",
};

const border = '1px solid rgba(224, 224, 224, 1)';
const requestTableHeader = {
  backgroundColor: '#f5f5f5',
  border,
};

// 단위 옵션 추가
const unitOptions = [
  { value: 'm²', label: 'm²' },     // SI 단위 - 소문자
  { value: 'EA', label: 'EA' },     // 일반 단위 - 대문자
  { value: 'kg', label: 'kg' },     // SI 단위 - 소문자
  { value: 'g', label: 'g' },       // SI 단위 - 소문자
  { value: 'L', label: 'L' },       // 리터는 혼동 방지를 위해 대문자 L 사용
  { value: 'mL', label: 'mL' }      // 밀리리터는 접두어 m은 소문자, L은 대문자
];

const GSupplyConfirmRequestDialog = ({
  crudMode,
  setCrudMode,
  open,
  setOpen,
  selectedRow,
  setSelectedRow,
}) => {
  const constructionWorkTypes = useSelector(state => state.constructionWorkType.constructionWorkTypes);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const [requestTableData, setRequestTableData] = useState([]);
  const [localInputValues, setLocalInputValues] = useState({}); // 입력 필드 상태를 로컬 상태로 관리
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [preparedTableData, setPreparedTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);  // 로딩 상태 추가
  const [alertInfo, setAlertInfo] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const selectAllConstructionWorks = () => dispatch(constructionWorkTypeActions.selectAll());
  const selectDirect = (id) => gprojectActions.selectDirect(id);
  const createBulk = (dataList) => {
    return new Promise((resolve, reject) => {
      dispatch(gsupplyConfirmActions.createBulk(dataList))
        .then(resolve)
        .catch(reject);  // 오류를 명시적으로 reject
    });
  };

  const handleDialogClose = () => {
    setOpen(false);

    initDialog();
  };

  // 프로젝트 다이얼로그가 닫힐 때만 테이블 데이터 재구성
  useEffect(() => {
    const updateSelectedRow = async () => {
      if (!openProjectDialog && selectedRow && selectedRow.id) {
        const selectedRowG04Gen = await selectDirect(selectedRow.id);
        setSelectedRow(selectedRowG04Gen);
      }
    };

    // openProjectDialog가 true에서 false로 변경될 때만 실행
    if (!openProjectDialog && openProjectDialog !== undefined) {
      updateSelectedRow();
    }
  }, [openProjectDialog]);
  
  const handleClickProjectModify = () => {
    setCrudMode("U");
    setOpenProjectDialog(true);
  }

  const initDialog = () => {
    reset(defaultValues);
    setRequestTableData([]);
    setLocalInputValues({}); // localInputValues도 함께 초기화
  }

  useEffect(() => {
    if (open) {
      selectAllConstructionWorks();
    }
  }, [open]);

  useEffect(() => {
    console.log(selectedRow);
    if (selectedRow) {
      for (const [item, value] of Object.entries(defaultValues)) {
        if (item === "startDate") {
          setValue("startDate", (new Date(selectedRow["startDate"])).getDateWithStartHours() || value);
        } else if (item === "endDate") {
          setValue("endDate", (new Date(selectedRow["endDate"])).getDateWithEndHours() || value);
        } else if (item === "completionDate") {
          setValue("completionDate", (new Date(selectedRow["completionDate"])).getDateWithEndHours() || value);
        }
      }

      const newPreparedData = prepareTableData();
      setPreparedTableData(newPreparedData);

      setRequestTableData([]);
      setLocalInputValues({}); // localInputValues도 함께 초기화
    }
  }, [selectedRow]);

  // 테이블 컬럼 정의
  const columns = [
    { field: 'category', headerName: '구분', width: 100, colSpan: 2 }, // colSpan 추가
    { field: 'no', headerName: '번호', width: 100, hide: true }, // hide 추가
    { field: 'specification', headerName: '제품명', width: 200 },
    { field: 'company', headerName: '업체명', width: 150 },
    { field: 'productName', headerName: '원판', width: 150 },
    { field: 'manufacturer', headerName: '원판제조사', width: 150 }
  ];

  // 테이블 데이터 구조화를 위한 함수
  const prepareTableData = () => {
    const tableData = [];

    let categoryCount = {
      '가공유리': 0,
      '가공부자재': 0,
      '시공부자재': 0
    };

    let noCount = {};

    // 가공유리 데이터 구조화
    selectedRow.selectedGlasses?.forEach(glass => {
      const glassSpecs = glass.selectedGcomponentItems?.reduce((acc, items) => {
        const productNameItem = items?.find(item => item.code === 'GLASS PRODUCT NAME');
        const thicknessItem = items?.find(item => item.code === 'GL THICKNESS');
        
        if (productNameItem && thicknessItem) {
          const spec = {
            // name: `${thicknessItem.value.code} ${productNameItem.value.code}`,
            name: `${thicknessItem.value.code} ${productNameItem.value.code.replace(/\(.*?\)/, "")}`,
            manufacturers: productNameItem.value.gclients || [],
            gcomponentItemId: productNameItem.value.id,
            gcomponentAttId: thicknessItem.value.id,
          };
          // if (!acc.some(s => s.name === spec.name)) {
          if (!acc.some(s => `${s.gcomponentAttId}-${s.gcomponentItemId}` === `${spec.gcomponentAttId}-${spec.gcomponentItemId}`)) {
            acc.push(spec);
          }
        }
        return acc;
      }, []);

      // noCount 계산
      if (!noCount[glass.no]) {
        noCount[glass.no] = glassSpecs.length;
      }

      const processClients = glass.selectedProcessGClients?.[glass.selectedProcessGClients.length - 1]?.selectedGClients || [];

      glassSpecs.forEach((spec, index) => {
        categoryCount['가공유리']++;

        tableData.push({
          category: '가공유리',
          isFirstInCategory: categoryCount['가공유리'] === 1,
          no: glass.no,
          isFirstInNo: !tableData.some(item => item.no === glass.no),
          noRowSpan: noCount[glass.no],
          specification: index === 0 ? glass.specification.replaceAll("|", " ").replaceAll("+", " + ") : '',
          company: index === 0 ? processClients.map(client => client.name).join(', ') : '',
          gclientId: index === 0 ? processClients.map(client => client.id).join(',') : '',
          gcomponentItemId: spec.gcomponentItemId,
          gcomponentAttId: spec.gcomponentAttId,
          productName: spec.name,
          appliedArea: glass.appliedArea,
          manufacturer: spec.manufacturers.map(m => m.name).join(', '),
          manufacturerId: spec.manufacturers.map(m => m.id).join(','),
          rowSpan: index === 0 ? glassSpecs.length : 0
        });
      });
    });
  
    // 가공부자재 데이터 구조화 수정
    selectedRow.selectedSubMaterialProcessItems?.filter(item => Object.keys(item.value).length > 0)
    .forEach((item) => {
      console.log(item);

      if (item.code === 'LAMINATED_FILM' && Array.isArray(item.value.gcomponentItemsSpecific)) {
        // 접합필름인 경우
        item.value.gcomponentItemsSpecific.forEach((specific, index) => {
          categoryCount['가공부자재']++;
 
          // const productName = `${item.value.name} (${specific.name})`;
          const productName = `${specific.code} ${item.value.name}`;
          
          tableData.push({
            category: '가공부자재',
            isFirstInCategory: categoryCount['가공부자재'] === 1,
            no: item.name,
            isFirstInNo: index === 0,
            noRowSpan: item.value.gcomponentItemsSpecific.length,
            specification: productName, // 각 행마다 제품명 표시
            company: item.value.gclients?.map(client => client.name).join(', '), // 각 행마다 업체명 표시
            gclientId: item.value.gclients?.[0]?.id,
            gcomponentItemId: item.value.id,
            gcomponentAttId: specific.id,
            g04docuGCertificationId: item.id,
            productName: '해당없음', // 원판은 해당없음으로 표시
            manufacturer: '해당없음',
            rowSpan: 1, // 각 행을 개별적으로 표시하므로 rowSpan은 1
            isSubMaterial: true
          });
        });
      } else { // 접합필름이 아닌 경우 기존 로직
        categoryCount['가공부자재']++;
        
        tableData.push({
          category: '가공부자재',
          isFirstInCategory: categoryCount['가공부자재'] === 1,
          no: item.name,
          isFirstInNo: true,
          noRowSpan: 1,
          specification: item.value.name,
          company: item.value.gclients?.map(client => client.name).join(', '),
          gclientId: item.value.gclients?.[0]?.id,
          gcomponentItemId: item.value.id,
          gcomponentAttId: '',
          g04docuGCertificationId: item.id,
          productName: '해당없음',
          manufacturer: '해당없음',
          rowSpan: 1,
          isSubMaterial: true
        });
      }
    });

    // 시공부자재 데이터 구조화 수정
    selectedRow.selectedSubMaterialBuildItems?.filter(item => Object.keys(item.value).length > 0)
    .forEach((item) => {
      categoryCount['시공부자재']++;

      tableData.push({
        category: '시공부자재',
        isFirstInCategory: categoryCount['시공부자재'] === 1,
        no: item.name,
        isFirstInNo: true,
        noRowSpan: 1,
        specification: item.value.name,
        company: item.value.gclients?.map(client => client.name).join(', '),
        gclientId: item.value.gclients?.[0]?.id,
        gcomponentItemId: item.value.id,
        gcomponentAttId: '',
        g04docuGCertificationId: item.id,
        productName: '해당없음',
        manufacturer: '해당없음',
        rowSpan: 1,
        isSubMaterial: true
      });
    });

    // 각 카테고리의 첫 번째 행에 전체 rowSpan 설정
    const newTableData = tableData.map(row => {
      if (row.isFirstInCategory) {
        row.categoryRowSpan = categoryCount[row.category];
      }
      return row;
    });
    console.log(newTableData);
    return newTableData;
  };

  const handleClickCalcRequest = () => {
    setIsLoading(true);  // 로딩 시작
    try {
      console.log(preparedTableData);
      const requestSummary = [];
      
      // 원판 데이터 처리
      const rawGlassProducts = new Map(); // Set을 Map으로 변경하여 업체명 정보도 저장
      preparedTableData.forEach(row => {
        if (row.category === '가공유리' && row.productName !== '-') {
          // rawGlassProducts.set(row.productName, {
          rawGlassProducts.set(`${row.gcomponentAttId}-${row.gcomponentItemId}`, {
            name: row.productName,
            manufacturer: row.manufacturer,
            manufacturerId: row.manufacturerId,
            gcomponentItemId: row.gcomponentItemId,
            gcomponentAttId: row.gcomponentAttId
          });
        }
      });
      
      rawGlassProducts.forEach((value, product) => {
        requestSummary.push({
          category: '원자재',
          subCategory: '원판',
          company: value. manufacturer, // 원판제조사를 업체명으로 설정
          gclientId: value.manufacturerId, // manufacturerId 추가
          gcomponentItemId: value.gcomponentItemId,
          gcomponentAttId: value.gcomponentAttId,
          // product: product,
          product: value.name,
          productCode: '',
          appliedArea: '',
          quantity: '',
          unit: 'EA',
          deliveryDate: '',
          note: '',
          etc: ''
        });
      });
    
      // 가공유리 데이터 처리 - 업체별로 그룹화
    const processedGlassMap = new Map(); // 업체별 가공유리 데이터 저장

    preparedTableData.forEach(row => {
      if (row.category === '가공유리' && row.specification) {
        const gclientIds = row.gclientId.split(',').map(id => id.trim());
        const companies = row.company.split(',').map(name => name.trim());
        
        companies.forEach((company, idx) => {
          const key = `${company}-${gclientIds[idx]}`;
          if (!processedGlassMap.has(key)) {
            processedGlassMap.set(key, []);
          }
          
          processedGlassMap.get(key).push({
            category: '가공유리',
            subCategory: row.no,
            company: company,
            gclientId: gclientIds[idx],
            gcomponentItemId: row.gcomponentItemId,
            gcomponentAttId: row.gcomponentAttId,
            product: row.specification,
            productCode: '',
            appliedArea: row.appliedArea,
            quantity: '',
            unit: 'EA',
            deliveryDate: '',
            note: '',
            etc: ''
          });
        });
      }
    });

    // 그룹화된 가공유리 데이터를 requestSummary에 추가
    processedGlassMap.forEach(items => {
      requestSummary.push(...items);
    });
    
      // 가공부자재 데이터 처리
      preparedTableData.forEach(row => {
        if (row.category === '가공부자재') {
          requestSummary.push({
            category: '가공부자재',
            subCategory: row.no,
            company: row.company, // 업체명 추가
            gclientId: row.gclientId,
            gcomponentItemId: row.gcomponentItemId,
            gcomponentAttId: row.gcomponentAttId,
            g04docuGCertificationId: row.g04docuGCertificationId,
            product: row.specification,
            productCode: '',
            appliedArea: '',
            quantity: '',
            unit: 'EA',
            deliveryDate: '',
            note: '',
            etc: ''
          });
        }
      });
    
      // 시공부자재 데이터 처리
      preparedTableData.forEach(row => {
        if (row.category === '시공부자재') {
          requestSummary.push({
            category: '시공부자재',
            subCategory: row.no,
            company: row.company, // 업체명 추가
            gclientId: row.gclientId,
            gcomponentItemId: row.gcomponentItemId,
            gcomponentAttId: row.gcomponentAttId,
            g04docuGCertificationId: row.g04docuGCertificationId,
            product: row.specification,
            productCode: '',
            appliedArea: '',
            quantity: '',
            unit: 'EA',
            deliveryDate: '',
            note: '',
            etc: ''
          });
        }
      });
    
      // selected 필드 추가
      setRequestTableData(requestSummary.map(row => ({ ...row, selected: true }))); // selected 필드 false에서 true로 변경
    } catch (error) {
      console.error('요청내역 산정 중 오류 발생:', error);
    } finally {
      setIsLoading(false);  // 로딩 종료
    }
  };

  const handleClickRequestList = () => {
    navigate('/gsupplyConfirmRequestList');
  }

  // 체크박스 핸들러
  const handleCheckboxChange = (index) => {
    setRequestTableData(prevData => 
      prevData.map((row, i) => 
        i === index ? { ...row, selected: !row.selected } : row
      )
    );
  };

  // 전체 선택 핸들러
  const handleSelectAll = (event) => {
    setRequestTableData(prevData => 
      prevData.map(row => ({ ...row, selected: event.target.checked }))
    );
  };

  // 입력 필드 변경 핸들러. 천단위 콤마 포맷팅 함수
  const formatNumber = (num) => {
    if (!num) return '';
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // 콤마 제거 함수
  const removeCommas = (str) => {
    if (!str) return '';
    return str.replace(/,/g, '');
  };

  // handleInputChange 함수. 디바운스된 업데이트 함수
  const debouncedUpdateRequestTable = useCallback(
    _.debounce((index, field, value) => {
      setRequestTableData(prevData => 
        prevData.map((row, i) => 
          i === index ? { ...row, [field]: value } : row
        )
      );
    }, 300),
    []
  );

  const handleInputChange = (index, field, value, event) => {
    if (field === 'quantity') {
      // 숫자와 콤마만 허용
      const numericValue = removeCommas(value);
      if (numericValue === '' || /^\d*$/.test(numericValue)) {
        // 로컬 상태 즉시 업데이트 (콤마 포맷팅 적용)
        setLocalInputValues(prev => ({
          ...prev,
          [`${index}-${field}`]: formatNumber(numericValue)
        }));
        
        // 실제 데이터도 즉시 업데이트
        setRequestTableData(prevData => 
          prevData.map((row, i) => 
            i === index ? { ...row, [field]: numericValue } : row
          )
        );

        // 디바운스 안하면 속도가 문젠데... 사실 디바운스 해도 그닥 빠르지는 않음
        // 실제 데이터는 디바운스하여 업데이트 (숫자값만 저장)
        // 디바운스된 업데이트는 추가적인 최적화를 위해 유지
        debouncedUpdateRequestTable(index, field, numericValue);
      }
    } else {
      // 다른 필드들은 기존과 동일하게 처리
      setLocalInputValues(prev => ({
        ...prev,
        [`${index}-${field}`]: value
      }));
      
       // 실제 데이터도 즉시 업데이트
      setRequestTableData(prevData => 
        prevData.map((row, i) => 
          i === index ? { ...row, [field]: value } : row
        )
      );

      debouncedUpdateRequestTable(index, field, value);
    }
  };

  const getInputValue = (index, field, defaultValue) => {
    const localValue = localInputValues[`${index}-${field}`];
    return localValue !== undefined ? localValue : defaultValue;
  };

  // 키보드 이벤트 핸들러
  const handleKeyDown = (event, index, field) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      
      // 다음 행의 같은 필드로 포커스 이동
      const nextIndex = index + 1;
      if (nextIndex < requestTableData.length) {
        const nextInput = document.querySelector(
          `input[name="requestTableData.${nextIndex}.${field}"]`
        );
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const handleClickRequest = async (mode) => {
    console.log(requestTableData);
    // 체크된 항목들만 필터링
    const selectedItems = requestTableData.filter(row => row.selected);
  
    // 1. 선택된 항목이 있는지 확인 => 선택하지 않으면 버튼 자체가 비활성화되어 있으므로 필요없음
    // if (selectedItems.length === 0) {
    //   setAlertInfo({
    //     titleAlert: "안내",
    //     messageAlert: "요청할 항목을 선택해주세요.",
    //     open: true,
    //   });
    //   return;
    // }

    console.log(selectedItems);

    if (mode === "request") {
      // 2. 필수 입력값 검증
      const invalidItems = selectedItems.filter(item => {
        return !item.quantity; // requestSize 체크
      });

      if (invalidItems.length > 0) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: "선택된 항목의 수량을 모두 입력해주세요.",
          open: true,
        });
        return;
        }
    }

    // return; // 테스트 중...

    // 3. gprojectId 확인 => 프로젝트 정보는 이미 있음
    // if (!selectedRow.id) {
    //   setAlertInfo({
    //     titleAlert: "안내",
    //     messageAlert: "프로젝트 정보가 올바르지 않습니다.",
    //     open: true,
    //   });
    //   return;
    // }

    try {
      // 회사별로 아이템 그룹화
      const itemsByGClientId = selectedItems.reduce((acc, item) => {
        if (!acc[item.gclientId]) {
          acc[item.gclientId] = [];
        }
        acc[item.gclientId].push(item);
        return acc;
      }, {});

      /**
       * 프로젝트 ID	    | gprojectId	            | String	| 프로젝트 ID	                       | O
       * 등록 옵션	      | status	                | String	| 작성중, 요청 가능.	               | O
       * 요청자 비고	    | requestComment	        | String	| 요청자 비고, 요청사항
       * 확인서 제품 목록	| items	                  | Array	   | 납품 세부 품목들.	               | O
       * 제품 구분	      | division	              | String	| 제품 구분	                        | O
       * 제품-자재 ID	    | gcomponentItemId	      | String	| 원자재, 시공부자재, 가공부자재	    | O
       * 제품-가공유리 no	| gprojectGlassesNo	      | String		
       * 자재 속성 ID	    | gcomponentAttId	        | String	| 자재 속성 - GComponentItems 의 id	
       * 자재 인증서 ID	  | g04docuGCertificationId	| String	| 부자재의 경우 구분명 조회에 사용됨.	
       * 납품 거래선 ID	  | gclientId	              | String	| 납품/발행 회사 ID	
       * 제품코드	        | itemCode	              | String	| 제품코드	                           
       * 적용부위	        | appliedArea	              | String	| 적용부위	                           
       * 단위	            | supplyUnit	            | String	| 단위	                            | O
       * 요청 수량	       | requestSize	          | Double	| 요청 납품 수량	                   | O
       * 요청자 제품 비고	 | requestComment	        | String	 | 품목 요청자 비고, 요청사항 등.	
       */
      // TODO : 아래가 더 간단함. 추후 명치 변경 고려
      // const CATEGORY_MAPPING = {
      //   '원자재': 'RAW_MATERIAL',
      //   '가공유리': 'PROCESSED_GLASS',
      //   '가공부자재': 'PROCESSED_MATERIAL',
      //   '시공부자재': 'CONSTRUCTION_MATERIAL'
      // };
      const CATEGORY_MAPPING = {
        '원자재': 'RAW_MATERIAL',
        '가공유리': 'PROCESS',
        '가공부자재': 'SUB_MATERIAL_PROCESS',
        '시공부자재': 'SUB_MATERIAL_BUILD'
      };

      // 회사별로 요청 데이터 구성
      const requestDataArray = Object.entries(itemsByGClientId).map(([gclientId, items]) => ({
        gprojectId: selectedRow.id,
        status: mode === "save" ? "작성중" : "요청",
        requestComment: items && Array.isArray(items) && items.length > 0 ? items[0].etc : '',
        items: items.map(item => ({
          division: CATEGORY_MAPPING[item.category] || item.category, // 매핑된 상수값 사용
          gcomponentItemId: item.category !== '가공유리' ? item.gcomponentItemId : '',
          gprojectGlassesNo: item.category === '가공유리' ? item.subCategory : '',
          gcomponentAttId: item.category !== '가공유리' ? item.gcomponentAttId : '',
          g04docuGCertificationId: (item.category === '가공부자재' || item.category === '시공부자재') ? item.g04docuGCertificationId : '',
          gclientId: item.gclientId,
          itemCode: item.productCode,
          appliedArea: item.appliedArea,
          supplyUnit: item.unit,
          requestSize: Number(item.quantity),
          requestComment: item.note,
        }))
      }));

      console.log("요청 데이터:", requestDataArray);
      
      await createBulk(requestDataArray);

      setAlertInfo({
        titleAlert: "안내", 
        messageAlert: mode === "save" ? 
          "납품확인서 요청 정보가 저장되었습니다." : 
          "납품확인서를 요청하였습니다.",
        open: true
      });

      if (mode === 'save' || mode === "request") {
        handleDialogClose();
      }
    } catch (error) {
      console.error("요청 저장 중 오류 발생:", error);
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: error.serverResponse.message,
        open: true
      });
    } finally {
      setOpenBackdrop(false);
    }
  
    
  };

  // 업체별 첫 번째 행과 행 수를 계산하는 함수
  const calculateCompanyRowSpans = (data) => {
    const companySpans = {};
    let currentCompany = null;
    let currentCount = 0;
    let firstIndex = null;
  
    data.forEach((row, index) => {
      // 업체명이 비어있지 않은 경우에만 처리
      if (row.company) {
        // 새로운 업체 시작 또는 연속되지 않은 같은 업체
        if (currentCompany !== row.company || 
            (index > 0 && data[index - 1].company !== row.company)) {
          // 이전 업체 데이터 저장
          if (currentCompany && currentCount > 0) {
            if (!companySpans[currentCompany]) {
              companySpans[currentCompany] = [];
            }
            companySpans[currentCompany].push({
              count: currentCount,
              firstIndex: firstIndex
            });
          }
          // 새로운 업체 초기화
          currentCompany = row.company;
          currentCount = 1;
          firstIndex = index;
        } else {
          // 같은 업체 계속
          currentCount++;
        }
      }
    });
  
    // 마지막 업체 데이터 저장
    if (currentCompany && currentCount > 0) {
      if (!companySpans[currentCompany]) {
        companySpans[currentCompany] = [];
      }
      companySpans[currentCompany].push({
        count: currentCount,
        firstIndex: firstIndex
      });
    }
  
    return companySpans;
  };

  const hasSelectedItems = requestTableData.some(row => row.selected);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Backdrop
          sx={{ 
            color: '#fff', 
            zIndex: (theme) => theme.zIndex.modal + 1 // Dialog의 z-index보다 1 높게 설정
          }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog
          fullScreen={true}
          open={open}
          onClose={handleDialogClose}
          aria-labelledby="draggable-dialog-title"
          maxWidth="lg"
          scroll="body"
          disableEscapeKeyDown={true}
        >
          <DialogTitleClose
            id="draggable-dialog-title"
            onClose={handleDialogClose}
            fullScreen={true}
            color="white"
            style={{ backgroundColor: "#1976d2" }}
          >
            <div id="dialog-position">
              {"납품확인서 요청하기"}
            </div>
          </DialogTitleClose>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sx={{ display: 'none' }}>
                <FormInputText
                  name={"id"}
                  control={control}
                  label={"아이디"}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  <FormInputText
                    name={"site"}
                    control={control}
                    label={"현장명"}
                    InputProps={{ readOnly: true }}
                    sx={{ width: { /*xs: 330, */sm: 508 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                    value={selectedRow.site}
                  />
                  <FormInputText
                    name={"siteAddress"}
                    control={control}
                    label={"현장주소"}
                    InputProps={{ readOnly: true }}
                    sx={{ width: { /*xs: 330, */sm: 508 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                    value={selectedRow.siteAddress}
                  />
                </Stack>
              </Grid>
              {/* gclient 정보는 project 정보가 이미 저장되어 있다면 owner 정보, 그렇지 않다면  로그인 정보의 gclient 정보를 활용 */}
              <Grid item xs={12} sx={{ display: 'none' }}>
                <FormInputText
                  name={"gclientId"}
                  control={control}
                  label={"업체아이디"}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  <FormInputText
                    name={"gclientName"}
                    control={control}
                    label={"업체명"}
                    InputProps={{ readOnly: true }}
                    sx={{ width: { /*xs: 330, */sm: 250 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                    value={selectedRow.owner?.name}
                  />
                  <FormInputText
                    name={"constructionCompanyName"}
                    control={control}
                    label={"건설사명(제출처)"}
                    InputProps={{ readOnly: true }}
                    sx={{ width: { /*xs: 330, */sm: 250 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                    value={selectedRow.constructionCompanyName}
                  />
                  <FormInputText
                    select
                    name={"constructionWorkTypeId"}
                    control={control}
                    label={"공종명"}
                    options={constructionWorkTypes.map(constructionWorkType => {
                      return {
                        label: constructionWorkType.name,
                        value: constructionWorkType.id,
                      }
                    })}
                    sx={{ width: { /*xs: 330, */sm: 250 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                    disabled={true}
                    value={selectedRow.constructionWorkTypeId}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  <FormInputDate
                    name="startDate"
                    control={control}
                    label={"시작"}
                    value={getValues("startDate")}
                    inputHeight={38}
                    fontSize={16}
                    customWidth={250}
                    disabled={true}
                  />
                  <FormInputDate
                    name="endDate"
                    control={control}
                    label={"마감"}
                    value={getValues("endDate")}
                    inputHeight={38}
                    fontSize={16}
                    customWidth={250}
                    disabled={true}
                  />
                  <FormInputDate
                    name="completionDate"
                    control={control}
                    label={"준공일"}
                    value={getValues("completionDate")}
                    inputHeight={38}
                    fontSize={16}
                    customWidth={250}
                    disabled={true}
                  />
                  <div style={{ width: '150px', paddingLeft: '20px', backgroundColor: selectedRow.doneYN ? "#5dc061" : "#ED6C02", color: 'white', borderRadius: '5px' }}>
                    <FormInputSwitch
                      name={"doneYN"}
                      checked={selectedRow.doneYN}
                      control={control}
                      label={selectedRow.doneYN ? "마감" : "마감전"}
                      color="success"
                    />
                  </div>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"comments"}
                  control={control}
                  label={"설명"}
                  multiline
                  maxRows={5}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3}}>
              <TableContainer 
                sx={{
                  borderRadius: '4px',
                  border,
                  '& .MuiPaper-root': {
                    boxShadow: 'none'
                  }
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.filter(col => !col.hide).map(column => (
                        <TableCell 
                          key={column.field}
                          align="center"
                          colSpan={column.colSpan || 1}
                          sx={{
                            backgroundColor: '#f5f5f5',
                            border,
                            padding: '8px 16px',
                          }}
                        >
                        {column.headerName}
                      </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {preparedTableData.map((row, index) => (
                      <TableRow key={index}>
                        {row.isFirstInCategory && (
                          <TableCell align="center" rowSpan={row.categoryRowSpan} sx={{ border, padding: '8px 16px' }}>{row.category}</TableCell>
                        )}
                        {row.isFirstInNo && (
                          <TableCell align="left" rowSpan={row.noRowSpan} sx={{ border, padding: '8px 16px' }}>{row.no}</TableCell>
                        )}
                        {row.rowSpan > 0 && (
                          <>
                            <TableCell align="left" rowSpan={row.rowSpan} sx={{ border, padding: '8px 16px' }}>{row.specification}</TableCell>
                            <TableCell align="left" rowSpan={row.rowSpan} sx={{ border, padding: '8px 16px' }}>{row.company}</TableCell>
                          </>
                        )}
                        <TableCell 
                          align="left" 
                          sx={{ 
                            border, 
                            padding: '8px 16px',
                            ...(row.productName === '해당없음' && {
                              backgroundColor: '#f5f5f5',
                              color: '#aaa',
                              // backgroundImage: 'linear-gradient(135deg, rgba(0,0,0,0.05) 25%, transparent 25%, transparent 50%, rgba(0,0,0,0.05) 50%, rgba(0,0,0,0.05) 75%, transparent 75%, transparent)',
                              // backgroundSize: '18px 18px'
                            })
                          }}
                        >
                          {row.productName.replace(/\(.*?\)/, "")}
                        </TableCell>
                        <TableCell 
                          align="left" 
                          sx={{ 
                            border, 
                            padding: '8px 16px',
                            ...(row.manufacturer === '해당없음' && {
                              backgroundColor: '#f5f5f5',
                              color: '#aaa',
                              // backgroundImage: 'linear-gradient(135deg, rgba(0,0,0,0.05) 25%, transparent 25%, transparent 50%, rgba(0,0,0,0.05) 50%, rgba(0,0,0,0.05) 75%, transparent 75%, transparent)',
                              // backgroundSize: '18px 18px'
                            })
                          }}
                        >
                          {row.manufacturer}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Grid container>
                <Grid display="flex" justifyContent="flex-start" item xs={12} sm={6}>
                  <Button 
                    variant="contained" 
                    startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <ArrowDropDown />}
                    onClick={handleClickCalcRequest}
                    disabled={isLoading}
                  >
                    {isLoading ? "산정 중..." : "요청내역 산정"}
                  </Button>
                </Grid>
                <Grid display="flex" justifyContent="flex-end" item xs={12} sm={6}>
                  <Button variant="outlined" sx={{ mr: 1 }} onClick={handleClickProjectModify}>{"프로젝트 수정"}</Button>
                  <Button variant="outlined" sx={{ mr: 1 }} onClick={() => handleClickRequest('save')} disabled={!hasSelectedItems}>{"저장"}</Button>
                  <Button variant="contained" onClick={() => handleClickRequest('request')} disabled={!hasSelectedItems}>{"요청하기"}</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <TableContainer sx={{
                '& .MuiTableCell-root': {
                  padding: '0px 8px'  /* checkbox 때문에 top 0px로 함 */
                }
              }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '30px', padding: '0px 0px' /* checkbox 때문에 top 0px로 함 */}}>
                        <Checkbox
                          size="small"
                          checked={requestTableData.length > 0 && requestTableData.every(row => row.selected)}
                          indeterminate={requestTableData.some(row => row.selected) && !requestTableData.every(row => row.selected)}
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '50px' }}>순번</TableCell>
                      <TableCell align="center" colSpan={2} sx={{ ...requestTableHeader }}>구분</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader }}>업체명</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader }}>제품명</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '160px' }}>제품코드</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '160px' }}>적용부위</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '120px' }}>수량</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '70px'}}>단위</TableCell>
                      {/* <TableCell align="center" sx={{ ...requestTableHeader }}>납품일자</TableCell> */}
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '250px' }}>비고</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '250px' }}>기타사항</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(() => {
                      // 업체별 행 수 계산
                      const companySpans = calculateCompanyRowSpans(requestTableData);
                      
                      return requestTableData.length > 0 && 
                        requestTableData.map((row, index) => {
                          // 현재 행의 업체에 대한 그룹 정보 찾기
                          const companyGroups = companySpans[row.company] || [];
                          const currentGroup = companyGroups.find(group => 
                            index >= group.firstIndex && 
                            index < (group.firstIndex + group.count)
                          );
                          
                          const isFirstCompanyRow = currentGroup?.firstIndex === index;
                          const companyRowSpan = currentGroup?.count || 1;

                          return (
                            <TableRow key={`${row.category}-${index}`} sx={{ height: '30px' }}>
                              <TableCell align="center" sx={{ border, width: '30px', padding: '0px 0px', backgroundColor: '#ffffcc' }}>
                                <Checkbox
                                  size="small"
                                  checked={row.selected || false}
                                  onChange={() => handleCheckboxChange(index)}
                                />
                              </TableCell>
                              <TableCell align="right" sx={{ border }}>{index + 1}</TableCell>
                              <TableCell align="center" sx={{ border }}>{row.category}</TableCell>
                              <TableCell align="left" sx={{ border }}>{row.subCategory}</TableCell>
                              <TableCell align="left" sx={{ border }}>{row.company}</TableCell>
                              <TableCell align="left" sx={{ border }}>{row.product}</TableCell>
                              {/* <TableCell align="left" sx={{ border }}>{row.category === '원자재' ? row.product.replace(/\(.*?\)/, "") : row.product}</TableCell> */}
                              <TableCell align="center" sx={{ border, padding: '0px !important', backgroundColor: '#ffffcc', height: '30px' }}>
                                <FormInputText
                                  control={control}
                                  name={`requestTableData.${index}.productCode`}
                                  value={getInputValue(index, 'productCode', row.productCode)}
                                  onChange={(e) => handleInputChange(index, 'productCode', e.target.value)}
                                  onKeyDown={(e) => handleKeyDown(e, index, 'productCode')}
                                  size="small"
                                  fullWidth
                                  sx={{ 
                                    height: '100%',
                                    '& .MuiInputBase-root': {
                                      height: '100%',
                                      display: 'flex',
                                      borderRadius: 0,
                                    },
                                    '& .MuiInputBase-input': { 
                                      height: '100%',
                                      padding: '0 8px',
                                      fontSize: '0.875rem',  // 추가: 기본 테이블 셀 폰트 크기와 동일하게 설정
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                      borderRadius: 0,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderRadius: 0,
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #1976d2',
                                        borderRadius: 0,
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center" sx={{ border, padding: '0px !important', backgroundColor: '#ffffcc', height: '30px' }}>
                                <FormInputText
                                  control={control}
                                  name={`requestTableData.${index}.appliedArea`}
                                  value={getInputValue(index, 'appliedArea', row.appliedArea)}
                                  onChange={(e) => handleInputChange(index, 'appliedArea', e.target.value)}
                                  onKeyDown={(e) => handleKeyDown(e, index, 'appliedArea')}
                                  size="small"
                                  fullWidth
                                  sx={{ 
                                    height: '100%',
                                    '& .MuiInputBase-root': {
                                      height: '100%',
                                      display: 'flex',
                                      borderRadius: 0,
                                    },
                                    '& .MuiInputBase-input': { 
                                      height: '100%',
                                      padding: '0 8px',
                                      fontSize: '0.875rem',  // 추가: 기본 테이블 셀 폰트 크기와 동일하게 설정
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                      borderRadius: 0,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderRadius: 0,
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #1976d2',
                                        borderRadius: 0,
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center" sx={{ border, padding: '0px !important', backgroundColor: '#ffffcc', height: '30px' }}>
                                <FormInputText
                                  control={control}
                                  name={`requestTableData.${index}.quantity`}
                                  value={getInputValue(index, 'quantity', formatNumber(row.quantity))}
                                  onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                                  onKeyDown={(e) => handleKeyDown(e, index, 'quantity')}
                                  size="small"
                                  // type="number"
                                  fullWidth
                                  sx={{ 
                                    height: '100%',
                                    '& .MuiInputBase-root': {
                                      height: '100%',
                                      display: 'flex',
                                      borderRadius: 0,
                                    },
                                    '& .MuiInputBase-input': { 
                                      height: '100%',
                                      padding: '0 8px',
                                      fontSize: '0.875rem',  // 추가: 기본 테이블 셀 폰트 크기와 동일하게 설정
                                      textAlign: 'right',  // 숫자 우측 정렬
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                      borderRadius: 0,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderRadius: 0,
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #1976d2',
                                        borderRadius: 0,
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center" sx={{ border, padding: '0px !important', backgroundColor: '#ffffcc', height: '30px' }}>
                                <FormInputText
                                  select
                                  control={control}
                                  name={`requestTableData.${index}.unit`}
                                  value={getInputValue(index, 'unit', row.unit)}
                                  onChange={(e) => handleInputChange(index, 'unit', e.target.value)}
                                  size="small"
                                  options={unitOptions}
                                  fullWidth
                                  sx={{ 
                                    height: '100%',
                                    '& .MuiInputBase-root': {
                                      height: '100%',
                                      display: 'flex',
                                      borderRadius: 0,
                                    },
                                    '& .MuiInputBase-input': { 
                                      height: '100%',
                                      padding: '0 8px',
                                      fontSize: '0.875rem',
                                      textAlign: 'center',
                                      display: 'flex',
                                      alignItems: 'center', // 추가
                                    },
                                    '& .MuiSelect-select': { // 추가
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      paddingRight: '24px', // 아이콘 공간 확보
                                    },
                                    '& .MuiSelect-icon': { // 추가
                                      right: 2,
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                      borderRadius: 0,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderRadius: 0,
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #1976d2',
                                        borderRadius: 0,
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                              {/* <TableCell align="center" sx={{ border, padding: '4px' }}></TableCell> */}
                              <TableCell align="center" sx={{ border, padding: '0px !important', backgroundColor: '#ffffcc', height: '30px' }}>
                                <FormInputText
                                  control={control}
                                  name={`requestTableData.${index}.note`}
                                  value={getInputValue(index, 'note', row.note)}
                                  onChange={(e) => handleInputChange(index, 'note', e.target.value)}
                                  onKeyDown={(e) => handleKeyDown(e, index, 'note')}
                                  size="small"
                                  fullWidth
                                  sx={{ 
                                    height: '100%',
                                    '& .MuiInputBase-root': {
                                      height: '100%',
                                      display: 'flex',
                                      borderRadius: 0,
                                    },
                                    '& .MuiInputBase-input': { 
                                      height: '100%',
                                      padding: '0 8px',
                                      fontSize: '0.875rem',  // 추가: 기본 테이블 셀 폰트 크기와 동일하게 설정
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                      borderRadius: 0,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderRadius: 0,
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #1976d2',
                                        borderRadius: 0,
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                              {/* 기타사항 셀 - 업체별로 그룹화 */}
                              {isFirstCompanyRow ? (
                                <TableCell align="center" rowSpan={companyRowSpan} sx={{ border, padding: '0px !important', backgroundColor: '#ffffcc', height: '30px' }}>
                                  <FormInputText
                                    control={control}
                                    name={`requestTableData.${index}.etc`}
                                    value={getInputValue(index, 'etc', row.etc)}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      const startIdx = currentGroup.firstIndex;
                                      const endIdx = startIdx + currentGroup.count;
                                      
                                      for (let i = startIdx; i < endIdx; i++) {
                                        handleInputChange(i, 'etc', newValue);
                                      }
                                    }}
                                    size="small"
                                    fullWidth
                                    multiline
                                    sx={{ 
                                      height: '100%',
                                      '& .MuiInputBase-root': {
                                        height: '100%',
                                        display: 'flex',
                                        borderRadius: 0,
                                        alignItems: 'flex-start',
                                      },
                                      '& .MuiInputBase-input': { 
                                        height: '100%',
                                        padding: '0px 0px', // 패딩값 수정
                                        fontSize: '0.875rem',
                                        alignItems: 'flex-start',
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none', 
                                        borderRadius: 0,
                                      },
                                      '& .MuiOutlinedInput-root': {
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                          border: '1px solid rgba(0, 0, 0, 0.23)',
                                          borderRadius: 0,
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                          border: '1px solid #1976d2',
                                          borderRadius: 0,
                                        }
                                      }
                                    }}
                                  />
                                </TableCell>
                              ) : null}
                            </TableRow>
                          );
                        });
                    })()}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>{"닫기"}</Button>
          </DialogActions>
        </Dialog>
        {/* 프로젝트 수정 다이얼로그 */}
        <GProjectG04Dialog
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          modify={true}
          open={openProjectDialog}
          setOpen={setOpenProjectDialog}
          selectedRow={selectedRow}
        />
        <AlertDialog
          alertInfo={alertInfo}
          setAlertInfo={setAlertInfo}
        />
      </ThemeProvider>
    </>
  );
};

export default GSupplyConfirmRequestDialog;
