import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import {
  Block,
  Circle,
  ChromeReaderMode,
  FilePresent,
  Preview,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import { ConfirmDialog } from "../../components/dialog";
import { StyledTooltip } from "../tooltip";
import { dateFormat } from "../../utils";

// store actions
import * as g04docuFileActions from "../../store/g04docuFile";
import * as gclientG04docuEtcMapActions from "../../store/gclientG04docuEtcMap";
import * as gclientG04docuGCertificationMapActions from "../../store/gclientG04docuGCertificationMap";

import G04docuFileDialogEtc from "../G04docuFile/G04docuFileDialogEtc";
import G04docuFileDialog from "../G04docuFile/G04docuFileDialog";

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const G04docuGCertification = ({
  handleClickFileDownload,
  handleClickViewEachDoc,
  selectedGClient,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [mode, setMode] = useState('all');
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedDocuFileType, setSelectedDocuFileType] = useState("");
  const [removeFileObject, setRemoveFileObject] = useState({});
  const [confirmOpenRemoveFile, setConfirmOpenRemoveFile] = useState(false);
  const [selectedRowEtc, setSelectedRowEtc] = useState([]);
  const [etcType, setEtcType] = useState("");
  const [open, setOpen] = useState(false);
  const [openEtc, setOpenEtc] = useState(false);
  const [selectedGComponentItemId, setSelectedGComponentItemId] = useState(null);
  const [selectedGComponentItemName, setSelectedGComponentItemName] = useState("");
  const [selectedGComponentItemType, setSelectedGComponentItemType] = useState("");

  const dispatch = useDispatch();

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const gcertifications = useSelector((state) => state.gclientG04docuGCertificationMap.gclientG04docuGCertificationMaps);
  
  const removeG04docuFile = (documentPath) => g04docuFileActions.removeDirect(documentPath)
  const removeGClientG04docuEtcMap = ({ gclientId, g04docuEtcId, documentPath }) => dispatch(gclientG04docuEtcMapActions.remove({ gclientId, g04docuEtcId, documentPath }))
  const selectMyG04docuGCertifications = (gclientId, status) => dispatch(gclientG04docuGCertificationMapActions.selectAllByGClientByStatus(gclientId, status)) // 인증서
  const removeGClientG04docuCertificationMap = ({ gclientId, g04docuGCertificationId, documentPath }) => dispatch(gclientG04docuGCertificationMapActions.remove({ gclientId, g04docuGCertificationId, documentPath }))

  useEffect(
    () => {
      selectMyG04docuGCertifications(sessionUser.id, 'all')
    }, [dispatch]
  )

  const handleChangeMode = (e, newMode) => {
    e.stopPropagation();

    setMode(newMode);

    selectMyG04docuGCertifications(sessionUser.id, newMode);
  }

  const handleClickGCertification/*GTest*/ = (e, gcertification, type, mode) => {
    // console.log(gcertification);
    if (gcertification.g04docuEtcId === undefined) { // undefined이면 일반문서, null이면 기타에서 일반문서로 첨부한 문서 // TODO : g04docuEtcId값으로 판단해도 될지...??
      const { id, name, gclientId, documentPath, startDate, endDate } = gcertification;
      if (mode === 'attachDocument') {
        setSelectedRow({
          g04docuGCertificationId: id,
          g04docuGCertificationName: name,
          documentPath,
          startDate,
          endDate,
        });
  
        setSelectedDocuFileType(type);
        setOpen(true);
      } else if (mode === 'deleteDocument') {
        if (documentPath) {
          setSelectedDocuFileType(type);
          setRemoveFileObject({ gclientId, g04docuGCertificationId: id, documentPath, type: 'CERTIFICATION' });
          setConfirmOpenRemoveFile(true);
        }
      }
    } else {
      const { id, code, gclientId, name, documentPath, validYN, startDate, endDate } = gcertification;
      if (mode === 'attachDocument') {
        setSelectedRowEtc({
          g04docuEtcId: id,
          documentType: code,
          title: name,
          documentPath,
          validYN,
          startDate,
          endDate,
        });
    
        setEtcType('CERTIFICATION');
        setOpenEtc(true);
      } else if (mode === 'deleteDocument') {
        if (documentPath) {
          setEtcType('CERTIFICATION');
          setRemoveFileObject({ gclientId, g04docuEtcId: id, documentPath, type: 'ETC' });
          setConfirmOpenRemoveFile(true);
        }
      }
    }
  }

  const selectAllGCertifications = (gclientId) => {
    selectMyG04docuGCertifications(gclientId, mode);
  }

  const removeFile = async () => {
    const { gclientId, g04docuGCertificationId, g04docuEtcId, documentPath, type } = removeFileObject;
    await removeG04docuFile(documentPath);

    if (type === 'CERTIFICATION') {
      await removeGClientG04docuCertificationMap({ gclientId, g04docuGCertificationId, documentPath });
      selectAllGCertifications(gclientId);
    } else if (type === 'ETC') {
      await removeGClientG04docuEtcMap({ gclientId, g04docuEtcId, documentPath });
      if (etcType === 'CERTIFICATION') {
        selectAllGCertifications(gclientId);
      }
    }
  }

  return (
    <>
      {
        // || mode === 'nofile' || mode === 'invalid' 조건이 없다면 인증서 필터링할 때 조회결과가 없으면 사라짐
        (gcertifications && gcertifications.length > 0 || mode === 'nofile' || mode === 'invalid') && (
          <Grid item xs={12}>
            <G04docuFileDialog
              open={open}
              setOpen={setOpen}
              selectedRow={selectedRow}
              selectedGClientId={sessionUser.id}
              selectedGClient={selectedGClient}
              selectedGComponentItemId={selectedGComponentItemId}
              selectedGComponentItemName={selectedGComponentItemName}
              selectedGComponentItemType={selectedGComponentItemType}
              selectedDocuFileType={selectedDocuFileType}
              refresh={selectAllGCertifications}
              from={'PROFILE'}
            />
            <G04docuFileDialogEtc
              open={openEtc}
              setOpen={setOpenEtc}
              selectedRow={selectedRowEtc}
              gclientId={sessionUser.id}
              gclient={selectedGClient}
              selectedGComponentItemType={selectedGComponentItemType}
              refresh={selectAllGCertifications} // TODO : 일반문서/인증서/성적서 종류에 따라 refresh 함수가 다름
            />
            <ConfirmDialog
              removeId={removeFileObject}
              title={"삭제"}
              open={confirmOpenRemoveFile}
              setOpen={setConfirmOpenRemoveFile}
              onConfirm={removeFile}
              onCancel={() => {}}
            >
              <div>
                <span style={{ color: "#1976d2" }}>{"첨부된 서류를 삭제하시겠습니까?"}</span>
                <br /><br />
                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
                  { `파일명 : ${removeFileObject?.documentPath?.split("/")[removeFileObject?.documentPath?.split("/")?.length-1]}` }
                </Box>
              </div>
            </ConfirmDialog>
            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
              <AccordionSummary aria-controls="basic-content" id="basic-header">
                <Grid container spacing={2}>
                  <Grid item xs={6} display="flex" justifyContent="flex-start" alignItems="center">
                    <Typography variant="h6" component="div">{"인증서"}</Typography>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
                    <Typography variant="subtitle2" component="div" /*color="primary" */sx={{ mr: 1 }} onClick={(e) => e.stopPropagation()}>{"보기"}</Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={mode}
                      exclusive
                      size="small"
                      onChange={handleChangeMode}
                    >
                      <ToggleButton value="nofile">{"인증서 없음"}</ToggleButton>
                      <ToggleButton value="invalid">{"만료일 경과"}</ToggleButton>
                      <ToggleButton value="all">{"전체"}</ToggleButton>
                    </ToggleButtonGroup>  
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container spacing={0} sx={{
                      pb: 1,
                      borderBottom: 1,
                      // height: '100%',
                      // borderRadius: 1,
                      borderColor: '#DCDCDC'
                    }}>
                      <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                        {"서류명"}
                      </Grid>
                      <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                        {"서류"}
                      </Grid>
                      <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                        {"만료일"}
                      </Grid>
                      <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                        {"최종수정일시"}
                      </Grid>
                      <Grid item xs={2} display="flex" justifyContent="center" alignItems="center" sx={{ ml: -1.6 }}>
                        {"업무"}
                      </Grid>
                    </Grid>
                  </Grid>
                  {
                    gcertifications?.map(gcertification => {
                      const {
                        code,
                        comments,
                        createdAt,
                        details,
                        divider,
                        division,
                        documentPath,
                        documentType,
                        endDate,
                        gclient,
                        gclientId,
                        gcomponentItemId,
                        gstandardId,
                        gstandardName,
                        gstandardNo,
                        id,
                        majority,
                        name,
                        order,
                        orderDetail,
                        selectedDetails,
                        startDate,
                        updatedAt,
                        mapCreatedAt,
                        mapUpdatedAt,
                        valid,
                        validYN,
                      } = gcertification;

                      return (
                        <Grid item xs={12}>
                          <Grid container spacing={0} sx={{
                            // pb: 1,
                            borderBottom: 1,
                            // height: '100%',
                            // borderRadius: 1,
                            borderColor: '#DCDCDC',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)', // MUI DataGrid의 기본 hover 색상
                              cursor: 'pointer'
                            },
                            transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                          }}>
                            <Grid item xs={4} display="flex" alignItems="center" sx={{ color: !documentPath ? red[700] : ((validYN || valid) ? "#000000" : "#ED6C02") }}>
                              {/* <Grid item xs={4} display="flex" alignItems="center" sx={{ color: valid === false ? red[700] : "" }}> */}
                              {
                                documentPath && (validYN || valid) && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#4caf50' }}><Circle sx={{ width: 14, height: 14 }} /></Grid>
                              }
                              {/* {
                                documentPath && !valid && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ED6C02' }}><Warning /></Grid>
                              } */}
                              {
                                documentPath && (!validYN && !valid) && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ED6C02' }}><Circle sx={{ width: 14, height: 14 }} /></Grid>
                              }
                              {/* {
                                !documentPath && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ef5350' }}><Block /></Grid>
                              } */}
                              {
                                !documentPath && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ef5350' }}><Circle sx={{ width: 14, height: 14 }} /></Grid>
                              }
                              {/* {name} */}
                              {
                                gstandardNo && (
                                  <Chip label={gstandardNo} size="small" sx={{ ml: 2 }}></Chip>
                                )
                              }
                              <Typography variant="subtitle1" sx={{ ml: 2 }}>{name}</Typography>
                            </Grid>
                            <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                              {
                                documentPath && (
                                  <StyledTooltip
                                    title={<Stack direction="row" display="flex" alignItems="center">
                                      <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                                        {`미리보기`}
                                      </Box>
                                      <Typography variant="subtitle2">{documentPath?.split("/")[documentPath?.split("/")?.length-1]}</Typography>
                                    </Stack>}
                                    placement="right"
                                    sx={tooltipTop}
                                  >
                                    <IconButton
                                      color="primary"
                                      aria-label="file"
                                      onClick={() => handleClickViewEachDoc(documentPath)}
                                      sx={{ color: (validYN || valid) ? "#1976D2" : "#ED6C02" }}
                                    >
                                      <Preview />
                                    </IconButton>
                                  </StyledTooltip>
                                )
                              }
                              {
                                !documentPath && (
                                  <Tooltip title={"서류없음"} placement="right">
                                    <IconButton
                                      aria-label="download"
                                      // edge="end"
                                      sx={{ color: '#ef5350' }}
                                    >
                                      <Block />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }
                            </Grid>
                            <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                              {
                                endDate ? (
                                  <Tooltip title={"만료일"} placement="right">
                                    <Chip label={` ~ ${dateFormat(endDate, 'yyyy-MM-dd')}`} color={valid === false ? "warning" : "primary"} variant={valid === false ? "" : "outlined"}></Chip>
                                  </Tooltip>
                                ) : ( validYN ? "만료일 없음" : "")
                              }
                            </Grid>
                            <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                              {
                                mapUpdatedAt ? (
                                  <Tooltip title={"최종수정일시"} placement="right">
                                    <Chip label={dateFormat(mapUpdatedAt)}></Chip>
                                  </Tooltip>
                                ) : ""
                              }
                            </Grid>
                            <Grid item xs={2} display="flex" justifyContent="center" alignItems="center" sx={{ ml: -1.6 }}>
                              <Button onClick={(e) => handleClickGCertification/*GTest*/(e, gcertification, 'CERTIFICATION', 'attachDocument')} sx={{ color: '#1976d2' }}>{"첨부"}</Button>
                              <Button
                                onClick={(e) => handleClickGCertification/*GTest*/(e, gcertification, 'CERTIFICATION', 'deleteDocument')}
                                disabled={!documentPath}
                                sx={{ color: '#1976d2' }}
                              >
                                {"삭제"}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )
      }
    </>
  )
}

export default G04docuGCertification;