// TODO : 찾기 다이얼로그, 등록/수정 다이얼로그가 서로 연계되어 있어 관리/다이얼로그 분리는 추후에 검토
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  ContentPaste,
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import usePrevious from "../hook/usePrevious";
import {
  FormInputCheckbox,
  FormInputDropdown,
  FormInputText,
  FormInputToggleButton,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import * as gstandardActions from "../../store/gstandard";
import * as g04docuGCertificationActions from "../../store/g04docuGCertification";
import * as gtestComparisonActions from "../../store/gtestComparison";
import * as dialogActions from "../../store/components/dialog";
import * as confirmDialogActions from "../../store/components/confirmDialog";
import GTestComparisonDialog from "./GTestComparisonDialog";
import GTestComparisonlDeleteConfirmDialog from "./GTestComparisonlDeleteConfirmDialog";
import { getGridHeight } from "../../constants/gridHeights";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  gstandardId: null,
  division: "RAW_MATERIAL", // 규격구분 :  RAW_MATERIAL / SUB_MATERIAL_PROCESS / SUB_MATERIAL_BUILD / PROCESS
  includeTest: null,
  name: "",
  code: "",
  comments: "",
  majority: false,
  divider: " ",
};

const theme = createTheme();

// TODO : 이렇게 객체로 쓰면 간편한데??? 추후 검토
const cellStyle = {
  borderColor: '#dcdcdc',
  borderBottomWidth: 0, borderBottomWidth: 1, borderBottomStyle: 'solid',
  borderRightWidth: 0, borderRightWidth: 1, borderRightStyle: 'solid',
};

const G04docuGCertificationManagement = () => {

  // 현재 화면에 맞는 Grid 높이 계산
  const GRID_HEIGHT = getGridHeight('ONE_LINE_LAYER', 0); // 0px 추가 여백
  
  const columnsGTestComparisonA = [ // 정렬없음
    {
      field: 'item',
      headerName: '구분',
      width: 120,
    },
    {
      field: 'unit',
      headerName: '단위',
      width: 100,
    },
    {
      field: 'criterion',
      headerName: '기준',
      width: 120,
    },
    {
      field: 'result',
      headerName: '판정',
      width: 120,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'method',
      headerName: '시험방법',
      width: 120,
    },
    {
      field: 'functions',
      headerName: '기능',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {          
        const buttons = [
          <IconButton size="small" onClick={() => handleSelectGTestComparison({ type: 'edit', params })}><Edit /></IconButton>,
          <IconButton size="small" onClick={() => handleSelectGTestComparison({ type: 'delete', params })}><Delete /></IconButton>,
        ];
        
        return buttons;
      }
    },
  ];

  const columnsGTestComparisonB = [ // 정렬기능 포함
    {
      field: 'item',
      headerName: '구분',
      width: 120,
    },
    {
      field: 'unit',
      headerName: '단위',
      width: 100,
    },
    {
      field: 'criterion',
      headerName: '기준',
      width: 120,
    },
    {
      field: 'result',
      headerName: '판정',
      width: 120,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'method',
      headerName: '시험방법',
      width: 120,
    },
  ];

  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loadedGTestComparison, setLoadedGTestComparison] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [checkedGTestComparision, setCheckedGTestComparision] = useState(false);
  const [columnsGTestComparison, setColumnsGTestComparison] = useState(columnsGTestComparisonA);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [classifications, setClassifications] = useState([]);
  const [classificationComponentsForTable, setClassificationComponentsForTable] = useState([]);
  // const [totalCount, setTotalCount] = useState(0);
  const [g04docuGCertificationDivision, setG04docuGCertificationDivision] = useState('RAW_MATERIAL');
  const [division, setDivision] = useState("RAW_MATERIAL"); // g04docuGCertificationDivision와 같은데 목록과 다이얼로그를 같은 js 파일에서 구현하다보니 변수 기능이 중복됨. 추후 분리할 것
  const [includeTest, setIncludeTest] = useState(true);
  
  const handleSelect = async ({ type, params }) => {
    
    const { id } = params;

    setOpenBackdrop(true);

    if (type === "detail") {
      await select(id);
      setCrudMode('R');
      setOpen(true);
    } else if (type === "edit") {
      // TODO : 아래 내용 재검토
      // 조회 후 다이얼로그 열면 데이터는 적용된 후 다이얼로그가 나타나 화면에서 데이터가 바뀌는게 보이는 것은 없어지나
      // 다소 다이얼로그가 늦게 나타나고,
      // 반대의 경우 다이얼로그는 빨리 나타나나 나타난 후 데이터가 바뀌는 것이 보임. 바뀌는 것이 보이는 것이 동적으로 변화하여
      // (마치 의도된 로딩처럼) 보기 좋을 수 있으나 화면 구성에 따라 보기 안좋은 경우도 있음.
      await select(id);
      setCrudMode('U');
      setOpen(true);
    } else if (type === "paste") {
      await select(id);
      // setValue("id", `${id}_copy`);
      // setValue("id", uuidv4());
      setCrudMode('C');
      setOpen(true);
    } else if (type === "delete") {
      setRemoveId(id);
      setParams(params);
      setConfirmOpen(true);
    } else if (type === "addChild") {
      setValue("pid", id);
      console.log(params)
      setValue("pname", params.row.name);
      setCrudMode('C');
      setOpen(true);
    }

    setOpenBackdrop(false);
  }

  const handleSelectGTestComparison = async ({ type, params }) => {
    const { id } = params;
    if (type === "edit") {
      await selectGTestComparison(id);
      dispatch(dialogActions.setOptions({ open: true, crudMode: 'U', name: 'GTestComparisonDialog' }));
    } else if (type === "delete") {
      dispatch(confirmDialogActions.setOptions({ open: true, params, action: removeGTestComparison }));
    }
  }
  
  const handleSelectUpDown = async ({ type, id }) => {
    setOpenBackdrop(true);

    await reorder({ type, id });
    setLoaded(false);
    if (g04docuGCertificationDivision === "ALL") {
      await selectAllByQuery();
    } else {
      await selectAllByDivisionByQuery(g04docuGCertificationDivision);
    }
    setTimeout(() => setLoaded(true), 300);

    setOpenBackdrop(false);
  }

  // Drag & Drop 순서 변경
  const handleRowOrderChange = async (params) => {
    
    const { oldIndex, targetIndex } = params;
    if (oldIndex === targetIndex) return;

    const newRows = [...rows];
    const [movedRow] = newRows.splice(oldIndex, 1);
    newRows.splice(targetIndex, 0, movedRow);
    
    setOpenBackdrop(true);
    try {
      // 새로운 순서 정보를 생성
      const movedRow = rows[oldIndex];
      
      let result = await modifyRowOrder({
        id: movedRow.id,
        type: 'to',
        toOrder: targetIndex+1,
        groupCode: g04docuGCertificationDivision // ToggleButton 규격구분 코드
      });

    } catch (error) {
      console.error("Error row order:", error.serverResponse.message, error);
    }

    setOpenBackdrop(false);
  };

  const handleSelectUpDownGTestComparison = async ({ type, id, g04docuGCertificationId }) => {
    // console.log({ type, id, g04docuGCertificationId });
    setLoadedGTestComparison(false);
    
    await reorderGTestComparisons({ type, id, g04docuGCertificationId }); // TODO : 추후 이 안에서 검색결과 리턴하면 아래처럼 굳이 또 조회 API를 호출하지 않아도 되지 않을까
    await selectGTestComparisions(g04docuGCertificationId);
    
    setTimeout(() => setLoadedGTestComparison(true), 300);
  }

  const remove = (removeId) => {
    dispatch(g04docuGCertificationActions.remove(removeId))
      .then(async res => {
        if (g04docuGCertificationDivision === "ALL") {
          await selectAllByQuery();
        } else {
          await selectAllByDivisionByQuery(g04docuGCertificationDivision);
        }
      })
      .catch(async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  const removeGTestComparison = async (params) => {
    const { id: removeId, row } = params;
    console.log(removeId)
    await dispatch(gtestComparisonActions.remove(removeId));
      
    selectGTestComparisions(row.g04docuGCertificationId);
  }

  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const handleChangeOrderGTestComparision = () => {
    setCheckedGTestComparision(!checkedGTestComparision);
    if (checkedGTestComparision) {
      setColumnsGTestComparison(columnsGTestComparisonA);
    } else {
      setColumnsGTestComparison(columnsGTestComparisonB);
    }
  }

  const handleChangeToggleDivision = async ({ division, params, e }) => {
    e.stopPropagation();

    setOpenBackdrop(true);

    await modifyG04docuGCertificationDivision({ id: params.id, division })

    setLoaded(false);
    // await selectAllByQuery();
    if (g04docuGCertificationDivision === "ALL") {
      await selectAllByQuery();
    } else {
      await selectAllByDivisionByQuery(g04docuGCertificationDivision);
    }
    setTimeout(() => setLoaded(true), 300);

    setOpenBackdrop(false);
  }

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    setClassifications([]);
    
    initializeGStandard();

    // 시헝성과 항목 부분의 초기화
    initGTestComparisions();
    setCheckedGTestComparision(false);
    setColumnsGTestComparison(columnsGTestComparisonA);
  }

  // 등록 다이얼로그
  const handleClickOpen = () => {
    setCrudMode('C');
    setOpen(true);
  }

  const handleDialogClose = () => {
    setOpen(false);
    setCrudMode('');

    initDialog();
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelect({ type: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ContentPaste />}
        label={"복사하여 등록"}
        onClick={() => handleSelect({ type: 'paste', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
    ];

    if (checked) {
      arrActions = arrActions.concat([
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowUp />}
            label={"맨위로"}
            onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowUp />}
            label={"위로"}
            onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowDown />}
            label={"아래로"}
            onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />,
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowDown />}
            label={"맨아래로"}
            onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />
        ]
      );
    }

    return arrActions;
  }

  const columns = useMemo(() => [
      {
        field: 'name',
        headerName: '이름',
        width: 300,
        // editable: true,
      },
      {
        // RAW_MATERIAL / SUB_MATERIAL_PROCESS / SUB_MATERIAL_BUILD / PROCESS
        field: 'division',
        headerName: '규격구분',
        width: 200,
        valueGetter: (params) => {
          let division = params.row.division;
          let text = "";
          if (division === 'RAW_MATERIAL') {
            text = "원자재";
          } else if (division === 'SUB_MATERIAL_PROCESS') {
            text = "가공부자재";
          } else if (division === 'SUB_MATERIAL_BUILD') {
            text = "시공부자재";
          }  else if (division === 'PROCESS') {
            text = "공정";
          }

          return text;
        },
      },
      {
        field: 'code',
        headerName: '코드',
        width: 200,
        // editable: true,
        hide: true,
      },
      {
        field: 'gstandardNo',
        headerName: '표준번호',
        width: 200,
        // editable: true,
      },
      {
        field: 'gstandardName',
        headerName: '표준명(한글)',
        width: 200,
        // editable: true,
      },
      {
        field: 'comments',
        headerName: '설명',
        width: 280,
        // editable: true,
      },
      // {
      //   field: 'pid',
      //   headerName: '부모아이디',
      //   width: 100,
      //   hide: true,
      //   // editable: true,
      // },
      {
        field: 'createdAt',
        headerName: '생성일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => dateFormat(params.value),
      },
      {
        field: 'updatedAt',
        headerName: '수정일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => dateFormat(params.value),
      },
      {
        field: 'actions',
        headerName: <Tooltip title={"상세/수정/복사하여 등록/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
        width: checked ? 200 : 70,
        // description: "상세/수정/복사하여 등록/삭제",
        // headerClassName: 'super-app-theme--header',
        // cellClassName: 'super-app-theme--cell',
        type: 'actions',
        getActions: (params) => generateActions(params),
      },
    ], [handleSelect]
  );

  const generateActionsGTestComparision = (params) => {
    let arrActions = [];

    if (checkedGTestComparision) {
      arrActions = arrActions.concat([
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowUp />}
            label={"맨위로"}
            onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowUp />}
            label={"위로"}
            onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowDown />}
            label={"아래로"}
            onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />,
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowDown />}
            label={"맨아래로"}
            onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />
        ]
      );
    }

    return arrActions;
  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const dispatch = useDispatch();

  const gstandard = useSelector((state) => state.gstandard.gstandard);
  const gstandards = useSelector((state) => state.gstandard.gstandards);
  const rows = useSelector((state) => state.g04docuGCertification.g04docuGCertifications);
  const selectedRow = useSelector((state) => state.g04docuGCertification.g04docuGCertification);
  const rowsGTestComparison = useSelector((state) => state.gtestComparison.gtestComparisons);
  
  // 데이터 관리
  const selectAllGStandard = () => dispatch(gstandardActions.selectAll())
  const initializeGStandard = () => dispatch(gstandardActions.initGStandard())
  const selectGStandardById = (id) => dispatch(gstandardActions.select(id))
  const selectAllByQuery = () => dispatch(g04docuGCertificationActions.selectAllByQuery())
  const selectAllByDivisionByQuery = (division) => dispatch(g04docuGCertificationActions.selectByDivisionByQuery(division))
  const select = (id) => dispatch(g04docuGCertificationActions.select(id))
  const addG04docuGCertification = ({ id, division, name, code, classifications, divider, comments, majority, gstandardId, includeTest }) => dispatch(g04docuGCertificationActions.create({ id, division, name, code, classifications, divider, comments, majority, gstandardId, includeTest }))
  const modifyG04docuGCertification = ({ id, division, name, code, classifications, divider, comments, majority, gstandardId, includeTest }) => dispatch(g04docuGCertificationActions.modify({ id, division, name, code, classifications, divider, comments, majority, gstandardId, includeTest }))
  const modifyG04docuGCertificationDivision = ({ id, division }) => dispatch(g04docuGCertificationActions.modifyDivision({ id, division }))
  const reorder = ({ type, id }) => g04docuGCertificationActions.reorder({ type, id })
  const modifyRowOrder = ({ id, type, toOrder, groupCode }) => g04docuGCertificationActions.modifyRowOrder({ id, type, toOrder, groupCode })

  const initGTestComparisions = () => dispatch(gtestComparisonActions.initGTestComparisions())
  const selectGTestComparisions = (g04docuGCertificationId) => dispatch(gtestComparisonActions.selectAllByG04docuGCertifications(g04docuGCertificationId))
  const reorderGTestComparisons = ({ type, id, g04docuGCertificationId }) => gtestComparisonActions.reorder({ type, id, g04docuGCertificationId })
  const selectGTestComparison = (id) => dispatch(gtestComparisonActions.select(id))

  const prevGStandard = usePrevious(gstandard);

  // TODO : 추후 아이디 "중복확인" 추가할 것
  const onSubmit = ({ id, division, name, code, divider, comments, majority, gstandardId }) => {
    setErrors([]);

    let func;
    if (crudMode === 'C') {
      func = addG04docuGCertification;
    } else if (crudMode === 'U') {
      // TODO : 자기 자신을 상위(또는 하위)로 가질 수 없으므로 id, pid 같이 않도록 유효성 체크 필요
      func = modifyG04docuGCertification;
    }

    // console.log({ id, division, name, code, classifications, divider, comments, majority, gstandardId, includeTest });
    // return;
    
    func({
      id,
      division,
      name,
      code,
      classifications,
      divider,
      comments,
      majority,
      gstandardId: gstandardId ?? null,
      ...(division === 'PROCESS' ? { includeTest: includeTest ?? null } : {}),
    })
      .then (async (res) => {
        handleDialogClose();
        
        if (g04docuGCertificationDivision === "ALL") {
          await selectAllByQuery();
        } else {
          await selectAllByDivisionByQuery(g04docuGCertificationDivision);
        }
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  
  setTimeout(() => hideWatermark(), 300);

  useEffect(
    async () => {
      // 다른 목록과 달리 로딩시간이 길어 Backdrop 사용
      // setOpenBackdrop(true);
      // await dispatch(g04docuGCertificationActions.selectAllByQuery());
      // await selectAllByQuery();
      selectAllGStandard();

      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      // setOpenBackdrop(false);
      // setShow(true);
      // setTimeout(() => setLoaded(true), 300);
    }, [dispatch]
  );

  useEffect(
    () => {
      setLoadedGTestComparison(true)
    }, [rowsGTestComparison]
  )

  // TODO : 아래는 목록에서도 들어온다. 검토해볼 것
  useEffect(
    () => {
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          setValue(item, selectedRow[item] || value);
          if (item === "division") {
            setDivision(selectedRow[item]);
          } else if (item === "includeTest") {
            setIncludeTest(selectedRow[item]);
          }
        }

        // 그외 설정할 것들은 여기서 한다.
        setClassifications(selectedRow["classifications"]);

        selectedRow["gstandardId"] && selectGStandardById(selectedRow["gstandardId"]);
        selectedRow["id"] && selectGTestComparisions(selectedRow["id"]);
      }
    }, [selectedRow]
  );

  useEffect(
    () => {
      if (crudMode === 'C') {
        setValue("id", uuidv4());
        setIncludeTest(true);
      }

      setDivision(g04docuGCertificationDivision);
      setValue("division", g04docuGCertificationDivision);
    }, [crudMode]
  );

  /**
   * TODO : 아래에서 만들어진 classifications는 DB의 G04docuGCertifications 테이블에 저장되어 인증등급 선택하는 화면에서 초기의 설정정보로 활용됨.
   * 각 화면(인증서/성적서 등록화면, 원부자재 화면 등)에서 인증등급을 설정한 후부터는 각 테이블의 selectecClassifcations에 설정정보가 저장되어 이후부터는 
   * 이것을 사용하는데 표준(GStandards)의 classificationComponents가 바뀌면 동기화 문제 발생(G04docuGCertifications의 classifications가 갱신되어야 하고
   * 각 테이블의 selectedClassifications가 갱신되어야 함. 추후 아래 classifications 생성논리를 서버로 옮기고 표준(GStandards)의 classificationComponents 변경시
   *  관련 인증규격(G04docuGCertifications)의 classifications를 갱신하고 각 화면 기능시 G04docuGCertifications의 classifications와 selectedClassifications를 merge하는 방안을 고려)
   */
  useEffect(
    async () => {
      // TODO 아래의 두 if 조건문 검토 필요
      if (prevGStandard?.id === gstandard?.id) {
        return;
      }

      setClassificationComponentsForTable(gstandard?.classificationComponents);

      if (prevGStandard !== null || crudMode === 'C') {
        
        if (gstandard) {
          const arrClassification = [];
          gstandard.classificationComponents?.forEach((classificationComponent, i) => {
            const newClassificationComponent = classificationComponent.map(c => ({...c, [`length${i}`]: classificationComponent.length }));
            arrClassification.push(newClassificationComponent);
          })
  
          console.log(arrClassification)
          const arrCartesian = arrClassification.reduce(
            (accumulator, currentValue) => accumulator.cross(currentValue),
          );
  
          console.log(arrCartesian);
          const flatArrCartesian = arrCartesian.map((i, idx) => {
            if (Array.isArray(i)) {
              return i.flat(Infinity);  
            } else {
              return i;
            }
            
          });

          console.log(flatArrCartesian);
          const arrClassificationStandards = [];
  
          let i = 0;
          // arrCartesian은 2차원 배열
          flatArrCartesian.forEach(arr1 => {
            let classificationStandard = "";
            if (Array.isArray(arr1)) {
              arr1.forEach(arr2 => {
                console.log(arr2)
                for (const [item, value] of Object.entries(arr2)) {
                  if (item.indexOf('length') === -1) {
                    classificationStandard += value + getValues("divider");
                  }
                }
              })
  
              const flat = JSON.parse(JSON.stringify(arr1));
              const flat1 = flat.reduce((accumulator, currentValue) => Object.assign(accumulator, currentValue));
              console.log(flat1)
  
              arrClassificationStandards.push({
                detail: {...flat1},
                type: classificationStandard.substring(0, classificationStandard.length-1),
                /*checked: false, */
                name: `${i}_classificationStandard`,
                divider: getValues('divider')
              });

              i++;
            } else {
              for (const [item, value] of Object.entries(arr1)) {
                if (item.indexOf('length') === -1) {
                  classificationStandard += value + getValues("divider");
                }
              }
              arrClassificationStandards.push({
                detail: {...arr1},
                type: classificationStandard.substring(0, classificationStandard.length-1),
                /*checked: false, */
                name: `${i}_classificationStandard`,
                divider: getValues('divider')
              });

              i++;
            }
          })
          
          console.log(arrClassificationStandards)
          setClassifications(arrClassificationStandards);
          // setClassificationComponentsForTable(gstandard.classificationComponents);
          // setTotalCount(arrClassificationStandards.length)
        } else {
          setClassifications([]);
          setClassificationComponentsForTable([]);
        }
      }
    }, [gstandard]
  )

  useEffect(
    async () => {
      // console.log(classifications)
      // const divider = getValues("divider");
      // g04docu === 'TEST' && classifications?.length > 0 && setValue("name", classifications.join(divider))
    }, [classifications]
  )

  useEffect(
    async () => {
      setLoaded(false)
      // setOpenBackdrop(true); // 다른 목록과 달리 로딩시간이 길어 Backdrop 사용
      // await dispatch(g04docuGCertificationActions.selectAllByQuery());
      
      if (g04docuGCertificationDivision === "ALL") {
        await selectAllByQuery();
      } else {
        await selectAllByDivisionByQuery(g04docuGCertificationDivision);
      }
      
      // setOpenBackdrop(false);
      setLoaded(true);
    }, [g04docuGCertificationDivision]
  )

  const handleChangeGStandard = (e) => {
    console.log(e)
    selectGStandardById(e.target.value);
  }

  const handleChangeClassifications = (e, i) => {
    // console.log(e)
    // console.log(i)
    const newClassifications = [].concat(classifications);
    newClassifications.splice(i, 1, e.target.value);
    console.log(newClassifications);
    setClassifications(newClassifications);
  }

  const handleChangeDivider = (e) => {
    const { value } = e.target;

    setValue("divider", value);

    setClassifications(classifications.map(detail => {
      let { type, divider } = detail;
      const arrType = type.split(divider);
      return {
        ...detail,
        type: arrType.join(value),
        divider: value,
      }  
    }));
  }

  const handleChangeG04docuGCertificationDivision = (e) => {
    setG04docuGCertificationDivision(e.target.value);
  }

  const handleClickAddGTestComparison = () => {
    dispatch(dialogActions.setOptions({ open: true, crudMode: 'C', name: 'GTestComparisonDialog' }));
  }

  const handleIncludeTestChange = (event) => {
    setIncludeTest(event.target.checked);
  };

  const handleChangeDivision = (event, newDivision) => {
    if (newDivision !== null) {
      setDivision(newDivision);
      console.log(newDivision)
      setValue("division", newDivision);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        {/* TODO : G04docuGCertificationDialog로 분리할 것 */}
        <Dialog
          PaperProps={{style: {overflowY: 'visible'}}}
          open={open}
          onClose={handleDialogClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          scroll={"body"}
          maxWidth={"md"}
        >
          <DialogTitleClose
            id="draggable-dialog-title"
            onClose={handleDialogClose}
            style={{ cursor: 'move' }}
          >
            { crudMode === 'C' && "인증규격 등록" }
            { crudMode === 'R' && "인증규격 상세" }
            { crudMode === 'U' && "인증규격 수정" }
          </DialogTitleClose>
          <DialogContent
            style={{ overflowY: 'visible' }}
            dividers
          >
            <ul>
              {errors.map((error, idx) => <li key={idx}>{error}</li>)}
            </ul>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* TODO : 읽기전용 기능 추가 필요 */}
                <FormInputToggleButton
                  name="division"
                  control={control}
                  label="규격구분"
                  exclusive
                  // RAW_MATERIAL / SUB_MATERIAL_PROCESS / SUB_MATERIAL_BUILD / PROCESS
                  options={[
                    { label: "원자재", value: "RAW_MATERIAL" },
                    { label: "가공부자재", value: "SUB_MATERIAL_PROCESS" },
                    { label: "시공부자재", value: "SUB_MATERIAL_BUILD" },
                    { label: "공정", value: "PROCESS" },
                  ]}
                  onChange={handleChangeDivision}
                />
                {
                  division === "PROCESS" && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={includeTest}
                          onChange={handleIncludeTestChange}
                          name="includeTest"
                          color="primary"
                        />
                      }
                      label="성적서 포함"
                      sx={{ ml: 1 }}
                    />
                  )
                }
              </Grid>
              <Grid item xs={12} display="flex" alignItems="end">
                <FormInputDropdown
                  name={"gstandardId"}
                  control={control}
                  label={"표준명(한글)"}
                  onChangeSelectValue={handleChangeGStandard}
                  options={
                    [{ label: '없음', value: "" }].concat(gstandards.map(gstandard => {
                      return {
                        label: gstandard.no,
                        value: gstandard.id,
                        name: gstandard.name,
                      }
                    }))
                  }
                  inputProps={
                    { readOnly: crudMode === 'R' }
                  }
                />
              </Grid>
              <Grid item xs={12} sx={{ display: 'none' }}>
                <FormInputText
                  name={"id"}
                  control={control}
                  label={"아이디"}
                  inputProps={
                    { readOnly : true }
                  }
                />
              </Grid>
              {
                gstandard && (
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    {/* <Grid container spacing={2}> */}
                      <Card>
                        <CardHeader
                          titleTypographyProps={{ variant: 'subtitle1' }}
                          // title={"종류등〮급호〮칭 또는 모델"}
                          title={"종류·등급·호칭 또는 모델"}
                          sx={{ bgcolor: "#eaeaea" }}
                        />
                        <CardContent>
                          {/* <Grid container spacing={1}>
                            {
                              details.map((item, i) => {
                                return (
                                  <Grid item xs={6} display="flex" alignItems="center">
                                    <Typography>{item.type}</Typography>
                                  </Grid>
                                )
                              })
                            }
                          </Grid> */}
                          <Grid conatiner>
                            <Grid item xs={12}>
                              <Stack direction="row">
                                {
                                  classificationComponentsForTable?.map((types, i) => {
                                    let prevArrayLength = 1;
                                    if (i > 0) {
                                      for(let k=i-1; k>=0; k--) prevArrayLength = classificationComponentsForTable[k].length*prevArrayLength; // 현재 배열의 앞 배열들의 곱집합 크기
                                    }
                                    return (
                                      <Grid container>
                                        {
                                          Array.from(' '.repeat(prevArrayLength)).map(() => ( // Array.from의 첫번째 인자에 '' 빈 문자열은 안됨에 유의
                                            types.map(type => (<Grid item xs={12} display="flex" sx={cellStyle}>
                                              <Typography sx={{ p: 1 }}>{type[`type${i}`]}</Typography>
                                            </Grid>))
                                          ))
                                        }
                                      </Grid>
                                    )
                                  })
                                }
                              </Stack>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                  </Grid>
                )
              }
              {
                gstandard && (
                  <Grid item xs={12}>
                    <FormInputText
                      select
                      name={"divider"}
                      control={control}
                      label={"구분자"}
                      inputProps={
                        { readOnly: crudMode === 'R' }
                      }
                      options={[
                        // { label: "없음", value: "none" },
                        { label: "공백", value: " " },
                        { label: "+", value: "+" },
                        { label: "-", value: "-" },
                        { label: "*", value: "*" },
                        { label: "/", value: "/" },
                        { label: ",", value: "," },
                      ]}
                      onChange={handleChangeDivider}
                    />
                  </Grid>
                )
              } 
              <Grid item xs={12}>
                <FormInputText
                  name={"name"}
                  control={control}
                  label={"이름"}
                  inputProps={
                    { readOnly: crudMode === 'R' }
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"code"}
                  control={control}
                  label={"코드"}
                  inputProps={
                    { readOnly: crudMode === 'R' }
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"comments"}
                  control={control}
                  label={"설명"}
                  multiline
                  maxRows={5}
                  inputProps={
                    { readOnly: crudMode === 'R' }
                  }
                />
              </Grid>
              {
                crudMode !== 'C' && (
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader
                        sx={{ bgcolor: "#eeeeee", color: "#888888" }}
                        titleTypographyProps={{ variant: 'subtitle1' }}
                        title={"시험성과 항목"}
                        action={
                          <>
                            <Button
                              onClick={handleClickAddGTestComparison}
                              startIcon={<Add />}
                              sx={{ mr: 2 }}
                            >
                              {"항목추가"}
                            </Button>
                            <FormControlLabel
                              control={
                                <Switch 
                                  checked={checkedGTestComparision}
                                  onChange={handleChangeOrderGTestComparision}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              }
                              label={"순서"}
                            />
                          </>
                        }
                      />
                      <Grid container spacing={1} sx={{ p: 2 }}>
                        <div style={{ height: 300, width: '100%', marginTop: 10, marginLeft: 10 }}>
                          <DataGridPro
                            localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                            sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                            initialState={{ pinnedColumns: { right: ['actions'] } }}
                            slots={{
                              noRowsOverlay: CustomNoRowsOverlay,
                              loadingOverlay: LinearProgress,
                            }}
                            columnHeaderHeight={38}
                            rowHeight={34}
                            loading={!loadedGTestComparison}
                            rows={rowsGTestComparison}
                            columns={columnsGTestComparison}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            pagination
                            onRowDoubleClick={(params) => handleSelectGTestComparison({ type: 'edit', params })}
                          />
                        </div>
                      </Grid>
                    </Card>
                  </Grid>
                )
              }
              
            </Grid>
          </DialogContent>
          <DialogActions>
            {
              crudMode === 'C' && (<>
                <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
                <Button onClick={() => reset()}>{"초기화"}</Button>
              </>)
            }
            { crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button> }
            <Button onClick={handleDialogClose}>{"닫기"}</Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            mt: 3,
            '& .super-app-theme--header': {
              backgroundColor: 'rgba(255, 7, 0, 0.55)',
            },
            '& .super-app-theme--cell' : {
              backgroundColor: 'rgba(255, 7, 0, 0.55)',
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={10} display="flex" alignItems="center">
              <ToggleButtonGroup
                color="primary"
                value={g04docuGCertificationDivision}
                exclusive
                onChange={handleChangeG04docuGCertificationDivision}
                aria-label="Platform"
                size="small"
              >
                {/* 규격구분 :  RAW_MATERIAL / SUB_MATERIAL_PROCESS / SUB_MATERIAL_BUILD / PROCESS */}
                <ToggleButton value={"ALL"} sx={{ fontWeight: 'bold' }}>{"전체"}</ToggleButton>
                <ToggleButton value={"RAW_MATERIAL"} sx={{ fontWeight: 'bold' }}>{"원자재"}</ToggleButton>
                <ToggleButton value={"SUB_MATERIAL_PROCESS"} sx={{ fontWeight: 'bold' }}>{"가공부자재"}</ToggleButton>
                <ToggleButton value={"SUB_MATERIAL_BUILD"} sx={{ fontWeight: 'bold' }}>{"시공부자재"}</ToggleButton>
                <ToggleButton value={"PROCESS"} sx={{ fontWeight: 'bold' }}>{"공정"}</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Add />}
                onClick={handleClickOpen}
              >
                {"등록하기"}
              </Button>
            </Grid>
          </Grid>
          <div style={{ height: GRID_HEIGHT.REDUCED, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              rowReordering={g04docuGCertificationDivision !== "ALL"}
              onRowOrderChange={handleRowOrderChange}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
              // onRowClick={(params, e) => e.detail === 2 && handleSelect({ type: 'edit', params })} // double click only
            />
          </div>
        </Box>
        {/* TODO : 하나의 ConfirmDialog 쓰기 위해 useState로 onConfirm 함수 설정 시도했으나 동작하지 않아 아래와 같이 두개 사용. 추후 재검토 필요 */}
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
        <GTestComparisonDialog g04docuGCertificationId={getValues("id")} />
        <GTestComparisonlDeleteConfirmDialog /> {/* 삭제 예/아니오 다이얼로그 */}
      </Container>
    </ThemeProvider>
  );
};

export default G04docuGCertificationManagement;
