import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Switch,
  Tooltip,
} from '@mui/material';
import  {
  Add,
  Delete,
  Edit,
  FileOpen,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Redo,
  Search,
} from '@mui/icons-material';
import {
  FormInputDate,
  FormInputText,
} from "../form";
import {
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import * as gclientActions from "../../store/gclient";
import * as gprojectActions from "../../store/gproject";
import * as gtypeActions from "../../store/gtype";
import * as gtypeDetailActions from "../../store/gtypeDetail";
import * as g04docuActions from "../../store/g04docu";
import * as g04docuGCertificationActions from "../../store/g04docuGCertification";
import GOrderManagement from "../GOrder/GOrderManagement";
import GProjectDialog from "./GProjectDialog";
import GProjectG04Dialog from "./GProjectG04Dialog";
import GProjectG04Dialog2 from "./GProjectG04Dialog2"; // TODO : 수정 전 이전 모듈
import GProjectG04GeneratorDialog from "./GProjectG04GeneratorDialog";
import GSupplyConfirmRequestDialog from "./GSupplyConfirmRequestDialog";
import { getGridHeight } from "../../constants/gridHeights";

const theme = createTheme();

const defaultValues = {
  searchName: "",
  searchSite: "",
  startDate: "",
  endDate: "",
  completionDate: "",
};

const GProjectManagement = () => {
  const [columnsProject, setColumnsProject] = useState([]);
  const [modify, setModify] = useState(false);
  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [openG04, setOpenG04] = useState(false);
  const [openG04Old, setOpenG04Old] = useState(false);
  const [openG04Generator, setOpenG04Generator] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  // const [checked, setChecked] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [selectedRowG04, setSelectedRowG04] = useState(undefined);
  const [selectedRowG04Gen, setSelectedRowG04Gen] = useState(undefined);
  // const [selectedGcomponentItems, setSelectedGcomponentItems] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [gprojectId, setGprojectId] = useState("");
  const [pageSize, setPageSize] = useState(100);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRowG04MaterialDeli, setSelectedRowG04MaterialDeli] = useState([]);
  const [openMaterailDeliReq, setOpenMaterailDeliReq] = useState(false);
  const [gsupplyConfirm, setGSupplyConfirm] = useState(false);
  
  // 현재 화면에 맞는 Grid 높이 계산
  const GRID_HEIGHT = getGridHeight('ONE_LINE_LAYER', -24); // 0px 추가 여백

  const handleSelect = async ({ type, params }) => {
    const { pathname } = location;
    const { id } = params;

    setOpenBackdrop(true);

    if (type === "edit" || type === "editOld") {
      setModify(true);
      setCrudMode('U');
      const selectedRow = await selectDirect(id); // 유리 설정 구성을 위해 데이터 결과를 redux를 통하지 않고 직접 지정
      if (pathname === "/projects") {
        setSelectedRow(selectedRow);
        setOpen(true);
      } else if (pathname === "/g04docuGenerateFile" ||
        pathname === "/g04docuProjects" ||
        pathname === "/gsupplyConfirmRequest") {
        setSelectedRowG04(selectedRow);
        // TODO : 아래 세줄은 임시주석
        // await selectByDivisionAndTypeWithItemsByQuery('SUB_MATERIAL_PROCESS', 'STANDARD');
        // await selectByDivisionAndTypeWithItemsByQuery('SUB_MATERIAL_BUILD', 'STANDARD');
        // await selectByDivisionAndTypeWithItemsByQuery('PROCESS', 'STANDARD'); // TODO : 임시주석
        await selectByDivisionWithItemsByQuery('SUB_MATERIAL_PROCESS');
        await selectByDivisionWithItemsByQuery('SUB_MATERIAL_BUILD');
        await selectByDivisionWithItemsByQuery('PROCESS');
        
        type === "edit" ? setOpenG04(true) : setOpenG04Old(true); // TODO : 임시
      }
    } else if (type === "delete") {
      setRemoveId(id);
      setParams(params);
      setConfirmOpen(true);
    } else if (type === "order") {
      setParams(params);
      setGprojectId(id);
      setDrawerOpen(true);
    } else if (type === "generate") {
      const selectedRowG04Gen = await selectDirect(id);
      setGSupplyConfirm(false);
      setSelectedRowG04Gen(selectedRowG04Gen);
      setOpenG04Generator(true);
    } else if (type === "request") {
      const selectedRowG04Gen = await selectDirect(id);
      setSelectedRowG04MaterialDeli(selectedRowG04Gen);
      setOpenMaterailDeliReq(true);
    } else if (type === "gsupplyConfirmGenerate") {
      const selectedRowG04Gen = await selectDirect(id);
      setGSupplyConfirm(true);
      setSelectedRowG04Gen(selectedRowG04Gen);
      setOpenG04Generator(true);

    }

    setOpenBackdrop(false);
  }

  // const handleSelectUpDown = async ({ type, id }) => {
  //   setOpenBackdrop(true);

  //   await reorder({ type, id });
  //   setLoaded(false);
  //   // await selectAll();
  //   const { pathname } = location;
  //   const { id: gclientId } = sessionUser;
  //   let docu04;
  //   if (pathname === "/projects") { // GOP에서 "프로젝트" 메뉴
  //     docu04 = false;
  //   } else if (pathname === "/g04docuGenerateFile" ||
  //     pathname === "/g04docuProjects" ||
  //     pathname === "/gsupplyConfirmRequest"
  //   ) { // 공사다큐에서 "자재승인서 발급" 메뉴
  //     docu04 = true;
  //   }

  //   if (sessionUser.type === 'ADMIN') { // 시스템 관리자
  //     await selectAllProjectsOr4docuProjects(docu04);
  //   } else { // 회원사 관리자나 회원사 사용자 모두 회원사 소속이면 데이터 검색이 가능 (같은 회원사의 사용자인 경우 A 사용자 자재승인서 발급 정보에 B 사용자가 접근이 가능)
  //     await selectMyProjectsOr4docuProjects(docu04, gclientId);
  //   }

  //   setTimeout(() => setLoaded(true), 300)

  //   setOpenBackdrop(false);
  // }

  const removeGProject = (removeId) => {
    setLoaded(false);

    dispatch(gprojectActions.remove(removeId))
    .then(async (res) => {
      refreshProjectsOr4docuProjects();
    })
    .catch(async (res) => {
      const data = await res.json();
      if (data && data.errors) setErrors(data.errors); // TODO : 삭제 에러 후 표시할 곳 마련하기
    });
  }

  const handleChangePeriod = (newValue, name) => {
    console.log({ name, newValue, today: new Date() });
    
    if (!newValue) {
      return true;
    }

    if (name === "startDate") {
      const endDate = getValues("endDate");
      if (endDate && newValue > endDate) {
        setValue("endDate", newValue);
        setEndDate(newValue);
      }
      setStartDate(newValue);
    } else if (name === "endDate") {
      const startDate = getValues("startDate");
      if (startDate && newValue < startDate) {
        setValue("startDate", newValue);
        setStartDate(newValue);
      }
      setEndDate(newValue);
    }
  
    setValue(name, newValue);
    return true;
  }

  const handleClickSearch = () => {
    refreshProjectsOr4docuProjects();
  }

  // 저장 다이얼로그
  const handleClickOpen = async () => {
    const { pathname } = location;

    setOpenBackdrop(true);
    setCrudMode('C');
    setModify(false);

    if (pathname === "/projects") {
      setSelectedRow(undefined);
      setOpen(true);
    } else if (pathname === "/g04docuGenerateFile" ||
      pathname === "/g04docuProjects" ||
      pathname === "/gsupplyConfirmRequest"
    ) {
      setSelectedRowG04(undefined);
      // TODO : 아래 세줄은 임시주석
      // await selectByDivisionAndTypeWithItemsByQuery('SUB_MATERIAL_PROCESS', 'STANDARD');
      // await selectByDivisionAndTypeWithItemsByQuery('SUB_MATERIAL_BUILD', 'STANDARD');
      // await selectByDivisionAndTypeWithItemsByQuery('PROCESS', 'STANDARD');
      await selectByDivisionWithItemsByQuery('SUB_MATERIAL_PROCESS');
      await selectByDivisionWithItemsByQuery('SUB_MATERIAL_BUILD');
      await selectByDivisionWithItemsByQuery('PROCESS');

      setOpenG04(true);
    }

    setOpenBackdrop(false);
  }

  // const handleChangeOrder = () => {
  //   setChecked(!checked);
  // }

  const handleChangeToggle = async ({ params, e }) => {
    e.stopPropagation();

    setOpenBackdrop(true);

    await modifyGProjectDoneYN({ id: params.id, doneYN: e.target.checked })
    
    setLoaded(false);
    await refreshProjectsOr4docuProjects();
    setTimeout(() => setLoaded(true), 300);

    setOpenBackdrop(false);
  }

  const generateActions = (params) => {
    const { pathname } = location;

    let arrActions = [
      <GridActionsCellItem
        icon={<Edit />}
        label={pathname === "/g04docuProjects" ? "수정" : "프로젝트 수정"}
        onClick={() => handleSelect({ type: 'edit', params })}
        showInMenu
      />,
      // <GridActionsCellItem
      //   icon={<Edit />}
      //   label={"수정(OLD)"}
      //   onClick={() => handleSelect({ type: 'editOld', params })}
      //   showInMenu
      // />,
      <GridActionsCellItem
        icon={<Delete />}
        label={pathname === "/g04docuProjects" ? "삭제" : "프로젝트 삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
      // <GridActionsCellItem
      //   icon={<Redo />}
      //   label={"발주"}
      //   onClick={() => handleSelect({ type: 'order', params })}
      //   showInMenu
      // />,
    ];

    if (pathname === "/projects") {
      arrActions = arrActions.concat([
        <GridActionsCellItem
          icon={<Redo />}
          label={"발주"}
          onClick={() => handleSelect({ type: 'order', params })}
          showInMenu
        />,
      ])
    } else if (pathname === "/g04docuGenerateFile") {
      arrActions = [
        <GridActionsCellItem
          icon={<FileOpen />}
          label={"자재승인서 생성"} 
          onClick={() => handleSelect({ type: 'generate', params })}
          showInMenu
        />,
        ...arrActions
      ];
    } else if (pathname === "/gsupplyConfirmRequest") {
      arrActions = [
        <GridActionsCellItem
          icon={<FileOpen />}
          label={"납품확인서 요청"} 
          onClick={() => handleSelect({ type: 'request', params })}
          showInMenu
        />,
        ...arrActions  // 기존 액션들을 뒤에 spread
      ];
    } else if (pathname === "/gsupplyConfirmGenerateFile") {
      arrActions = [
        <GridActionsCellItem
          icon={<FileOpen />}
          label={"납품확인서 생성"} 
          onClick={() => handleSelect({ type: 'gsupplyConfirmGenerate', params })}
          showInMenu
        />,
        ...arrActions
      ];
    }

    // if (checked) {
    //   arrActions = arrActions.concat([
    //       <GridActionsCellItem
    //         icon={<KeyboardDoubleArrowUp />}
    //         label={"맨위로"}
    //         onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
    //         disabled={params.row.orderDetail === 'F'}
    //       />,
    //       <GridActionsCellItem
    //         icon={<KeyboardArrowUp />}
    //         label={"위로"}
    //         onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
    //         disabled={params.row.orderDetail === 'F'}
    //       />,
    //       <GridActionsCellItem
    //         icon={<KeyboardArrowDown />}
    //         label={"아래로"}
    //         onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
    //         disabled={params.row.orderDetail === 'L'}
    //       />,
    //       <GridActionsCellItem
    //         icon={<KeyboardDoubleArrowDown />}
    //         label={"맨아래로"}
    //         onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
    //         disabled={params.row.orderDetail === 'L'}
    //       />
    //     ]
    //   );
    // }

    return arrActions;
  }

  const columns = [
    // {
    //   field: 'name',
    //   headerName: '이름',
    //   width: 200,
    //   // editable: true,
    // },
    // {
    //   field: 'code',
    //   headerName: '코드',
    //   width: 100,
    //   // editable: true,
    // },
    {
      field: 'site',
      headerName: '현장명',
      width: 250,
      // editable: true,
    },
    {
      field: 'siteAddress',
      headerName: '현장주소',
      width: 400,
      // editable: true,
    },
    {
      field: 'constructionCompanyName',
      headerName: '건설사명',
      width: 160,
    },
    // {
    //   field: 'period',
    //   headerName: '기간',
    //   width: 200,
    //   valueGetter: (params) => {
    //     // dateFormat(params.value)
    //     let period = "";
    //     params.row.period.forEach((date, idx) => {
    //       idx === 0 ? period += dateFormat(date, 'yyyy-MM-dd') : period += " ~ " + dateFormat(date, 'yyyy-MM-dd')
    //     })
        
    //     return period;
    //   },
    // },
    // TODO: 날짜의 경우 GMT+9 고려할 것
    {
      field: 'startDate',
      headerName: '시작',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'endDate',
      headerName: '마감',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'completionDate',
      headerName: '준공일',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value && dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'doneYN',
      headerName: '마감여부',
      headerAlign: 'center',
      align: 'center',
      width: 120,
      renderCell: (params) => {
        let doneYN = params.row.doneYN;
        return (
          <Switch
            // defaultChecked를 사용하면 목록에서의 변경이 아닌 수정다이얼로그에서 변경 후 목록 갱신시 안됨
            // 반대로 checked를 사용하면 실제 switch 클릭시 변경이 일어나지 않고 재검색 후 일어남(그러나 마치 switch 누른 후 변경된 것처럼 보임)
            // TODO : 추후 useState([]) 사용하는 방법 강구
            // defaultChecked={doneYN}
            checked={doneYN}
            onChange={(e) => handleChangeToggle({ params, e })}
          />
        )
      },
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 400,
      // editable: true,
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"수정/삭제/생성"} followCursor><Box>{"기능"}</Box></Tooltip>,
      // width: checked ? 200 : 70,
      width: 70,
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];

  const columnsAdmin = [{
    field: 'owner',
    headerName: '공급업체',
    width: 200,
    valueGetter: (params) => params.row?.owner?.name,
  }].concat(columns);

  const dispatch = useDispatch();
  const location = useLocation();

  const sessionUser = useSelector(state => state.session.sessionUser);
  const rows = useSelector((state) => state.gproject.gprojects);
  
  // 유리 등록 및 수정관련
  const gtypes = useSelector((state) => state.gtype.gtypes);
  // const gtypeDetailsWithGComponent = useSelector((state) => state.gtypeDetail.gtypeDetailsWithGComponent);
  const g04docusSubMaterialProcess = useSelector((state) => state.g04docu.g04docusSubMaterialProcess);
  const g04docusSubMaterialBuild = useSelector((state) => state.g04docu.g04docusSubMaterialBuild);
  const g04docusProcess = useSelector((state) => state.g04docu.g04docusProcess);
  
  const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues });

  // 데이터 관리
  const selectAllGClients = (valid) => dispatch(gclientActions.selectAll(valid)) // TODO : 회원사 정보 조회를 다이얼로그가 나타날 때 해야 할 것으로 보임
  const selectAll = () => dispatch(gprojectActions.selectAll())
  const selectAllProjectsOr4docuProjects = (docu04, searchName, searchSite, startDate, endDate, completionDate) => dispatch(gprojectActions.selectAllProjectsOr4docuProjects(docu04, searchName, searchSite, startDate, endDate, completionDate))
  const selectMyProjectsOr4docuProjects = (docu04, gclientId, searchName, searchSite, startDate, endDate, completionDate) => dispatch(gprojectActions.selectProjectsOr4docuProjects(docu04, gclientId, searchName, searchSite, startDate, endDate, completionDate))
  const selectDirect = (id) => gprojectActions.selectDirect(id)
  const modifyGProjectDoneYN = ({ id, doneYN }) => dispatch(gprojectActions.modifyDoneYN({ id, doneYN }))
  const reorder = ({ type, id }) => gprojectActions.reorder({ type, id })

  // 유리 등록 및 수정관련
  const selectAllGTypes = () => dispatch(gtypeActions.selectAll())
  // const selectByDivisionAndTypeWithItemsByQuery = (division, type) => dispatch(g04docuActions.selectByDivisionAndTypeWithItemsByQuery(division, type))
  const selectByDivisionWithItemsByQuery = (division) => dispatch(g04docuGCertificationActions.selectByDivisionWithItemsByQuery(division))
  // const selectGTypeDetailsWithGComponent = (gtypeId) => dispatch(gtypeDetailActions.selectGTypeDetailsWithGComponent(gtypeId))

  // 한 화면을 두 개의 router에서 사용하므로 useEffect([location])으로 변경. 아래처럼 useEffect([dispatch])로 하면 연달아 router 실행시 본 이벤트는 발생하지 않음
  // useEffect(
  //   async () => {
  //     const { pathname } = location;
  //     if (pathname === "/projects") {
  //       await selectAllProjectsOr4docuProjects(false);
  //     } else if (pathname === "/g04docuGenerateFile" ||
  //       pathname === "/g04docuProjects" ||
  //       pathname === "/gsupplyConfirmRequest"
  //     ) {
  //       await selectAllProjectsOr4docuProjects(true);
  //     }
      
  //     await selectAllGTypes();

  //     await hideWatermark();
  //     // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
  //     setShow(true);
  //     setTimeout(() => setLoaded(true), 300);
  //   }, [dispatch]
  // );

  const refreshProjectsOr4docuProjects = async () => {
    const searchName = getValues("searchName");
    const searchSite = getValues("searchSite");
    let startDate = getValues("startDate");
    let endDate = getValues("endDate");
    let completionDate = getValues("completionDate");
    startDate = startDate ? dateFormat(startDate.getDateWithStartHours()) : null;
    endDate = endDate ? dateFormat(endDate.getDateWithEndHours()) : null;
    completionDate = completionDate ? dateFormat(completionDate.getDateWithEndHours()) : null;

    console.log({ startDate, endDate, completionDate });

    setLoaded(false);

    const { pathname } = location;
    const { id: gclientId, type } = sessionUser;
    let docu04;
    if (pathname === "/projects") { // GOP에서 "프로젝트" 메뉴
      docu04 = false;
    } else if (pathname === "/g04docuGenerateFile" ||
      pathname === "/g04docuProjects" ||
      pathname === "/gsupplyConfirmRequest" ||
      pathname === "/gsupplyConfirmGenerateFile"
    ) {
      docu04 = true;
    }

    if (type === 'ADMIN') { // 시스템 관리자
      setColumnsProject(columnsAdmin);
      await selectAllProjectsOr4docuProjects(docu04, searchName, searchSite, startDate, endDate, completionDate);
    } else { // 회원사 관리자나 회원사 사용자 모두 회원사 소속이면 데이터 검색이 가능 (같은 회원사의 사용자인 경우 A 사용자 자재승인서 발급 정보에 B 사용자가 접근이 가능)
      setColumnsProject(columns);
      await selectMyProjectsOr4docuProjects(docu04, gclientId, searchName, searchSite, startDate, endDate, completionDate);
    }
    
    setLoaded(true);
  }

  useEffect(
    async () => {
      // setOpenBackdrop(true);

      await refreshProjectsOr4docuProjects();
      await selectAllGTypes();

      // setOpenBackdrop(false);

      // await selectAllGClients(); // 중간에 검색중일 때 GProjectG04GeneratorDialog에서 사용 중 오류 발생하여 아래로 옮김
    }, [location]
  );

  useEffect(() => {
    const fetchGClients = async () => {
      // 회원사 정보가 많으므로 여기서 한번만 조회하고 다이얼로그에서는 조회하지 
      // TODO : 필요한 필드만 조회한다. 필드 중 json이 있는 경우도 사용하는 속성만 조회
      await selectAllGClients(true);
    };
    fetchGClients();
  }, []);

  // useEffect(
  //   () => {
  //     alert("111")
  //     console.log(gtypeDetailsWithGComponent)
  //     // 선택 속성 데이터 구조 마련
  //     const selected = gtypeDetailsWithGComponent.map(gtypeDetails => {
  //       return gtypeDetails.map(gcomponent => {
  //         return {
  //           id: gcomponent.id,
  //           name: gcomponent.name,
  //           value: {},
  //         }
  //       });
  //     });
  //     setSelectedGcomponentItems(selected);
  //   }, [gtypeDetailsWithGComponent]
  // )

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // console.log({ ...state, [anchor]: open });
    setDrawerOpen(open);
  };
  
  var timer;
  const handleChangeSearch = async (e) => {
    // // 과도한 API 호출 방지를 위한 debouncing 코드. TODO : 라이브러리 활용 검토
    if (timer) {
      clearTimeout(timer);
    }
    
    // 타이머 설정
    timer = setTimeout(async () => {
      refreshProjectsOr4docuProjects();
    }, 500);
  };

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        {/* TODO : 현재 GOP(ERP)는 사용하지 않으므로 주석처리함 */}
        {/* <GProjectDialog
          modify={modify}
          setModify={setModify}
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
          // setSelectedRow={setSelectedRow}
          // gtypeDetailsWithGComponent={gtypeDetailsWithGComponent}
          gtypes={gtypes}
          refresh={selectAllProjectsOr4docuProjects}
          // selectedGcomponentItems={selectedGcomponentItems}
          // setSelectedGcomponentItems={setSelectedGcomponentItems}
        /> */}
        <GProjectG04Dialog
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          modify={modify}
          setModify={setModify}
          open={openG04}
          setOpen={setOpenG04}
          selectedRow={selectedRowG04}
          setSelectedRow={setSelectedRowG04}
          // gtypeDetailsWithGComponent={gtypeDetailsWithGComponent}
          gtypes={gtypes}
          // TODO : type === USER일 경우는 우선 null을 넘김
          // refresh={sessionUser.type === 'ADMIN' ? selectAllProjectsOr4docuProjects : selectMyProjectsOr4docuProjects/*(sessionUser.type === 'GCLIENT' ? selectMyProjectsOr4docuProjects: null)*/}
          refresh={refreshProjectsOr4docuProjects}
          setSearchValue={setValue}
          // selectedGcomponentItems={selectedGcomponentItems}
          // setSelectedGcomponentItems={setSelectedGcomponentItems}
        />
        {/* <GProjectG04Dialog2
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          modify={modify}
          setModify={setModify}
          open={openG04Old}
          setOpen={setOpenG04Old}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          // gtypeDetailsWithGComponent={gtypeDetailsWithGComponent}
          gtypes={gtypes}
          refresh={selectAllProjectsOr4docuProjects}
          // selectedGcomponentItems={selectedGcomponentItems}
          // setSelectedGcomponentItems={setSelectedGcomponentItems}
        /> */}
        <GProjectG04GeneratorDialog
          gsupplyConfirm={gsupplyConfirm}
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          open={openG04Generator}
          setOpen={setOpenG04Generator}
          selectedRow={selectedRowG04Gen}
        />
        <GSupplyConfirmRequestDialog
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          open={openMaterailDeliReq}
          setOpen={setOpenMaterailDeliReq}
          selectedRow={selectedRowG04MaterialDeli}
          setSelectedRow={setSelectedRowG04MaterialDeli}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={8}>
              <Stack direction="row" spacing={1}>
                {
                  sessionUser.type === 'ADMIN' && (
                    <FormInputText
                      name={"searchName"}
                      control={control}
                      label={"공급업체 또는 건설사명으로 검색하세요."}
                      onCustomChange={handleChangeSearch}
                      sx={{
                        width: 408,
                        '& .MuiInputBase-root': {
                          background: "#E3EEFA"
                        },
                        '& .MuiFormLabel-root' : {
                          color: '#2196f3',
                          fontSize: '0.85rem',
                        },
                        input: { color: '#2196f3', fontSize: '0.85rem' }
                      }}
                    />
                  )
                }
                <FormInputText
                  name={"searchSite"}
                  control={control}
                  label={"현장명 또는 현장주소로 검색하세요."}
                  onCustomChange={handleChangeSearch}
                  sx={{
                    width: 408,
                    '& .MuiInputBase-root': {
                      background: "#E3EEFA"
                    },
                    '& .MuiFormLabel-root' : {
                      color: '#2196f3',
                      fontSize: '0.85rem',
                    },
                    input: { color: '#2196f3', fontSize: '0.85rem' }
                  }}
                />
                <FormInputDate
                  name="startDate"
                  control={control}
                  label={"시작"}
                  onChangePeriodValue={handleChangePeriod}
                  // value={getValues("startDate")}
                  value={startDate}
                  getValues={getValues}
                  setValue={setValue}
                  inputHeight={37}
                  fontSize={14}
                  clearable={true}
                  customWidth={250} // TODO : FormInputDate 내부의 FormControl의 sx 속성에 width를 반영했는데 아래처럼 sx 속성을 통해 할 수 있는 방안이 있는지 추후 검토 필요
                  iconSize={"small"}
                  // sx={{ width: { /*xs: 330, */sm: 500 } }}
                />
                <FormInputDate
                  name="endDate"
                  control={control}
                  label={"마감"}
                  onChangePeriodValue={handleChangePeriod}
                  // value={getValues("endDate")}
                  value={endDate}
                  getValues={getValues}
                  setValue={setValue}
                  inputHeight={37}
                  fontSize={14}
                  clearable={true}
                  customWidth={250}
                  iconSize={"small"}
                />
                <FormInputDate
                  name="completionDate"
                  control={control}
                  label={"준공일"}
                  onChangePeriodValue={handleChangePeriod}
                  // value={getValues("completionDate")}
                  setValue={setValue}
                  inputHeight={37}
                  clearable={true}
                  fontSize={14}
                  customWidth={250}
                  iconSize={"small"}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ mr: 1 }}
                startIcon={<Search />}
                onClick={handleClickSearch}
              >
                {"검색"}
              </Button>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={handleClickOpen}
              >
                {
                  location.pathname === "/g04docuProjects" ? "생성하기" : "프로젝트 생성하기"
                }
              </Button>
            </Grid>
          </Grid>
          {/* <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={checked}
                    onChange={handleChangeOrder}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={"순서"}
              />
            </Grid>
          </Grid> */}
          <div style={{ height: GRID_HEIGHT.REDUCED, width: '100%' }}>
            {/* TODO : Skeleton 처리를 하는게 보기 좋은지 아닌지 의견 수렴 필요 */}
            {/* <Skeleton variant="rounded" height={show ? 0 : 800}/> */}
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{
                cursor: 'pointer',
                fontSize: '0.85em',
              }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={rows}
              columns={columnsProject}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
            />
          </div>
        </Box>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={removeGProject}
          onCancel={() => {}}
        >
          <div>
            {"현장명 "}<span style={{ color: "#1976d2" }}>{`${params && params.row && params.row.site  || ""}`}</span>{`을(를) 삭제하시겠습니까?`}<br /><br />
            <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
              { `아이디 : ${params && params.id  || ""}` }<br />
              { `현장주소 : ${params && params.row && params.row.siteAddress  || ""}` }<br />
              { `건설사명 : ${params && params.row && params.row.constructionCompanyName  || ""}` }<br />
              { `준공일 : ${params && params.row && params.row.completionDate && dateFormat(params.row.completionDate, 'yyyy-MM-dd')  || ""}` }
            </Box>
          </div>
        </ConfirmDialog>
      </Container>
      <Drawer
        anchor={"bottom"} // TODO : 추후 사용자가 환경설정에서 위치 설정하면 전체 반영하는 방법 강구
        open={drawerOpen}
        PaperProps={{
          sx: { width: "100%" },
        }}
        onClose={toggleDrawer(false)}
      >
        <Grid display="flex" justifyContent={"center"} alignItems="center" sx={{ backgroundColor: "#f3f3f3" }}>
          <Tooltip title={"닫기"}>
            <IconButton aria-label="close drawer" size="small" component="span" onClick={toggleDrawer(false)}>
              <KeyboardArrowDown />
            </IconButton>
          </Tooltip>
        </Grid>
        {/* TODO : 아래 props를 하나로 넘겼을 때랑 아래처럼 따로 각각 넘겼을 때(setState를 각각 하고 있으므로) render 횟수 체크해 볼 것 */}
        <GOrderManagement
          title={`발주 ( 프로젝트 : ${params?.row?.site} )`}
          // gprojectId={gprojectId}
          from={
            {
              source: "GProjectManagement",
              params: {
                gprojectId,
              }
            }
          }
          // statusWorkOrder={statusWorkOrder}
          // originRefresh={() => selectAllByStatusReceptionByQuery(['PRE-RECEIVE', 'RECEIVE', 'RETURN'])}
        />
      </Drawer>
    </ThemeProvider>
  );
};

export default GProjectManagement;
