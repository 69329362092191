import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,  
  Switch,
  TextField,
  ToggleButtonGroup, 
  ToggleButton,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  FormInputDate,
  FormInputText,
} from "../form";
import  {
  Add,
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
  Search,
  Settings,
  Preview,
} from '@mui/icons-material';
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import 'dayjs/locale/ko';  // 한국어 로케일 추가
import * as gclientActions from "../../store/gclient";
import GClientDocumentDialog from "./GClientDocumentDialog";
import { getGridHeight } from "../../constants/gridHeights";
import debounce from 'lodash/debounce';

const theme = createTheme();

const defaultValues = {
  status: "DEFAULT",
  gclientName: "",
};

const GClientDocument = ({ title }) => {

  const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues });

  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [params, setParams] = useState({});
  const [pageSize, setPageSize] = useState(100);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openDocumentDialog, setOpenDocumentDialog] = useState(false);
  const searchInputRef = useRef("");

  // 현재 화면에 맞는 Grid 높이 계산
  const GRID_HEIGHT = getGridHeight('ONE_LINE_LAYER', 0); // 0px 추가 여백

  useEffect(() => {
    handleClickSearch();
  }, []);

  const handleSelect = async ({ type, params }) => {
    const { id } = params;

    setOpenBackdrop(true);

    if (type === 'detail') {
      setSelectedRow(params);  // 선택된 행 데이터를 상태로 저장
      setOpenDocumentDialog(true);
    }

    setOpenBackdrop(false);
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
    ];

    return arrActions;
  }


  const columns = [
    {
      field: 'gclientName',
      headerName: '거래선명',
      width: 220,
    },
    
    {
      field: 'bizRegNumber',
      headerName: '사업자등록번호',
      width: 160,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'ceo',
      headerName: '대표자',
      width: 120,
    },
    {
      field: 'phone',
      headerName: '연락처',
      width: 120,
    },
    {
      field: 'ntaxCnt',
      headerName: '국세납입증명서',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        console.log("params:", params);
        return params.value === 0 || params.value === null || params.value === undefined ? '없음' : params.value;
      }
    },
    {
      field: 'ltaxCnt',
      headerName: '지방세납입증명서',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        return params.value === 0 || params.value === null || params.value === undefined ? '없음' : params.value;
      }
    },
    {
      field: 'certCnt',
      headerName: '인증서',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        return params.value === 0 || params.value === null || params.value === undefined ? '없음' : params.value;
      }
    },
    {
      field: 'testCnt',
      headerName: '성적서',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        return params.value === 0 || params.value === null || params.value === undefined ? '없음' : params.value;
      }
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      type: 'actions',
      getActions: (params) => generateActions(params),  
    },
  ];

  // debounce된 검색 함수 생성
  const debouncedSearch = useCallback(
    debounce((searchName) => {
      handleClickSearch();
    }, 500),  // 500ms 딜레이
    [] // 컴포넌트가 마운트될 때만 생성
  );

  const handleChangeSearchName = useCallback((e) => {
    const searchName = e.target.value;
    setValue('gclientName', searchName);
    debouncedSearch(searchName);
  }, [debouncedSearch]);

  // 컴포넌트 언마운트 시 debounce 취소
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // 검색 버튼 클릭 시 호출
  const handleClickSearch = async () => {
    const gclientName = getValues('gclientName');
    const status = getValues('status');

    console.log("gclientName:", gclientName, "; status:", status);

    const newParams = {};
    if (gclientName) newParams.gclientName = gclientName;
    if (status) newParams.status = status;

    setLoaded(false);
    try {
      const response = await gclientActions.selectDocuments(newParams);
      setRows(response);
      setLoaded(true);
    } catch (error) {
      console.error('문서 검색 중 오류가 발생했습니다:', error);
      setErrors(error);
      setLoaded(true);
    }
  }

  // 문서상태 변경
  const handleChangeStatus = (e) => {
    const { value } = e.target;
    setValue('status', value);
    handleClickSearch();
  }
  

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <GClientDocumentDialog
          open={openDocumentDialog}
          setOpen={setOpenDocumentDialog}
          params={selectedRow}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={2}>
                <FormInputText
                  select
                  name="status"
                  control={control}
                  label={"문서상태"}
                  options={
                    [
                      // { label: '전체', value: '' },
                      // { label: '미등록', value: 'UNREG' },
                      { label: '만료일 (1주전)', value: 'ONEWEEK' },
                      { label: '만료일 (경과)', value: 'DEFAULT' },
                    ]
                  }
                  onChange={handleChangeStatus}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormInputText
                  name="gclientName"
                  control={control}
                  label={"거래선명으로 검색하세요."}
                  onCustomChange={handleChangeSearchName}
                  inputRef={searchInputRef}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={7} container justifyContent="flex-end">
                <Button
                  variant="contained"
                  startIcon={<Search />}
                  onClick={handleClickSearch}
                  sx={{ ml: 5, mr: 1 }}
                >
                  {"검색"}
                </Button>
              </Grid>
          </Grid>
          <div style={{ height: GRID_HEIGHT.FULL, width: '100%', marginTop: 10 }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{
                cursor: 'pointer',
                fontSize: '0.85em',
              }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              getRowId={(row) => row.id || row.gclientId}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              onRowDoubleClick={(params) => handleSelect({ type: 'detail', params })}
            />
          </div>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default GClientDocument;
