// 아이디(id) 설정 안되면 mouse over 안됨

const menus = [
  {
    id: "dashboard",
    title: "대시보드",
    href:"/glass/dashboard"
  },
  {
    href: "/profile",
    id: "profile",
    title: "내 정보",
  },
  {
    id: "g04docu_projects",
    title: "프로젝트",
    href: "/g04docuProjects",
  },
  {
    id: "material_approvement",
    title: "자재승인서",
    submenus: [
      {
        id: "g04docu_generate_file",
        title: "생성하기",
        form_title: "자재승인서 생성하기",
        href: "/g04docuGenerateFile",
      },
      {
        id: "g04docu_material_approval",
        title: "생성목록",
        form_title: "자재승인서 생성목록",
        href: "/g04docuMaterialApproval",
      },
    ],
  },
  {
    id: "gsupply_confirm",
    title: "납품확인서",
    submenus: [
      {
        id: "gsupply_confirm_request_publish",
        title: "요청/발행",
        submenus: [
          {
            id: "gsupply_confirm_request",
            title: "요청하기",
            form_title: "납품확인서 요청하기",
            href: "/gsupplyConfirmRequest",
          },
          {
            id: "gsupply_confirm_request_list",
            title: "요청목록",
            form_title: "납품확인서 요청목록",
            href: "/gsupplyConfirmRequestList",
          },
          {
            id: "gsupply_confirm_publish",
            title: "발행하기",
            form_title: "납품확인서 발행하기",
            href: "/gsupplyConfirmPublish",
          },
          {
            id: "gsupply_confirm_publish_list",
            title: "발행목록",
            form_title: "납품확인서 발행목록",
            href: "/gsupplyConfirmPublishList",
          }
        ]
      },
      {
        id: "gsupply_confirm_generate_file",
        title: "생성하기",
        form_title: "납품확인서 생성하기",
        href: "/gsupplyConfirmGenerateFile",
      },
      {
        id: "gsupply_confirm_generate_file_list",
        title: "생성목록",
        form_title: "납품확인서 생성목록",
        href: "/gsupplyConfirmGenerateFileList",
      },
    ],
  },
  {
    id: "g04docu_projects",
    title: "첨부서류",
    submenus: [
      {
        id: "g04docu_files",
        title: "첨부",
        href: "/g04docuFiles",
      },
      {
        id: "g04docu_search",
        title: "메일전송",
        href: "/g04docuSearch",
      },
      {
        id: "g04docu_alarm_settings",
        title: "알람설정",
        href: "/g04docuAlarmSettings",
      },
    ],
  },
  {
    id: "histories",
    title: "이력관리",
    submenus: [
      {
        id: "gsend_g04docus",
        title: "전송목록",
        href: "/gsendG04docus",
      },
    ],
  },
  {
    id: "publish",
    title: "기술문서",
    submenus: [
      // {
      //   id: "gdomestic_auths",
      //   title: "국내산인증서",
      //   form_title: "국내산인증서 발급",
      //   href: "/gdomesticAuths",
      // },
      {
        id: "gperformance_datas",
        title: "성능확인서",
        form_title: "성능확인서 발급",
        href: "/gperformanceDatas",
      },
      // {
      //   id: "structureReviews",
      //   title: "내풍압 구조검토서",
      //   href: "/structureReviews",
      // },
    ]
  },
  {
    id: "settings",
    title: "기준정보",
    permissions: ["ADMIN"],
    submenus: [
      {
        id: "construction_work_type",
        title: "공종",
        href: "/constructionWorkType",
      },
      {
        id: "products",
        title: "가공유리제품",
        submenus: [
          {
            id: "materials",
            title: "구성자재",
            href: "/gcomponentItem?searchItemType=materials",
          },
          {
            id: "preferences",
            title: "자재속성",
            href: "/gcomponentItem?searchItemType=preferences",
          },
          {
            id: "component",
            title: "전체면구조",
            href: "/gcomponent",
          },
          {
            id: "gtemplate",
            title: "단면조합",
            href: "/gtype",
          },
          {
            id: "glasses_management",
            title: "품종",
            href: "/glasses",
          },
          {
            id: "gdomestic_auths",
            title: "국내산 판별기준",
            href: "/gdomesticAuthCriteria",
          },
        ],
      },
      {
        id: "g04docu",
        title: "자재승인",
        submenus: [
          {
            id: "gstandard_types",
            title: "표준 구분",
            href: "/gstandardTypes",
          },
          {
            id: "gstandards",
            title: "표준", // 표준㉿
            href: "/gstandards",
          },
          {
            id: "g04docu_g_certifications",
            title: "인증규격",
            href: "/g04docuGCertifications",
          },
          {
            id: "g04_general_docus",
            title: "일반서류 종류",
            href: "/g04GeneralDocus",
          },
          // {
          //   id: "g04docuGCertificationByGItemsOrGlasses",
          //   title: "인증부여 (종류·등급·호칭 또는 모델)",
          //   href: "/g04docuGCertificationByGItemsOrGlasses",
          // }
        ],
      },
      {
        id: "gclient",
        title: "거래선",
        submenus: [
          {
            id: "type",
            title: "구분",
            submenus: [
              {
                id: "gclient_type",
                title: "대분류",
                href: "/gclientTypes",
              },
              {
                id: "gclient_type_04docu",
                title: "세분류",
                href: "/gclientType04docus",
              },
            ]
          },
          {
            id: "gclients",
            title: "거래선",
            href: "/gclients",
          },
          {
            id: "securities_by_g_client",
            title: "거래선별 문서보안",
            href: "/securitiesByGClient",
          },
          {
            id: "gclient_g04docus",
            title: "문서현황",
            href: "/gclientG04docus",
          },
          {
            id: "gclient_mail_test",
            title: "메일테스트",
            href: "/gclientMailTest",
          },
        ]
      },
    ]
  },
  {
    id: "gclient_management",
    title: "회원사관리",
    permissions: ["ADMIN", "KFGWA"],
    submenus: [
      {
        id: "gclient_status",
        title: "가입현황",
        href: "/association/gclients",
      },
      {
        id: "gclient_monthly_settlement",
        title: "월정산",
        href: "/association/settlements",
      },
    ]
  }
];

// 사용자의 권한에 따라 메뉴를 필터링하는 함수
const filterMenusByPermission = (menus, userPermission) => {
  return menus.reduce((acc, menu) => {
    // 1. 상위 메뉴 권한 체크
    if (menu.permissions && !menu.permissions.includes(userPermission)) {
      return acc;
    }

    // 2. 메뉴 객체 생성 (하위 메뉴 처리 전)
    const filteredMenu = { ...menu };

    // 3. 하위 메뉴 처리
    if (menu.submenus) {
      // 하위 메뉴 필터링
      const filteredSubmenus = menu.submenus.reduce((subAcc, submenu) => {
        // 하위 메뉴에 권한이 있고, 해당 권한이 없는 경우 제외
        if (submenu.permissions && !submenu.permissions.includes(userPermission)) {
          return subAcc;
        }

        // 더 깊은 하위 메뉴가 있는 경우 재귀적으로 처리
        if (submenu.submenus) {
          const deepFilteredSubmenus = filterMenusByPermission([submenu], userPermission);
          if (deepFilteredSubmenus.length > 0) {
            subAcc.push(deepFilteredSubmenus[0]);
          }
          return subAcc;
        }

        // 권한이 있거나 권한 설정이 없는 경우 포함
        subAcc.push(submenu);
        return subAcc;
      }, []);

      // 필터링된 하위 메뉴 설정 (빈 배열이어도 설정)
      filteredMenu.submenus = filteredSubmenus;
    }

    // 4. 메뉴 추가 (하위 메뉴 유무와 관계없이)
    acc.push(filteredMenu);
    return acc;
  }, []);
};

// 사용 예시
const adminMenus = filterMenusByPermission(menus, "ADMIN");
const gclientMenus = filterMenusByPermission(menus, "GCLIENT");
const kfgwaMenus = filterMenusByPermission(menus, "KFGWA");
const userMenus = filterMenusByPermission(menus, "USER");

console.log({ adminMenus, gclientMenus, kfgwaMenus, userMenus });

export {
  adminMenus,
  gclientMenus,
  kfgwaMenus,
  userMenus,
}