import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Stack,
  Switch,
  Tooltip,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, HelpOutline as HelpOutlineIcon, Help } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormInputText, FormInputSwitch } from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  AlertDialog,
} from "../dialog";
import { mode } from "../../config";
import * as gclientActions from '../../store/gclient';
import * as addressBookActions from '../../store/addressBook';
import { fields } from '../../utils/validationSchemas';
import { formatMobileNumber } from '../../utils/formatters';

const defaultValues = {
  seq: 0,
  name: "",
  email: "",
  phone: "",
  company: "",
  duties: "",
  publicYN: false
};

const addressBookSchema = yup.object({
  name: fields.name,
  email: fields.email,
  phone: yup.string()
    .transform((value) => (value ? value.replace(/-/g, '') : ''))
    .test('format', '모바일은 숫자만 입력해주세요.', (value) => {
      //if (!value) return true;  // 빈 값이면 통과
      return /^[0-9]+$/.test(value);
    })
    .test('length', '올바른 전화번호 형식이 아닙니다.', (value) => {
      //if (!value) return true;  // 빈 값이면 통과
      return value.length >= 9 && value.length <= 11;
    })
}).required();

const AddressBookDialogRegister = ({ 
  crudMode,
  setCrudMode,
  open, 
  setOpen, 
  selectedRow,
  onSuccess,
}) => {

  const [searchParams] = useSearchParams();
  const queryStrings = Object.fromEntries([...searchParams]);

  const { handleSubmit, reset, control, getValues, setValue, formState: { errors } } = useForm({ 
    resolver: yupResolver(addressBookSchema),
    defaultValues: defaultValues 
  });
  const [loaded, setLoaded] = useState(true);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [alertInfo, setAlertInfo] = useState({ open: false, titleAlert: '오류', messageAlert: '' });
  const [publicYN, setPublicYN] = useState(false);
  const [title, setTitle] = useState("주소록");

  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
    }
  }, [open, dispatch]);

  const handleClose = () => {
    setOpen(false);
    reset(defaultValues);
    setCrudMode('');
  };

  useEffect(() => {
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          if (item === 'phone') {
            // phone 필드인 경우 모바일 포맷 적용
            setValue(item, selectedRow[item] ? formatMobileNumber(selectedRow[item]) : value);
          } else {
            setValue(item, selectedRow[item] || value);
          }
        }
        // publicYN 상태 동기화
        setPublicYN(selectedRow.publicYN || false);
      } else {
        // 새로운 등록인 경우 초기값으로 리셋
        setPublicYN(false);
      }
    }, [selectedRow, setValue]
  );

  useEffect(() => {
    const errorMessages = Object.values(errors).map(error => error.message);
    if (errorMessages.length > 0) {
      setAlertInfo({
        open: true,
        titleAlert: '필수사항 입력오류',
        messageAlert: (
          <span>
            {errorMessages.map((message, index) => (
              <React.Fragment key={index}>
                <span style={{ paddingRight: '50px' }}>{message}</span>
                {index < errorMessages.length - 1 && <br />}
              </React.Fragment>
            ))}
          </span>
        )
      });
    }
  }, [errors]);

  const onSubmit = async (data) => {
    //console.log('onSubmit => ', data);
    setLoaded(false);
    try {
      let response;
      if (crudMode === 'C') { 
        response = await dispatch(addressBookActions.create(data));
      } else if (crudMode === 'U') {
        response = await dispatch(addressBookActions.modify(data));
      }

      if (response.ok) {
        handleClose();
        if (onSuccess) {
          onSuccess();
        }
      } else {
        //setErrors(errorData.errors || ['주소록 추가에 실패했습니다.']);
      }
    } catch (error) {
      console.error('주소록 저장 실패:', error);
      const message = error.serverResponse?.message || '주소록 저장에 실패했습니다.';
      setAlertInfo({
        open: true,
        titleAlert: '등록 오류',
        messageAlert: message
      });
    } finally {
      setLoaded(true);
    }
  };

  const handleChangePublicYN = (event) => {
    const newValue = event.target.checked;
    setPublicYN(newValue);
    setValue('publicYN', newValue);
  };

  return (
    <>
      <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          fullWidth
          maxWidth="sm"
        >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleClose}
          style={{ cursor: 'move' }}
        >
          { crudMode === 'C' && `${title} 등록` }
          { crudMode === 'R' && `${title} 상세` }
          { crudMode === 'U' && `${title} 수정` }
        </DialogTitleClose>
        <DialogContent dividers>
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormInputText required control={control} name={"name"} label={"이름"} 
                  errorStyle={{border: true }}
                  inputProps={{ readOnly: crudMode === 'R' }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText required control={control} name={"email"} label={"이메일"} 
                  errorStyle={{border: true }}
                  inputProps={{ readOnly: crudMode === 'R' }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText required control={control} name={"phone"} label={"모바일"} 
                  errorStyle={{border: true }}
                  placeholder={"모바일 번호를 입력해주세요. - 는 자동입력됩니다."}
                  mobileFormat 
                  inputProps={{ readOnly: crudMode === 'R' }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText control={control} name={"company"} label={"거래선명"} 
                  inputProps={{ readOnly: crudMode === 'R' }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText control={control} name={"duties"} label={"직책"} 
                  inputProps={{ readOnly: crudMode === 'R' }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset" fullWidth>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <FormLabel component="legend">공유여부</FormLabel>
                    <Tooltip arrow placement="top-start"
                      title="공유로 설정하면 회사내 모든 사용자가 이 연락처를 볼 수 있습니다." 
                    >
                      <Help fontSize="small" color="action" sx={{ ml: 1 }} />
                    </Tooltip>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={publicYN}
                          onChange={handleChangePublicYN}
                          disabled={crudMode === 'R'}
                        />
                      }
                      label={publicYN ? "공유" : "비공유"}
                    />
                  </Stack>
                </FormControl>
                
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
        {
            crudMode === 'C' && (<>
              <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
              <Button onClick={() => reset()}>{"초기화"}</Button>
            </>)
          }
          { crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button> }
          <Button onClick={handleClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
    </>
  );
};

export default AddressBookDialogRegister;