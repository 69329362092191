import { csrfFetch } from "./csrf.js";
import { authServerUrl } from '../config';

const SET_PAYMENT = 'gclientPayments/setGClientPayment';
const REMOVE_PAYMENT = "gclientPayments/removeGClientPayment";
const SET_PAYMENTS = 'gclientPayments/setGClientPayments';

const setGClientPayment = (gclientPayment) => ({
  type: SET_PAYMENT,
  payload: gclientPayment,
});

const removeGClientPayment = () => ({
  type: REMOVE_PAYMENT,
});

const setGClientPayments = (gclientPayments) => ({
  type: SET_PAYMENTS,
  payload: gclientPayments,
}); 

export const create = ({ gclientId, membership, amount, startDate, endDate, comment, periodType }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/payments`, {
    method: "POST",
    body: JSON.stringify({ gclientId, membership, amount, startDate, endDate, comment, periodType }),
  });
  const data = await response.json();
  dispatch(setGClientPayment(data.gclientPayment));
  return response;
};  

export const modify = ({ seq, membership, amount, startDate, endDate, comment }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/payments/seq/${seq}`, {
    method: "PUT",
    body: JSON.stringify({ seq, membership, amount, startDate, endDate, comment }),
  });
  const data = await response.json();
  dispatch(setGClientPayment(data.gclientPayment));
  return response;
};

export const remove = ({ seq }) => async dispatch => {
  console.log('remove seq => ', seq);
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/payments/seq/${seq}`, {
    method: "DELETE",
  });
  dispatch(removeGClientPayment());
  return response;
};

//export const selectAll = (params = {}) => async dispatch => {
export const selectAll = (gclientId) => async dispatch => {
    //const queryString = new URLSearchParams(params).toString();
    //const url = `${authServerUrl}/api/payments${queryString ? `?${queryString}` : ''}`;   
    if (!gclientId) {
        return;
    }

    const url = `${authServerUrl}/api/payments?gclientId=${gclientId}`;
    const response = await csrfFetch(dispatch, url);
    const responseData = await response.json();

    if (responseData.membershipPayments.length > 0) {
        console.log('formattedData => ', responseData.membershipPayments);
        const formattedData = {
          gclientPayments: responseData.membershipPayments.map(payment => ({
            ...payment,
            id: payment.seq // seq를 id로 사용
          })),  
          count: responseData.totalElements
        };

        dispatch(setGClientPayments(formattedData));
    } else {
      // 데이터가 없는 경우 빈 배열과 0으로 초기화
      dispatch(setGClientPayments({
        gclientPayments: [],
        count: 0
      }));
    }
    return response;
};

export const select = ({ seq }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/payments/seq/${seq}`);
  const data = await response.json();
  dispatch(setGClientPayments(data.gclientPayments));
  return response;
};

export const getByGclientId = ({ gclientId }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclientPayments?gclientId=${gclientId}`);
  const data = await response.json();
  dispatch(setGClientPayments(data.gclientPayments));
  return response;
};

export const getByGclientIdAndPaymentDate = ({ gclientId, paymentDate }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclientPayments?gclientId=${gclientId}&paymentDate=${paymentDate}`);
  const data = await response.json();
  dispatch(setGClientPayments(data.gclientPayments));
  return response;
};

const initialState = {
  gclientPayment: null,
  gclientPayments: [],
  count: 0,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_PAYMENT:
      newState = Object.assign({}, state, { gclientPayment: action.payload });
      return newState;
    case REMOVE_PAYMENT:
      newState = Object.assign({}, state, { gclientPayment: null });
      return newState;
    case SET_PAYMENTS:
      newState = Object.assign({}, state, { gclientPayments: action.payload.gclientPayments, count: action.payload.count });
      return newState;
    default:
      return state;
  }
}

export default reducer; 
