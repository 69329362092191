import React, { useState, useRef, useCallback } from 'react';
import { Autocomplete, TextField, Chip, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

const CustomInputSelect = ({ 
  defaultOptions, 
  addressOptions, 
  label, 
  placeholder, 
  onChange,
  width = '100%',
  size = 'small'
}) => {
  //console.log('CustomInputSelect', defaultOptions, addressOptions, label, placeholder, onChange, width, size);
  const allOptions = [...defaultOptions, ...addressOptions];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [editIndex, setEditIndex] = useState(-1);
  const [editValue, setEditValue] = useState('');
  const [chipWidths, setChipWidths] = useState({});
  const chipRefs = useRef({});
  const editInputRef = useRef(null);

  const handleChange = useCallback((event, newValue) => {
    const updatedValue = newValue.map(option => 
      typeof option === 'string' ? { id: option, name: option, group: '사용자 입력' } : option
    );
    setSelectedOptions(updatedValue);
    onChange(updatedValue);
  }, [onChange]);

  const handleInputChange = useCallback((event, newInputValue) => {
    setInputValue(newInputValue);
  }, []);

  const handleBlur = useCallback(() => {
    if (inputValue && !selectedOptions.some(option => option.name === inputValue)) {
      const newOption = { id: inputValue, name: inputValue, group: '사용자 입력' };
      const newOptions = [...selectedOptions, newOption];
      setSelectedOptions(newOptions);
      onChange(newOptions);
      setInputValue('');
    }
  }, [selectedOptions, onChange, inputValue]);

  const handleEdit = (index) => {
    setEditIndex(index);
    setEditValue(selectedOptions[index].name);
    if (chipRefs.current[index]) {
      setChipWidths(prev => ({...prev, [index]: chipRefs.current[index].offsetWidth}));
    }
  };

  const handleEditChange = (e) => {
    setEditValue(e.target.value);
  };

  const handleEditComplete = () => {
    if (editValue.trim() === '') {
      handleDelete(editIndex);
    } else {
      const newOptions = [...selectedOptions];
      newOptions[editIndex] = { ...newOptions[editIndex], name: editValue.trim() };
      setSelectedOptions(newOptions);
      onChange(newOptions);
    }
    setEditIndex(-1);
    setEditValue('');
  };

  const handleEditBlur = () => {
    handleEditComplete();
  };

  const handleDelete = (index) => {
    const newOptions = selectedOptions.filter((_, i) => i !== index);
    setSelectedOptions(newOptions);
    onChange(newOptions);
    if (index === editIndex) {
      setEditIndex(-1);
      setEditValue('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Backspace' && inputValue === '' && selectedOptions.length > 0) {
      event.preventDefault();

      const newOptions = selectedOptions.slice(0, -1);
      setSelectedOptions(newOptions);
      onChange(newOptions);
    }
  };

  const handleEditKeyDown = (e) => {
    if (e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.stopPropagation();
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      handleEditComplete();
    }
  };

  const isPresetOption = (option) => {
    return allOptions.some(item => item.id === option.id);
  };

  const groupHeaderStyle = {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    padding: '8px 10px',
  };

  const chipStyle = {
    backgroundColor: '#E5F1FC',
    height: 22,
    fontSize: '0.8125rem',
  };

  const editTextFieldStyle = {
    height: '100%',
    fontSize: 'inherit',
    padding: 0,
    '& .MuiInputBase-input': {
      padding: '0 8px',
      height: '100%',
      fontSize: 'inherit',
    },
  };

  const iconButtonStyle = {
    padding: 1,
    marginLeft: -4,
    width: 16,
    height: 16,
  };

  const editIconButtonStyle = {
    ...iconButtonStyle,
  };

  const closeIconButtonStyle = {
    ...iconButtonStyle,
    marginRight: 2,
  };

  const iconStyle = {
    fontSize: 12,
  };

  const containerStyle = {
    width: width !== undefined ? (typeof width === 'number' ? `${width}px` : width) : '100%',
  };


  return (
    <div style={containerStyle}>
      <Autocomplete
        multiple
        freeSolo
        options={allOptions}
        groupBy={(option) => option.group}
        renderGroup={(params) => (
          <div key={params.key}>
            <Typography style={groupHeaderStyle}>{params.group}</Typography>
            {params.children}
          </div>
        )}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
            return option.name || '';
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedOptions}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...chipProps } = getTagProps({ index });
            return (
              <Chip
                key={key}
                {...chipProps}
                ref={el => chipRefs.current[index] = el}
                label={
                  editIndex === index ? (
                    <TextField
                      inputRef={editInputRef}
                      value={editValue}
                      onChange={handleEditChange}
                      onBlur={handleEditBlur}
                      onKeyDown={handleEditKeyDown}
                      autoFocus
                      variant="standard"
                      InputProps={{
                          disableUnderline: true,
                          style: editTextFieldStyle,
                      }}
                      style={{ 
                          width: chipWidths[index] ? `${chipWidths[index] - 16}px` : '100%', 
                          height: '100%' 
                      }}
                  />
                  ) : (
                  option.name || ''
                  )
                }
                onDelete={editIndex === index ? undefined : () => {}}
                deleteIcon={
                  editIndex !== index ? (
                    <React.Fragment>
                    {!isPresetOption(option) && (
                        <IconButton size="small" onClick={() => handleEdit(index)} style={editIconButtonStyle}>
                        <EditIcon style={iconStyle} />
                        </IconButton>
                    )}
                    <IconButton size="small" onClick={() => handleDelete(index)} style={closeIconButtonStyle}>
                        <CloseIcon style={iconStyle} />
                    </IconButton>
                    </React.Fragment>
                  ) : null
                }
                style={chipStyle}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={placeholder}
            size={size}
          />
        )}
      />  {/* end of Autocomplete */}
    </div>
  );
};

export default CustomInputSelect;