// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl, projectServerUrl } from '../config';

const SET_GGLASS_PERFORMANCES = 'gglassPerformance/setGGlassPerformances';
const SET_PUBLISHED_TOTAL_COUNT = 'gglassPerformance/setPublishedTotalCount';
const SET_GPROJECTS_WITH_PERFORMANCE = 'gglassPerformance/setGProjectsWithPerformance';
const SET_GLASS_PERFORM_MAPS = 'gglassPerformance/setGGlassPerformMaps';

const setGGlassPerformances = (gglassPerformances) => ({
  type: SET_GGLASS_PERFORMANCES,
  payload: gglassPerformances,
});

const setPublishedTotalCount = (publishedTotalCount) => ({
  type: SET_PUBLISHED_TOTAL_COUNT,
  payload: publishedTotalCount,
});

const setGProjectsWithPerformance = (gprojects) => ({
  type: SET_GPROJECTS_WITH_PERFORMANCE,
  payload: gprojects,
});

const setGGlassPerformMaps = (gglassPerformMaps) => ({
  type: SET_GLASS_PERFORM_MAPS,
  payload: gglassPerformMaps,
});

// gcore 서버에서 데이터 조회
export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gglassPerformances`);
  const data = await response.json();
  dispatch(setGGlassPerformances(data.gglassPerformances));
  return response;
}

export const selectByCodeDirect = async (code) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gglassPerformances/code/${code}`);
  const data = await response.json();
  return data.gglassPerformances;
}

export const selectByCode = (code) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gglassPerformances/code/${code}`);
  const data = await response.json();
  dispatch(setGGlassPerformances(data.gglassPerformances));
  return response;
}

// gproject 서버에서 데이터 조회
export const selectPublishedTotalCount = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojects/glassPerformances/totalCount`);
  const data = await response.json();
  dispatch(setPublishedTotalCount(data.totalCnt));
  return response;
}

export const selectProjectsWithPerformance = (searchName, searchSite, startDate, endDate, completionDate) => async dispatch => {
  const params = new URLSearchParams(); // URLSearchParams는 브라우저 내장 객체
  
  if (searchName) params.append('searchName', searchName);
  if (searchSite) params.append('searchSite', searchSite);
  if (startDate) params.append('startDate', startDate);
  if (endDate) params.append('endDate', endDate);
  if (completionDate) params.append('completionDate', completionDate);

  const queryString = params.toString();

  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojects/glassPerformances?${queryString}`);
  const data = await response.json();
  console.log(data)
  dispatch(setGProjectsWithPerformance(data.gprojects));
  return response;
}

export const selectPerformanceByGProject = (gprojectId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojects/glassPerformances/gprojectId/${gprojectId}`);
  const data = await response.json();
  console.log(data)
  dispatch(setGGlassPerformMaps(data.gglassPerformMaps));
  return response;
}

const initialState = {
  gglassPerformances: [],
  publishedTotalCount: 0,
  gprojectsWithPerformance: [],
  gglassPerformMaps: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GGLASS_PERFORMANCES:
      newState = Object.assign({}, state, { gglassPerformances: action.payload });
      return newState;
    case SET_PUBLISHED_TOTAL_COUNT:
      newState = Object.assign({}, state, { publishedTotalCount: action.payload });
      return newState;
    case SET_GPROJECTS_WITH_PERFORMANCE:
      newState = Object.assign({}, state, { gprojectsWithPerformance: action.payload });
      return newState;
    case SET_GLASS_PERFORM_MAPS:
      newState = Object.assign({}, state, { gglassPerformMaps: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
