import * as yup from 'yup';

export const requiredMessage = (field) => `${field}을(를) 입력해주세요.`;
export const emailMessage = "올바른 이메일 형태로 입력해주세요.";

// 개별 필드 정의
export const fields = {
  name: yup.string().required(requiredMessage('이름')),
  email: yup.string().email(emailMessage).required(requiredMessage('이메일')),
  phone: yup.string().matches(/^[0-9]+$/, '숫자만 입력해주세요.').required(requiredMessage('전화번호')),
  mobile: yup.string().matches(/^[0-9]+$/, '숫자만 입력해주세요.').required('모바일'),
  company: yup.string().required(requiredMessage('회사명')),
  duties: yup.string().required(requiredMessage('직책')),
  address: yup.string().required(requiredMessage('주소')),
  memo: yup.string(),
  bizRegNumber: yup.string().required(requiredMessage('사업자등록번호')),
  password: yup.string().required(requiredMessage('비밀번호')),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], '비밀번호가 일치하지 않습니다.')
    .required(requiredMessage('비밀번호 확인')),
};

// // 주소록 스키마
// export const addressBookSchema = yup.object({
//   name: fields.name,
//   email: fields.email,
// }).required();

// // 사용자 스키마 (회원가입용)
// export const userSchema = yup.object({
//   bizRegNumber: fields.bizRegNumber,
//   name: fields.name,
//   phone: fields.phone,
//   address: fields.address,
//   email: fields.email,
//   password: fields.password,
//   confirmPassword: fields.confirmPassword,
// }).required();

