import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  Edit,
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Preview,
  Remove,
} from '@mui/icons-material';
import {
  FormInputText,
} from "../form";
import {
  ConfirmDialog,
} from "../dialog";
import { StyledTooltip } from "../tooltip";
import {
  CustomNoRowsOverlay,
} from "../datagrid";
import {
  dateFormat,
} from "../../utils";
import { uploadFilePath, fileServerUrl } from "../../config";
import { getGridHeight } from "../../constants/gridHeights";
import * as gperformReportActions from "../../store/gperformReport";
import GPerformanceDataDialog from "./GPerformanceDataDialog";

// 현재 화면에 맞는 Grid 높이 계산
const GRID_HEIGHT = getGridHeight('ONE_LINE_LAYER', -24); // 0px 추가 여백

const theme = createTheme();

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const defaultValues = {
  searchGClient: "",
  searchSite: "",
  id: "",
}

const GPerformaceDataManagement = ({
}) => {
  const { control, setValue, getValues } = useForm({ defaultValues: defaultValues });
  
  const [columns, setColumns] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [modify, setModify] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});

  const sessionUser = useSelector(state => state.session.sessionUser);
  const gperformReports = useSelector(state => state.gperformReport.gperformReports);

  const dispatch = useDispatch();
  
  const selectAllGPerformReports = (searchGClient, searchSite) => dispatch(gperformReportActions.selectAll(searchGClient, searchSite));
  const selectGPerformReport = (id) => gperformReportActions.selectDirect(id);

  useEffect(() => {
    const fetchData = async () => {
      setLoaded(false);
      try {
        await refreshGPerformProjects();
        setLoaded(true);
      } catch (error) {
        console.error('데이터 조회 중 오류 발생:', error);
        setLoaded(true);
      }
    };

    fetchData();
  }, [dispatch]);

  // const handleClickViewEachDoc = (documentPath) => {
  //   const path = documentPath.replace(uploadFilePath, fileServerUrl);

  //   // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
  //   const randNumber = Math.floor(Math.random()*99);

  //   window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  // }
  const handleClickViewEachDoc = (documentPath) => {
    const pathParts = documentPath.replace(uploadFilePath, '').split('/');
    
    // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
    const randNumber = Math.floor(Math.random()*99);
    
    // 경로를 '/'로 분리하고 각 부분을 개별적으로 인코딩
    const encodedPath = pathParts.map(part => encodeURIComponent(part)).join('/');

    const path = `${fileServerUrl}${encodedPath}?q=cat&${randNumber}`;

    window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  }

  const COLUMN_CONFIGS = [
    {
      field: 'id',
      headerName: '보고서번호',
      width: 160,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'publishedAt',
      headerName: '발행일자',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.row.createdAt, 'yyyy-MM-dd'),
    },
    {
      field: 'gclientName',
      headerName: '의뢰처',
      width: 200,
    },
    {
      field: 'site',
      headerName: '현장명',
      width: 300,
    },
    {
      field: 'siteAddress',
      headerName: '현장주소',
      width: 400,
    },
    {
      field: 'performanceDocumentPath',
      headerName: '성능확인서',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        // console.log(params)
        const performanceDocumentPath = params.value;
        if (params.value) {
          return (
            <StyledTooltip
              title={<Stack direction="row" display="flex" alignItems="center">
                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                  {`미리보기`}
                </Box>
                <Typography variant="subtitle2">{performanceDocumentPath?.split("/")[performanceDocumentPath?.split("/")?.length-1]}</Typography>
              </Stack>}
              placement="right"
              sx={tooltipTop}
            >
              <IconButton
                color="primary"
                aria-label="file"
                onClick={() => handleClickViewEachDoc(performanceDocumentPath)}
                sx={{ color: "#1976D2" }}
              >
                <Preview />
              </IconButton>
            </StyledTooltip>
          );
        }
      }
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"수정/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
      // width: checked ? 200 : 70,
      width: 70,
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];

  const handleSelect = async ({ type, params }) => {
    const { id } = params;
    if (type === 'edit') {
      setModify(true);
      const gperformReport = await selectGPerformReport(id);
      setSelectedRow(gperformReport);
      setValue("id", id);
      setOpenDialog(true);
    } else if (type === 'delete') {
      setRemoveId(id);
      setParams(params);
      setConfirmOpen(true);
    }
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelect({ type: 'edit', params })}
        showInMenu
      />,
      // <GridActionsCellItem
      //   icon={<Edit />}
      //   label={"수정(OLD)"}
      //   onClick={() => handleSelect({ type: 'editOld', params })}
      //   showInMenu
      // />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
    ];
    return arrActions;
  }

  const getColumnsByRoute = () => {
    return sessionUser.type === 'ADMIN' ? COLUMN_CONFIGS : COLUMN_CONFIGS.filter(column => column.field !== 'gclientName') ;
  };

  useEffect(() => {
    setColumns(getColumnsByRoute());
  }, [sessionUser.type]);

  const handleClickPublishNew = () => {
    setSelectedRow(undefined);
    setOpenDialog(true);
  }

  const refreshGPerformProjects = async () => {
    const searchGClient = getValues("searchGClient");
    const searchSite = getValues("searchSite");

    setLoaded(false);
    await selectAllGPerformReports(searchGClient, searchSite);
    setTimeout(() => {
      setLoaded(true);
    }, 300);
  }

  const removeGPerformProject = (removeId) => {
    setLoaded(false);

    dispatch(gperformReportActions.remove(removeId))
    .then(async (res) => {
      alert("삭제되었습니다.")
      refreshGPerformProjects();
    })
    .catch(async (res) => {
      console.log(res);
    });
  }

  var timer;
  const handleChangeSearch = async (e) => {
    // 과도한 API 호출 방지를 위한 debouncing 코드. TODO : 라이브러리 활용 검토
    if (timer) {
      clearTimeout(timer);
    }
    
    // 타이머 설정
    timer = setTimeout(async () => {
      refreshGPerformProjects();
    }, 500);
  };

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <GPerformanceDataDialog
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        modify={modify}
        setModify={setModify}
        refresh={refreshGPerformProjects}
      />
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={10}>
              <Stack direction="row" spacing={1}>
                {
                  sessionUser.type === 'ADMIN' && (
                    <FormInputText
                      name={"searchGClient"}
                      control={control}
                      label={"의뢰처로 검색하세요."}
                      onCustomChange={handleChangeSearch}
                      sx={{
                        width: 200,
                        // '& .MuiInputBase-root': {
                        //   background: "#E3EEFA"
                        // },
                        '& .MuiFormLabel-root' : {
                          // color: '#2196f3',
                          fontSize: '0.85rem',
                        },
                        input: {
                          // color: '#2196f3',
                          fontSize: '0.85rem',
                        }
                      }}
                    />
                  )
                }
                <FormInputText
                  name={"searchSite"}
                  control={control}
                  label={"현장명 또는 현장주소로 검색하세요."}
                  onCustomChange={handleChangeSearch}
                  sx={{
                    width: 408,
                    // '& .MuiInputBase-root': {
                    //   background: "#E3EEFA"
                    // },
                    '& .MuiFormLabel-root' : {
                      // color: '#2196f3',
                      fontSize: '0.85rem',
                    },
                    input: {
                      // color: '#2196f3',
                      fontSize: '0.85rem',
                    }
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                // startIcon={<Search />}
                onClick={handleClickPublishNew}
              >
                {"신규발급"}
              </Button>
            </Grid>
          </Grid>
          <div style={{ height: GRID_HEIGHT.REDUCED, width: '100%' }}>
            {/* TODO : Skeleton 처리를 하는게 보기 좋은지 아닌지 의견 수렴 필요 */}
            {/* <Skeleton variant="rounded" height={show ? 0 : 800}/> */}
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={gperformReports}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
            />
          </div>
        </Box>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={removeGPerformProject}
          onCancel={() => {}}
        >
          <div>
            {"현장명 "}<span style={{ color: "#1976d2" }}>{`${params && params.row && params.row.site  || ""}`}</span>{`을(를) 삭제하시겠습니까?`}<br /><br />
            <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
              { `보고서번호 : ${params && params.id  || ""}` }<br />
              { `현장주소 : ${params && params.row && params.row.siteAddress  || ""}` }<br />
              {/* { `건설사명 : ${params && params.row && params.row.constructionCompanyName  || ""}` }<br /> */}
            </Box>
          </div>
        </ConfirmDialog>
      </Container>
    </ThemeProvider>
  );
};

export default GPerformaceDataManagement;
