import React from 'react';
import { Route } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import { GPerformanceDataManagement, GDomesticAuthManagement } from "../components/GPublish";

// 납품확인서 관리
const PublishRoute = ({sessionUser}) => {

    return (
        <>
            {/* 성능확인서 발급 관리 */}
            <Route path="/gperformanceDatas" element={
                <RequireAuth sessionUser={sessionUser}>
                    <GPerformanceDataManagement />
                </RequireAuth>
            } />

            {/* 국내산인증서 발급 관리 */}
            <Route path="/gdomesticAuths" element={
                <RequireAuth sessionUser={sessionUser}>
                    <GDomesticAuthManagement />
                </RequireAuth>
            } />
        </>
    );
};

export default PublishRoute;

