const GlassUtils = {
  /**
   * #20240816-01
   * 원자재의 경우 제품명과 제품코드에 괄호와 괄호 안에 특정 문자열이 들어가 있는 경우가 있다. 추후 바뀔 수 있음
   * 1. 유리원판의 경우 제품명(제조사). 예) CL(KCC), CL(LX) 등
   * 2. 필름타입의 경우 필름타입(상세제품명). 예) PVB(Trosifol Clear) 등
   * 
   * 그러나,
   * 성능확인서를 발행하기 위한 계산식을 사용할 때, KCC에서 제공받은 자료에 PVB로만 적용되어 있기 때문에 괄호를 제거하거나 계산 DB에 괄호와 제품명을 넣어 추가입력해야 함
   * 성적서를 찾을 때 예를 들면 복층의 경우 CL(KCC)나 CL(LX) 들어간 경우 제조사 상관없이 성적서를 취득하므로 그에 맞게 성적서를 검색해야 하는데 괄호까지 검색조건에 사용하면 동일한 성적서를 두번 등록해야 하는 문제가 있음
   * 이런 문제들 때문에 괄호와 괄호 안 문자열을 제거하고 사용
   * 
   * 단, 유리원판 중 괄호 안에 제조사가 아닌 제품명에 괄호를 포함시킨 경우는 괄호 제거하지 않는다. 예) EMT178(강화)
   * 추가로 코드는 검색 등 시스템 내부적 동작에 필요하고 제품명은 화면에 보여질 때만 적용된다.
   */
  getMaterialItemCodeOrName: (code, value) => {
    // console.log({ code, value })
    // <<<주의 : 코드 하드코딩 사용>>>
    let selectedCode = value;
    if (code && value) {
      if (code === 'GLASS PRODUCT NAME' && value.indexOf("(강화)") <= 0) {
        selectedCode = selectedCode.replace(/\(.*?\)/, "");
      } else if (code === 'FILM TYPE') {
        selectedCode = selectedCode.replace(/\(.*?\)/, "");
      }
    }
    
    return selectedCode;
  },
  /**
   * 유리 사양을 구하는 함수
   * @param {*} gcomponentItems 
   * @returns 
   */
  // getSpecification : (gcomponentItems) => { // 화살표 함수는 this 바인딩 제공하지 않아 암시적 바인딩 사용
  getSpecification : function (gcomponentItems) {
    let specification = "";
    let otherSpecs = "";

    const selected = gcomponentItems;
    selected && selected.forEach(gtypeDetails => {
      let productName = "";
      let otherSpec = "";
      gtypeDetails.forEach(gcomponentItem => {
        if (Array.isArray(gcomponentItem.value)) {
          gcomponentItem.value.forEach(item => {
            const { applyType, code } = item;
            if (applyType === 'productName') {
              productName += code ? `${code}|` : "";
            } else if (applyType === 'etc') {
              otherSpec += code ? `${code}|` : "";
            }
          })
        } else {
          const itemCode = gcomponentItem.code;
          const { applyType, code } = gcomponentItem.value;
          if (applyType === 'productName') {
            console.log(this)
            productName += code ? `${this.getMaterialItemCodeOrName(itemCode, code)}|` : "";
          } else if (applyType === 'etc') {
            otherSpec += code ? `${code}|` : "";
          }
        }
      });
      productName = productName.substring(0, productName.length - 1);
      specification += (productName !== "" ? `${productName}+` : "");

      otherSpec = otherSpec.substring(0, otherSpec.length - 1);
      otherSpecs += (otherSpec !== "" ? `${otherSpec}+` : "");
    });
    
    return [specification.substring(0, specification.length - 1), otherSpecs.substring(0, otherSpecs.length - 1)];
  },
  /**
   * 유리 인증등급을 구하는 함수
   * @param { rawMaterial, type, specificationElements }
   * @returns classification 인증등급
   * <<< 인증등급 부여 논리 >>>
   * 1. 원판
   *    - 원자재에서 설정한 인증등급이 들어 있다. 그말은 원자재에서 인증등급을 설정하여야 한다는 것이다.
   * 
   * 2. 강화유리 
   *    - KS L 2005(무늬유리) : TP 무늬 강화유리
   *    - KS L 2014(열선반사유리) : TR 열선 반사 강화유리
   *    - 그외: TF 플로트 강화유리, 곡면이 있으면 TC 곡면 플로트 강화유리
   * 
   * 3. 복층유리
   *    -------------------------------------------------------------------------------------------------------------------
   *                   종류                 열관류저항  열관류율     태양         조건1	          조건2          조건3
   *                                                               제거율    로이적용여부      열관류 저항     공기층 두께 
   *    -------------------------------------------------------------------------------------------------------------------
   *    열복층유리            A종    U1        0.25 	    4.00 		          KS L 2017 미적용	  0.25 이상 	   12 mm 미만
   *                                U2        0.31 	     3.23 		         KS L 2017 미적용	   0.31 이상 	    12 mm 이상
   * 	                       B종    U3-1      0.37 	     2.70 		         KS L 2017 적용	     0.37 이상 	    12 mm 미만
   * 		                            U3-2	    0.43 	     2.33 		         KS L 2017 적용	     0.43 이상 	    12 mm 이상
   *    -------------------------------------------------------------------------------------------------------------------
   *    태양열 차폐 복층유리	  C종	  E4	      0.25 	    4.00 	     0.35 	   KS L 2014 적용	   0.35 이상
   * 		                            E5	      0.25 	    4.00 	     0.50 	   KS L 2014 적용	   0.50 이상 	
   *    -------------------------------------------------------------------------------------------------------------------
   */
  // TODO : 최종 인증등급 값은 현재는 하드코딩 되어 있으나(아래에서 classification에 직접 쓰는 것을 말함 ) GStandards에서 가져와 조합하는 방식으로 가야할 것으로 보임
  getClassification : function ({ rawMaterial, type, specificationElements, selectedGcomponentItems }) {
    let classification = "";

    if (type === 'RAW_MATERIAL') { // 원판
      if (rawMaterial) {
        const { selectedClassifications } = rawMaterial;
        if (selectedClassifications && Array.isArray(selectedClassifications) && selectedClassifications.length > 0) {
          const classifications = selectedClassifications.filter(classification => classification.checked === true);
          if (classifications.length === 1) {
            classification = classifications[0].type;
          }
        }
      }
    } else {
      if (specificationElements.length === 0 && selectedGcomponentItems && selectedGcomponentItems.length > 0) {
        selectedGcomponentItems.forEach((gtypeDetails, i) => {
          // specification 구성 및 specification 구성부품 마련
          gtypeDetails.forEach((item, j) => {
            if (j === 0) { // 각 레이어의 첫번째는 상세 구성요소의 상위 개념 (예를 들면 유리원판, 중공층 등...)으로 스킵
              return;
            }
            
            if (Array.isArray(item.value)) {
              item.value.forEach(i => {
                const { applyType, code } = i;
                if (applyType === 'productName') {
                  specificationElements.push({ code, value: i.value });
                }
              })
            } else {
              const { applyType } = item.value;
              if (applyType === 'productName') {
                specificationElements.push({ code: item.code, value: item.value });
              }
            }
          })
        })
      }

      // <<<주의 : 코드 하드코딩 사용>>>
      if (type === 'TEMPERED') { // 강화유리
        specificationElements.forEach(ele => {
          const { code, value } = ele;
          // <<<주의 : 코드 하드코딩 사용>>>
          if (code === 'GLASS PRODUCT NAME') {
            const { gstandard } = value;
            // gstandard는 SELECT JSON_AGG(t) ~의 결과이므로 결과가 하나라도 배열임에 유의
            // console.log(gstandard)
            if (gstandard && Array.isArray(gstandard) && gstandard.length === 1) {
              const { no } = gstandard[0];
              if (no === 'KS L 2005') {
                classification = "TP 무늬 강화유리";
              } else if (no === 'KS L 2014') {
                classification = "TR 열선 반사 강화유리";
              } else /*if (no === 'KS L 2012')*/ {
                // if (곡가공이 있으면) { // TODO : 곡가공의 경우 "속성"에서 "이름"이 아닌 "비고"로 되어 있어 화면에서 선택하지 않고 있다. 그 결과로 "TC 곡면 플로트 강화유리" 인증등급은 현재 나타날 수 없는 구조임
                //   classification = "TC 곡면 플로트 강화유리";
                // } else {
                  classification = "TF 플로트 강화유리";
                // }
              }
            }
          }
        })
      } else if (type === 'INSULATED_GLASS_UNIT') { // 복층유리
        let ksl2017 = false;
        let ksl2014 = false;
        let spaceThicknessGreaterThan12mm = false;
        let spaceThickness = 0;
        
        for (let i=0; i<specificationElements.length; i++) {
          const ele = specificationElements[i];

          const { code, value } = ele;
          
          // <<<주의 : 코드 하드코딩 사용>>>
          if (code === 'GLASS PRODUCT NAME') {
            const { gstandard } = value;
            // gstandard는 SELECT JSON_AGG(t) ~의 결과이므로 결과가 하나라도 배열임에 유의
            // console.log(gstandard)
            if (gstandard && Array.isArray(gstandard) && gstandard.length === 1) {
              if (gstandard[0].no === 'KS L 2017') {
                ksl2017 = true;
              } else if (gstandard[0].no === 'KS L 2014') {
                ksl2014 = true;
                break;
              }
            }
          } else if (code === 'SPACER THICKNESS') {
            // console.log(value.code)
            // TODO : 코드 설계시 숫자만 들어가야 함. 현재는 정수지만 소수자리도 있을 수 있다는 가정하에 parseInt가 아닌 Number 사용하였음
            // if (Number(value.code) >= 12) {
            //   spaceThicknessGreaterThan12mm = true;
            // }
            // TODO : 공기층두께 합산일 경우로 일단 처리
            spaceThickness = spaceThickness + Number(value.code);
          }
        }

        if (Number(spaceThickness) >= 12) {
          spaceThicknessGreaterThan12mm = true;
        }
        
        /**
         * KS L 2014 (열선 반사 유리) 가 있는 경우 (TODO : 제품에 E4, E5 부여할 예정)
         *  1. 성적서 규격 등록에서는 C종 E4, E5 모두 오픈 (아래와 같이 C종 설정해주면 C종으로 검색하므로 조건부합)
         *  2. 자재승인서 발급(프로젝트)에서는 C종만 나오고 내구도 선택하게 됨 => TODO : 성적서 검색시 C종과 내구도만 가지고 C종 E4, E5 모두 검색하고 그 중 성적서 선택하게 해야 함 (순서상 맨 위의 것을 검색하므로 우선 검색되어질 것을 위로 배치???)
         */ 
        if (ksl2014) {
          classification = "C종";
        } else {
          if (ksl2017 === false && spaceThicknessGreaterThan12mm === false) {
            classification = "A종 U1";
          } else if (ksl2017 === false && spaceThicknessGreaterThan12mm === true) {
            classification = "A종 U2";
          } else if (ksl2017 === true && spaceThicknessGreaterThan12mm === false) {
            classification = "B종 U3-1";
          } else if (ksl2017 === true && spaceThicknessGreaterThan12mm === true) {
            classification = "B종 U3-2";
          }
        }
      }
    }
    
    return classification;
  },
  /**
   * 시험성과대비표의 시험항목 중 스펙에 따라 기준치가 다른 경우 해당 기준치를 찾아주는 함수
   * @param {*} criteria 
   * @param {*} code 
   * @returns 
   * 스펙에 따른 기준치는 GTestComparisons의 criterion 등록시 아래와 같은 기준으로 등록한다.
   *   입력방법 : 스펙1=기준치1;스펙2=기준치2,...
   *   아래는 플로트판유리 및 마판유리(KS L 2012)의 두께에 따른 허용오차를 입력하는 예시이다. 주의할 점은 앞의 스펙부분을 어떻게 설정하느냐임. (원판과 가공은 현재 G04docuGTests의 code를 사용함)
   *     (예)2=± 0.2;3=± 0.3;4=± 0.3;5=± 0.3;6=± 0.3;8=± 0.6;10=± 0.6;12=± 0.8;15=± 0.8;19=± 1.2
   * (검토)
   *   현재 code나 type으로 검색하고 그 후 공정의 경우는 두께에 대하여 해당 범위에 해당하는지 판단한다.
   *   type: 종류·등급·호칭 또는 모델을 나타내는 텍스트
   *   아래 로직 개선 여지 검토
   */
  selectCriterion : function (criteria, code, type, division, sample, gstandardNo) {
    let returnValue = criteria;
    
    try {
      const arrCriteria = criteria.split(";");
      if (arrCriteria && Array.isArray(arrCriteria)) {
        let thickness = 0;
        if (division === 'PROCESS' && sample && sample.details && sample.details.selectedGcomponentItems) {
          sample.details.selectedGcomponentItems.forEach((gtypeDetails, i) => {
            gtypeDetails.forEach((item, j) => {
              if (j === 0) { // 각 레이어의 첫번째는 상세 구성요소의 상위 개념 (예를 들면 유리원판, 중공층 등...)으로 스킵
                return;
              }
              
              const { code, value } = item;
              if (code === 'GL THICKNESS' || code === 'SPACER THICKNESS' || (gstandardNo === "KS L 2004" && code === 'FILM THICKNESS')) { // <<<주의 : 코드 하드코딩 사용>>>
                const { code } = value;
                try {
                  thickness = thickness + Number(code);
                } catch (e) {
                  thickness = thickness + 0;
                }
              }
            })
          })
        }
      
        const found = arrCriteria.filter(i => {
          const condition = i.split("=")[0];
          if (condition == code || condition == type) {
            return true;
          } else {
            if (division === 'PROCESS' && thickness > 0) {
              console.log(thickness);
              // criteria => gte:22=± 2.0;gte:17,lt:22=± 1.5;lt:17=± 1.0
              let result = false;
              const conditions = condition.split(",");
              if (conditions.length === 1) { // 배강도 예를 들면 code = 5|H/S인데 5로 검색해야 하므로 thickness로 같은 것을 찾는다.
                const expression = conditions[0].split(":");
                if (expression.length === 1) {
                  return expression[0] === thickness.toString();
                }

                const inequality = expression[0];
                const compare = Number(expression[1]);
                
                switch(inequality) {
                  case 'gte':
                    result = thickness >= compare;
                    break;
                  case 'gt':
                    result = thickness > compare;
                    break;
                  case 'lte':
                    result = thickness <= compare;
                    break;
                  case 'lt':
                    result = thickness < compare;
                    break;
                  default:
                    break;
                }
                // console.log({ compare, result })
                return result;
              } else if (conditions.length === 2) { // 사이의 범위인 경우 (예) a <(또는 <=) x <(또는 <=) b
                const conditions0 = conditions[0];
                const expression0 = conditions0.split(":");
                const inequality0 = expression0[0];
                const compare0 = Number(expression0[1]);

                const conditions1 = conditions[1];
                const expression1 = conditions1.split(":");
                const inequality1 = expression1[0];
                const compare1 = Number(expression1[1]);

                let result0 = false;
                switch(inequality0) {
                  case 'gte':
                    result0 = thickness >= compare0;
                    break;
                  case 'gt':
                    result0 = thickness > compare0;
                    break;
                  case 'lte':
                    result0 = thickness <= compare0;
                    break;
                  case 'lt':
                    result0 = thickness < compare0;
                    break;
                  default:
                    break;
                }

                let result1 = false;
                switch(inequality1) {
                  case 'gte':
                    result1 = thickness >= compare1;
                    break;
                  case 'gt':
                    result1 = thickness > compare1;
                    break;
                  case 'lte':
                    result1 = thickness <= compare1;
                    break;
                  case 'lt':
                    result1 = thickness < compare1;
                    break;
                  default:
                    break;
                }

                result = result0 && result1;
                // console.log({ compare0, compare1, result })
                return result;
              }
            } else {
              return false;
            }
          }
        });

        if (found.length > 0 ) {
          returnValue = found[0].split("=")[1];
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      return returnValue;
    }
  },
  getLayerGlassSpecification : function (arrElem) {
    let specification = "";
    if (arrElem && Array.isArray(arrElem)) {
      const i = arrElem.findIndex(e => e.code === 'FILM THICKNESS');
      if (i > 0) { // 접합이 있는 경우
        // 앞판. FILM THICKNESS 앞으로 GL THICKNESS까지
        // 뒷판. FILM THICKNESS 뒤부터 SPACER THICKNESS 전까지 또는 SPACER THICKNESS가 없다면 끝까지
        let frontIndex = -1;
        let rearIndex = -1;
        for(let j=i; j>=0; j--) {
          let targetCode = arrElem[j].code;
          if (targetCode === 'GL THICKNESS') {
            frontIndex = j;
            break;
          }
        }

        const arrFront = arrElem.slice(frontIndex, i); // slice는 배열크기 넘어가도 됨
        console.log({ arrFront })

        for(let j=i; j<arrElem.length-1; j++) {
          if (j > i && arrElem[j].code === 'SPACER THICKNESS') {
            rearIndex = j;
            break;
          }
        }

        const arrRearWithFilm = arrElem.slice(i, rearIndex === -1 ? arrElem.length : rearIndex); // slice는 배열크기 넘어가도 됨
        console.log({ arrRearWithFilm })
        let rearSpecification = "";
        arrRearWithFilm.forEach((s, i) => {
          if (i === 0) {
            rearSpecification = s.value.code;  
          } else {
            rearSpecification = rearSpecification + (s.code.indexOf('THICKNESS') >= 0 ? "+" : "|") + s.value.code;
          }
        })

        specification = `${arrFront.map(s => s.value.code).join("|")}+${rearSpecification}`;
      } else {
        specification = arrElem.map(l => l.value.code).join("|");
      }
    }

    return specification;
  },
  getLayerGlassSpecificationNoParentheses : function (arrElem, gtrans) {
    let specification = "";
    if (arrElem && Array.isArray(arrElem)) {
      const i = arrElem.findIndex(e => e.code === 'FILM THICKNESS');
      if (i > 0) { // 접합이 있는 경우
        // 앞판. FILM THICKNESS 앞으로 GL THICKNESS까지
        // 뒷판. FILM THICKNESS 뒤부터 SPACER THICKNESS 전까지 또는 SPACER THICKNESS가 없다면 끝까지
        let frontIndex = -1;
        let rearIndex = -1;
        for(let j=i; j>=0; j--) {
          let targetCode = arrElem[j].code;
          if (targetCode === 'GL THICKNESS') {
            frontIndex = j;
            break;
          }
        }

        const arrFront = arrElem.slice(frontIndex, i); // slice는 배열크기 넘어가도 됨
        console.log({ arrFront })

        for(let j=i; j<arrElem.length-1; j++) {
          if (j > i && arrElem[j].code === 'SPACER THICKNESS') {
            rearIndex = j;
            break;
          }
        }

        const arrRearWithFilm = arrElem.slice(i, rearIndex === -1 ? arrElem.length : rearIndex); // slice는 배열크기 넘어가도 됨
        console.log({ arrRearWithFilm })
        let rearSpecification = "";
        arrRearWithFilm.forEach((s, i) => {
          if (i === 0) {
            rearSpecification = s.value.code; // 실제로는 필름 두께임
          } else {
            // <<<주의 : 코드 하드코딩 사용>>>
            rearSpecification = rearSpecification + (s.code.indexOf('THICKNESS') >= 0 ? "+" : "|") + this.getMaterialItemCodeOrName(s.code, s.value.code);
          }
        })

        specification = `${arrFront.map(s => this.getMaterialItemCodeOrName(s.code, s.value.code)).join("|")}+${rearSpecification}`;
      } else {
        specification = arrElem.map(l => this.getMaterialItemCodeOrName(l.code, l.value.code)).join("|");
      }
    }

    console.log(specification)
    // specification이 접합인 경우가 있고 단판인 경우가 있다.
    let matched;
    const layers = specification.split("+"); // 접합인 경우
    if (layers.length === 3) {
      const ex_glass = layers[0];
      const matched_ex_glass = gtrans.find(i => i.org_name === ex_glass);
      const in_glass = layers[2];
      const matched_in_glass = gtrans.find(i => i.org_name === in_glass);
      if (matched_ex_glass && matched_ex_glass.replace_name && matched_in_glass && matched_in_glass.replace_name) {
        specification = `${matched_ex_glass.replace_name}+${layers[1]}+${matched_in_glass.replace_name}`;
      } else {
        specification = "";
      }
    } else { // 단판인 경우
      matched = gtrans.find(i => i.org_name === specification);
      if (matched) {
        specification = matched.replace_name;
      } else {
        specification = "";
      }
    }

    return specification;
  },
}

export default GlassUtils;