import Cookies from "js-cookie";
import { csrfFetch } from "./csrf.js";
// 자재승인서류를 만드는 서버는 gcoreMAServer, 결과를 조회하는 서버는 gcoreServer를 통한다.
import { gcoreServerUrl } from '../config.js';
import { gcoreMAServerUrl } from '../config.js';

const SET_G04_DOCU_MATERIAL_APPROVAL = 'g04docuMaterialApproval/setG04docuMaterialApproval';
const SET_G04_DOCU_MATERIAL_APPROVALS = 'g04docuMaterialApproval/setG04docuMaterialApprovals';
const REMOVE_G04_DOCU_MATERIAL_APPROVAL = "g04docuMaterialApproval/removeG04docuMaterialApproval";
const SET_G04_DOCU_MATERIAL_APPROVALS_GENERATE = 'g04docuMaterialApproval/setG04docuMaterialApprovalsGenerate';
const SET_G04_DOCU_MATERIAL_APPROVAL_PROGRESS = 'g04docuMaterialApproval/setG04docuMaterialApprovalProgress';

const setG04docuMaterialApproval = (g04docuMaterialApproval) => ({
  type: SET_G04_DOCU_MATERIAL_APPROVAL,
  payload: g04docuMaterialApproval,
});

const setG04docuMaterialApprovals = (g04docuMaterialApprovals) => ({
  type: SET_G04_DOCU_MATERIAL_APPROVALS,
  payload: g04docuMaterialApprovals,
});

const removeG04docuMaterialApproval = () => ({
  type: REMOVE_G04_DOCU_MATERIAL_APPROVAL,
});

const setG04docuMaterialApprovalsGenerate = (g04docuMaterialApprovalsGenerate) => ({
  type: SET_G04_DOCU_MATERIAL_APPROVALS_GENERATE,
  payload: g04docuMaterialApprovalsGenerate,
});

const setG04docuMaterialApprovalProgress = (g04docuMaterialApprovalProgress) => ({
  type: SET_G04_DOCU_MATERIAL_APPROVAL_PROGRESS,
  payload: g04docuMaterialApprovalProgress,
});

// gcoreMAServer 사용
export const makeMaterialApproval = async ({ docType, gclientId, gclient, gprojectId, gproject, generatedInformation, generatedInformationWithDocus, generatedInformationForPdfs, excluded, fileName, comments, generateOptions, domesticAuths }) => {
  const params = [];
  if (docType) {
    params.push(`docType=${encodeURIComponent(docType)}`);
  }
  let querString = params.join("&");

  const response = await csrfFetch(null, `${gcoreMAServerUrl}/api/g04docuMaterialApprovals/makeMaterialApproval${querString ? `?${querString}` : ""}`, {
    method: "POST",
    body: JSON.stringify({ gclientId, gclient, gprojectId, gproject, generatedInformation, generatedInformationWithDocus, generatedInformationForPdfs, excluded, fileName, comments, generateOptions, domesticAuths }),
  });
  
  const data = await response.json();
  return data.g04docuMaterialApproval;
};

// gcoreServer 사용
export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuMaterialApprovals`);
  const data = await response.json();
  dispatch(setG04docuMaterialApprovals(data.g04docuMaterialApprovals));
  return response;
}

export const selectPaging = (queryPaging, docType) => async dispatch => {
  const params = [];
  if (docType) {
    params.push(`docType=${encodeURIComponent(docType)}`);
  }
  let querString = params.join("&");

  const { page, pageSize } = queryPaging;
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuMaterialApprovals/offset/${page}/limit/${pageSize}${querString ? `?${querString}` : ""}`);
  const data = await response.json();
  dispatch(setG04docuMaterialApprovals(data));
  return response;
}

export const selectByGClient = (gclientId, docType) => async dispatch => {
  const params = [];
  if (docType) {
    params.push(`docType=${encodeURIComponent(docType)}`);
  }
  let querString = params.join("&");

  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuMaterialApprovals/gclient/${gclientId}${querString ? `?${querString}` : ""}`);
  const data = await response.json();
  // dispatch(setG04docuMaterialApprovals(data.g04docuMaterialApprovals));
  dispatch(setG04docuMaterialApprovals(data));
  return response;
}

export const selectByGProject = (gprojectId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuMaterialApprovals/gproject/${gprojectId}`);
  const data = await response.json();
  // dispatch(setG04docuMaterialApprovals(data.g04docuMaterialApprovals));
  dispatch(setG04docuMaterialApprovals(data));
  return response;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuMaterialApprovals/${id}`);
  const data = await response.json();
  dispatch(setG04docuMaterialApproval(data.g04docuMaterialApproval));
  return response;
}

export const selectById = (id, docType, initialLoad=false) => async dispatch => {
  const params = [];
  if (docType) {
    params.push(`docType=${encodeURIComponent(docType)}`);
  }
  let querString = params.join("&");

  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuMaterialApprovals/id/${id}${querString ? `?${querString}` : ""}`);
  const data = await response.json();
  console.log(data)
  if (initialLoad) {
    // 초기 로드시 상태를 PROCESSING으로 강제 변경
    const modifiedData = {
      ...data,
      g04docuMaterialApprovals: data.g04docuMaterialApprovals.map(item => ({
        ...item,
        results: { status: 'PROCESSING', details: [] },
      }))
    };
    dispatch(setG04docuMaterialApprovalsGenerate(modifiedData));
  } else {
    dispatch(setG04docuMaterialApprovalsGenerate(data));
  }
  
  return response;
}

export const removeWithFile = ({ id, documentPath, docType }) => async (dispatch) => {
  const params = [];
  if (docType) {
    params.push(`docType=${encodeURIComponent(docType)}`);
  }
  let querString = params.join("&");

  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuMaterialApprovals/${id}/withFile${querString ? `?${querString}` : ""}`, {
    method: "DELETE",
    body: JSON.stringify({ documentPath }),
  });
  dispatch(removeG04docuMaterialApproval());
  return response;
};

export const sendWithLocalFileDirect = async (formData) => {
  const options = {
    method: 'POST',
    // headers: { 'Content-Type': 'multipart/form-data' }, // TODO : 왜 이것을 지정하면 서버에서 못받지??? 추후 분석
    body: formData,
  }

  options.headers = {};
  options.headers["XSRF-Token"] = Cookies.get("XSRF-TOKEN");
  const accessToken = sessionStorage.getItem('accessToken');
  if (accessToken) {
    options.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  const response = await window.fetch(`${gcoreServerUrl}/api/g04docuMaterialApprovals/send/files`, options);
  const data = await response.json();
  return data;
};

export const selectProgressById = (id, docType) => async dispatch => {
  const params = [];
  if (docType) {
    params.push(`docType=${encodeURIComponent(docType)}`);
  }
  let querString = params.join("&");

  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuMaterialApprovals/progress/${id}${querString ? `?${querString}` : ""}`);
  const data = await response.json();
  console.log(data)
  dispatch(setG04docuMaterialApprovalProgress(data.g04docuMaterialApprovalProgress));
  return response;
}

export const initProgress = () => async dispatch => {
  dispatch(setG04docuMaterialApprovalProgress(initialState.g04docuMaterialApprovalProgress));
}

const initialState = {
  g04docuMaterialApproval: null,
  g04docuMaterialApprovals: [],
  g04docuMaterialApprovalsGenerate: [],
  count: 0,
  g04docuMaterialApprovalProgress: null,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_G04_DOCU_MATERIAL_APPROVAL:
      newState = Object.assign({}, state, { g04docuMaterialApproval: action.payload });
      return newState;
    case SET_G04_DOCU_MATERIAL_APPROVALS:
      newState = Object.assign({}, state, { g04docuMaterialApprovals: action.payload.g04docuMaterialApprovals, count: action.payload.count });
      return newState;
    case REMOVE_G04_DOCU_MATERIAL_APPROVAL:
      newState = Object.assign({}, state, { g04docuMaterialApproval: null });
      return newState;
    case SET_G04_DOCU_MATERIAL_APPROVALS_GENERATE:
      newState = Object.assign({}, state, { g04docuMaterialApprovalsGenerate: action.payload.g04docuMaterialApprovals, count: action.payload.count });
      return newState;
    case SET_G04_DOCU_MATERIAL_APPROVAL_PROGRESS:
      newState = Object.assign({}, state, { g04docuMaterialApprovalProgress: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
