import { csrfFetch } from "./csrf.js";
import { authServerUrl, gcoreServerUrl } from '../config.js';

const INIT_G04_DOCU_GCERTIFICATION = 'g04docuGCertification/initG04docuGCertification';
const INIT_G04_DOCU_GCERTIFICATIONS = 'g04docuGCertification/initG04docuGCertifications';
const INIT_G04_DOCU_GCERTIFICATIONS_SUB_MATERIAL_PROCESS = 'g04docuGCertification/initG04docuGCertificationsSubMaterialProcess';
const INIT_G04_DOCU_GCERTIFICATIONS_SUB_MATERIAL_BUILD = 'g04docuGCertification/initG04docuGCertificationsSubMaterialBuild';
const INIT_G04_DOCU_GCERTIFICATIONS_PROCESS = 'g04docuGCertification/initG04docuGCertificationsProcess';
const SET_G04_DOCU_GCERTIFICATION = 'g04docuGCertification/setG04docuGCertification';
const REMOVE_G04_DOCU_GCERTIFICATION = "g04docuGCertification/removeG04docuGCertification";
const SET_G04_DOCU_GCERTIFICATIONS = 'g04docuGCertification/setG04docuGCertifications';
const SET_G04_DOCU_GCERTIFICATIONS_SELECT_COUNT = 'g04docuGCertification/setG04docuGCertificationsSelectCount';
const INIT_G04_DOCU_GCERTIFICATIONS_SELECT_COUNT = 'g04docuGCertification/initG04docuGCertificationsSelectCount';
const SET_G04_DOCU_GCERTIFICATIONS_ALL_SELECT_COUNT = 'g04docuGCertification/setG04docuGCertificationsAllSelectCount';
const SET_G04_DOCU_GCERTIFICATIONS_RAW_MATERIAL = 'g04docuGCertification/setG04docuGCertificationsRawMaterial';
const SET_G04_DOCU_GCERTIFICATIONS_SUB_MATERIAL_PROCESS = 'g04docuGCertification/setG04docuGCertificationsSubMaterialProcess';
const SET_G04_DOCU_GCERTIFICATIONS_SUB_MATERIAL_BUILD = 'g04docuGCertification/setG04docuGCertificationsSubMaterialBuild';
const SET_G04_DOCU_GCERTIFICATIONS_PROCESS = 'g04docuGCertification/setG04docuGCertificationsProcess';
const SET_CLASSIFICATIONS_DISTINCT = 'g04docuGCertification/setClassificationsDistinct';

const initG04docuGCertification = () => ({
  type: INIT_G04_DOCU_GCERTIFICATION,
});

const initG04docuGCertifications = () => ({
  type: INIT_G04_DOCU_GCERTIFICATIONS,
});

const initG04docuGCertificationsSubMaterialProcess = () => ({
  type: INIT_G04_DOCU_GCERTIFICATIONS_SUB_MATERIAL_PROCESS,
});

const initG04docuGCertificationsSubMaterialBuild = () => ({
  type: INIT_G04_DOCU_GCERTIFICATIONS_SUB_MATERIAL_BUILD,
});

const initG04docuGCertificationsProcess = () => ({
  type: INIT_G04_DOCU_GCERTIFICATIONS_PROCESS,
});

const setG04docuGCertification = (g04docuGCertification) => ({
  type: SET_G04_DOCU_GCERTIFICATION,
  payload: g04docuGCertification,
});

const setClassificationsDistinct = (classificationsDistinct) => ({
  type: SET_CLASSIFICATIONS_DISTINCT,
  payload: classificationsDistinct,
});

const removeG04docuGCertification = () => ({
  type: REMOVE_G04_DOCU_GCERTIFICATION,
});

const setG04docuGCertifications = (g04docuGCertifications) => ({
  type: SET_G04_DOCU_GCERTIFICATIONS,
  payload: g04docuGCertifications,
});

const setG04docuGCertificationsRawMaterial = (g04docuGCertificationsRawMaterial) => ({
  type: SET_G04_DOCU_GCERTIFICATIONS_RAW_MATERIAL,
  payload: g04docuGCertificationsRawMaterial,
});

const setG04docuGCertificationsSubMaterialProcess = (g04docuGCertificationsSubMaterialProcess) => ({
  type: SET_G04_DOCU_GCERTIFICATIONS_SUB_MATERIAL_PROCESS,
  payload: g04docuGCertificationsSubMaterialProcess,
});

const setG04docuGCertificationsSubMaterialBuild = (g04docuGCertificationsSubMaterialBuild) => ({
  type: SET_G04_DOCU_GCERTIFICATIONS_SUB_MATERIAL_BUILD,
  payload: g04docuGCertificationsSubMaterialBuild,
});

const setG04docuGCertificationsProcess = (g04docuGCertificationsProcess) => ({
  type: SET_G04_DOCU_GCERTIFICATIONS_PROCESS,
  payload: g04docuGCertificationsProcess,
});

const setG04docuGCertificationsSelectCount = () => ({
  type: SET_G04_DOCU_GCERTIFICATIONS_SELECT_COUNT,
});

const initG04docuGCertificationsSelectCount = () => ({
  type: INIT_G04_DOCU_GCERTIFICATIONS_SELECT_COUNT,
});

const setG04docuGCertificationsAllSelectCount = (count) => ({
  type: SET_G04_DOCU_GCERTIFICATIONS_ALL_SELECT_COUNT,
  payload: count,
});

export const create = ({ id, division, name, code, classifications, divider, comments, majority, gstandardId, includeTest }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGCertifications`, {
    method: "POST",
    body: JSON.stringify({ id, division, name, code, classifications, divider, comments, majority, gstandardId, includeTest }),
  });
  const data = await response.json();
  dispatch(setG04docuGCertification(data.g04docuGCertification));
  return response;
};

export const modify = ({ id, division, name, code, classifications, divider, comments, majority, gstandardId, includeTest }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGCertifications`, {
    method: "PUT",
    body: JSON.stringify({ id, division, name, code, classifications, divider, comments, majority, gstandardId, includeTest }),
  });
  const data = await response.json();
  dispatch(setG04docuGCertification(data.g04docuGCertification));
  return response;
};

export const modifyDivision = ({ id, division }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGCertifications/division`, {
    method: "PUT",
    body: JSON.stringify({ id, division }),
  });
  const data = await response.json();
  dispatch(setG04docuGCertification(data.g04docuGCertification));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGCertifications`);
  const data = await response.json();
  dispatch(setG04docuGCertifications(data.g04docuGCertifications));
  return response;
}

export const selectAllByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGCertifications/query/selectAll`);
  const data = await response.json();
  dispatch(setG04docuGCertifications(data.g04docuGCertifications));
  return response;
}

export const selectByDivisionByQuery = (division) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGCertifications/query/selectAll/division/${division}`);
  const data = await response.json();
  dispatch(setG04docuGCertifications(data.g04docuGCertifications));
  return response;
}

export const selectByDivision = (division) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGCertifications/division/${division}`);
  const data = await response.json();
  dispatch(setG04docuGCertifications(data.g04docuGCertifications));
  return response;
}

export const selectByDivisionDirect = async (division) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuGCertifications/division/${division}`);
  const data = await response.json();
  return data.g04docuGCertifications;
}

export const selectByDivisionByQueryDirect = async (division) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuGCertifications/query/selectAll/division/${division}`);
  const data = await response.json();
  return data.g04docuGCertifications;
}

export const selectByDivisionWithItemsByQuery = (division) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGCertifications/query/selectAllWithItems/division/${division}`);
  const data = await response.json();
  if (division === 'RAW_MATERIAL') {
    dispatch(setG04docuGCertificationsRawMaterial(data.g04docuGCertifications));
  } else if (division === 'SUB_MATERIAL_PROCESS') {
    dispatch(setG04docuGCertificationsSubMaterialProcess(data.g04docuGCertifications));
  } else if (division === 'SUB_MATERIAL_BUILD') {
    dispatch(setG04docuGCertificationsSubMaterialBuild(data.g04docuGCertifications));
  } else if (division === 'PROCESS') {
    dispatch(setG04docuGCertificationsProcess(data.g04docuGCertifications));
  }
  
  return response;
}

export const selectAllMyProcessesByStatusForTree = (gclientId, status) => async dispatch => {
  // 거래선 세부타입에 해당하는 규격도 추가로 조회함
  const res = await csrfFetch(null, `${authServerUrl}/api/gclients/query/selectG04docuGCertificationIds/gclient/${gclientId}`);
  const dat = await res.json();
  const g04docuGCertificationIds = dat.g04docuGCertificationIds;
  console.log(g04docuGCertificationIds);

  if (g04docuGCertificationIds && Array.isArray(g04docuGCertificationIds) && g04docuGCertificationIds.length > 0) {
    const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGCertifications/myProcesses/tree/gclient/${gclientId}/status/${status}`, {
      method: "POST",
      body: JSON.stringify({ g04docuGCertificationIds }),
    });
    const data = await response.json();
    dispatch(setG04docuGCertificationsProcess(data.g04docuGCertifications));
    return response;
  } else {
    dispatch(setG04docuGCertificationsProcess([]));
  }
};

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGCertifications/${id}`);
  const data = await response.json();
  dispatch(setG04docuGCertification(data.g04docuGCertification));
  return response;
}

export const selectByDirect = async (id) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuGCertifications/${id}`);
  const data = await response.json();
  return data.g04docuGCertification;
}

export const selectClassificationsDistinct = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGCertifications/query/selectClassificationsDistinct`);
  const data = await response.json();
  dispatch(setClassificationsDistinct(data.classificationsDistinct));
  return response;
}

export const initializeG04docuGCertifications = () => async dispatch => {
  dispatch(initG04docuGCertifications());
}

export const initializeG04docuGCertificationsSubMaterialProcess = () => async dispatch => {
  dispatch(initG04docuGCertificationsSubMaterialProcess());
}

export const initializeG04docuGCertificationsSubMaterialBuild = () => async dispatch => {
  dispatch(initG04docuGCertificationsSubMaterialBuild());
}

export const initializeG04docuGCertificationsProcess = () => async dispatch => {
  dispatch(initG04docuGCertificationsProcess());
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGCertifications/${id}`, {
    method: "DELETE",
  });
  dispatch(removeG04docuGCertification());
  return response;
};

export const increaseG04docuGCertificationsSelectCount = () => async (dispatch) => {
  dispatch(setG04docuGCertificationsSelectCount());
}

export const initializeG04docuGCertificationsSelectCount = () => async (dispatch) => {
  dispatch(initG04docuGCertificationsSelectCount());
}

export const initializeG04docuGCertification = () => async (dispatch) => {
  dispatch(initG04docuGCertification());
}

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuGCertifications/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

export const modifyRowOrder = async ({ id, type, toOrder, groupCode }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuGCertifications/reorder`, {
    method: "POST",
    body: JSON.stringify({ id, type, toOrder, groupCode }),
  });
  
  return response;
}

const initialState = {
  g04docuGCertification: null,
  g04docuGCertifications: [],
  g04docuGCertificationsSelectCount: 0,
  g04docuGCertificationsAllSelectCount: 0,
  g04docuGCertificationsRawMaterial: [],
  g04docuGCertificationsSubMaterialProcess: [],
  g04docuGCertificationsSubMaterialBuild: [],
  g04docuGCertificationsProcess: [],
  classificationsDistinct: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_G04_DOCU_GCERTIFICATION:
      newState = Object.assign({}, state, { g04docuGCertification: null });
      return newState;
    case INIT_G04_DOCU_GCERTIFICATIONS:
      newState = Object.assign({}, state, { g04docuGCertifications: initialState.g04docuGCertifications });
      return newState;
    case INIT_G04_DOCU_GCERTIFICATIONS_SUB_MATERIAL_PROCESS:
      newState = Object.assign({}, state, { g04docuGCertificationsSubMaterialProcess: initialState.g04docuGCertificationsSubMaterialProcess });
      return newState;
    case INIT_G04_DOCU_GCERTIFICATIONS_SUB_MATERIAL_BUILD:
      newState = Object.assign({}, state, { g04docuGCertificationsSubMaterialBuild: initialState.g04docuGCertificationsSubMaterialBuild });
      return newState;
    case INIT_G04_DOCU_GCERTIFICATIONS_PROCESS:
      newState = Object.assign({}, state, { g04docuGCertificationsProcess: initialState.g04docuGCertificationsProcess });
      return newState;
    case SET_G04_DOCU_GCERTIFICATION:
      newState = Object.assign({}, state, { g04docuGCertification: action.payload });
      return newState;
    case REMOVE_G04_DOCU_GCERTIFICATION:
      newState = Object.assign({}, state, { g04docuGCertification: null });
      return newState;
    case SET_G04_DOCU_GCERTIFICATIONS:
      newState = Object.assign({}, state, { g04docuGCertifications: action.payload });
      return newState;
    case SET_G04_DOCU_GCERTIFICATIONS_SELECT_COUNT:
      const count = state.g04docuGCertificationsSelectCount + 1;
      newState = Object.assign({}, state, { g04docuGCertificationsSelectCount: count });
      return newState;
    case INIT_G04_DOCU_GCERTIFICATIONS_SELECT_COUNT:
      newState = Object.assign({}, state, { g04docuGCertificationsSelectCount: 0 });
      return newState;
    case SET_G04_DOCU_GCERTIFICATIONS_ALL_SELECT_COUNT:
      newState = Object.assign({}, state, { g04docuGCertificationsAllSelectCount: action.payload });
      return newState;
    case SET_G04_DOCU_GCERTIFICATIONS_RAW_MATERIAL:
      newState = Object.assign({}, state, { g04docuGCertificationsRawMaterial: action.payload });
      return newState;
    case SET_G04_DOCU_GCERTIFICATIONS_SUB_MATERIAL_PROCESS:
      newState = Object.assign({}, state, { g04docuGCertificationsSubMaterialProcess: action.payload });
      return newState;
    case SET_G04_DOCU_GCERTIFICATIONS_SUB_MATERIAL_BUILD:
      newState = Object.assign({}, state, { g04docuGCertificationsSubMaterialBuild: action.payload });
      return newState;
    case SET_G04_DOCU_GCERTIFICATIONS_PROCESS:
      newState = Object.assign({}, state, { g04docuGCertificationsProcess: action.payload });
      return newState;
    case SET_CLASSIFICATIONS_DISTINCT:
      newState = Object.assign({}, state, { classificationsDistinct: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
