import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGridPro, koKR } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
} from '@mui/material';
import {
  CustomNoRowsOverlay,
} from "../datagrid";
import * as securityRequestActions from "../../store/securityRequest";

const theme = createTheme();

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const defaultValues = {
  gclient: {},
};

const SecurityRequestApproval = () => {
  const [loadedRequest, setLoadedRequest] = useState(false);
  const [pageSizeRequest, setPageSizeRequest] = useState(100);
  const [pageRequest, setPageRequest] = useState(0);
  const [selectionModelRequest, setSelectionModelRequest] = useState([]);
  const [selectedRowsRequest, setSelectedRowsRequest] = useState([]);

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const rowsToRequest = useSelector((state) => state.securityRequest.securityToRequests);

  const dispatch = useDispatch();

  const selecteSecurityToRequestsByQuery = (senderId) => dispatch(securityRequestActions.selecteSecurityToRequestsByQuery(senderId))
  const modifySecurityRequestStatus = ({ id, senderId, receiverId, service, status }) => dispatch(securityRequestActions.modifyStatus({ id, senderId, receiverId, service, status }))
  const modifySecurityRequestsByIdsStatus = ({ ids, senderId, receiverIds, service, status }) => dispatch(securityRequestActions.modifyStatusByIds({ ids, senderId, receiverIds, service, status }))

  const columnsRequest = [
    {
      field: 'funcions',
      headerName: '상태/기능',
      width: 140,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { status } = params.row;
        if (status) {
          if (status === '요청') {
            return (
              <Button
                disabled
                sx={{ borderRadius: 30, maxWidth: '60px', maxHeight: '60px', minWidth: '60px', minHeight: '60px', backgroundImage: 'url("/requesting.png")', backgroundSize: "60px 60px" }}
              >             
              </Button>
            )
          } else if (status === '비공개') {
            return (
              <Button
                onClick={(e) => handleClickRequestAgain(e, params)}
                sx={{ borderRadius: 30, maxWidth: '60px', maxHeight: '60px', minWidth: '60px', minHeight: '60px', backgroundImage: 'url("/request-again.png")', backgroundSize: "60px 60px" }}
              >             
              </Button>
            )
          }
        }
      },
    },
    {
      field: 'bizRegNumber',
      headerName: '사업자등록번호',
      width: 160,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'name',
      headerName: '거래선명',
      width: 240,
    },
    {
      field: 'ceo',
      headerName: '대표자',
      width: 240,
    },
    {
      field: 'phone',
      headerName: '대표전화번호',
      width: 160,
    },
    {
      field: 'email',
      headerName: '회사대표메일',
      width: 200,
    },
    {
      field: 'address',
      headerName: '주소',
      width: 240,
    },
  ];

  useEffect(
    async () => {
      selectToRequests();
    }, [dispatch]
  );

  const handleClickRequestAgain = async (e, params) => {
    e.stopPropagation();

    // 자재승인서 발급 부분에서는 id가 gclientId임. GClients LEFT JOIN SecurityRequests
    // 여기서는 SecurityRequests LEFT JOIN GClients 임
    const { id } = params.row;
    console.log(params)
    const senderId = sessionUser.id;
    const receiverId = params.row.gclientId;
    await modifySecurityRequestStatus({ id, senderId, receiverId, service: '04docu', status: '요청' });

    selectToRequests();
  }

  const handleClickRequestAgainSelected = async () => {
    // console.log(selectionModelRequest)
    // 내부적으로 목록을 반환하므로 redux를 통해 다시 refresh 할 수 있지만 api에서 리턴되는 목록의 세부 항목이 다르므로 selectToRequests 호출
    const senderId = sessionUser.id;
    const receiverIds = selectedRowsRequest.map(row => row.gclientId);
    console.log({ senderId, receiverIds })
    
    await modifySecurityRequestsByIdsStatus({ ids: selectionModelRequest, senderId, receiverIds, service: '04docu', status: '요청' });

    selectToRequests();

    setSelectionModelRequest([]);
    setSelectedRowsRequest([]);
  }

  const selectToRequests = async () => {
    await selecteSecurityToRequestsByQuery(sessionUser.id);
    
    setTimeout(() => setLoadedRequest(true), 300);
  }

  const handlePageChangeRequest = (newPage) => {
    setPageRequest(newPage);
  }

  return (
    <Paper sx={{ p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
            <Grid container>
              <Grid item xs={6} display="flex" alignItems="center">
                {"열람요청"}
              </Grid>
              <Grid item xs={6} display="flex" justifyContent={"flex-end"} alignItems="center">
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleClickRequestAgainSelected}
                  disabled={selectionModelRequest.length === 0}
                >
                  {"재요청"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: 400, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              // density="standard"
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              headerHeight={64}
              rowHeight={64}
              loading={!loadedRequest}
              rows={rowsToRequest}
              columns={columnsRequest}
              pageSize={pageSizeRequest}
              onPageSizeChange={(newPageSize) => setPageSizeRequest(newPageSize)}
              rowsPerPageOptions={[1, 10, 20, 50, 100]}
              onPageChange={handlePageChangeRequest}
              page={pageRequest}
              pagination
              disableColumnMenu
              checkboxSelection
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                console.log({ ids, rowsToRequest });
                const newIds = rowsToRequest.filter(row => selectedIDs.has(row.id) && row.status === '비공개');
                console.log(newIds)
                // setSelectionModelRequest(ids);
                setSelectionModelRequest(newIds.map(i => i.id));
                
                const selected = rowsToRequest.filter(row => selectedIDs.has(row.id) && row.status === '비공개');
                setSelectedRowsRequest(selected);
              }}
              selectionModel={selectionModelRequest}
            />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SecurityRequestApproval;