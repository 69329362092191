import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { DataGridPro, koKR } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Grid,
  LinearProgress,
  Paper,
} from '@mui/material';
import {
  FormInputAutoComplete,
  FormInputText,
} from "../form";
import {
  CustomNoRowsOverlay,
} from "../datagrid";
import {
  dateFormat,
  objectEmptyCheck,
} from "../../utils";
import * as gprojectMapActions from "../../store/gprojectMap";

const theme = createTheme();

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const defaultValues = {
  gclient: {},
};

const columnsProjectMapBasic = [
  {
    field: 'no',
    headerName: '품번',
    width: 100,
    valueGetter: (params) => {
      if (params.value) {
        return params.value;
      } else {
        return "-";
      }
    },
  },
  {
    field: 'specification',
    headerName: '사양',
    width: 300,
    valueGetter: (params) => {
      if (params.value) {
        return params.value.replaceAll("|", " ").replaceAll("+", " + ");
      } else {
        return "-";
      }
    },
  },
  {
    field: 'division',
    headerName: '구분',
    width: 140,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => {
      let division = "";
      const { value } = params;
      if (value === 'RAW_MATERIAL') {
        division = "원자재";
      } else if (value === 'SUB_MATERIAL_PROCESS') {
        division = "가공부자재";
      } else if (value === 'SUB_MATERIAL_BUILD') {
        division = "시공부자재";
      } else if (value === 'PROCESS') {
        division = "공정";
      }
      return division;
    },
  },
  {
    field: 'type',
    headerName: '공정구분',
    width: 140,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => {
      let type = "-";
      const { value } = params;
      if (value === 'HEAT_STRENGTHENED') {
        type = "배강도유리";
      } else if (value === 'TEMPERED') {
        type = "강화유리";
      } else if (value === 'LAMINATED') {
        type = "접합유리";
      } else if (value === 'INSULATED_GLAZING') {
        type = "가스주입 단열유리";
      } else if (value === 'INSULATED_GLASS_UNIT') {
        type = "복층유리";
      } else if (value === 'ONE_PANE') {
        type = "단품유리(절단)";
      }
      return type;
    },
  },
  {
    field: 'itemName',
    headerName: '제품명',
    width: 200,
    valueGetter: (params) => {
      // console.log(params.row)
      const { division, selectedGcomponentItems, selectedSubMaterialProcessItems,  selectedSubMaterialBuildItems } = params.row;
      let itemName = "-";
      if (division === 'RAW_MATERIAL') {
        selectedGcomponentItems?.forEach((gtypeDetails, i) => {
          gtypeDetails.forEach((item, j) => {
            if (j === 0) { // 각 레이어의 첫번째는 상세 구성요소의 상위 개념 (예를 들면 유리원판, 중공층 등...)으로 스킵
              return;
            }
            
            const { code, value } = item;
            if (code === 'GLASS PRODUCT NAME') { // <<<주의 : 코드 하드코딩 사용>>>
              const { id, name } = value;
              if (id === params.row.gcomponentItemId) {
                itemName = name;
                return;
              }
            }
          })
        });
      } else if (division === 'SUB_MATERIAL_PROCESS') {
        selectedSubMaterialProcessItems.forEach(item => {
          const { usable, value } = item;
          if (usable && !objectEmptyCheck(value)) {
            const { id, name } = value;
            if (id === params.row.gcomponentItemId) {
              itemName = name;
              return;
            }
          }
        })
      } else if (division === 'SUB_MATERIAL_BUILD') {
        selectedSubMaterialBuildItems.forEach(item => {
          const { usable, value } = item;
          if (usable && !objectEmptyCheck(value)) {
            const { id, name } = value;
            if (id === params.row.gcomponentItemId) {
              itemName = name;
              return;
            }
          }
        })
      }
      return itemName;
    },
  },
];

const ProjectParticipation = ({ gclients }) => {
  const [selectedParticipantId, setSelectedParticipantId] = useState("");
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [loadedJoinedProject, setLoadedJoinedProject] = useState(false);
  const [pageJoinedProject, setPageJoinedProject] = useState(0);
  const [pageSizeJoinedProject, setPageSizeJoinedProject] = useState(100);
  const [loadedProjectMap, setLoadedProjectMap] = useState(true);
  const [pageProjectMap, setPageProjectMap] = useState(0);
  const [pageSizeProjectMap, setPageSizeProjectMap] = useState(100);
  const [columnsProjectMap, setColumnsProjectMap] = useState(columnsProjectMapBasic);
  const [participantValue, setParticipantValue] = useState(null);

  const { control, setValue, getValues } = useForm({ defaultValues });

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const rowsProjectMap = useSelector((state) => state.gprojectMap.gprojectMaps);
  const rowsJoinedProject = useSelector((state) => state.gprojectMap.gprojects);

  const dispatch = useDispatch();
  
  const selectGProjectMapsByGProjectAndOwnerByQuery = (gprojectId, ownerId) => dispatch(gprojectMapActions.selectAllByGProjectAndOwnerByQuery(gprojectId, ownerId));
  const selectJoinedGProjectsByOwnerByQuery = (ownerId) => dispatch(gprojectMapActions.selectGProjectsByOwnerByQuery(ownerId));
  const selectGProjectsBySearchByQuery = (ownerId, searchName, searchSite) => dispatch(gprojectMapActions.selectGProjectsBySearchByQuery(ownerId, searchName, searchSite));
  const initGprojectMaps = () => dispatch(gprojectMapActions.initGprojectMaps());

  const columnsJoinedProject = [
    {
      field: 'owner',
      headerName: '공급업체',
      width: 200,
      valueGetter: (params) => params.row?.owner?.name,
    },
    {
      field: 'site',
      headerName: '현장명',
      width: 200,
    },
    {
      field: 'siteAddress',
      headerName: '현장주소',
      width: 200,
    },
    {
      field: 'constructionCompanyName',
      headerName: '건설사명',
      width: 160,
    },
    {
      field: 'completionDate',
      headerName: '준공일',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value && dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'startDate',
      headerName: '시작',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'endDate',
      headerName: '마감',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
  ];
  
  useEffect(
    async () => {
      const { id } = sessionUser;

      await selectJoinedGProjectsByOwnerByQuery(id);
      setLoadedJoinedProject(true);

      setSelectedParticipantId(id);
      
      // 해당 거래선 세분류에 맞게 컬럼 조정
      adjustColumnsProjectMapByGlient(sessionUser);
    }, [dispatch]
  );

  const adjustColumnsProjectMapByGlient = (gclient) => {
    const { gclientType04docus } = gclient;
    // gclientType04docus가 null일 경우 TypeError: Cannot convert undefined or null to object 발생하여 아래 처리
    if (gclientType04docus && !objectEmptyCheck(gclientType04docus) && Array.isArray(gclientType04docus)) {
      const arrColumnNames = [];
      gclientType04docus.forEach(type => {
        const { code } = type;
        if (code === 'HEAT_STRENGTHENED' || code === 'TEMPERED' || code === 'LAMINATED' || code === 'INSULATED_GLASS_UNIT') {
          arrColumnNames.push('품번', '사양', '구분', '공정구분');
        } else if (code === 'MANUFACTURER_RAW_MATERIAL') {
          arrColumnNames.push('품번', '사양', '구분', '제품명');
        } else if (code === 'MANUFACTURER_SUB_MATERIAL_PROCESS' || code === 'MANUFACTURER_SUB_MATERIAL_BUILD') {
          arrColumnNames.push('구분', '제품명');
        }
      })

      const columnNames = [...new Set([...arrColumnNames])]; // 중복제거

      setColumnsProjectMap(columnsProjectMapBasic.filter(column => columnNames.find(name => name === column.headerName)));
    }
  }

  const handleChangeParticipants = async (e, value) => {
    if (!value) return;

    initGprojectMaps();
    
    const participantId = value.id;
    setParticipantValue(value); // 선택된 value를 state에 저장
    setSelectedParticipantId(participantId);
    const newSelectedParticipant = gclients.find(gclient => gclient.id === participantId);
    if (newSelectedParticipant) {
      setSelectedParticipant(newSelectedParticipant);
    }

    // 해당 거래선 세분류에 맞게 컬럼 조정
    adjustColumnsProjectMapByGlient(newSelectedParticipant);
    

    // 참여 프로젝트 검색
    setLoadedJoinedProject(false);
    await selectJoinedGProjectsByOwnerByQuery(participantId);
    setLoadedJoinedProject(true);
  }

  const handlePageChangeJoinedProject = (newPage) => {
    setPageJoinedProject(newPage);
  }
  
  const handlePageChangeProjectMap = (newPage) => {
    setPageProjectMap(newPage);
  }

  var timer;
  const handleChangeSearchParticipants = async (e) => {
    console.log({ name: getValues("searchNameParticipants"), site: getValues("searchSiteParticipants"), selectedParticipantId })
    const searchName = getValues("searchNameParticipants");
    const searchSite = getValues("searchSiteParticipants");
    // // 과도한 API 호출 방지를 위한 debouncing 코드. TODO : 라이브러리 활용 검토
    if (timer) {
      clearTimeout(timer);
    }
    
    // 타이머 설정
    timer = setTimeout(async () => {
      setLoadedJoinedProject(false);
      await selectGProjectsBySearchByQuery(selectedParticipantId, searchName, searchSite);
      setLoadedJoinedProject(true);
    }, 500);
  };

  return (
    <Paper sx={{ p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
            <Grid container>
              <Grid item xs={12} sm={10} display="flex" alignItems="center">
                {`참여 프로젝트 현황 (${rowsJoinedProject.length})`}
              </Grid>
              {
                sessionUser.type === 'ADMIN' && (<Grid item xs={12} sm={2} display="flex" justifyContent={"flex-end"} alignItems="center">
                  <FormInputAutoComplete
                    name={"participants"}
                    control={control}
                    label={"거래선명"}
                    options={gclients.map(gclient => ({ label: gclient.name, id: gclient.id }))}
                    setValue={setValue}
                    onCustomChange={handleChangeParticipants}
                    value={participantValue}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '34px',
                      },
                      '& .MuiFormLabel-root': {
                        fontSize: '0.8rem',
                        // marginTop: 0.3,
                      },
                      '& .MuiInputBase-input': {
                        fontSize: '0.8rem',
                      },
                      '& .MuiFormLabel-filled': {
                        marginTop: 0.3,
                      }
                    }}
                  />
                </Grid>)
              }
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <FormInputText
                    name={"searchNameParticipants"}
                    control={control}
                    label={"공급업체 또는 건설사명으로 검색하세요."}
                    onCustomChange={handleChangeSearchParticipants}
                    sx={{
                      '& .MuiInputBase-root': {
                        background: "#E3EEFA"
                      },
                      '& .MuiFormLabel-root' : {
                        color: '#2196f3',
                        fontSize: '0.85rem',
                      },
                      input: { color: '#2196f3', fontSize: '0.85rem' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormInputText
                    name={"searchSiteParticipants"}
                    control={control}
                    label={"현장명 또는 현장주소로 검색하세요."}
                    onCustomChange={handleChangeSearchParticipants}
                    sx={{
                      '& .MuiInputBase-root': {
                        background: "#E3EEFA"
                      },
                      '& .MuiFormLabel-root' : {
                        color: '#2196f3',
                        fontSize: '0.85rem',
                      },
                      input: { color: '#2196f3', fontSize: '0.85rem' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ height: 400, width: '100%' }}>
                    <DataGridPro
                      localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                      // density="standard"
                      sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                      initialState={{ pinnedColumns: { right: ['actions'] } }}
                      slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: LinearProgress,
                      }}
                      columnHeaderHeight={38}
                      rowHeight={34}
                      loading={!loadedJoinedProject}
                      rows={rowsJoinedProject}
                      columns={columnsJoinedProject}
                      pageSize={pageSizeJoinedProject}
                      onPageSizeChange={(newPageSize) => setPageSizeJoinedProject(newPageSize)}
                      rowsPerPageOptions={[1, 10, 20, 50, 100]}
                      onPageChange={handlePageChangeJoinedProject}
                      page={pageJoinedProject}
                      pagination
                      disableColumnMenu
                      disableMultipleRowSelection={true}
                      onRowSelectionModelChange={async ids => {
                        if (ids.length === 1) {
                          const gprojectId = ids[0];
                          setLoadedProjectMap(false);
                          await selectGProjectMapsByGProjectAndOwnerByQuery(gprojectId, selectedParticipantId);
                          setLoadedProjectMap(true);
                        }
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ height: 445, width: '100%' }}>
                <DataGridPro
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                  // density="standard"
                  sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                  initialState={{ pinnedColumns: { right: ['actions'] } }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: LinearProgress,
                  }}
                  columnHeaderHeight={38}
                  rowHeight={34}
                  loading={!loadedProjectMap}
                  rows={rowsProjectMap}
                  columns={columnsProjectMap}
                  pageSize={pageSizeProjectMap}
                  onPageSizeChange={(newPageSize) => setPageSizeProjectMap(newPageSize)}
                  rowsPerPageOptions={[1, 10, 20, 50, 100]}
                  onPageChange={handlePageChangeProjectMap}
                  page={pageProjectMap}
                  pagination
                  disableColumnMenu
                  // onRowSelectionModelChange={(ids) => {
                  //   const selectedIDs = new Set(ids);
                  //   setSelectionModelApproval(ids);
                    
                  //   const selected = rowsToApproval.filter((row) => selectedIDs.has(row.id));
                  //   setSelectedRowsApproval(selected);
                  // }}
                  // selectionModel={selectionModelApproval}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProjectParticipation;