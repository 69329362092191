import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  ListItemIcon,
  Grid,
  Stack,
} from '@mui/material';
import {
  Add,
} from '@mui/icons-material';

import { Controller } from "react-hook-form";
// import { areDayPropsEqual } from "@mui/lab/PickersDay/PickersDay";

const FormInputText = ({ name, initialValue, control, label, size = "small", options, color, focused, onCustomChange, onCustomBlur, onEdit, autoSelectOneOption, setAutoSelectParam, setAutoSelectValue, errorStyle, inputRef, numberOnly,mobileFormat,telFormat, ...props }) => {
  // console.log({ name, options })
  const [selectedValue, setSelectedValue] = useState("");

  const generateSingleOptions = () => {
    return options && options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value} disabled={option.disabled !== undefined ? option.disabled : false}>
          {
            option.ico ? (
              <Stack direction="row">
                <img src={option.ico} style={{ width: 20, height: 20, marginRight: '10px' }}/>
                {option.label}
              </Stack>
              // <>
              //   <Grid container>
              //     <Grid item xs={2} display="flex" justifyContent={"flex-end"} alignItems={"center"} sx={{ ml: 1 }}>
              //       <ListItemIcon>
              //         <img src={option.ico} style={{ width: 20, height: 20 }}/>
              //       </ListItemIcon>
              //     </Grid>
              //     <Grid item xs={10} display="flex" justifyContent={"flex-start"} alignItems={"center"}>
              //       {option.label}
              //     </Grid>
              //   </Grid>
              // </>
            ) : (
              <>
                {option.label}
              </>
            )
          }
        </MenuItem>
      );
    });
  };
  
  // autoSelectOneOption 옵션이 있고 select 인 경우 유효한 값이 하나인 경우 자동 선택하기 위한 부분
  //  "없음"이 있을 때 없을 때 구분하여 동작 => 그럴 필요가 없어보임
  useEffect(
    () => {
      if (autoSelectOneOption && options && Array.isArray(options)) {
        if (options.length === 0) { // 없는 경우 이렇게 초기화해주지 않으면 기존값이 그대로 설정되어 있어 label이 상단에 위치하고 값이 없는 상태가 됨
          setSelectedValue("");
          setAutoSelectValue && setAutoSelectParam && setAutoSelectValue({ ...setAutoSelectParam, selectedGClientId: "" });
        } /*else if (options.length === 1) {
          const selected = options.filter(option => option.label !== '없음' && option.value !== '')
          if (selected.length === 1) {
            setSelectedValue(selected[0].value);
          } else {
            setSelectedValue("");
          }
        } else if (options.length === 2 && options.filter(option => option.label === '없음' && option.value === '').length > 0) {
          const selected = options.filter(option => option.label !== '없음' && option.value !== '')
          if (selected.length === 1) {
            setSelectedValue(selected[0].value);
          }
        }*/ else if (options.length > 0) {
          const selected = options.filter(option => option.label !== '없음' && option.value !== '')
          if (selected.length === 1) {
            setSelectedValue(selected[0].value);
            setAutoSelectValue && setAutoSelectParam  && setAutoSelectValue({ ...setAutoSelectParam, selectedGClientId: selected[0].value });
          } else {
            setSelectedValue("");
            setAutoSelectValue && setAutoSelectParam  && setAutoSelectValue({ ...setAutoSelectParam, selectedGClientId: "" });
          }
        }
      }
    }, [options]
  )

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
        formState,
      }) => {
        // const { select } = props;
        // if (select) {
        //   console.log(selectedValue)
        //   console.log(value)
        // }
        const formatTelNumber = (value) => {
          const numbers = value.replace(/[^\d]/g, '');
          
          // 서울 지역번호 (02)
          if (numbers.startsWith('02')) {
            if (numbers.length <= 2) return numbers;
            if (numbers.length <= 5) return `${numbers.slice(0, 2)}-${numbers.slice(2)}`;
            if (numbers.length <= 9) return `${numbers.slice(0, 2)}-${numbers.slice(2, 5)}-${numbers.slice(5)}`;
            return `${numbers.slice(0, 2)}-${numbers.slice(2, 6)}-${numbers.slice(6, 10)}`;
          } 
          // 기타 지역번호 (0##)
          else {
            if (numbers.length <= 3) return numbers;
            if (numbers.length <= 6) return `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
            if (numbers.length <= 10) return `${numbers.slice(0, 3)}-${numbers.slice(3, 6)}-${numbers.slice(6)}`;
            return `${numbers.slice(0, 3)}-${numbers.slice(3, 7)}-${numbers.slice(7, 11)}`;
          }
        };        
        const formatMobileNumber = (value) => {
          const numbers = value.replace(/[^\d]/g, '');
          if (numbers.length <= 3) return numbers;
          if (numbers.length <= 7) return `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
          return `${numbers.slice(0, 3)}-${numbers.slice(3, 7)}-${numbers.slice(7, 11)}`;
        };
        const handleChange = (e) => {
          let newValue = e.target.value;
          if (numberOnly || mobileFormat || telFormat) {
            newValue = newValue.replace(/[^0-9]/g, '');
          }
          if (mobileFormat) {
            newValue = formatMobileNumber(newValue);
          } else if (telFormat) {
            newValue = formatTelNumber(newValue);
          }
          e.target.value = newValue;
          onChange(e);
          onEdit && onEdit();
          onCustomChange && onCustomChange(e);
        };
        return (
          <TextField
            label={label}
            helperText={errorStyle?.message && error ? error.message : null}
            size={size}
            error={errorStyle?.border && !!error}
            color={color}
            focused={focused}
            // TODO : 이렇게 하면 두번 션택해야 함. 선택시 추가 작업을 하려면 어떻게 해야 하는지 방법을 찾아볼 것
            // onChange={(e) => { onChange(e); onCustomChange(e); }}
            // onChange={onChange}
            onChange={handleChange}
            onBlur={(e) => {
              onBlur(e);
              onCustomBlur && onCustomBlur(e);
            }}
            value={initialValue !== undefined ? initialValue : (autoSelectOneOption && selectedValue ? selectedValue : value || '')}
            fullWidth
            variant="outlined"
            inputRef={inputRef}
            name={name} // name prop을 직접 추가
            // TODO : warning 해소건. 그러나 inputProps에서 null 오류 발생으로 보류
            // InputProps={{
            //   ...(props.InputProps || {}), // 기존 InputProps 유지
            //   inputProps: {
            //     ...(props.InputProps?.inputProps || {}), // 기존 inputProps 유지
            //     name: name, // name을 inputProps에도 추가
            //   },
            // }}
            inputProps={{
              ...(props.inputProps || {}),
              inputMode: (numberOnly || mobileFormat || telFormat) ? 'numeric' : 'text',
              pattern: (numberOnly || mobileFormat || telFormat) ? '[0-9]*' : undefined,
              maxLength: (mobileFormat || telFormat) ? 13 : undefined, // ###-####-#### 형식의 최대 길이
            }}
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: props.inputProps?.readOnly ? 'rgba(0, 0, 0, 0.03)' : 'inherit',
              },
              '& .MuiInputBase-input': {
                color: props.inputProps?.readOnly ? 'rgba(0, 0, 0, 0.85)' : 'inherit',
              },
            }}
            {...props}
          >
            {generateSingleOptions()}
          </TextField>
        )
      }}
    />
  );
};

export default FormInputText;
