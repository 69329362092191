import React from 'react';
import { Route } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import { ConstructionWorkTypeManagement } from "../components/ConstructionWorkType";
import { GComponentManagement, GComponentItemManagement } from "../components/GComponent";
import { GTypeManagement } from "../components/GType";
import { GGlassManagement } from "../components/GGlass";
import { GSubManagement, GSubItemManagement } from "../components/GSub";
import { GStandardTypeManagement } from "../components/GStandardType";
import { GStandardManagement } from "../components/GStandard";
import { G04docuManagement } from "../components/G04docu";
import { G04docuGCertificationManagement } from "../components/G04docuGCertification";
import { GClientTypeManagement } from "../components/GClientType";
import { G04GeneralDocuManagement } from "../components/G04GeneralDocu";
import { GClientType04docuManagement } from "../components/GClientType04docu";
import { GClientManagement } from "../components/GClient";
import { GDomesticAuthManagement } from "../components/GDomesticAuth";
import { GSecurityByGClientManagement } from "../components/GSecurityByGClient";
import { GClientDocument, GClientMailTest } from '../components/GClient';

// 설정
const SettingsRoute = ({ sessionUser }) => ( 
    <>
        {/* 공종 */}
        <Route path="/constructionWorkType" element={
            <RequireAuth sessionUser={sessionUser}>
                <ConstructionWorkTypeManagement />
            </RequireAuth>
        } />
        {/* 원부자재, 속성 */}
        <Route path="/gcomponentItem" element={
            <RequireAuth sessionUser={sessionUser}>
                <GComponentItemManagement />
            </RequireAuth>
        } />
        {/* 구성단위 */}
        <Route path="/gcomponent" element={
            <RequireAuth sessionUser={sessionUser}>
                <GComponentManagement />
            </RequireAuth>
        } />
        {/* 단면구조 */}
        <Route path="/gtype" element={
            <RequireAuth sessionUser={sessionUser}>
                <GTypeManagement />
            </RequireAuth>
        } />
        {/* 품종 */}
        <Route path="/glasses" element={
            <RequireAuth sessionUser={sessionUser}>
                <GGlassManagement />
            </RequireAuth>
        } />
        {/* 국내산 판별기준 */}
        <Route path="/gdomesticAuthCriteria" element={
            <RequireAuth sessionUser={sessionUser}>
                <GDomesticAuthManagement />
            </RequireAuth>
        } />
        {/* 시공부자재 - 구성요소 제품 */}
        <Route path="/gsubItem" element={
              <RequireAuth sessionUser={sessionUser}>
                <GSubItemManagement />
            </RequireAuth>
        } />
        {/* 시공부자재 - 구성요소 */}
        <Route path="/gsub" element={
            <RequireAuth sessionUser={sessionUser}>
                <GSubManagement />
            </RequireAuth>
        } />    
        {/* 표준 구분 */}
        <Route path="/gstandardTypes" element={
            <RequireAuth sessionUser={sessionUser}>
                <GStandardTypeManagement />
            </RequireAuth>
        } />
        {/* 표준 */}
        <Route path="/gstandards" element={
            <RequireAuth sessionUser={sessionUser}>
                <GStandardManagement />
            </RequireAuth>
        } />
        {/* 인증규격(old) */}
        <Route path="/g04docus" element={
            <RequireAuth sessionUser={sessionUser}>
                <G04docuManagement />
            </RequireAuth>
        } />
        {/* 인증규격 */}
        <Route path="/g04docuGCertifications" element={
            <RequireAuth sessionUser={sessionUser}>
                <G04docuGCertificationManagement />
            </RequireAuth>
        } />
        {/* 인증부여 (종류·등급·호칭 또는 모델)
            <Route path="/g04docuGCertificationByGItemsOrGlasses" element={
            <RequireAuth sessionUser={sessionUser}>
                <G04docuGCertificationByGItemOrGlassManagement />
            </RequireAuth>
        } /> */}
        {/* 일반서류 종류 */}
        <Route path="/g04GeneralDocus" element={
            <RequireAuth sessionUser={sessionUser}>
                <G04GeneralDocuManagement />
            </RequireAuth>
        } />
        {/* 거래선 구분 - 대분류 */}
        <Route path="/gclientTypes" element={
            <RequireAuth sessionUser={sessionUser}>
                <GClientTypeManagement />
            </RequireAuth>
        } />
        {/* 거래선 구분 - 세분류 - 04 DOCU */}
        <Route path="/gclientType04docus" element={
            <RequireAuth sessionUser={sessionUser}>
                <GClientType04docuManagement />
            </RequireAuth>
        } />
        {/* 거래선 */}
        <Route path="/gclients" element={
            <RequireAuth sessionUser={sessionUser}>
                <GClientManagement />
            </RequireAuth>
        } />
        {/* 거래선별 문서보안 */}
        <Route path="/securitiesByGClient" element={
            <RequireAuth sessionUser={sessionUser}>
                <GSecurityByGClientManagement />
            </RequireAuth>
        } />
        {/* 거래선별 문서현황 */}
        <Route path="/gclientG04docus" element={
            <RequireAuth sessionUser={sessionUser}>
                <GClientDocument />
            </RequireAuth>
        } />
        {/* 거래선별 메일테스트 */}
        <Route path="/gclientMailTest" element={
            <RequireAuth sessionUser={sessionUser}>
                <GClientMailTest />
            </RequireAuth>
        } />
    </>
);

export default SettingsRoute;

