import React, { useEffect, useMemo } from "react";
import {
  FormControl,
  TextField,
} from '@mui/material';
import { Controller } from "react-hook-form";
// TODO : 추후 Locale 적용할 수 있음
import koLocale from 'date-fns/locale/ko';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import DatePicker from '@mui/lab/DatePicker';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// TODO : 시간 부분 처리 고려할 것
// 본 컴포넌트의 입출력은 문자열이 아닌 Date 객체로 한다.
// 단, 유효기간 설정시 사용하는 경우 마지막 기간의 날짜를 ~23:59:59.999Z(~T14:59:59.999Z) 로 설정하는데, 이때 본 컴포넌트의 날짜 변경시 milliseconds 부분이 000으로 되므로 재설정 필요
const FormInputDate = ({
  inputHeight='38',
  fontSize = '16',
  value: initialValue,
  setValue,
  getValues,
  name,
  control,
  size = "medium",
  label,
  color,
  customWidth,
  focused,
  onEdit,
  onChangePeriodValue,
  views=null,
  inputFormat='yyyy-MM-dd',
  mask='____-__-__',
  clearable=false,
  iconSize,
  ...props
}) => {
  const [dateValue, setDateValue] = React.useState(initialValue);

  const displayDateValue = React.useMemo(() => {
    if (getValues && getValues(name)) {
      return getValues(name);
    }
    return dateValue;
  }, [getValues, name, dateValue]);

  useEffect(() => {
    if (dateValue) {
      setValue && setValue(name, dateValue);
    }
  }, [dateValue, name, setValue]);

  useEffect(() => {
    setDateValue(initialValue);
  }, [initialValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={koLocale}>
      <FormControl
        size={size}
        fullWidth
        style={{ width: customWidth }}
      >
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
            <DatePicker
              views={views}
              inputFormat={inputFormat}
              format={inputFormat}
              mask={mask}
              label={label}
              value={displayDateValue}
              slots={{
                textField: TextField
              }}
              slotProps={{
                field: { clearable },
                textField: {
                  size: size,
                  color: color,
                  focused: focused,
                  ...props,
                  // TODO : 추후 폰트사이즈, input height가 주어졌을 때 중앙정렬 등 좀 더 정확한 처리가 필요
                  sx: {
                    input: { color: color === "error" ? "red" : "" },
                    '& .MuiInputBase-root': {
                      height: inputHeight,
                      fontSize: fontSize,
                    },
                    // TODO : 라벨 위치 조정 : 추후 좀 더 정확한 처리가 필요
                    '& .MuiInputLabel-root': {
                      transform: `translate(14px, ${parseInt(inputHeight) / 2 - 10}px) scale(0.9)`,
                    },
                    '& .MuiInputLabel-shrink': {
                      transform: 'translate(14px, -9px) scale(0.75)',
                    },
                    // '& .MuiInputBase-input': {
                    //   padding: `${(parseInt(inputHeight) - parseInt(fontSize)) / 2}px 14px`,
                    // },
                    // '& .MuiIconButton-root': {
                    //   padding: '4px',  // 아이콘 버튼의 패딩
                    // },
                    '& .MuiSvgIcon-root': {
                      fontSize: iconSize === "small" ? '1.2rem' : '1.5rem',  // 아이콘 크기
                    },
                  }
                }
              }}
              onChange={
                (newValue) => {
                  if (onChangePeriodValue && !onChangePeriodValue(newValue, name)) {
                    return;
                  }

                  setDateValue(newValue);
                  onChange(newValue);
                  onEdit && onEdit();
                }
              }
              // renderInput={(params) => {
              //   // console.log(params)
              //   return (
              //     <TextField
              //       size={size}
              //       color={color}
              //       focused={focused}
              //       {...params}
              //       {...props}
              //       sx={{ input: { color: color === "error" ? "red" : "" }, width: customWidth }}
              //     />
              //   )
              // }}
              {...props}
            />
          )}
        />
      </FormControl>
    </LocalizationProvider>
  )
}

export default FormInputDate;