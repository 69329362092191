
export const EMAIL_DOMAINS = [
    { label: "네이버", value: "naver.com", ico: "/naver.ico" },
    { label: "다음", value: "daum.net", ico: "/daum.ico" },
    { label: "카카오", value: "kakao.com", ico: "/kakao.ico" },
    { label: "지메일", value: "gmail.com", ico: "/gmail.ico" },
    { label: "네이버웍스", value: "worksmobile.com", ico: "/naver_works.ico" },
    { label: "네이트", value: "nate.com", ico: "/nate.ico" },
    { label: "직접입력", value: "MANUAL" },
  ];
