import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormGroup,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  TableContainer,
  Table,  
  TableCell,
  TableBody,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import  {
  Clear,
  Block,
  Delete,
  FilePresent,
  Send,
  Image,
  PictureAsPdf,
  Preview,
  TrafficRounded,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReactMultiEmail } from "react-multi-email";
// import "react-multi-email/dist/style.css";
import "./react-multi-email.css";
import { FilePond } from 'react-filepond';
import uploadLabels from '../../FildPondLabel';
import usePrevious from "../hook/usePrevious";
import {
  FormInputAutoComplete,
  FormInputMultipleSelect,
  FormInputText,
} from "../form";
import CustomInputSelect from "../custom/select/CustomInputSelect";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import { StyledTooltip } from "../tooltip";
import {
  AlertDialog,
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import { uploadFilePath, fileServerUrl, filePreviewServerUrl } from '../../config';
import {
  StringAvatar,
} from "../avatar";
import {
  dateFormat,
  differenceInDays,
  hideWatermark,
} from "../../utils";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { fontModules, fontFormats, smallStyle, registerFonts } from "../../utils/QuillConfig";

import { objectEmptyCheck } from "../../utils/ObjectUtils";
import * as gclientActions from "../../store/gclient";
import * as userActions from "../../store/user";
import * as g04docuFileActions from "../../store/g04docuFile";
import * as gsendBasketActions from "../../store/gsendBasket";
import * as errorActions from "../../store/error";
import * as addressBookActions from '../../store/addressBook';

import { el } from "date-fns/locale";

// 아래 form components의 name과 연계
const defaultValues = {
  // to: "",
  // cc: "",
  // ccInternal: "",
  subject: "",
  html: "",

  // 날인문구
  supplierLabel: "제공사",
  supplier: "",
  recipientLabel: "제출처",
  recipient: "",
  siteLabel: "현장명",
  site: "",
  phrase: "",
};

let pond;

const commonStyles = {
  bgcolor: 'background.paper',
  m: 1,
  border: 1,
  width: '5rem',
  height: '5rem',
};

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const SendBasketDialog = ({
  max=1,
  open,
  setOpen,
}) => {
  const uploadFilesBoxRef = useRef();
  const inputFilesRef = useRef();

  const [gclients, setGClients] = useState([]);
  const [gclientId, setGClientId] = useState("");
  // const [errors, setErrors] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmOpenSend, setConfirmOpenSend] = useState(false);
  const [removeCreatorId, setRemoveCreatorId] = useState("");
  const [openOption, setOpenOption] = useState(false);
  const [includeGTestComparisons, setIncludeGTestComparisons] = useState(true);
  const [myCertified, setMyCertified] = useState(false);
  const [signOption, setSignOption] = useState("CERTIFIED_TRUE_COPY_AND_SIGN");
  const [emailOptionsTo, setEmailOptionsTo] = useState([]);
  const [emailOptionsCc, setEmailOptionsCc] = useState([]);
  const [emailOptionsCcInternal, setEmailOptionsCcInternal] = useState([]);
  const [mailTos, setMailTos] = useState([]);
  const [mailCcs, setMailCcs] = useState([]);
  const [mailCcInternals, setMailCcInternals] = useState([]);
  const [mailDirect, setMailDirect] = useState(false);
  const [mailToDirect, setMailToDirect ] = useState([]);
  const [mailCcDirect, setMailCcDirect ] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertInfo, setAlertInfo] = useState({open: false,titleAlert: '',messageAlert: ''});
  const [URLThumbnail, setURLThumbnail] = useState("");
  const [emptyRecipient, setEmptyRecipient] = useState(false);
  const [emptySite, setEmptySite] = useState(false);
  const [uploadBoxStyle, setUploadBoxStyle] = useState({ border: 1, borderRadius: 2, borderColor: 'divider' });
  const [addedFiles, setAddedFiles] = useState([]);
  const [checked, setChecked] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);

  // 컴포넌트 마운트 시 폰트 등록
  useEffect(() => {
    const Quill = ReactQuill.Quill;
    registerFonts(Quill);
  }, []); 

  const columns = [
    // {
    //   field: 'gclientId',
    //   headerName: '거래선 아이디',
    //   width: 200,
    //   // editable: true,
    //   hide: true,
    // },
    {
      field: 'gclient',
      headerName: '거래선',
      width: 200,
      // editable: true,
      // hide: true,
      valueGetter: (params) => {
        return params.row.gclient.name;
      }
    },
    {
      field: 'name',
      renderHeader: (params) => (
        <>
          <Stack direction="row" spacing={1} alignItems="center">
            <span style={{ fontWeight: 'bold', color: '#1976D2' }}>{"이름"}</span>
            <StringAvatar name={"원"} width={20} height={20} fontSize={"small"} color={"#64b5f6"} />{"자재"}
            <StringAvatar name={"가"} width={20} height={20} fontSize={"small"} color={"#a5d6a7"} />{"공부자재"}
            <StringAvatar name={"시"} width={20} height={20} fontSize={"small"} color={"#ffb74d"} />{"공부자재"}
            <StringAvatar name={"공"} width={20} height={20} fontSize={"small"} color={"#ce93d8"} />{"정"}
          </Stack>
        </>
      ),
      width: 800,
      // editable: true,
      renderCell: (params) => {
        console.log(params)
        let value = "";
        const { type, division, g04docuGCertificationName, name, gcomponentItemName, selectedClassifications/*, validYN, valid*/ } = params.row.name;
        const { validYN, valid, documentPath } = params.row;
        const elemSelectedClassifications = (selectedClassifications?.filter(value => value.checked === true).map(value=> <Chip label={value.type} size="small" sx={{ ml: 1 }}></Chip>));
        let divisionName = "";
        let color = "";
        if (division === 'RAW_MATERIAL') {
          divisionName = "원자재";
          color = "#64b5f6";
        } else if (division === 'SUB_MATERIAL_PROCESS') {
          divisionName = "가공부자재";
          color = "#a5d6a7";
        } else if (division === 'SUB_MATERIAL_BUILD') {
          divisionName = "시공부자재";
          color = "#ffb74d";
        } else if (division === 'PROCESS') {
          divisionName = "공정";
          color = "#ce93d8";
        }
        return (
          <>
            <Chip label={type} color="primary" size="small" sx={{ mr: 1, bgcolor: documentPath ? ((validYN || valid) ? '1976d2' : '#ed6c02') : '#ef5350' }}></Chip>
            <Typography variant={"subtitle2"}>
              {/* 아래 Box 이전에 span style을 사용했었는데 render가 바로 되지 않아 색상 변화에 문제가 있었음 */}
              {/* <span style={{ fontWeight: 'bold', color: documentPath ? ((validYN || valid) ? '1976d2' : '#ed6c02') : '#ef5350' }}></Box> */}
              <Box sx={{ fontWeight: 'bold', color: documentPath ? ((validYN || valid) ? '1976d2' : '#ed6c02') : '#ef5350' }}>
                {`${division === 'RAW_MATERIAL' && gcomponentItemName ? gcomponentItemName : ""} ${name}`}
              </Box>
            </Typography>
            {
              division && (
                <Box sx={{ ml: 1 }}>
                  <StringAvatar
                    name={divisionName.substring(0, 1)}
                    width={20}
                    height={20}
                    fontSize={"small"}
                    color={color}
                  />
                </Box>
              )
            }
            {/* 1976d2, ed6c02 */}
            {
              documentPath ? (
                <StyledTooltip
                  title={<Stack direction="row" display="flex" alignItems="center">
                    <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                      {`미리보기`}
                    </Box>
                    <Typography variant="subtitle2">{documentPath?.split("/")[documentPath?.split("/")?.length-1]}</Typography>
                  </Stack>}
                  placement="right"
                  sx={tooltipTop}
                >
                  <IconButton
                    color="primary"
                    aria-label="file"
                    onClick={() => handleClickViewEachDoc(documentPath, params.row.gclientId)}
                    sx={{ color: (validYN || valid) ? "#1976D2" : "#ED6C02" }}
                  >
                    <Preview />
                  </IconButton>
                </StyledTooltip>
              ) : (
                <IconButton
                  color="primary"
                  aria-label="file"
                  edge="end"
                  sx={{ mr: 0.2, color: '#ef5350' }}
                >
                  <Block />
                </IconButton>
              )
            }
            
            {type === "성적서" && g04docuGCertificationName && (<Chip label={g04docuGCertificationName} size="small"></Chip>)}
            {elemSelectedClassifications}
          </>
        );
      }
    },   
    {
      field: 'actions',
      headerName: "삭제",
      width: 70,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { id, creator } = params.row;
        return (
          <IconButton
            color="secondary"
            aria-label="file"
            // edge="end"
            onClick={() => handleClickDeleteBasketItem(id, creator.id)}
          >
            <Clear />
          </IconButton>
        )
      }
    },
  ];

  const handleClickDeleteBasketItem = async (id, creatorId) => {
    await removeGSendBasketItem(id);
    await selectGSendBasketsByCreatorByQuery(creatorId);
  }

  const handleDialogClose = () => {
    setOpen(false);
  };

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const sessionUser = useSelector(state => state.session.sessionUser);
  const rowsGSendBaskets = useSelector(state => state.gsendBasket.gsendBaskets);
  const rowsAddressBooks = useSelector((state) => state.addressBook.addressBooks);

  // 데이터 관리
  const dispatch = useDispatch();

  const selectAllGClientDirect = () => gclientActions.selectAllDirect()
  const selectGSendBasketsByCreatorByQuery = (creatorId) => dispatch(gsendBasketActions.selectAllByCreatorByQuery(creatorId))
  const removeGSendBasketItem = (id) => dispatch(gsendBasketActions.remove(id))
  const removeAllGSendBasketItems = (creatorId) => dispatch(gsendBasketActions.removeByCreator(creatorId))
  const initializeGSendBaskets = () => dispatch(gsendBasketActions.initializeGSendBaskets())
  const sendDirect = ({ gclientTo, mailTo, mailCc, mailCcInternal, subject, html, gsendBaskets, options, phrases }) => gsendBasketActions.sendDirect({ gclientTo, mailTo, mailCc, mailCcInternal, subject, html, gsendBaskets, options, phrases })
  const sendWithLocalFileDirect = (formData) => gsendBasketActions.sendWithLocalFileDirect(formData)

  const downloadFile = (documentPath) => gclientActions.downloadDirect(documentPath)
  const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)
  const selectAllByGClientDirect = (gclientId) => userActions.selectAllByGClientDirect(gclientId)
  const selectAllAddressBook = (searchWord, sort, initConst) => dispatch(addressBookActions.selectAll(searchWord, sort, initConst));
  
  const handlePageChange = (newPage) => {
    setPage(newPage);
  }

  // 주소록 데이터 변환 함수
  const convertAddressBooks = () => {
    if (rowsAddressBooks && Array.isArray(rowsAddressBooks) && rowsAddressBooks.length > 0) {
      const uniqueAddresses = Array.from(
        new Map(
          rowsAddressBooks.map(address => [
            address.email,
            {
              id: `${address.name} <${address.email}>`,
              name: `${address.name} <${address.email}>`,
              group: "주소록"
            }
          ])
        ).values()
      );
      
      setAddressOptions(uniqueAddresses);
      return uniqueAddresses;
    }

    // rowsAddressBooks가 없거나 비어있는 경우 빈 배열 반환
    setAddressOptions([]);
    return [];
  };

  useEffect(
    () => {
      if (open) {
        const select = async () => {
          if (sessionUser) {
            // console.log(sessionUser)
            const { id, type, signDocumentPath, sessionUserLoginType } = sessionUser;
            let creatorId = "";
            if (sessionUserLoginType === 'GCLIENT') {
              creatorId = id;
            } else if (sessionUserLoginType === 'USER') {
              if (sessionUser.user) {
                creatorId = sessionUser.user.id;
              }
            }
            // if (type === 'ADMIN' || type === 'GCLIENT') {
              await selectGSendBasketsByCreatorByQuery(creatorId);
             
              if (signDocumentPath) { // TODO : 해당 문서의 소유 회원사의 인감이 각 문서에 날인되어야 하므로 아래가 필요할지 검토
                const res = await downloadFile(signDocumentPath); // TODO : 서버에 파일이 없을 경우 오류나서 렌더링되지 않으므로 try catch 할 것
                const blob = await res.blob(); // res.blob()는 비동기 함수이므로 await 필요
                const url = URL.createObjectURL(blob);
                setURLThumbnail(url);
              }
            // }
          }

          await hideWatermark();

          // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
          setShow(true);
          setTimeout(() => setLoaded(true), 300);
        }
        setLoaded(false);
  
        select();
      }
    }, [open, sessionUser]
  )

  useEffect(
    async () => {
      if (openOption) {
        console.log({ uploadFilesBoxRef, inputFilesRef })

        const uploadBox = uploadFilesBoxRef.current;
        const input = inputFilesRef.current;
        
        if (uploadBox) {
          // alert("uploadBox")
        }
        if (input) {
          // alert("input")
        }
        console.log({ uploadBox, input })

        if (uploadBox && input) {
          // alert("222")
          console.log({ uploadBox, input })

          const dropHandler = (e) => {
            e.preventDefault();
            e.stopPropagation();
        
            setUploadBoxStyle({ border: 1, borderRadius: 2, borderColor: 'divider' })
        
            const { files } = e.dataTransfer;
            console.log(files)
            if (!files || !files[0]) return;
            
            // createImageURLAndUpload(files[0]);
          };


          const changeHandler = async (e) => {        
            e.preventDefault();
            e.stopPropagation();
        
            const { files } = e.target;
            console.log(files)
        
            if (!files || !files[0]) return;
            
            // createImageURLAndUpload(files[0]);
        
            e.target.value = ''; // 같은 파일을 올리면 onChange 이벤트가 발생하지 않으므로 초기화
          };
          
          uploadBox.addEventListener("drop", dropHandler);
          // uploadBox.addEventListener("dragover", dragOverHandler);
          // uploadBox.addEventListener("dragleave", dragLeaveHandler);
          // uploadBox.addEventListener("mouseover", mouseOverHandler);
          // uploadBox.addEventListener("mouseleave", mouseLeaveHandler);
          
          input.addEventListener("change", changeHandler);
          
          return () => {
            uploadBox.removeEventListener("drop", dropHandler);
            // uploadBox.removeEventListener("dragover", dragOverHandler);
            // uploadBox.removeEventListener("dragleave", dragLeaveHandler);
            // uploadBox.removeEventListener("mouseover", mouseOverHandler);
            // uploadBox.removeEventListener("mouseleave", mouseLeaveHandler);
            
            input.removeEventListener("change", changeHandler);
          };
        }
      
        const selectGClients = async () => {
          const resGClients = await selectAllGClientDirect();
          console.log(resGClients)
          setGClients(resGClients);
        }

        selectGClients();

        // 내부참조
        const { id, email } = sessionUser; // sessionUser의 id는 회원사 관리자 id임 (접속한 계정이 회원사 사용자이더라도...)
        const users = await selectAllByGClientDirect(id);
        const emailList = [];
        
        if (email) {
          emailList.push({ /*code*/id: email, name: `회사대표메일 <${email}>`, group: "거래선" }); // code가 아니라 id임에 유의
        }

        if (users.length > 0) {
          users.forEach(user => {
            const { name, userId } = user;
            emailList.push({ id: `${name} <${userId}>`, name: `${name} <${userId}>`, group: "거래선" });
          })
        }

        // console.log(emailList)
        if (emailList.length > 0) {
          setEmailOptionsCcInternal(emailList);
        } else {
          setEmailOptionsCcInternal([]);
        }

        const none = rowsGSendBaskets.filter(row => !row.documentPath);
        if (none.length > 0) {
          setAlertInfo({
            titleAlert: "안내",
            messageAlert: (<>
              <span style={{ color: "#1976d2" }}>{"등록되어 있지 않은 문서가 포함"}</span><span>{"되어 있습니다."}</span><br />
              <span>{"메일 보낼 때 "}</span><span style={{ color: "#1976d2" }}>{"등록되지 않은 문서는 제외"}</span><span>{"됩니다."}</span>
            </>),
            open: true,
          });
        }
      }
    }, [openOption]
  )

  const prevGClientId = usePrevious(gclientId);

  useEffect(
    async () => {
      if (prevGClientId !== gclientId) {
        setMailTos([]);
        setMailCcs([]);
      }

      // console.log({ prevGClientId, gclientId })
      const selectedGClient = gclients.filter(gclient => gclient.id === gclientId);
      console.log(selectedGClient)
      if (selectedGClient.length === 1) {
        const { id, email, inCharges } = selectedGClient[0];

        const emailList = [];
        if (email) {
          emailList.push({ id: email, name: `회사대표메일 <${email}>`, group: "거래선" });
        }

        // if (inCharges && Array.isArray(inCharges)) {
        //   inCharges.forEach(inCharge => {
        //     const { inChargeName, inChargeEmail } = inCharge;
        //     emailList.push({ id: `${inChargeName} <${inChargeEmail}>`, name: `${inChargeName} <${inChargeEmail}>` });
        //   })
        // }
        const users = await selectAllByGClientDirect(id);
        if (users.length > 0) {
          users.forEach(user => {
            const { name, userId } = user;
            emailList.push({ id: `${name} <${userId}>`, name: `${name} <${userId}>`, group: "거래선" });
          })
        }

        // TODO : inCharges 사용하지 않으므로 바꾸어야 함
        if (emailList.length > 0) {
          setEmailOptionsTo(emailList);
          setEmailOptionsCc(emailList);
        } else {
          setEmailOptionsTo([]);
          setEmailOptionsCc([]);
        }
      }
    }, [gclientId]
  )

  useEffect(
    () => {
      setLoaded(true);
    }, [rowsGSendBaskets]
  )

  const handleClickFileDownload = async (e, documentPath) => {
    e.stopPropagation();

    const arr = documentPath.split("/");
    const fileName = arr[arr.length - 1];

    const res = await downloadG04docuFile(documentPath);
    const blob = await res.blob(); // res.blob는 promise를 리턴함???
    
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));            
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  // const handleClickViewEachDoc = (documentPath, gclientId) => {
  //   let path = "";
  //   if (sessionUser.type === 'ADMIN' || sessionUser.id === gclientId) {
  //     const randNumber = Math.floor(Math.random()*99); // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
  //     const encodedPath = encodeURIComponent(documentPath.replace(uploadFilePath, ''));
  //     path = `${fileServerUrl}${encodedPath}?q=cat&${randNumber}`;
  //   } else {
  //     path = `${filePreviewServerUrl}${encodeURIComponent(documentPath)}`;
  //   }
  //   window.open(`${path}`, "미리보기", 'target="_self"');
  // }

  const handleClickViewEachDoc = (documentPath, gclientId) => {
    let path = "";
    if (sessionUser.type === 'ADMIN' || sessionUser.id === gclientId) {
      const pathParts = documentPath.replace(uploadFilePath, '').split('/');
    
      // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
      const randNumber = Math.floor(Math.random()*99);
      
      // 경로를 '/'로 분리하고 각 부분을 개별적으로 인코딩
      const encodedPath = pathParts.map(part => encodeURIComponent(part)).join('/');
      path = `${fileServerUrl}${encodedPath}?q=cat&${randNumber}`;
    } else {
      const pathParts = documentPath.split('/');
      const encodedPath = pathParts
        .map(part => encodeURIComponent(part))
        .join('/');
      path = `${filePreviewServerUrl}${encodedPath}`;
    }
    
    window.open(`${path}`, "미리보기", 'target="_self"');
  }

  const handleClickRemoveAllGSendBasket = async () => {
    const { id, sessionUserLoginType } = sessionUser;
    let creatorId = "";
    if (sessionUserLoginType === 'GCLIENT') {
      creatorId = id;
    } else if (sessionUserLoginType === 'USER') {
      if (sessionUser.user) {
        creatorId = sessionUser.user.id;
      }
    }

    setRemoveCreatorId(creatorId);
    setConfirmOpen(true);
  }

  const remove = async (creatorId) => {
    await removeAllGSendBasketItems(creatorId);
    initializeGSendBaskets();
  }

  // 메일 전송 옵션 다이얼로그 호출
  const handleClickSendOptionDialog = async () => {
    console.log(sessionUser)
    if (sessionUser) {
      if (sessionUser["phrases"]) {
        const { supplierLabel, supplier, phrase } = sessionUser["phrases"];
        setValue("supplierLabel", supplierLabel);
        setValue("supplier", supplier);
        setValue("phrase", phrase);
      }

      try {
        // 주소록 조회
        await selectAllAddressBook();
        convertAddressBooks();
        console.log('Address Book Data:', addressOptions);
  
        // 서명 설정 초기화
        if (sessionUser.mailSettings) {
          const footer = sessionUser.mailSettings.footer || {};
          console.log(footer);
          if (footer.useYn) {
            const initialSignature = footer.message ?? "";
            setValue('html', initialSignature);
          }
        }
      } catch (error) {
        console.error('Error initializing mail settings:', error);
      }
    }

    console.log(rowsGSendBaskets)
    setOpenOption(true);
  }

  const initOptionDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    setSignOption('CERTIFIED_TRUE_COPY_AND_SIGN');
    setGClients([]);
    setGClientId("");
    setEmailOptionsTo([]);
    setEmailOptionsCc([]);
    setEmailOptionsCcInternal([]);
    // 아래 두개의 초기화는 useEffect([gclientId])안에서 이루어짐
    // setMailTos([]);
    // setMailCcs([]);
    setMailCcInternals([]);
    setMailDirect(false);

    setEmptyRecipient(false);
    setEmptySite(false);

    setAddedFiles([]);
    setChecked([]);
    setCheckedAll(false);
  }

  const handleOptionDialogClose = () => {
    setOpenOption(false);

    initOptionDialog();
  }

  const handleChangeSignOption = (e, value) => {
    // console.log(e)
    // TODO : GProjectG04TreeView에 동일한 함수가 있는데 비교해 볼 것
    // e.target.value 는 ToggleButton안에 요소가 있는 경우 undefined임
    // setSignOption(e.target.value);
    setSignOption(value);

    if (value === 'NONE') {
      setMyCertified(false);
    }
  }

  const handleChangeGClient = (e, value) => {
    console.log(value)
    if (!value) {
      setValue("recipient", "");
      return;
    }

    const { id: gclientId, label } = value;
    setGClientId(gclientId);

    setValue("recipient", label);
  }

  const onSubmit = async ({ mailTo, mailCc, mailCcInternal, subject, html, supplierLabel, supplier, recipientLabel, recipient, siteLabel, site, phrase }) => {
    // setErrors([]);
    const objMailSettingsValues = [];
    const objUserMailSettingsValues = [];
    let userMailSettings;
    const { sessionUserLoginType, mailSettings } = sessionUser;
    
    // sessionUserLoginType === 'USER'여도 사용자 메일설정이 안되어 있으면 관리자 메일설정으로도 보낼 수 있는 기능 때문에
    // 관리자 메일설정은 항상 체크해야 함

    const traverseObj = (obj) => {
      for (const key in obj) {
          if (typeof obj[key] === "object") {
              traverseObj(obj[key]);
          } else {
            objMailSettingsValues.push({ key, value: obj[key].toString() });
          }
      }
    }

    traverseObj(mailSettings);

    if (sessionUserLoginType === 'USER') {
      userMailSettings = sessionUser.user.mailSettings;

      const traverseObj = (obj) => {
        for (const key in obj) {
            if (typeof obj[key] === "object") {
                traverseObj(obj[key]);
            } else {
              objUserMailSettingsValues.push({ key, value: obj[key] });
            }
        }
      }

      traverseObj(userMailSettings);
    }

    const emptyMailSettingsResults = objMailSettingsValues.filter(obj => obj.value ? false : true);
    const emptyUserMailSettingsResults = objUserMailSettingsValues.filter(obj => obj.value ? false : true);

    // 관리자 메일 설정이 안되어 있는 경우
    const isNotMailSet = objMailSettingsValues.length === 0 || emptyMailSettingsResults.length > 0;
    if (sessionUserLoginType === 'GCLIENT' && isNotMailSet) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: (<>
          <span>{`메일정보 설정이 되어 있지 않습니다.`}</span><br />
          <span style={{ color: "#1976d2" }}>{`내정보`}</span>
          <span>{`에서 메일정보를 설정해주세요.`}</span>
        </>),
        open: true,
      });
      return;
    }

    // 사용자 메일 설정이 안되어 있는 경우
    const isNotUserMailSet = objUserMailSettingsValues.length === 0 || emptyUserMailSettingsResults.length > 0;
    if (sessionUserLoginType === 'USER' && isNotUserMailSet) {
      // 1. 관리자 메일이 설정되어 있다면 관리자 메일로 보낼 것(내 메일은 참조로...)인지 선택
      if (!isNotMailSet) {
        setConfirmOpenSend(true);
        return;
      } else { // 2. 관리자 메일도 사용자 메일도 설정되어 있지 않은 경우
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: (<>
            <span>{`메일정보 설정이 되어 있지 않습니다.`}</span><br />
            <span style={{ color: "#1976d2" }}>{`내정보`}</span>
            <span>{`에서 메일정보를 설정해주세요.`}</span>
          </>),
          open: true,
        });
        return;
      }
    }
    
    // mailTo, mailCc, mailCcInternal, subject, html, supplierLabel, supplier, recipientLabel, recipient, siteLabel, site, phrase
    await sendMail({ mailTo, mailCc, mailCcInternal, subject, html, supplierLabel, supplier, recipientLabel, recipient, siteLabel, site, phrase, includeGTestComparisons, myCertified });
  }

  const sendMail = async ({ mailTo, mailCc, mailCcInternal, subject, html, supplierLabel, supplier, recipientLabel, recipient, siteLabel, site, phrase, includeGTestComparisons, myCertified }, sendReplace) => {
    // console.log({ mailTo, mailCc, mailCcInternal, subject, html, supplierLabel, supplier, recipientLabel, recipient, siteLabel, site, phrase, includeGTestComparisons, myCertified, sendReplace })
    // return;

    if (signOption === 'CERTIFIED_TRUE_COPY_AND_SIGN') {
      if (!recipient) {
        setEmptyRecipient(true);
      }

      if (!site) {
        setEmptySite(true);
      }

      if (!recipient || !site) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: `제출처나 현장명을 모두 입력해주세요.`,
          open: true,
        });
        return;
      }
    }

    const phrases = {
      supplierLabel, supplier, recipientLabel, recipient, siteLabel, site, phrase
    };

    // 메일 보내기 전 유효성 체크, TODO : 더 체크할게 있는지 검토할 것
    const mailToArr = mailDirect ? mailToDirect.map(item => ({ id: item, name: item })) : (mailTo ? mailTo : []);
    if (mailToArr) {
      if (mailToArr.length <= 0) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: `받는 사람을 입력해주세요.`,
          open: true,
        });
        return;
      }
    }

    try {
      // console.log(addedFiles)
      
      setLoading(true);

      const selectedGClient = gclients.filter(gclient => gclient.id === gclientId); // 직접쓰기하면 gclientId = "" => selectedGClient = []

      // console.log({
      //   gclientTo: selectedGClient.length === 1 ? selectedGClient[0] : {},
      //   mailTo: mailToArr,
      //   mailCc: mailDirect ? mailCcDirect.map(item => ({ id: item, name: item })) : (mailCc ? mailCc : []),
      //   mailCcInternal,
      //   subject,
      //   html,
      //   mailDirect,
      //   gsendBaskets: rowsGSendBaskets,
      //   options: { signOption },
      //   phrases,
      // });

      const addedFileNames = [];

      const formData = new FormData();
      
      formData.append("includeGTestComparisons", includeGTestComparisons ? 'Y' : 'N');
      formData.append("myCertified", myCertified);
      
      // if (sendReplace) {
        formData.append("sendReplace", sendReplace);
      // }
      
      formData.append("mailData", JSON.stringify({
        gclientTo: selectedGClient.length === 1 ? selectedGClient[0] : {}, // TODO : 체크 필요
        mailTo: mailToArr,
        mailCc: mailDirect ? mailCcDirect.map(item => ({ id: item, name: item })) : (mailCc ? mailCc : []),
        mailCcInternal,
        subject,
        html,
        // TODO : 보내는 시점에 인감과 프로젝트 날인 정보가 변경되었다면 이미 저장된 데이터로 전송하므로 최신정보가 적용되지 않는다.
        // gsendBaskets: rowsGSendBaskets,
        gsendBaskets: rowsGSendBaskets
                        .filter(row => row.documentPath)
                        .map(row => {
                          const newGClients = gclients.filter(gclient => gclient.id === row.gclientId);
                          return {
                            ...row,
                            gclient: newGClients.length === 1 ? newGClients[0] : {},
                          }
                        }),
        options: { signOption },
        phrases,
      }));
      
      // addedFiles는 배열이 아니고 객체이므로 아래와 같이 배열로 변환
      console.log(addedFiles)
      // if (addedFiles && !objectEmptyCheck(addedFiles) && Array.isArray([...addedFiles])) {
      //   [...addedFiles].forEach((file) => { // formData.append('addedFiles', addedFiles); 이렇게 넘기면 안됨
      //     formData.append("addedFiles", file);
      //     addedFileNames.push(file.name)
      //   });
      // }
      
      addedFiles.forEach((file) => { // formData.append('addedFiles', addedFiles); 이렇게 넘기면 안됨
        formData.append("addedFiles", file);
        addedFileNames.push(file.name)
      });
      
      // console.log(addedFileNames);
      formData.append("addedFileNames", JSON.stringify(addedFileNames));

      const res = await sendWithLocalFileDirect(formData);
      // const res = await sendDirect({
      //   gclientTo: selectedGClient.length === 1 ? selectedGClient[0] : {},
      //   mailTo: mailDirect ? mailToDirect.map(item => ({ id: item, name: item })) : (mailTo ? mailTo : []),
      //   mailCc: mailDirect ? mailCcDirect.map(item => ({ id: item, name: item })) : (mailCc ? mailCc : []),
      //   mailCcInternal,
      //   subject,
      //   html,
      //   gsendBaskets: rowsGSendBaskets,
      //   options: { signOption },
      //   phrases,
      // });

      // console.log(res)
      if (res) {
        if (res.gsendG04docu) {
          setLoading(false);
          if (res.gsendG04docu?.id) { // TODO : 추후 res.gsendG04docu.results를 보고 판단하도록 할 것. (현재 서버에서 작업이 안된 상태)
            setAlertInfo({
              titleAlert: "안내",
              messageAlert: `메일이 전송되었습니다.`,
              open: true,
            });

            setTimeout(() => handleOptionDialogClose(false), 500);
          }
        } else {
          // if (res?.e) {
          //   const { code, errno, path } = res?.e;
          //   console.log({ code, errno, path })
          //   if (code && errno) {
          //     if (path && code === "ENOENT") {
          //       let messageAlert = "";
          //       if (path.indexOf("CertifiedTrueCopy") > 0) {
          //         messageAlert = (<><span>{`[시스템 오류] 원본대조필 파일이 없습니다.`}</span><br /><br /><span>{`시스템 관리자에게 문의바랍니다.`}</span></>);
          //       } else/* if (path.indexOf("SIGN") > 0) */{
          //         messageAlert = (<><span>{`인감이 등록되지 않았습니다.`}</span><br /><br /><span>{`내정보에서 먼저 인감을 등록해주세요.`}</span></>);
          //       }
                
          //       if (messageAlert) {
          //         setAlertInfo({
          //           titleAlert: "안내",
          //           messageAlert,
          //           open: true,
          //         });
          //       }
          //     } else if (code === 400 && errno === 20000) {
          //       setAlertInfo({
          //         titleAlert: "안내",
          //         messageAlert: (<><span>{`메일정보 설정이 되어 있지 않습니다.`}</span><br /><br /><span>{`내정보에서 메일정보를 설정해주세요.`}</span></>),
          //         open: true,
          //       });
          //     } else {
          //       setAlertInfo({
          //         titleAlert: "안내",
          //         messageAlert: `[시스템 오류] 시스템 관리자에게 문의바랍니다. 상세(code: ${code}, errno: ${errno})`,
          //         open: true,
          //       });
          //     }
          //   }
          // }
          console.log(res)
          dispatch(errorActions.occurError({ response: null, serverResponse: res })); // 오류 처리 방식 4
        }
      }

      setLoading(false);
    } catch (e) {
      console.log(e)
      setLoading(false);

      // TODO : 에러 메시지
    }
  }

  const handleChangeDirect = (e) => {
    const { checked } = e.target;
    setMailDirect(checked);
    if (checked) {
      setMailToDirect([]);
      setMailCcDirect([]);
    } else {      
      setMailTos([]);
      setMailCcs([]);
      setMailCcInternals([]);
    }

    // setGClients([]); // TODO : 왜 초기화하지???
    setGClientId("");
  }

  const handleChangeRecipient = (e) => {
    const { value } = e.target;
    // console.log(value)
    if (value) {
      setEmptyRecipient(false);
    } else {
      setEmptyRecipient(true);
    }

    setValue("recipient", value);
  }

  const handleChangeSite = (e) => {
    const { value } = e.target;
    // console.log(value)
    if (value) {
      setEmptySite(false);
    } else {
      setEmptySite(true);
    }

    setValue("site", value);
  }

  const handleFileChange = (e) => {
    console.log({ files: e.target.files, addedFiles })
    setAddedFiles(addedFiles.concat([...e.target.files])) // e.target.files는 배열이 아니고 객체임에 주의
    setCheckedAll(false);
    
    document.getElementById('input_files').value = "";
  }

  const handleDeleteCheckedFiles = (e) => {
    const newAddedFiles = addedFiles.filter((file, i) => checked.indexOf(i) > -1 ? false : true);
    setAddedFiles(newAddedFiles);
    setChecked([]);
  }

  const handleDeleteAddedFile = (e, idx) => {
    console.log(addedFiles)
    const newAddedFiles = addedFiles.filter((file, i) => idx !== i)
    setAddedFiles(newAddedFiles);
    setCheckedAll(false);

    const newChecked = checked.map(i => {
      if (i === idx) {
        return null;
      } else if (i >= idx) {
        return i-1;
      } else {
        return i;
      }
    });
    setChecked(newChecked.filter(i => i !== null));
  }

  const handleToggleAll = (e) => {
    console.log(e.target.checked)
    setCheckedAll(e.target.checked);
    if (e.target.checked) {
      const newChecked = addedFiles.map((file, i) => i);
      setChecked(newChecked);
    } else {
      setChecked([]);
    }
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    console.log(newChecked)
    setChecked(newChecked);

    console.log(newChecked.length)
    console.log(addedFiles.length)
    if (newChecked.length === addedFiles.length) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  };

  const sendMailByGClient = async () => {
    const mailTo = getValues("mailTo");
    const mailCc = getValues("mailCc");
    const mailCcInternal = getValues("mailCcInternal");
    const subject = getValues("subject");
    const html = getValues("html");
    const supplierLabel = getValues("supplierLabel");
    const supplier = getValues("supplier");
    const recipientLabel = getValues("recipientLabel");
    const recipient = getValues("recipient");
    const siteLabel = getValues("siteLabel");
    const site = getValues("site");
    const phrase = getValues("phrase");

    console.log({ mailTo, mailCc, mailCcInternal, subject, html, supplierLabel, supplier, recipientLabel, recipient, siteLabel, site, phrase, includeGTestComparisons, myCertified })
    
    await sendMail({ mailTo, mailCc, mailCcInternal, subject, html, supplierLabel, supplier, recipientLabel, recipient, siteLabel, site, phrase, includeGTestComparisons, myCertified }, true/*sendReplace*/);
  }

  const handleChangeMyCertified = (e, checked) => {
    setMyCertified(checked);
  }

  const handleIncludeGTestComparisons = (e, checked) => {
    setIncludeGTestComparisons(checked);
  }
  
  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          {"전송바구니"}
        </DialogTitleClose>
        <DialogContent dividers>
          {/* <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul> */}
          <Grid container spacing={2}>
            <Grid item xs={9} display="flex" justifyContent="flex-start" alignItems="center">

            </Grid>
            <Grid item xs={3} display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                variant="outlined"
                startIcon={<Clear />}
                onClick={handleClickRemoveAllGSendBasket}
                sx={{ mr: 1 }}
                disabled={rowsGSendBaskets.filter(row => row.documentPath).length === 0}
              >
                {"전체삭제"}
              </Button>
              <Button
                variant="contained"
                startIcon={<Send />}
                onClick={handleClickSendOptionDialog}
                disabled={rowsGSendBaskets.filter(row => row.documentPath).length === 0}
              >
                {"보내기"}
              </Button>
            </Grid>
          </Grid>
          <div style={{ height: 500, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', mt: 2, fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={rowsGSendBaskets}
              columns={columns}
              // pageSize={5}
              // rowsPerPageOptions={[5]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[1, 10, 20, 50, 100]}
              onPageChange={handlePageChange}
              page={page}
              // rowCount={rowCount}
              pagination
              // onRowDoubleClick={(params) => handleSelect({ type: 'addToSendBasket', params })}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        removeId={removeCreatorId}
        title={"삭제"}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={remove}
        onCancel={() => {}}
      >
        {"전송바구니를 비우시겠습니까?"}
      </ConfirmDialog>
      {/* 옵션 다이얼로그 */}
      <Dialog
        open={openOption}
        onClose={handleOptionDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleOptionDialogClose}
          style={{ cursor: 'move' }}
        >
          {"메일 전송"}
        </DialogTitleClose>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  titleTypographyProps={{ variant: 'subtitle1' }}
                  title={<Typography sx={{ mb: 1 }}>발급옵션</Typography>}
                  sx={{ bgcolor: "#eaeaea" }}
                />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Stack spacing={0.5}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={includeGTestComparisons} 
                              onChange={handleIncludeGTestComparisons}
                            />
                          }
                          label="시험성과대비표 포함"
                          sx={{ margin: 0 }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  titleTypographyProps={{ variant: 'subtitle1' }}
                  title={"문서처리옵션"}
                  sx={{ bgcolor: "#eaeaea" }}
                />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      {"날인정보 "}
                      <span style={{ fontWeight: 'bold', color: '#1976d2' }}>{"원본대조필과 인감, 현장정보"}</span>
                      {" 를 문서에 날인합니다."}
                    </Grid>
                    <Grid item xs={12}>
                      <ToggleButtonGroup
                        color="primary"
                        value={signOption}
                        exclusive
                        // size="small"
                        onChange={handleChangeSignOption}
                      >
                        {/* <ToggleButton value="CERTIFIED_TRUE_COPY">{"원본대조필"}</ToggleButton> */}
                        <ToggleButton value="CERTIFIED_TRUE_COPY_AND_SIGN">{"날인합니다."}</ToggleButton>
                        {/* <ToggleButton value="NONE"><del>{"원본대조필 + 날인"}</del></ToggleButton> */}
                        <ToggleButton value="NONE">{"날인하지 않습니다."}</ToggleButton>
                      </ToggleButtonGroup>  
                    </Grid>
                    <Grid item xs={12} sx={{ display: signOption === 'NONE' ? 'none' : "" }}>
                      <Stack direction="row" display="flex" justifyContent={"flex-start"} alignItems="center">
                        <Typography variant="body2">{"해당 문서 제공사의 날인정보가 없을 경우 제공사의 날인정보를 날인합니다."}</Typography>
                        <Checkbox
                          onChange={handleChangeMyCertified}
                          checked={myCertified}
                          sx={{ mt: -0.4 }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sx={{ display: signOption === 'NONE' ? 'none' : "" }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={12} display="flex" justifyContent={"center"} alignItems="center">
                              <TableContainer>
                                <Table aria-label="날일정보" sx={{ width: 400 }} size="small">
                                  <TableBody>
                                    <TableRow>
                                      <TableCell colspan={2} sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, borderRight: 1, width: '90%', fontSize: '20px', color: '#664AA0' }}>
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                          {"원본대조필 (인)"}
                                        </Box>
                                      </TableCell>
                                      <TableCell sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, color: '#664AA0', p: 0 }}>
                                        {/* {
                                          URLThumbnail ? (<Box sx={{ width: 60, height: 60 }} display="flex" justifyContent={"center"} alignItems={"center"}>
                                            <img src={URLThumbnail} alt="이미지 미리보기" style={{ maxWidth: '58px' }} />
                                          </Box>) : (<Box sx={{ width: 60, height: 60 }} display="flex" justifyContent={"center"} alignItems={"center"}>
                                            {"(인)"}
                                          </Box>)
                                        } */}
                                        {
                                          URLThumbnail && (<Box sx={{ width: 60, height: 60 }} display="flex" justifyContent={"center"} alignItems={"center"}>
                                            <img src={URLThumbnail} alt="이미지 미리보기" style={{ maxWidth: '58px' }} />
                                          </Box>)
                                        }
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, borderRight: 1, color: '#664AA0', width: '30%' }}>
                                        <Grid container>
                                          <Grid item xs={10}>
                                            <FormInputText
                                              autoComplete="supplierLabel"
                                              name={"supplierLabel"}
                                              control={control}
                                              required
                                              fullWidth
                                              // label={"제공사 라벨명"}
                                              errorStyle={{ message: false, border: true }}
                                              // onChange={handleChangeSupplierLabel}
                                              variant="standard"
                                              InputProps={{
                                                disableUnderline: true,
                                                readOnly: true,
                                              }}
                                              inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                            />
                                          </Grid>
                                          <Grid item xs={2}>
                                            {" : "}
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                      <TableCell colSpan={2} sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, color: '#664AA0' }}>
                                        <FormInputText
                                          autoComplete="supplier"
                                          name={"supplier"}
                                          control={control}
                                          required
                                          fullWidth
                                          // label={"제공사"}
                                          errorStyle={{ message: false, border: true }}
                                          // onChange={handleChangeSupplier}
                                          variant="standard"
                                          inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                          InputProps={{
                                            disableUnderline: true,
                                            readOnly: true,
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, borderRight: 1, width: '30%', fontSize: '14px', color: '#664AA0' }}>
                                        <Grid container>
                                          <Grid item xs={10}>
                                            <FormInputText
                                              name={"recipientLabel"}
                                              control={control}
                                              variant="standard"
                                              inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                              InputProps={{
                                                disableUnderline: true,
                                                readOnly: true,
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={2}>
                                            {" : "}
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                      <TableCell colSpan={2} sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, fontSize: '14px', color: '#664AA0' }}>
                                        <FormInputText
                                          name={"recipient"}
                                          control={control}
                                          variant="standard"
                                          inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                          error={emptyRecipient}
                                          errorStyle={{ message: false, border: true }}
                                          onChange={handleChangeRecipient}
                                        />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, borderRight: 1, width: '30%', fontSize: '14px', color: '#664AA0' }}>
                                        <Grid container>
                                          <Grid item xs={10}>
                                            <FormInputText
                                              name={"siteLabel"}
                                              control={control}
                                              variant="standard"
                                              inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                              InputProps={{
                                                disableUnderline: true,
                                                readOnly: true,
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={2}>
                                            {" : "}
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                      <TableCell colSpan={2} sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, fontSize: '14px', color: '#664AA0' }}>
                                        <FormInputText
                                          name={"site"}
                                          control={control}
                                          variant="standard"
                                          inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                          error={emptySite}
                                          errorStyle={{ message: false, border: true }}
                                          onChange={handleChangeSite}
                                        />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell colSpan={3} sx={{ border: 1, borderColor: '#664AA0' }}>
                                        <FormInputText
                                          name={"phrase"}
                                          control={control}
                                          // label={"문구"}
                                          // fullWidth
                                          // multiline // multiline이 들어가면 색상 변경이 안됨???
                                          // maxRows={5}
                                          // onChange={handleChangePhrase}
                                          variant="standard"
                                          InputProps={{
                                            disableUnderline: true,
                                            readOnly: true,
                                          }}
                                          inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}

                                        />
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  titleTypographyProps={{ variant: 'subtitle1' }}
                  title={"메일 쓰기"}
                  sx={{ bgcolor: "#eaeaea" }}
                  // action={
                  //   <FormGroup>
                  //     <FormControlLabel control={<Checkbox onChange={handleChangeDirect}/>} label="직접쓰기" />
                  //   </FormGroup>
                  // }
                />
                <CardContent>
                  <Grid container spacing={2}>
                    {
                        <>
                          <Grid item xs={12}>
                            <FormInputAutoComplete
                              control={control}
                              label={"거래선명"}
                              options={gclients.map(gclient => ({ label: gclient.name, id: gclient.id }))}
                              setValue={setValue}
                              onCustomChange={handleChangeGClient}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            {/* <FormInputMultipleSelect
                              id="multiple-mail-to-select"
                              name="mailTo"
                              label={"받는 사람"}
                              control={control}
                              setValue={setValue}
                              dynamicItemsBasic={mailTos}
                              onChangeItem={(value) => setMailTos(value.map(i => ({ ...JSON.parse(i) })))}
                              options={emailOptionsTo}
                              chipSize={"small"}
                            /> */}
                            <CustomInputSelect
                              defaultOptions={emailOptionsTo} // 기존 emailOptionsTo를 defaultOptions로 사용
                              addressOptions={addressOptions}
                              label="받는 사람"
                              placeholder="이메일 선택 또는 직접 입력하세요."
                              onChange={(value) => {
                                setMailTos(value);
                                setValue('mailTo', value); // react-hook-form 값 설정
                              }}
                              width="100%" // Grid 시스템 사용시 100%로 설정
                              size="small" // MUI Grid와 일관성을 위해 small 사이즈 사용
                            />
                          </Grid>
                          <Grid item xs={12}>
                            {/* <FormInputMultipleSelect
                              id="multiple-mail-cc-select"
                              name="mailCc"
                              label={"참조"}
                              control={control}
                              setValue={setValue}
                              dynamicItemsBasic={mailCcs}
                              onChangeItem={(value) => setMailCcs(value.map(i => ({ ...JSON.parse(i) })))}
                              options={emailOptionsCc}
                              chipSize={"small"}
                            /> */}
                            <CustomInputSelect
                              defaultOptions={emailOptionsTo} // 기존 emailOptionsTo를 defaultOptions로 사용
                              addressOptions={addressOptions}
                              label="참조"
                              placeholder="이메일 선택 또는 직접 입력하세요."
                              onChange={(value) => {
                                setMailCcs(value);
                                setValue('mailCc', value); // react-hook-form 값 설정
                              }}
                              width="100%" // Grid 시스템 사용시 100%로 설정
                              size="small" // MUI Grid와 일관성을 위해 small 사이즈 사용
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormInputMultipleSelect
                              id="multiple-mail-cc-internal-select"
                              name="mailCcInternal"
                              label={"내부참조"}
                              control={control}
                              setValue={setValue}
                              dynamicItemsBasic={mailCcInternals}
                              onChangeItem={(value) => setMailCcInternals(value.map(i => ({ ...JSON.parse(i) })))}
                              options={emailOptionsCcInternal}
                              chipSize={"small"}
                            />
                          </Grid>
                        </>
                    }
                    <Grid item xs={12}>
                      <FormInputText
                        name={"subject"}
                        control={control}
                        label={"제목"}
                      />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="flex-end" sx={{ color: 'white', fontSize: '0.9em' }}>
                      <label
                        id="input_files_label"
                        htmlFor={"input_files"}
                        /*ref={uploadFilesBoxRef}*/
                        style={{ cursor: 'pointer', borderRadius: '4px', backgroundColor: '#1976D2' }}
                      >
                        <div className="input-label" style={{ padding: '8px' }}>
                          {"추가 파일첨부"}
                        </div>
                      </label>
                      <input hidden type="file" multiple accept="image/*,.pdf" id={"input_files"}/* ref={inputFilesRef}*/onChange={handleFileChange} />
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={smallStyle}>
                        <Typography 
                          variant="subtitle1" 
                          sx={{ 
                            marginBottom: 1,
                            fontWeight: 500,
                            color: 'rgba(0, 0, 0, 0.87)'  // MUI 기본 라벨 색상
                          }}
                        >
                          메일 내용
                        </Typography>
                        <ReactQuill
                          value={getValues('html')}
                          onChange={(content) => {
                            setValue('html', content);
                          }}
                          modules={fontModules}
                          formats={fontFormats}
                          placeholder="메시지를 입력하세요..."
                          theme="snow"
                          style={{ 
                            height: '150px',      // 전체 높이
                            marginBottom: '20px'  // 하단 마진
                          }}
                          
                        />
                      </Box>
                    </Grid>
                    
                    {
                      addedFiles.length > 0 && (
                        <Grid item xs={12}>
                          <List dense={true}>
                            <ListItem
                              disablePadding>
                              <ListItemButton>
                                <Checkbox
                                  size="small"
                                  checked={checkedAll}
                                  onChange={handleToggleAll}
                                />
                                <Button size="small" disabled={checked.length > 0 ? false : TrafficRounded} onClick={(e) => handleDeleteCheckedFiles(e)}>
                                  {"삭제"}
                                </Button>
                              </ListItemButton>
                            </ListItem>
                            {
                              addedFiles.map((file, idx) => {
                                // console.log(file)
                                const { name } = file;
                                const pos = name.lastIndexOf(".");
                                let ext = "";
                                if (pos > -1) {
                                  ext = name.substring(pos+1);
                                }
    
                                return (
                                  <ListItem
                                    secondaryAction={
                                      <IconButton size="small" onClick={(e) => handleDeleteAddedFile(e, idx)}>
                                        <Clear  sx={{ width: 14, height: 14 }} />
                                      </IconButton>
                                    }
                                    disablePadding>
                                    <ListItemButton>
                                      <Checkbox
                                        onChange={handleToggle(idx)}
                                        checked={checked.indexOf(idx) !== -1}
                                        inputProps={{ 'aria-labelledby': idx }}
                                        size="small"
                                      />
                                      <ListItemIcon>
                                        { ext.toLowerCase() === 'pdf' ? <PictureAsPdf /> : <Image /> }
                                      </ListItemIcon>
                                      <ListItemText primary={name} />
                                    </ListItemButton>
                                  </ListItem>
                                )
                              })
                            }
                          </List>
                        </Grid>
                      )
                    }
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pt: 2, pb: 2, pr: 3 }}>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            // onClick={files.length > 0 ? () => sendMail() : () => handleSubmit(onSubmit)()}
            loading={loading}
          >
            {"보내기"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
      <ConfirmDialog
        removeId={""}
        title={"안내"}
        open={confirmOpenSend}
        setOpen={setConfirmOpenSend}
        onConfirm={sendMailByGClient}
        onCancel={() => {}}
        maxWidth="sm"
      >
        <>
          <span>{`메일설정이 되어 있지 않습니다. 내정보에서 메일설정을 해주세요.`}</span><br /><br />
          <span>{`나중에 설정하고 지금은 아래 관리자 메일로 보내시겠습니까?`}</span><br />
          <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#1976d2', p: 2, mt: 1, fontSize: '1em' }}>
            <span>{`보내는 사람 ${sessionUser?.email}`}</span><br />
            <span>{`참조 ${sessionUser && sessionUser.user ? sessionUser.user.userId : ""}`}</span>
          </Box>
        </>
        {/* {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`} */}
      </ConfirmDialog>
    </>
  );
};

export default SendBasketDialog;
