import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
// TODO : 최신버전은 TreeView가 @mui/lab에서 @mui/x-tree-view로 이동했으나 기존 개발버전이 작동하지 않아 올드버전 사용
// -import { TreeView, TreeItem } from '@mui/lab';
// +import { TreeView, TreeItem } from '@mui/x-tree-view';
import MuiTreeView from '@mui/lab/TreeView';
// import * as MuiTreeView from '@mui/x-tree-view/TreeView';
import SvgIcon from '@mui/material/SvgIcon';
import TreeItem from './GProjectG04TreeItem';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import {
  Preview,
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RefreshIcon from '@mui/icons-material/Refresh';

import LoadingButton from '@mui/lab/LoadingButton';
import { selectedGridRowsCountSelector } from '@mui/x-data-grid-pro';
import { StayCurrentLandscapeTwoTone } from '@mui/icons-material';
import {
  AlertDialog,
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import {
  // FormInputCheckbox,
  FormInputText,
} from "../form";
import * as gclientActions from "../../store/gclient";
import * as errorActions from "../../store/error";

// const data = [
//   {
//     id: 'applications',
//     label: 'Applications',
//     // type: 'main',
//     children: [
//       {
//         id: 'applications.calendar',
//         label: 'Calender',
//         // type: 'sub',
//       },
//       {
//         id: 'applications.chrome',
//         label: 'Chrome',
//         // type: 'sub',
//       },
//       {
//         id: 'applications.webstorm',
//         label: 'Webstorm',
//         // type: 'sub',
//       },
//     ],
//   },
//   {
//     id: 'documents',
//     label: 'Documents',
//     // type: 'main',
//     children: [
//       {
//         id: 'documents.bootstrap',
//         label: 'Bootstrap',
//         // type: 'sub',
//         children: [
//           {
//             id: 'documents.bootstrap.button',
//             label: 'Button',
//             // type: 'cat',
//           },
//           {
//             id: 'documents.bootstrap.typography',
//             label: 'Typography',
//             // type: 'cat',
//           },
//         ],
//       },
//       {
//         id: 'documents.oss',
//         label: 'OSS',
//         // type: 'sub',
//       },
//       {
//         id: 'documents.material-ui',
//         label: 'Material UI',
//         // type: 'sub',
//         children: [
//           {
//             id: 'documents.material-ui.button',
//             label: 'Button',
//             // type: 'cat',
//           },
//           {
//             id: 'documents.material-ui.typography',
//             label: 'Typography',
//             // type: 'cat',
//           },
//           {
//             id: 'documents.material-ui.textfield',
//             label: 'Text Field',
//             // type: 'cat',
//           },
//         ],
//       },
//     ],
//   },
// ];

// TODO : 이렇게 객체로 쓰면 간편한데??? 추후 검토
const cellStyle = {
  borderColor: '#dcdcdc',
  borderBottomWidth: 1, borderBottomStyle: 'solid',
  borderRightWidth: 1, borderRightStyle: 'solid',
};

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      // className="close" // 이 속성을 주면 나타나지 않음???
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

const defaultValues ={
  fileName: "",
  comments: "",

  // 날인문구
  // supplierLabel: "제공사",
  // supplier: "",
  recipientLabel: "제출처",
  recipient: "",
  siteLabel: "현장명",
  site: "",
  // phrase: "",
}

const TreeView = ({
  gsupplyConfirm,
  crudMode,
  setCrudMode,
  // itemsWithDocus = data,
  itemsWithDocus,
  itemsWithoutDocus,
  selected: selectedProps = [],
  setSelected,
  onSelect: onSelectProps = () => {},
  disableMultiParentSelection = true,
  handleClickViewEachDoc,
  handleClickViewSummary,
  handleClickAddSubstituteTestDoc,
  handleClickViewMaterialApproval,
  makeMaterialApproval,
  gclient,
  gproject,
  from,
  // toggleDrawer,
  showMaterialApproval,
  setParentShow,
  reGenerate,
  closedGClientList,
  setOpenRequest,
  refreshClosedGClients,
}) => {
  // console.log(handleClickViewEachDoc)
  // console.log(handleClickViewMaterialApproval)
  const [expanded, setExpanded] = useState([
    // // 'material-ui',
    // // 'documents',
    // // 'applications',
    // 'documents',
    // 'documents.bootstrap',
    // 'documents.material-ui',
  ]);
  // const [checkedAll, setCheckedAll] = useState(false);
  // const [showPreview, setShowPreview] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertInfo, setAlertInfo] = useState({});
  
  // 기존 문서관련 발급 옵션 유지
  const [docAttachedNValidDate, setDocAttachedNValidDate] = useState(true);
  const [docValidDate, setDocValidDate] = useState(false);
  const [docInvalidDate, setDocInvalidDate] = useState(false);
  const [docAttached, setDocAttached] = useState(false);
  const [docUnattached, setDocUnattached] = useState(false);

  // 단순화한 문서관련 발급 옵션
  const [includeExpiredDocs, setIncludeExpiredDocs] = useState(false);
  const [includeUnattachedDocs, setIncludeUnattachedDocs] = useState(false);
  const [includeGTestComparisons, setIncludeGTestComparisons] = useState(true);

  const [myCertified, setMyCertified] = useState(false);
  const [signOption, setSignOption] = useState("CERTIFIED_TRUE_COPY_AND_SIGN");

  const [URLThumbnail, setURLThumbnail] = useState("");
  const [emptyRecipient, setEmptyRecipient] = useState(false);
  const [emptySite, setEmptySite] = useState(false);

  const [showAll, setShowAll] = useState(true);
  const [showOnlyMissingDocs, setShowOnlyMissingDocs] = useState(false);
  const [showOnlyExpiredDocs, setShowOnlyExpiredDocs] = useState(false);
  const [filteredItems, setFilteredItems] = useState(itemsWithDocus);

  // 단순화된 UI의 체크박스 핸들러
  const handleIncludeExpiredDocs = (e, checked) => {
    setIncludeExpiredDocs(checked);
    
    // 기존 state 동기화
    setDocAttachedNValidDate(!checked);
    setDocValidDate(!checked);
    setDocInvalidDate(checked);
    if (!docAttached && !docUnattached) {
      setDocAttached(true);
    }
  };

  const handleIncludeUnattachedDocs = (e, checked) => {
    setIncludeUnattachedDocs(checked);
    
    // 기존 state 동기화
    setDocAttachedNValidDate(!checked);
    setDocUnattached(checked);
    setDocAttached(!checked);
    if (!docValidDate && !docInvalidDate) {
      setDocValidDate(true);
    }
  };

  const handleIncludeGTestComparisons = (e, checked) => {
    setIncludeGTestComparisons(checked);
  };

  const handleAllToggle = () => {
    setShowAll(true);
    setShowOnlyMissingDocs(false);
    setShowOnlyExpiredDocs(false);
    setFilteredItems(itemsWithDocus);
  };
  
  const handleMissingDocsToggle = () => {
    setShowAll(false);
    setShowOnlyMissingDocs(prev => !prev);
    setShowOnlyExpiredDocs(false);
    filterItems();
  };
  
  const handleExpiredDocsToggle = () => {
    setShowAll(false);
    setShowOnlyMissingDocs(false);
    setShowOnlyExpiredDocs(prev => !prev);
    filterItems();
  };

  const filterMissingDocs = (nodes) => {
    return nodes.filter(node => {
      if (node.children && node.children.length > 0) {
        node.children = filterMissingDocs(node.children);
        return node.children.length > 0;
      }
      return node.type?.substring(0, 3) === 'DOC' && 
        !(node.data?.documentPath || node.data?.performanceData?.performanceDocumentPath);
    });
  };

  const filterExpiredDocs = (nodes) => {
    return nodes.filter(node => {
      if (node.children && node.children.length > 0) {
        node.children = filterExpiredDocs(node.children);
        return node.children.length > 0;
      }
      return node.type?.substring(0, 3) === 'DOC' && 
        node.data?.documentPath && 
        !(node.data?.valid || node.data?.validYN);
    });
  };
  
  const deepClone = (obj) => {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
  
    if (Array.isArray(obj)) {
      return obj.map(deepClone);
    }
  
    const clonedObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        clonedObj[key] = deepClone(obj[key]);
      }
    }
    return clonedObj;
  };

  const filterItems = () => {
    if (!itemsWithDocus) return; // itemsWithDocus가 undefined일 경우 함수 종료
  
    // console.time('JSON');
    // let newFilteredItems = JSON.parse(JSON.stringify(itemsWithDocus)); // 깊은 복사
    // console.timeEnd('JSON');

    // console.time('deepClone');
    let newFilteredItems = deepClone(itemsWithDocus);
    // console.timeEnd('deepClone');
  
    if (showOnlyMissingDocs) {
      newFilteredItems = filterMissingDocs(newFilteredItems);
    }
  
    if (showOnlyExpiredDocs) {
      newFilteredItems = filterExpiredDocs(newFilteredItems);
    }
  
    console.log({ itemsWithDocus, newFilteredItems })
    setFilteredItems(newFilteredItems);
  };

  useEffect(() => {
    if (itemsWithDocus) { // itemsWithDocus가 존재할 때만 filterItems 실행
      filterItems();
    }
  }, [showOnlyMissingDocs, showOnlyExpiredDocs, itemsWithDocus]);

  const { handleSubmit, reset, control, setValue } = useForm({ defaultValues: defaultValues });

  const dispatch = useDispatch();

  const sessionUser = useSelector(state => state.session.sessionUser);

  const downloadFile = (documentPath) => gclientActions.downloadDirect(documentPath)

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (value) => {
    onSelectProps((prevSelected) => {
      // if (
      //   prevSelected.some(
      //     (prevSelect) =>
      //       prevSelect.startsWith('main.') && prevSelect !== value,
      //   )
      // ) {
      //   return prevSelected;
      // }

      if (prevSelected.includes(value)) {
        return prevSelected.filter((prevSelect) => prevSelect !== value);
      }

      return [...prevSelected, value];
    });
  };

  useEffect(
    async () => {
      if (sessionUser) {
        const { signDocumentPath } = sessionUser;
        if (signDocumentPath) {
          const res = await downloadFile(signDocumentPath);
          const blob = await res.blob(); // res.blob()는 비동기 함수이므로 await 필요
          const url = URL.createObjectURL(blob);
          setURLThumbnail(url);
        }
      }
    }, [open]
  )
  // useEffect(() => {
  //   setSelected(selectedProps);
  // }, [selectedProps]);

  const collectIds = (node) => {
    const ids = [];
    
    function traverse(item) {
      if (item.id) {
        ids.push(item.id);
      }
      if (item.children && Array.isArray(item.children)) {
        item.children.forEach(traverse);
      }
    }
    
    traverse(node);
    return ids;
  };

  const handleExpandClick = () => {
    const ids = itemsWithDocus.flatMap(collectIds);

    setExpanded((oldExpanded) =>
      oldExpanded.length === 0 ? ids : [],
    );
  };

  // const handleClickCheckedDoc = async () => {
  //   // console.log(itemsWithDocus)
  //   // const data = [];
  //   // JSON.stringify(itemsWithDocus, function(key, value) {
  //   //   // console.log({ key, value });
  //   //   if (key === 'documentPath' && value !== "") {
  //   //     data.push({ key, value });
  //   //   }

  //   //   return value; // return 하지 않으면 탐색 안됨
  //   // });

  //   // console.log(data)
  //   // handleSelect("SUPPLIER");

  //   // console.log(selectedProps);
  //   // console.log(itemsWithDocus)
  //   const documentAttached = [];
  //   const documentToGenerate = [];
    
  //   itemsWithDocus.forEach(root => { // root : 공급업체(SUPPLIER), 원자재(RAW_MATERIAL), 가공(PROCESS), 가공부자재(SUB_MATERIAL_PROCESS), 시공부자재(SUB_MATERIAL_BUILD)
  //     if (selectedProps.some(selectedProp => selectedProp === root.id)) return;

  //     const gclients = root.children;
  //     if(gclients && Array.isArray(gclients)) { // root.children : 업체목록
  //       gclients.forEach(gclient => {
  //         if (selectedProps.some(selectedProp => selectedProp === gclient.id)) return;
          
  //         documentToGenerate.push({
  //           name: gclient.label,
  //           // parent: { name: root.label },
  //           main: root.label,
  //           type: "MAIN",
  //         }); // 1. 메인분류(공급업체,원자재 등)와 업체명

  //         const categories = gclient.children;
  //         if (categories && Array.isArray(categories)) { // categories : 일반문서(GENERALS), 인증서(CERTIFICATIONS), 성적서(TESTS), 기타(ETCS)
  //           categories.forEach(category => {
  //             if (selectedProps.some(selectedProp => selectedProp === category.id)) return;

  //             const docs = category.children // docs : 실제 문서들. 성적서의 경우 ITEM임(한단계(children) 더 있음)
  //             if (docs && Array.isArray(docs)) {
                
  //               documentToGenerate.push({
  //                 name: category.label,
  //                 list: docs.length > 0 ? docs.map(doc => ({ name: doc.label, list: doc.children?.map(item => ({ name: item.label })) })) : [],
  //                 type: "CONTENTS",
  //               }); // 2. 문서종류와 하위목차

  //               docs.forEach(doc => {
  //                 if (selectedProps.some(selectedProp => selectedProp === doc.id)) return;

  //                 console.log(doc)
  //                 const { type, label } = doc;
  //                 if (type === 'ITEM') {
  //                   const tests = doc.children;
  //                   if (tests && Array.isArray(tests)) {
  //                     tests.forEach(test => {
  //                       if (test && test.data?.documentPath) {
  //                         // console.log(test)
  //                         !selectedProps.some(selectedProp => selectedProp === test.id) && documentAttached.push(test);
  //                         !selectedProps.some(selectedProp => selectedProp === test.id) && documentToGenerate.push({
  //                           header: { right: `${label} - ${test.label}`, left : gclient.label.split(":")[0] },
  //                           content: test.data.documentPath,
  //                           type: "DOC",
  //                         }); // 3. 실제문서 : 성적서
  //                       }
  //                     })
  //                   }
                    
  //                 } else {
  //                   if (doc && doc.data?.documentPath) {
  //                     // console.log(doc)
  //                     !selectedProps.some(selectedProp => selectedProp === doc.id) && documentAttached.push(doc);
  //                     !selectedProps.some(selectedProp => selectedProp === doc.id) && documentToGenerate.push({
  //                       header: { right: doc.label, left : gclient.label.split(":")[0] },
  //                       content: doc.data.documentPath,
  //                       type: "DOC",
  //                     }); // 3. 실제문서 : 성적서 이외의 문서
  //                   }
  //                 }
  //               })
  //             }
  //           })
  //         }
  //       })
  //     }
  //   })

  //   console.log(documentAttached);
  //   console.log(documentToGenerate);

  //   const g04docuMaterialApproval = await makeMaterialApproval({
  //     gclientId: gclient.id,
  //     gclient,
  //     gprojectId: gproject.id,
  //     gproject: { // TODO : 불필요한 데이터는 빼고 필요한 데이터만 추출하는 세련된 방법 필요 (객체의 함수로 만들고 scope를 정하여 해당 정보만을 리턴하도록 하는 등...)
  //       id: gproject.id,
  //       site: gproject.site,
  //       owner: gproject.owner,
  //       doneYN: gproject.doneYN,
  //       startDate: gproject.startDate,
  //       endDate: gproject.endDate,
  //       siteAddress: gproject.siteAddress,
  //     },
  //     generatedInformation: itemsWithoutDocus,
  //     generatedInformationWithDocus: itemsWithDocus,
  //     generatedInformationForPdfs: documentToGenerate,
  //     excluded: selectedProps,
  //   });

  //   // setShowPreview(true);
  // }

  // const handleClickCheckedDocInBrowser = () => {
  //   const documentAttached = [];
  //   const documentToGenerate = [];
    
  //   itemsWithDocus.forEach(root => { // root : 공급업체(SUPPLIER), 원자재(RAW_MATERIAL), 가공(PROCESS), 가공부자재(SUB_MATERIAL_PROCESS), 시공부자재(SUB_MATERIAL_BUILD)
  //     if (selectedProps.some(selectedProp => selectedProp === root.id)) return;

  //     const gclients = root.children;
  //     if(gclients && Array.isArray(gclients)) { // root.children : 업체목록
  //       gclients.forEach(gclient => {
  //         if (selectedProps.some(selectedProp => selectedProp === gclient.id)) return;
          
  //         documentToGenerate.push({
  //           name: gclient.label,
  //           // parent: { name: root.label },
  //           main: root.label,
  //           type: "MAIN",
  //         }); // 1. 메인분류(공급업체,원자재 등)와 업체명

  //         const categories = gclient.children;
  //         if (categories && Array.isArray(categories)) { // categories : 일반문서(GENERALS), 인증서(CERTIFICATIONS), 성적서(TESTS), 기타(ETCS)
  //           categories.forEach(category => {
  //             if (selectedProps.some(selectedProp => selectedProp === category.id)) return;

  //             const docs = category.children // docs : 실제 문서들. 성적서의 경우 ITEM임(한단계(children) 더 있음)
  //             if (docs && Array.isArray(docs)) {
                
  //               documentToGenerate.push({
  //                 name: category.label,
  //                 list: docs.length > 0 ? docs.map(doc => ({ name: doc.label, list: doc.children?.map(item => ({ name: item.label })) })) : [],
  //                 type: "CONTENTS",
  //               }); // 2. 문서종류와 하위목차

  //               docs.forEach(doc => {
  //                 if (selectedProps.some(selectedProp => selectedProp === doc.id)) return;

  //                 console.log(doc)
  //                 const { type, label } = doc;
  //                 if (type === 'ITEM') {
  //                   const tests = doc.children;
  //                   if (tests && Array.isArray(tests)) {
  //                     tests.forEach(test => {
  //                       if (test && test.data?.documentPath) {
  //                         // console.log(test)
  //                         !selectedProps.some(selectedProp => selectedProp === test.id) && documentAttached.push(test);
  //                         !selectedProps.some(selectedProp => selectedProp === test.id) && documentToGenerate.push({
  //                           header: { right: `${label} - ${test.label}`, left : gclient.label.split(":")[0] },
  //                           content: test.data.documentPath,
  //                           type: "DOC",
  //                         }); // 3. 실제문서 : 성적서
  //                       }
  //                     })
  //                   }
                    
  //                 } else {
  //                   if (doc && doc.data?.documentPath) {
  //                     // console.log(doc)
  //                     !selectedProps.some(selectedProp => selectedProp === doc.id) && documentAttached.push(doc);
  //                     !selectedProps.some(selectedProp => selectedProp === doc.id) && documentToGenerate.push({
  //                       header: { right: doc.label, left : gclient.label.split(":")[0] },
  //                       content: doc.data.documentPath,
  //                       type: "DOC",
  //                     }); // 3. 실제문서 : 성적서 이외의 문서
  //                   }
  //                 }
  //               })
  //             }
  //           })
  //         }
  //       })
  //     }
  //   })

  //   console.log(documentAttached);
  //   console.log(documentToGenerate);

  //   // // create a document and pipe to a blob
  //   // var doc = new PDFDocument();
  //   // var stream = doc.pipe(blobStream());

  //   // // draw some text
  //   // doc.fontSize(25).text('Here is some vector graphics...', 100, 80);

  //   // // some vector graphics
  //   // doc
  //   //   .save()
  //   //   .moveTo(100, 150)
  //   //   .lineTo(100, 250)
  //   //   .lineTo(200, 250)
  //   //   .fill('#FF3300');

  //   // doc.circle(280, 200, 50).fill('#6600FF');

  //   // // an SVG path
  //   // doc
  //   //   .scale(0.6)
  //   //   .translate(470, 130)
  //   //   .path('M 250,75 L 323,301 131,161 369,161 177,301 z')
  //   //   .fill('red', 'even-odd')
  //   //   .restore();

  //   // // and some justified text wrapped into columns
  //   // doc
  //   //   .text('And here is some wrapped text...', 100, 300)
  //   //   .font('Times-Roman', 13)
  //   //   .moveDown()
  //   //   .text(lorem, {
  //   //     width: 412,
  //   //     align: 'justify',
  //   //     indent: 30,
  //   //     columns: 2,
  //   //     height: 300,
  //   //     ellipsis: true
  //   //   });

  //   // // end and display the document in the iframe to the right
  //   // doc.end();
  //   // stream.on('finish', function() {
  //   //   iframe.src = stream.toBlobURL('application/pdf');
  //   // });
  //   const iframe = document.querySelector("iframe");
  //   // iframe.src = stream.toBlobURL('application/pdf');
  //   // iframe.src = 'http://www.warpcore.co.kr'
  //   iframe.src = 'http://localhost:5002/files/Material Approvals/(주)워프코어(356d05cd-2bf1-46b4-8075-c6a951707bb7)/NEW(ccfdbe72-d918-4132-a884-41b876bc0bc6)/NEW_ma_2023-08-03-183410-660.pdf'
  // }

  const handleCheckClick = () => {
    // checkedAll ? onSelectProps([]) : onSelectProps(itemsWithDocus.map(i => i.id));
    // setCheckedAll(!checkedAll);
    onSelectProps([]);
  }

  const equalsIgnoreOrder = (a, b) => {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
      const aCount = a.filter(e => e === v).length;
      const bCount = b.filter(e => e === v).length;
      if (aCount !== bCount) return false;
    }
    return true;
  }

  // useEffect(
  //   () => {
  //     setCheckedAll(true);
  //     onSelectProps(itemsWithDocus.map(i => i.id));
  //   }, [itemsWithDocus]
  // )

  // useEffect(
  //   () => {
  //     (itemsWithDocus?.length > 0 && equalsIgnoreOrder(selectedProps, itemsWithDocus.map(i => i.id))) ? setCheckedAll(true) : setCheckedAll(false)
  //   }, [selectedProps]
  // )

  const onSubmit = async ({ fileName, comments, supplierLabel, supplier, recipientLabel, recipient, siteLabel, site, phrase }, e) => {
    setLoading(true);
    
    // console.log({ fileName, comments, supplierLabel, supplier, recipientLabel, recipient, siteLabel, site, phrase })
    // setLoading(false);
    // return;

    setErrors([]);
    
    // if (signOption === 'CERTIFIED_TRUE_COPY_AND_SIGN') {
    //   if (!recipient) {
    //     setEmptyRecipient(true);
    //   }

    //   if (!site) {
    //     setEmptySite(true);
    //   }

    //   if (!recipient || !site) {
    //     setAlertInfo({
    //       titleAlert: "안내",
    //       // messageAlert: `제출처나 현장명을 모두 입력해주세요.`,
    //       messageAlert: `제출처를 입력해주세요.`,
    //       open: true,
    //     });
    //     return;
    //   }
    // }

    const phrases = {
      // supplierLabel,
      // supplier,
      recipientLabel,
      recipient,
      siteLabel,
      site,
      // phrase,
    };

    const generateOptions = {
      // 발급옵션
      docAttachedNValidDate: docAttachedNValidDate ? 'Y' : 'N',
      docValidDate: docValidDate ? 'Y' : 'N',
      docInvalidDate: docInvalidDate ? 'Y' : 'N',
      docAttached: docAttached ? 'Y' : 'N',
      docUnattached: docUnattached ? 'Y' : 'N',
      includeGTestComparisons: includeGTestComparisons ? 'Y' : 'N',
      // 문서처리옵션
      myCertified: myCertified ? 'Y' : 'N',
      sign: signOption,
      phrases,
    };

    // console.log({ fileName, comments, generateOptions });
    // setLoading(false);
    // return;

    // console.log(itemsWithDocus)
    // const data = [];
    // JSON.stringify(itemsWithDocus, function(key, value) {
    //   // console.log({ key, value });
    //   if (key === 'documentPath' && value !== "") {
    //     data.push({ key, value });
    //   }

    //   return value; // return 하지 않으면 탐색 안됨
    // });

    // console.log(data)
    // handleSelect("SUPPLIER");

    // console.log(selectedProps);
    // console.log(itemsWithDocus)
    const documentIncluded = [];
    const documentToGenerate = [];
    const domesticAuths = [];

    // const documentToGenerate1 = [
    //   {
    //     main: "납품확인서 제출업체",
    //     name: "공사다큐어드민",
    //     phrases: null,
    //     sign: null,
    //     type: "MAIN",
    //   },
    //   {
    //     exist: true,
    //     list: [
    //       {
    //         exist: true,
    //         name: "납품확인서 요약표",
    //         opened: true,
    //         valid: true,
    //         validYN: true,
    //       },
    //       {
    //         exist: true,
    //         name: "납품확인서 - (주)KCC글라스",
    //         opened: true,
    //         valid: true,
    //         validYN: true,
    //       },
    //       {
    //         exist: true,
    //         name: "납품확인서 - 금강글라스(주)",
    //         opened: true,
    //         valid: true,
    //         validYN: true,
    //       }
    //     ],
    //     name: "납품확인서",
    //     type: "CONTENTS",
    //     valid: true,
    //     validYN: true
    //   },
    //   {
    //     content: null, // 요약 데이터
    //     exist: true,
    //     header: { right: "납품확인서 요약표", left: "공사다큐어드민" },
    //     opened: true,
    //     type: "TABLE_DATA",
    //     valid: true,
    //     validYN: true,
    //   },
    //   {
    //     content: "/home/warpcore/project/g04docu/SupplyConfirms/공사다큐어드민(bc7f545c-9545-4268-8d05-f631924773bf)/강서구_가양동(f19e7f9b-f2a9-44b8-8064-d97061631d3b)/(주)KCC글라스_2024-11-26-112320-877.pdf",
    //     exist: true,
    //     header: { right: "납품확인서", left: "(주)KCC글라스" },
    //     opened: true,
    //     type: "DOC",
    //     valid: true,
    //     validYN: true,
    //   },
    //   {
    //     content: "/home/warpcore/project/g04docu/SupplyConfirms/공사다큐어드민(bc7f545c-9545-4268-8d05-f631924773bf)/강서구_가양동(f19e7f9b-f2a9-44b8-8064-d97061631d3b)/금강글라스(주)_2024-11-27-123401-657.pdf",
    //     exist: true,
    //     header: { right: "납품확인서", left: "금강글라스(주)" },
    //     opened: true,
    //     type: "DOC",
    //     valid: true,
    //     validYN: true,
    //   }
    // ];
    
    itemsWithDocus.forEach(root => { // root : 공급업체(SUPPLIER), 원자재(RAW_MATERIAL), 가공(PROCESS), 가공부자재(SUB_MATERIAL_PROCESS), 시공부자재(SUB_MATERIAL_BUILD)
      if (selectedProps.some(selectedProp => selectedProp === root.id)) return;

      // TODO : 여기의 gclients는 GProjectGlasses의 selectedGcomponentItems의 gclient임. 그리고 GProjectGlasses의 selectedGcomponentItems의 gclient는 GClientGComponentItemMaps의 gclient임
      // GClientGComponentItemMaps은 거래선에서 제품 매핑할 때 기록되는데 이후 거래선 정보가 변경될 때 반영되지 못하는 심각한 문제가 발생
      // 갱신된 gclient 정보관리에 대해 어떻게 해야 할지 고민중... DB 필드에 gclient가 있는 부분 전체 점검이 필요하고 gclientId만 저장하고 해당 아이디로 authServer에서 gclients 정보를 가져와 비교해야 할 것으로 보임
      const gclients = root.children;
      if(gclients && Array.isArray(gclients)) { // root.children : 업체목록
        gclients.forEach(gclient => {
          // console.log(gclient)
          // console.log(gclient.data.gclient)
          if (selectedProps.some(selectedProp => selectedProp === gclient.id)) return;
          
          // 국내가공유리제품 사전 인증서는 따로 관리함
          if (gclient.type === "DOMESTIC_AUTH") {
            domesticAuths.push(gclient); // 업체명였다가 뒤에 국내가공유리제품 사전 인증서 기능이 추가되어 변수명 gclients는 맞지 않지만 그대로 사용함
            return; // continue
          } else if (gclient.type === "GSUPPLY_CONFIRM_SUMMARY" || gclient.type === "GSUPPLY_CONFIRM_DOC") {
            console.log(root)
            console.log(gclient)
            // 납품확인서 관련 데이터 추가
            if (gclient.type === "GSUPPLY_CONFIRM_SUMMARY") {
              // 메인 제목 추가
              documentToGenerate.push({
                main: "납품확인서 제출업체",
                name: gclient.data.gclient.name,
                // phrases: null,
                // sign: null,
                type: "MAIN",
                // 납품확인서용
                docType: 'GSUPPLY_CONFIRM',
              });
              // 납품확인서 목차 추가
              const list = root.children
                .filter(child => !selectedProps.some(selectedProp => selectedProp === child.id))
                .map((child, index) => ({
                  exist: index === 0 ? true : (child.data?.documentPath ? true : false), // 첫번째 항목은 무조건 true
                  name: child.label,
                  opened: child.data?.opened || true,
                  valid: child.data?.valid || true,
                  validYN: child.data?.validYN || true,
                }));
              documentToGenerate.push({
                exist: true,
                list,
                name: "납품확인서",
                type: "CONTENTS",
                valid: true,
                validYN: true,
                // 납품확인서용
                docType: 'GSUPPLY_CONFIRM',
              });
              // 요약표 데이터 추가
              documentToGenerate.push({
                content: null, // 요약 데이터는 서버에서 처리
                exist: true,
                header: { right: "납품확인서 요약표", left: root.label },
                opened: true,
                type: "TABLE_DATA",
                valid: true,
                validYN: true,
                // 납품확인서용
                docType: 'GSUPPLY_CONFIRM',
                tableData: gclient.data?.tableData,
                sign: gclient.data?.gclient?.signDocumentPath,
                phrases: gclient.data?.gclient?.phrases,
              });
            } else if (gclient.type === "GSUPPLY_CONFIRM_DOC") {
              // 개별 납품확인서 문서 추가
              documentToGenerate.push({
                content: gclient.data?.documentPath,
                exist: gclient.data?.documentPath ? true : false,
                header: { right: "납품확인서", left: gclient.label },
                opened: gclient.data?.opened || true,
                type: "DOC",
                valid: gclient.data?.valid || true,
                validYN: gclient.data?.validYN || true,
                // 납품확인서용
                docType: 'GSUPPLY_CONFIRM',
                sign: gclient.data?.gclient?.signDocumentPath,
                phrases: gclient.data?.gclient?.phrases,
              });
            }

            return; // continue
          }

          documentToGenerate.push({
            name: gclient.label,
            // parent: { name: root.label },
            main: root.label,
            type: "MAIN",
            sign: gclient?.data?.gclient?.signDocumentPath,
            phrases: gclient?.data?.gclient?.phrases,
          }); // 1. 메인분류(공급업체,원자재 등)와 업체명

          const categories = gclient.children;
          if (categories && Array.isArray(categories)) { // categories : 일반문서(GENERALS), 인증서(CERTIFICATIONS), 성적서(TESTS), 기타(ETCS)
            categories.forEach(category => {
              if (selectedProps.some(selectedProp => selectedProp === category.id)) return;
              
              // docs : 실제 문서들. 성적서의 경우 원자재나 가공은 ITEM(한단계(children) 더 있음)이고 시가공부자재는 인증규격(제품그룹)이고 그 아래에 제품(ITEM) 여기에 하나더 하위가 실제 문서임
              const docs = category.children;
              if (docs && Array.isArray(docs) && docs.length > 0) {
                
                // console.log({ name: category.label, docs });
                
                let list = null;
                // let exist = true;
                if (docs.length > 0) {
                  const { type } = docs[0];
                  // console.log(docs)
                  if (type === 'DOCGENERAL' || type === 'DOCCERTIFICATION') {
                    list = docs.filter(doc => !selectedProps.some(selectedProp => (selectedProp === doc.id)))
                               .map(doc => ({ name: doc.label, exist: doc.data?.documentPath ? true : false, valid: doc.data?.valid ? true : false, validYN: doc.data?.validYN, opened: gclient.data.gclient.opened }))
                  } else if (type === 'ITEM') {
                    list = docs.filter(doc => !selectedProps.some(selectedProp => (selectedProp === doc.id)))
                               .map(doc => {
                                  if (doc.children && doc.children.length > 0) {
                                    const filtered = doc.children.filter(item => !selectedProps.some(selectedProp => (selectedProp === item.id)))
                
                                    const itemList = filtered.map(item => {
                                      const subItemList = []
                                      if (item.data.division === 'SUB_MATERIAL_PROCESS' || item.data.division === 'SUB_MATERIAL_BUILD') {
                                        const filteredSubs = item.children.filter(i => !selectedProps.some(selectedProp => (selectedProp === i.id)));
                                        filteredSubs.forEach(subItem => {
                                          const substitute = subItem.data?.substitute === 'Y' ? 'Y' : 'N';
                                          subItemList.push({
                                            name: `${subItem.label}${substitute === 'Y' ? " (*대체성적서)" : ""}`,
                                            exist: subItem.data?.documentPath ? true : false,
                                            valid: subItem.data?.valid ? true : false,
                                            validYN: subItem.data?.validYN,
                                            // substitute,
                                            // substituteInformation: subItem.data?.substituteInformation,
                                          });
                                        });
                                      }
                                      // console.log(item)
                                      // 아래에서 시가공부자재는 item.data가 성적서가 아니고 item.children이 실제 성적서임
                                      if (item.data.division === 'SUB_MATERIAL_PROCESS' || item.data.division === 'SUB_MATERIAL_BUILD') {
                                        let exist;
                                        let valid;
                                        let validYN;
                                        if (item.children && Array.isArray(item.children)) {
                                          item.children.forEach(c => {
                                            exist = exist || (c.data.documentPath ? true : false);
                                            valid = valid || c.data.valid;
                                            validYN = validYN || c.data.validYN;
                                          });
                                        }
                                        // console.log({ exist, valid, validYN })
                                        return {
                                          name: item.label,
                                          exist,
                                          valid,
                                          validYN,
                                          list: subItemList,
                                        }
                                      } else {
                                        const gglassPerformanceYN = item.data?.performanceData?.performanceDocumentPath ? 'Y' : 'N';
                                        return {                                          
                                          name: `${item.label}${!item.data?.documentPath && gglassPerformanceYN === 'Y' ? " (*성능확인서)" : ""}${item.data?.substitute === 'Y' ? " (*대체성적서)" : ""}`,
                                          exist: (item.data?.documentPath || item.data?.performanceData?.performanceDocumentPath) ? true : false, // TODO : 복층유리에서만 필요
                                          valid: item.data?.performanceData?.performanceDocumentPath ? true : (item.data?.valid ? true : false), // TODO : 복층유리에서만 필요
                                          // validYN: doc.data?.validYN,
                                          validYN: item.data?.validYN,
                                          // list: subItemList.length > 0 ? subItemList : [],
                                          gglassPerformanceYN,
                                          // substitute: item.data?.substitute,
                                          // substituteInformation: item.data?.substituteInformation,
                                        }
                                      }
                                    });
                                    
                                    // console.log(doc)
                                    // console.log(filtered)
                                    let exist;
                                    let valid;
                                    let validYN;
                                    // let gglassPerformanceYN = "";
                                    // 아래에서 시가공부자재는 filtered안의 item.data가 성적서가 아니고 item.children이 실제 성적서임
                                    filtered.forEach(f => {
                                      if (doc.data.itemType === 'SUB_MATERIAL_PROCESS' || doc.data.itemType === 'SUB_MATERIAL_BUILD') {
                                        f.children.forEach(c => {
                                          exist = exist || c.data.documentPath;
                                          valid = valid || c.data.valid;
                                          validYN = validYN || c.data.validYN;
                                        });
                                      } else {
                                        exist = exist || (f.data.documentPath || f.data?.performanceData?.performanceDocumentPath); // TODO : 복층유리에서만 필요
                                        valid = valid || f.data?.performanceData?.performanceDocumentPath ? true : f.data.valid; // TODO : 복층유리에서만 필요
                                        validYN = validYN || f.data.validYN;
                                        // gglassPerformanceYN = f.data?.performanceData?.performanceDocumentPath ? 'Y' : 'N';
                                      }
                                    });

                                    return {
                                      name: doc.label,
                                      list: itemList,
                                      exist: exist ? true : false,
                                      valid: valid ? true : false,
                                      validYN: validYN ? true : false,
                                      opened: gclient.data.gclient.opened,
                                    }
                                  } else {
                                    return { name: doc.label, list, exist: false, valid: false, validYN: false }
                                  }
                               });
                  }
                }

                let exist = false;
                let valid = false;
                let validYN = false;
                // console.log(list)
                if (list && Array.isArray(list) && list.length > 0) {
                  list.forEach(L => {
                    if (L.list) {
                      L.list.forEach(l => {
                        exist = exist || l.exist;
                        valid = valid || l.valid;
                        validYN = validYN || l.validYN;
                      });
                    } else {
                      exist = exist || L.exist;
                      valid = valid || L.valid;
                      validYN = validYN || L.validYN;
                    }
                  });

                  exist = exist ? true : false;
                  valid = valid ? true : false;
                  validYN = validYN ? true : false;

                  // const resultsExist = list.filter(item => {
                  //   if (item.list) {
                  //     return (item.list.filter(item1 => item1.exist === true).length > 0 ? true : false)
                  //   }

                  //   return (item.exist === true ? true : false);
                  // })
                  // exist = resultsExist.length > 0 ? true : false;

                  // const resultsValid = list.filter(item => {
                  //   if (item.list) {
                  //     return (item.list.filter(item1 => item1.valid === true).length > 0 ? true : false)
                  //   }

                  //   return (item.valid === true ? true : false);
                  // })
                  // valid = resultsValid.length > 0 ? true : false;

                  // const resultsValidYN = list.filter(item => {
                  //   if (item.list) {
                  //     return (item.list.filter(item1 => item1.validYN === true).length > 0 ? true : false)
                  //   }

                  //   return (item.validYN === true ? true : false);
                  // })
                  // validYN = resultsValidYN.length > 0 ? true : false;
                }

                // console.log({ exist, valid, validYN })
                // console.log({ exist1, valid1, validYN1 })
                const contents = {
                  name: category.label,
                  list,
                  exist,
                  valid,
                  validYN,
                  type: "CONTENTS",
                };

                documentToGenerate.push(contents);

                if (docs.length > 0) {
                  docs.forEach(doc => {
                    if (selectedProps.some(selectedProp => selectedProp === doc.id)) return;
  
                    // console.log(doc)
                    const { type, label } = doc;
                    if (type === 'ITEM') {
                      const tests = doc.children;
                      if (tests && Array.isArray(tests)) {
                        // console.log(tests)
                        tests.forEach(test => {
                          // if (test && test.data?.documentPath) {
                          if (test && test.data) {
                            // console.log(test)
                            !selectedProps.some(selectedProp => selectedProp === test.id) && documentIncluded.push(test);

                            if (test.data.division === 'SUB_MATERIAL_PROCESS' || test.data.division === 'SUB_MATERIAL_BUILD') {
                              const subTests = test.children;
                              // console.log(subTests)
                              if (subTests && Array.isArray(subTests)) {
                                subTests.forEach(subTest => {
                                  const substitute = subTest.data?.substitute === 'Y' ? 'Y' : 'N';
                                  if (!selectedProps.some(selectedProp => selectedProp === subTest.id)) {
                                    documentIncluded.push(subTest);
                                    documentToGenerate.push({
                                      header: { right: `${test.label} - ${subTest.label}${substitute === 'Y' ? " (*대체성적서)" : ""}`, left : gclient.label.split(":")[0] },
                                      content: subTest.data.documentPath,
                                      /**
                                       * TODO : 시험성과대비표가 있는 경우 => documentToGenerated에 추가로 push하지 않고 아래와 같이 기존 성적서에 contentGTestComparison만 추가
                                       * 시험성과대비표는 추후 최상위 계층의 요약으로 이동할 예정이며, 현재로선 자재승인서 작성서 서버에서 성적서 다음에 출력하도록 하고 있음
                                       */
                                      contentGTestComparison: subTest.data.gtestComparisonDocumentPath,
                                      type: "DOC",
                                      exist: subTest.data?.documentPath ? true : false,
                                      valid: subTest.data?.valid ? true : false,
                                      validYN: subTest.data?.validYN ? true : false,
                                      opened: gclient.data.gclient.opened,
                                      // substitute,
                                      // substituteInformation: subTest.data?.substituteInformation,
                                    }); // 3. 실제문서 : 성적서
                                  }
                                })
                              }
                            } else {
                              const gglassPerformanceYN = test.data?.performanceData?.performanceDocumentPath ? 'Y' : 'N';
                              const substitute = test.data?.substitute === 'Y' ? 'Y' : 'N';
                              const generateObj = {
                                header: { right: `${label} - ${test.label}${!test.data.documentPath && gglassPerformanceYN === 'Y' ? " (*성능확인서)" : ""}${substitute === 'Y' ? " (*대체성적서)" : ""}`, left : gclient.label.split(":")[0] },
                                content: test.data.documentPath || test.data?.performanceData?.performanceDocumentPath, // TODO : 복층유리에서만 필요
                                /**
                                 * TODO : 시험성과대비표가 있는 경우 => documentToGenerated에 추가로 push하지 않고 아래와 같이 기존 성적서에 contentGTestComparison만 추가
                                 * 시험성과대비표는 추후 최상위 계층의 요약으로 이동할 예정이며, 현재로선 자재승인서 작성서 서버에서 성적서 다음에 출력하도록 하고 있음
                                 */
                                contentGTestComparison: test.data.gtestComparisonDocumentPath,
                                type: "DOC",
                                exist: (test.data?.documentPath || test.data?.performanceData?.performanceDocumentPath) ? true : false, // TODO : 복층유리에서만 필요
                                valid: test.data?.performanceData?.performanceDocumentPath ? true : (test.data?.valid ? true : false), // TODO : 복층유리에서만 필요
                                validYN: test.data?.validYN ? true : false,
                                opened: gclient.data.gclient.opened,
                                gglassPerformanceYN,
                                // substitute,
                                // substituteInformation: test.data?.substituteInformation,
                              };

                              !selectedProps.some(selectedProp => selectedProp === test.id) && documentToGenerate.push(generateObj); // 3. 실제문서 : 성적서
                            }
                          }
                        })
                      }
                      
                    } else {
                      // if (doc && doc.data?.documentPath) {
                      if (doc && doc.data) {
                        if (!selectedProps.some(selectedProp => selectedProp === doc.id)) {
                          documentIncluded.push(doc);
                          documentToGenerate.push({
                            header: { right: doc.label, left : gclient.label.split(":")[0] },
                            content: doc.data.documentPath,
                            type: "DOC",
                            exist: doc.data?.documentPath ? true : false,
                            valid: doc.data?.valid ? true : false,
                            validYN: doc.data?.validYN ? true : false,
                            opened: gclient.data.gclient.opened,
                          }); // 3. 실제문서 : 성적서 이외의 문서
                        }
                      }
                    }
                  })
                } else {
                  // !selectedProps.some(selectedProp => selectedProp === doc.id) && documentIncluded.push(doc);
                  // !selectedProps.some(selectedProp => selectedProp === doc.id) && 
                  documentToGenerate.push({
                    header: { right: category.label, left : gclient.label.split(":")[0] },
                    content: null,
                    type: "DOC",
                    exist: false,
                    valid: false,
                    validYN: false,
                  }); // 문서가 없는 경우 더미문서 추가
                }
              } else if (category.label !== '기타') {
                // 기타 카테고리가 아니고 문서가 없는 경우에만 더미 문서 추가
                documentToGenerate.push({
                  header: { right: category.label, left : gclient.label.split(":")[0] },
                  content: null,
                  type: "DOC",
                  exist: false,
                  valid: false,
                  validYN: false,
                });
              }
            })
          }
        })
      }
    })

    console.log(itemsWithDocus);
    console.log(documentIncluded);
    console.log("첨부되지 않은 문서 목록")
    console.log(documentIncluded.filter(item => item.data.documentPath === null)); // 첨부되지 않은 문서
    console.log("유효기간이 지난 문서 목록")
    console.log(documentIncluded.filter(item => item.data.documentPath !== null && item.data.valid !== true && item.data.validYN !== true)); // 유효기간이 지난 문서
    console.log(documentToGenerate);
    console.log(documentToGenerate.filter(item => item.type === 'CONTENTS'))
    console.log(generateOptions);
    console.log(domesticAuths);

    // TODO : 테스트용으로 주석 풀었음
    // setLoading(false);
    // return;

    try {
      // gclient는 sessionUser임
      const g04docuMaterialApproval = await makeMaterialApproval({
        docType: gsupplyConfirm ? "GSUPPLY_CONFIRM" : "MATERIAL_APPROVAL", // 자재승인서/납품확인서 구분
        gclientId: gclient.id,
        gclient,
        gprojectId: gproject.id,
        gproject: { // TODO : 불필요한 데이터는 빼고 필요한 데이터만 추출하는 세련된 방법 필요 (객체의 함수로 만들고 scope를 정하여 해당 정보만을 리턴하도록 하는 등...)
          id: gproject.id,
          site: gproject.site,
          owner: gproject.owner,
          doneYN: gproject.doneYN,
          startDate: gproject.startDate,
          endDate: gproject.endDate,
          siteAddress: gproject.siteAddress,
        },
        generatedInformation: itemsWithoutDocus,
        generatedInformationWithDocus: itemsWithDocus,
        generatedInformationForPdfs: documentToGenerate,
        excluded: selectedProps,
        fileName,
        comments,
        generateOptions,
        domesticAuths,
      });

      console.log(g04docuMaterialApproval);
      const { results } = g04docuMaterialApproval;
      if (results && results.status === 'ERROR' && results.details[0] === 'Duplicated file name') {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: "같은 파일이 존재합니다. 다른 파일 이름을 사용해주세요.",
          open: true,
        });

        // setLoading(false);
        return;
      }
      
      setTimeout(() => {
        setLoading(false);
        setOpen(false);
        
        // 서류보관함 열기
        setParentShow(false); // 현재 다이얼로그를 숨기고 Drawer 열어야 함. 그래서 toggleDrawer 내부를 보면 닫힐 때 다시 다이얼로그를 보이게 하는 부분이 있음
        // toggleDrawer("G04DOCU_MATERIAL_APPROVAL", true)(e);
        showMaterialApproval({ g04docuMaterialApproval });
      }, 100);
    } catch (e) {
      console.log(e);
      // setAlertInfo({
      //   titleAlert: "오류",
      //   messageAlert: `자재승인서류 작성 중 오류가 발생하였습니다. 관리자에게 문의해주세요.`,
      //   open: true,
      // });
      dispatch(errorActions.occurError({ response: e.response, serverResponse: e.serverResponse })); // 오류 처리 방식 4
      setTimeout(() => setLoading(false), 2000);
    }
  }
  
  const handleChangeSignOption = (e) => {
    const { value } = e.target;
    setSignOption(value);

    if (value === 'NONE') {
      setMyCertified(false);
    }
  }

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그 외 초기화
    setDocAttachedNValidDate(true);
    setDocValidDate(false);
    setDocInvalidDate(false);
    setDocAttached(false);
    setDocUnattached(false);

    setSignOption("CERTIFIED_TRUE_COPY_AND_SIGN");

    setEmptyRecipient(false);
    setEmptySite(false);
  }

  const handleDialogClose = () => {
    setOpen(false);
    setLoading(false);
    setCrudMode('');

    initDialog();
  }

  const handleChangeDocAttachedNValidDate = (e, checked) => {
    console.log(checked)
    setDocAttachedNValidDate(checked);

    if (checked) {
      setDocValidDate(false);
      setDocInvalidDate(false);
      setDocAttached(false);
      setDocUnattached(false);
    } else {
      setDocValidDate(true);
      setDocUnattached(true);
    }
  }

  const handleChangeDocValidDate = (e, checked) => {
    // console.log(checked)
    setDocValidDate(checked);
    
    setDocInvalidDate(!checked);
    setDocAttachedNValidDate(false);

    if(!docAttached && !docUnattached) {
      setDocAttached(true);
    }
  }

  const handleChangeDocInvalidDate = (e, checked) => {
    // console.log(checked)
    setDocInvalidDate(checked);
    
    setDocValidDate(!checked);
    setDocAttachedNValidDate(false);

    if(!docAttached && !docUnattached) {
      setDocAttached(true);
    }
  }

  const handleChangeDocAttached = (e, checked) => {
    // console.log(checked)
    setDocAttached(checked);

    setDocUnattached(false);
    setDocAttachedNValidDate(false);

    if(!docValidDate && !docInvalidDate) {
      setDocValidDate(true);
    }
  }

  const handleChangeDocUnattached = (e, checked) => {
    // console.log(checked)
    setDocUnattached(checked);

    setDocAttached(false);
    setDocAttachedNValidDate(false);

    if(!docValidDate && !docInvalidDate) {
      setDocValidDate(true);
    }
  }

  const handleChangeMyCertified = (e, checked) => {
    setMyCertified(checked);
  }

  const handleClickGenerate = () => {
    // console.log(sessionUser)
    if (sessionUser) {
      // if (sessionUser["phrases"]) {
      //   const { supplierLabel, supplier, phrase } = sessionUser["phrases"];
      //   setValue("supplierLabel", supplierLabel);
      //   setValue("supplier", supplier);
      //   setValue("phrase", phrase);
      // }
      setValue("supplierLabel", "제공사");
      setValue("supplier", "해당 문서의 제공사가 표시됩니다.");
      setValue("recipient", gproject.constructionCompanyName);
      setValue("site", gproject.site);
      setValue("phrase", "해당 문서의 제공사의 안내문구가 표시됩니다.");
    }

    setOpen(true);
  }

  // const handleChangeRecipient = (e) => {
  //   const { value } = e.target;
  //   // console.log(value)
  //   if (value) {
  //     setEmptyRecipient(false);
  //   } else {
  //     setEmptyRecipient(true);
  //   }

  //   setValue("recipient", value);
  // }

  const handleChangeSite = (e) => {
    const { value } = e.target;
    // console.log(value)
    if (value) {
      setEmptySite(false);
    } else {
      setEmptySite(true);
    }

    setValue("site", value);
  }

  return (
    <>
      <Box sx={{ height: 'calc(100vh - 120px)' }}>
        <Stack direction="row" sx={{ mt: 2, mb: 2 }} justifyContent="space-between">
          <Box>
            {
              from?.source === "GProjectG04GeneratorDialog" && (
                <>{
                    closedGClientList.length > 0 && (
                      <Button variant={"outlined"} onClick={() => {
                        refreshClosedGClients();
                        setOpenRequest(true);
                      }} sx={{ mr: 1 }}>
                        {"열람요청"}
                      </Button>
                    )
                  }
                  <Button variant={"contained"} onClick={handleClickGenerate}>
                    {"생성"}
                  </Button>
                </>
              )
            }
            {
              from?.source === "G04docuMaterialApprovalDialog" && (
                <Button 
                  variant="outlined" 
                  onClick={() => handleClickViewMaterialApproval(from.params.g04docuMaterialApproval.documentPath)}
                  startIcon={<Preview />}
                >
                  {"자재승인서류 보기"}
                </Button>
              )
            }
          </Box>
          <Box>
            <ToggleButtonGroup size="small" exclusive color="primary">
              <ToggleButton
                value="all"
                selected={showAll}
                onChange={handleAllToggle}
              >
                {"전체"}
              </ToggleButton>
              <ToggleButton
                value="missingDocs"
                selected={showOnlyMissingDocs}
                onChange={handleMissingDocsToggle}
              >
                {"서류없음"}
              </ToggleButton>
              <ToggleButton
                value="expiredDocs"
                selected={showOnlyExpiredDocs}
                onChange={handleExpiredDocsToggle}
              >
                {"만료일 경과"}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Stack>
        <Box sx={{ 
          border: '1px solid #e0e0e0', 
          borderRadius: '4px', 
          overflow: 'hidden',
          position: 'relative',  // 추가
          height: '95%',       // 높이 설정 (필요에 따라 조정)
          overflowY: 'auto'      // 세로 스크롤 추가
        }}>
          <Box sx={{ 
            bgcolor: '#f5f5f5', 
            pl: 2,
            pr: 2,
            pb: 1,
            pt: 1,
            borderBottom: '1px solid #e0e0e0',
            position: 'sticky',  // 고정 위치 설정
            top: 0,              // 상단에 고정
            zIndex: 1,           // 다른 요소 위에 표시
          }}>
            <Grid container alignItems="center">
              <Grid item xs={1}>
                <Tooltip title={expanded.length === 0 ? "전체 펼치기" : "전체 닫기"}>
                  <IconButton
                    onClick={handleExpandClick}
                    size="small"
                  >
                    {expanded.length === 0 ? <AddIcon /> : <RemoveIcon />}
                  </IconButton>
                </Tooltip>
                {
                  from?.source === "GProjectG04GeneratorDialog" && (
                    <Tooltip title="모든 체크 해제">
                      <IconButton
                        onClick={handleCheckClick}
                        size="small"
                      >
                        <CheckBoxOutlineBlankIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }
                {
                  from?.source === "GProjectG04GeneratorDialog" && (
                    <Tooltip title="서류 다시 검색">
                      <IconButton
                        size="small"
                        onClick={async () => {
                          setLoading(true);
                          await reGenerate();
                          setExpanded([]);
                          setTimeout(() => setLoading(false), 1000);
                        }}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }
              </Grid>
              <Grid item xs={4.5} sx={{ textAlign: 'center' }}>
                <Typography variant="subtitle1">목차</Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Typography variant="subtitle1" sx={{ pl: 2 }}>미리 보기</Typography>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: 'center' }}>
                <Typography variant="subtitle1">만료일</Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: 'center' }}>
                <Typography variant="subtitle1" sx={{ pl: 3 }}>최종수정일시</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ p: 2 }}>
            <MuiTreeView
              // multiSelect
              // className={classes.root}
              // defaultCollapseIcon={<ExpandMoreIcon />}
              // defaultExpandIcon={<ChevronRightIcon />}
              defaultCollapseIcon={<MinusSquare />}
              defaultExpandIcon={<PlusSquare />}
              // defaultEndIcon={<CloseSquare />}
              expanded={expanded}
              onNodeToggle={handleToggle}
            >
              {
                // data.length > 0 && (
                itemsWithDocus?.length > 0 && (
                  <TreeItem
                    crudMode={crudMode}
                    items={filteredItems}
                    selected={selectedProps}
                    onSelect={onSelectProps}
                    disableMultiParentSelection={disableMultiParentSelection}
                    handleClickViewEachDoc={handleClickViewEachDoc}
                    handleClickViewSummary={handleClickViewSummary}
                    handleClickAddSubstituteTestDoc={handleClickAddSubstituteTestDoc}
                    reGenerate={reGenerate}
                    readOnly={from?.source !== "GProjectG04GeneratorDialog"}
                  />
                )
              }
            </MuiTreeView>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="sm"
        scroll="body"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
        >
          <div id="dialog-position">
            {"생성"}
          </div>
        </DialogTitleClose>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ color: "#1565c0", fontSize: 14 }}>
              &#8251;&nbsp;{"서류의 양과 시스템 상황에 따라 수 분이 소요될 수 있습니다."}
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"fileName"}
                control={control}
                label={"파일명"}
                placeholder={"파일명이 없으면 프로젝트의 '현장명+날짜정보.pdf'로 자동 생성됩니다."}
              />
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  titleTypographyProps={{ variant: 'subtitle1' }}
                  title={
                    <>
                      
                      <Typography sx={{ mb: 1 }}>
                        문서포함 여부 선택 {<span style={{ color: '#1565c0', fontSize: 14 }}>&nbsp;기본적으로 유효한 문서(만료일 내 + 첨부된 문서)만 포함됩니다.</span>}
                      </Typography>
                    </>
                  }
                  sx={{ bgcolor: "#eaeaea" }}
                />
                {/* <CardContent>
                  <Grid container>
                    <Grid item xs={12} display="flex" sx={{ ...cellStyle, borderLeftWidth: 1, borderLeftStyle: 'solid', borderTopWidth: 1, borderTopStyle: 'solid' }}>
                      <Grid container>
                        <Grid item xs={10} display="flex" justifyContent="flex-start" alignItems="center">
                          <Typography sx={{ p: 1 }}>{"유효문서만"}</Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center">
                          <Checkbox
                            onChange={handleChangeDocAttachedNValidDate}
                            checked={docAttachedNValidDate}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Stack direction="row">
                    <Grid container>
                      <Grid item xs={12} display="flex" sx={{ ...cellStyle, borderLeftWidth: 1, borderLeftStyle: 'solid' }} alignItems="center">
                        <Typography sx={{ p: 1 }}>{"상세 옵션"}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} display="flex" sx={cellStyle}>
                        <Grid container>
                          <Grid item xs={10} display="flex" justifyContent="flex-start" alignItems="center">
                            <Typography sx={{ p: 1 }}>{"만료일 내 문서만"}</Typography>
                          </Grid>
                          <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center">
                            <Checkbox
                              onChange={handleChangeDocValidDate}
                              checked={docValidDate}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} display="flex" sx={cellStyle}>
                        <Grid container>
                          <Grid item xs={10} display="flex" justifyContent="flex-start" alignItems="center">
                            <Typography sx={{ p: 1 }}>{"만료일 경과 문서 포함"}</Typography>
                          </Grid>
                          <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center">
                            <Checkbox
                              onChange={handleChangeDocInvalidDate}
                              checked={docInvalidDate}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} display="flex" sx={cellStyle}>
                        <Grid container>
                          <Grid item xs={10} display="flex" justifyContent="flex-start" alignItems="center">
                            <Typography sx={{ p: 1 }}>{"첨부된 문서만"}</Typography>
                          </Grid>
                          <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center">
                            <Checkbox
                              onChange={handleChangeDocAttached}
                              checked={docAttached}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} display="flex" sx={cellStyle}>
                        <Grid container>
                          <Grid item xs={10} display="flex" justifyContent="flex-start" alignItems="center">
                            <Typography sx={{ p: 1 }}>{"첨부되지 않은 문서 포함"}</Typography>
                          </Grid>
                          <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center">
                            <Checkbox
                              onChange={handleChangeDocUnattached}
                              checked={docUnattached}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Stack>
                </CardContent> */}
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Stack spacing={0.5}>
                        <FormControlLabel
                          control={
                            <Checkbox 
                              checked={includeExpiredDocs}
                              onChange={handleIncludeExpiredDocs}
                            />
                          }
                          label="만료일이 경과된 문서도 포함"
                          sx={{ margin: 0 }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={includeUnattachedDocs} 
                              onChange={handleIncludeUnattachedDocs}
                            />
                          }
                          label="첨부되지 않은 문서도 포함 (목차 표시 및 빈 문서로 발급)"
                          sx={{ margin: 0 }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={includeGTestComparisons} 
                              onChange={handleIncludeGTestComparisons}
                            />
                          }
                          label="시험성과대비표 포함"
                          sx={{ margin: 0 }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  titleTypographyProps={{ variant: 'subtitle1' }}
                  title={"문서날인 여부 선택"}
                  sx={{ bgcolor: "#eaeaea" }}
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {"날인정보 "}
                      <span style={{ fontWeight: 'bold', color: '#1976d2' }}>{"원본대조필과 인감, 현장정보"}</span>
                      {" 를 문서에 날인합니다."}
                    </Grid>
                    <Grid item xs={12}>
                      <ToggleButtonGroup
                        color="primary"
                        value={signOption}
                        exclusive
                        // size="small"
                        onChange={handleChangeSignOption}
                      >
                        <ToggleButton value="CERTIFIED_TRUE_COPY_AND_SIGN">{"날인합니다."}</ToggleButton>
                        <ToggleButton value="NONE">{"날인하지 않습니다."}</ToggleButton>
                      </ToggleButtonGroup>  
                    </Grid>
                    <Grid item xs={12} sx={{ 
                      opacity: signOption === 'NONE' ? 0.5 : 1,
                      pointerEvents: signOption === 'NONE' ? 'none' : 'auto',
                      filter: signOption === 'NONE' ? 'blur(2px)' : 'none', // 이 줄을 추가
                    }}>
                      <Stack direction="row" display="flex" justifyContent={"flex-start"} alignItems="center">
                        <Typography variant="body2">{"해당 문서 제공사의 날인정보가 없을 경우 제공사의 날인정보를 날인합니다."}</Typography>
                        <Checkbox
                          onChange={handleChangeMyCertified}
                          checked={myCertified}
                          sx={{ mt: -0.4 }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sx={{ 
                      opacity: signOption === 'NONE' ? 0.5 : 1,
                      pointerEvents: signOption === 'NONE' ? 'none' : 'auto',
                      filter: signOption === 'NONE' ? 'blur(2px)' : 'none', // 이 줄을 추가
                    }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid xs={12} display="flex" justifyContent={"center"} alignItems="center">
                              <TableContainer>
                                <Table aria-label="날일정보" sx={{ width: 400 }} size="small">
                                  <TableBody>
                                    <TableRow>
                                      <TableCell colspan={2} sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, borderRight: 1,width: '90%', fontSize: '20px', color: '#664AA0' }}>
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                          {"원본대조필 (인)"}
                                        </Box>
                                      </TableCell>
                                      <TableCell sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, color: '#664AA0', p: 0 }}>
                                        {
                                          URLThumbnail && (<Box sx={{ width: 60, height: 60 }} display="flex" justifyContent={"center"} alignItems={"center"}>
                                            <img src={URLThumbnail} alt="이미지 미리보기" style={{ maxWidth: '58px' }} />
                                          </Box>)
                                        }
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, borderRight: 1, color: '#664AA0', width: '30%' }}>
                                        <Grid container>
                                          <Grid item xs={10}>
                                            <FormInputText
                                              autoComplete="supplierLabel"
                                              name={"supplierLabel"}
                                              control={control}
                                              required
                                              fullWidth
                                              errorStyle={{ message: false, border: true }}
                                              variant="standard"
                                              InputProps={{
                                                disableUnderline: true,
                                                readOnly: true,
                                              }}
                                              inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                            />
                                          </Grid>
                                          <Grid item xs={2}>
                                            {" : "}
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                      <TableCell colSpan={2} sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, color: '#664AA0' }}>
                                        <FormInputText
                                          autoComplete="supplier"
                                          name={"supplier"}
                                          control={control}
                                          required
                                          fullWidth
                                          errorStyle={{ message: false, border: true }}
                                          variant="standard"
                                          inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                          InputProps={{
                                            disableUnderline: true,
                                            readOnly: true,
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, borderRight: 1, width: '30%', fontSize: '14px', color: '#664AA0' }}>
                                        <Grid container>
                                          <Grid item xs={10}>
                                            <FormInputText
                                              name={"recipientLabel"}
                                              control={control}
                                              variant="standard"
                                              inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                              InputProps={{
                                                disableUnderline: true,
                                                readOnly: true,
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={2}>
                                            {" : "}
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                      <TableCell colSpan={2} sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, fontSize: '14px', color: '#664AA0' }}>
                                        <FormInputText
                                          name={"recipient"}
                                          control={control}
                                          variant="standard"
                                          inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                          InputProps={{
                                            disableUnderline: true,
                                            readOnly: true,
                                          }}
                                          error={emptyRecipient}
                                          errorStyle={{ message: false, border: true }}
                                          // onChange={handleChangeRecipient}
                                        />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, borderRight: 1, width: '30%', fontSize: '14px', color: '#664AA0' }}>
                                        <Grid container>
                                          <Grid item xs={10}>
                                            <FormInputText
                                              name={"siteLabel"}
                                              control={control}
                                              variant="standard"
                                              inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                              InputProps={{
                                                disableUnderline: true,
                                                readOnly: true,
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={2}>
                                            {" : "}
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                      <TableCell colSpan={2} sx={{ borderTop: 1, borderRight: 1, borderBottom: 1, fontSize: '14px', color: '#664AA0' }}>
                                        <FormInputText
                                          name={"site"}
                                          control={control}
                                          variant="standard"
                                          inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                          InputProps={{
                                            disableUnderline: true,
                                            readOnly: true,
                                          }}
                                          error={emptySite}
                                          errorStyle={{ message: false, border: true }}
                                          onChange={handleChangeSite}
                                        />
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell colSpan={3} sx={{ border: 1, borderColor: '#664AA0' }}>
                                        <FormInputText
                                          name={"phrase"}
                                          control={control}
                                          // label={"문구"}
                                          // fullWidth
                                          // multiline // multiline이 들어가면 색상 변경이 안됨???
                                          // maxRows={5}
                                          // onChange={handleChangePhrase}
                                          variant="standard"
                                          InputProps={{
                                            disableUnderline: true,
                                            readOnly: true,
                                          }}
                                          inputProps={{ style: { fontSize: 14, color: '#664AA0' } }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"comments"}
                control={control}
                label={"설명"}
                multiline
                maxRows={5}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            size="small"
            variant="contained"
            // onClick={handleClickCheckedDoc}
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            {"작성"}
          </LoadingButton>
          <Button
            size="small"
            onClick={handleDialogClose}
          >
            {"닫기"}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
        setLoading={setLoading}
      />
    </>
  );
};

export default TreeView;
