import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Chip,
  CircularProgress,
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  LinearProgress,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import  {
  Attachment,
  Search,
  Delete,
  Edit,
  FilePresent,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
  Preview,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputAutoComplete,
  FormInputText
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import { StyledTooltip } from "../tooltip";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import { uploadFilePath, fileServerUrl, filePreviewServerUrl, mode } from '../../config';
import * as gclientActions from "../../store/gclient";
import * as g04docuGCertificationActions from "../../store/g04docuGCertification";
import * as g04docuGTestActions from "../../store/g04docuGTest";
import * as g04docuFileActions from "../../store/g04docuFile";
import * as G04docuGTestSubstituteActions from "../../store/g04docuGTestSubstitute";
import * as gclientG04docuGTestMapActions from "../../store/gclientG04docuGTestMap";
import * as gcomponentItemActions from "../../store/gcomponentItem";
// import G04docuGTestDialog from "./G04docuGTestDialog";
import G04docuDialogGTest from "../G04docuFile/G04docuDialogGTest";

const theme = createTheme();

const defaultValues = {
  // gclientId: "",
  division: '',
  itemOrCertificationName: "",
  name: "",
  classification: "",
};

// const ITEM_HEIGHT = 48;

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const G04docuGTestManagement = ({
  title,
  from,
}) => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const [gclientId, setGClientId] = useState("");
  const [gclients, setGClients] = useState([]);
  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [classificationTypes, setClassificationTypes] = useState([]);
  const [display, setDisplay] = useState('none');
  // const [rowCount, setRowCount] = useState(100);
  
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedGComponentItemName, setSelectedGComponentItemName] = useState("");
  const [gstandardId, setGStandardId] = useState("");

  // const openFunctionMenu = Boolean(anchorEl);
  
  // 기능버튼 메뉴
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  
  const handleSelect = async ({ type, params }) => {
    // setAnchorEl(null);
    console.log(params)
    setOpenBackdrop(true);
    // const id = selectedRows[0].id;
    const { id } = params;
    
    if (type === "detail") {
      const res = await selectG04docuGTestDirect(id);
      if (params.row.division === 'RAW_MATERIAL') {
        await selectAllGLThickness('GL THICKNESS'); // TODO : 매번 호출하지 않아도 될 듯
      }
      console.log(res[0])
      console.log(params.row)
      // setSelectedRow(res[0]);
      setSelectedGComponentItemName(params.row.gcomponentItemName)
      setGStandardId(params.row.gstandardId)
      setSelectedRow(params.row);
      setCrudMode('R');
      setOpen(true);
    } else if (type === "attachSubstitute") {
      // TODO : G04docuGTestSubstitutes, GClientG04docuGTestSubstituteMaps에 저장
      // documentPath의 파일 복사 (어디로???)
      const {
        id,
        division,
        name,
        code,
        sample,
        divider,
        comments,
        majority,
        gcomponentItemId,
        g04docuGCertificationId,
        gstandardTypeId,
        gclientId,
        gclient,
        selectedClassifications,
        // supplierId,
        // supplier,
        // gprojectId,
        documentPath,
        documentType,
        startDate,
        endDate,
      } = params.row;
      
      await createSubstitute({
        id: uuidv4(),
        division,
        name: from?.params?.name,
        code: from?.params?.code,
        substituteInformation: {
          id,
          name,
          code,
          sample,
          divider,
          comments,
          majority,
          selectedClassifications,
        },
        // sample,
        // divider,
        // comments,
        // majority,
        gcomponentItemId,
        g04docuGCertificationId,
        gstandardTypeId,
        gclientId,
        gclient,
        // selectedClassifications,
        // supplierId,
        // supplier,
        gprojectId: from?.params?.gprojectId,
        site: from?.params?.site,
        documentPath,
        documentType,
        startDate,
        endDate,
      });

      const func = from?.params?.closeDrawerAndRegenerate;
      func && func();
    }

    setOpenBackdrop(false);
  }

  // const options = [
  //   {
  //     text: '수정',
  //     icon: <Edit fontSize="small" />,
  //     type: 'edit',
  //   },
  //   {
  //     text: '삭제',
  //     icon: <Delete fontSize="small" />,
  //     type: 'delete',
  //   },
  // ];
  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
    ];

    if (from?.params?.gclientId && params.row.documentPath) {
      arrActions.push(<GridActionsCellItem
        icon={<Attachment />}
        label={"대체첨부"}
        onClick={() => handleSelect({ type: 'attachSubstitute', params })}
        showInMenu
      />);
    }

    return arrActions;
  }

  const columns = [
    // {
    //   field: 'code',
    //   headerName: '코드',
    //   width: 200,
    //   // editable: true,
    //   hide: true,
    // },
    // {
    //   field: 'gclientId',
    //   headerName: '거래선 아이디',
    //   width: 200,
    //   // editable: true,
    //   hide: true,
    // },
    {
      field: 'gclient',
      headerName: '거래선명',
      width: 200,
      // editable: true,
      valueGetter: (params) => {
        console.log(params)
        return (params.value?.name)
      },
    },
    {
      field: 'division',
      headerName: '종류',
      width: 120,
      // editable: true,
      valueGetter: (params) => {
        let value = "";
        if (params.value === 'RAW_MATERIAL') {
          value = "원자재";
        } else if (params.value === 'SUB_MATERIAL_PROCESS') {
          value = "가공부자재";
        } else if (params.value === 'SUB_MATERIAL_BUILD') {
          value = "시공부자재";
        } else if (params.value === 'PROCESS') {
          value = "공정";
        }
        return value;
      },
    },
    {
      field: 'gcomponentItemNameOrG04docuGCertificationName',
      headerName: '이름',
      width: 160,
      // editable: true,
      valueGetter: (params) => {
        const { division, g04docuGCertificationName, gcomponentItemName } = params.row;
        let value = "";
        if (division === 'RAW_MATERIAL') {
          value = gcomponentItemName;
        } else { // 공정(가공), 시공부자재, 가공부자재
          value = g04docuGCertificationName;
        }

        return value;
      },
    },
    {
      field: 'name',
      // headerName: '시료',
      headerName: '제품명',
      width: 400,
      // editable: true,
    },
    {
      field: 'documentPath',
      headerName: '자재승인서류',
      width: 160,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        if (params.value) {
          const { valid, validYN, documentPath } = params.row;
          return (
            <StyledTooltip
              title={<Stack direction="row" display="flex" alignItems="center">
                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                  {`미리보기`}
                </Box>
                <Typography variant="subtitle2">{documentPath?.split("/")[documentPath?.split("/")?.length-1]}</Typography>
              </Stack>}
              placement="right"
              sx={tooltipTop}
            >
              <IconButton
                color="primary"
                aria-label="file"
                onClick={() => handleClickViewEachDoc(documentPath, params.row.gclientId)}
                sx={{ color: (validYN || valid) ? "#1976D2" : "#ED6C02" }}
              >
                <Preview />
              </IconButton>
            </StyledTooltip>
          );
        }
      }
    },
    // {
    //   field: 'majority',
    //   // headerName: '시료',
    //   headerName: '대표성적서',
    //   width: 140,
    //   headerAlign: 'center',
    //   align: 'center',
    //   // editable: true,
    //   renderCell: (params) => {
    //     if (params.value) {
    //       return (
    //         <IconButton>
    //           <CheckBox color='primary' />
    //         </IconButton>
    //       );
    //     } else {
    //       return (
    //         <IconButton onClick={() => handleClickMajority(true, params.row)}>
    //           <CheckBoxOutlineBlank color='primary' />
    //         </IconButton>
    //       );
    //     }
    //   }
    // },
    {
      field: 'gstandardTypeName',
      headerName: '표준 종류',
      width: 140,
    },
    {
      field: 'selectedClassifications',
      headerName: '종류·등급·호칭 또는 모델',
      width: 300,
      renderCell: (params) => {
        // return params.value.filter(value => value.checked === true).map(value=> value.type).join(", ");
        return params.value?.filter(value => value.checked === true).map(value=> <Chip label={value.type} size="small" sx={{ ml: 1 }}></Chip>);
      }
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 400,
      // editable: true,
    },
    // {
    //   field: 'startDate',
    //   headerName: '유효기간 시작',
    //   width: 120,
    //   // editable: true,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => params.value ? dateFormat(params.value, 'yyyy-MM-dd') : "",
    // },
    {
      field: 'endDate',
      headerName: '만료일',
      width: 120,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value ? dateFormat(params.value, 'yyyy-MM-dd') : "",
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];

  const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  // TODO : 아래 rows와 rowCount 따로 받아서 두번 렌더링 되는지 확인해볼 것. 그렇다면 하나의 객체로 통합할 것
  const sessionUser = useSelector((state) => state.session.sessionUser);
  const rows = useSelector((state) => state.g04docuGTest.g04docuGTests);
  const classifications = useSelector((state) => state.g04docuGCertification.classificationsDistinct);
  
  // 데이터 관리
  const dispatch = useDispatch();

  const selectAllGLThickness = (gcomponentCode) => dispatch(gcomponentItemActions.selectAllByGcomponentCode(gcomponentCode));
  const selectG04docuGTestDirect = (id) => gclientG04docuGTestMapActions.selectByQueryDirect(id)
  const selectAllByQuery = ({ gclientId, division, itemOrCertificationName, name, classifications }) => dispatch(g04docuGTestActions.selectAllByQuery({ gclientId, division, itemOrCertificationName, name, classifications }))
  const select = (id) => dispatch(g04docuGTestActions.select(id))
  const initializeG04docuGTests = () => dispatch(g04docuGTestActions.initializeG04docuGTests())
  const reorder = ({ type, id }) => g04docuGTestActions.reorder({ type, id })
  const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)
  const selectAllGClientDirect = () => gclientActions.selectAllDirect()
  const selectClassificationsDistinct = () => dispatch(g04docuGCertificationActions.selectClassificationsDistinct())
  const createSubstitute = ({
    id,
    division,
    name,
    code,
    substituteInformation,
    gcomponentItemId,
    g04docuGCertificationId,
    gstandardTypeId,
    gclientId,
    gclient,
    // supplierId,
    // supplier,
    gprojectId,
    site,
    documentPath,
    documentType,
    startDate,
    endDate,
  }) => dispatch(G04docuGTestSubstituteActions.createWithDoc({
    id,
    division,
    name,
    code,
    substituteInformation,
    gcomponentItemId,
    g04docuGCertificationId,
    gstandardTypeId,
    gclientId,
    gclient,
    // supplierId,
    // supplier,
    gprojectId,
    site,
    documentPath,
    documentType,
    startDate,
    endDate,
  }))

  const queryPaging = useMemo(
    () => ({
      page,
      pageSize,
    }), [page]
  );

  console.log("queryPaging")
  console.log(queryPaging)

  useEffect(
    async () => {
      const resGClients = await selectAllGClientDirect();
      setGClients(resGClients);

      selectClassificationsDistinct();
      // TODO : 아래 코드를 selectPaging으로 바꾸고 페이지 변경시 호출. 단점은 hideWatermark가 매번 호출됨
      if (from?.params) {
        const paramGClientId = from?.params?.gclientId;
        const paramDivision = from?.params?.division;
        const paramItemOrCertificationName = from?.params?.itemOrCertificationName;

        console.log({ gclientId: paramGClientId, division: paramDivision, itemOrCertificationName: paramItemOrCertificationName });
        
        setGClientId(from?.params?.gclientId);
        setValue("division", from?.params?.division);
        setValue("itemOrCertificationName", paramItemOrCertificationName);

        console.log(from.params)
        
        await selectAllByQuery({
          gclientId: paramGClientId,
          division: paramDivision,
          itemOrCertificationName: paramItemOrCertificationName,
        });
      } else {
        // 거래선과 종류(원자재/가공부자재/시공부자재/공정), 제품 또는 공정명 검색 필드를 보이도록 한다.
        setDisplay('');
        
        await initializeG04docuGTests();
      }

      await hideWatermark();
      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      setShow(true);
      setTimeout(() => setLoaded(true), 300);
    }, [dispatch]
  );

  // const selectPaging = async () => {
  //   setLoaded(false)

  //   await selectAllByQuery(queryPaging);

  //   await hideWatermark();
  //   // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
  //   setShow(true);
  //   setTimeout(() => setLoaded(true), 300);
  // }

  // useEffect(
  //   () => {
  //     selectPaging();
  //   }, [queryPaging]
  // );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  }

  const handleClickFileDownload = async (e, documentPath) => {
    e.stopPropagation();

    const arr = documentPath.split("/");
    const fileName = arr[arr.length - 1];

    const res = await downloadG04docuFile(documentPath);
    const blob = await res.blob(); // res.blob는 promise를 리턴함???
    
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));            
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  // const handleClickViewEachDoc = (documentPath) => {
  //   const path = documentPath.replace(uploadFilePath, fileServerUrl);

  //   // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
  //   const randNumber = Math.floor(Math.random()*99);

  //   window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  // }

  const handleClickViewEachDoc = (documentPath, gclientId) => {
    let path = "";
    const pathParts = documentPath.replace(uploadFilePath, '').split('/');
    
    // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
    const randNumber = Math.floor(Math.random()*99);
    
    // 경로를 '/'로 분리하고 각 부분을 개별적으로 인코딩
    const encodedPath = pathParts.map(part => encodeURIComponent(part)).join('/');

    if (sessionUser.type === 'ADMIN' || sessionUser.id === gclientId) {
      path = `${fileServerUrl}${encodedPath}?q=cat&${randNumber}`;
    } else {
      const pathParts = documentPath.split('/');
      const encodedPath = pathParts
        .map(part => encodeURIComponent(part))
        .join('/');
      path = `${filePreviewServerUrl}${encodedPath}`;
    }
    
    window.open(`${path}`, "미리보기", 'target="_self"');
  }

  // useEffect(
  //   () => {
  //     const paramGClientId = gclientId;
  //     const paramDivision = getValues("division");
  //     const paramName = getValues("name");
      
  //     const paramClassification = getValues("classification");

  //     let paramClassifications = [];
  //     if (paramClassification) {
  //       const selectedClassificationTypes = classificationTypes.filter(c => c.indexOf(paramClassification) >= 0);
  //       paramClassifications = selectedClassificationTypes.length > 0 ? selectedClassificationTypes : [paramClassification];
  //     }

  //     console.log({ paramGClientId, paramName, paramDivision, paramClassification, paramClassifications });

  //     const select = async () => {
  //       await selectAllByQuery({
  //         gclientId: paramGClientId,
  //         division: paramDivision,
  //         name: paramName,
  //         classifications: paramClassifications,
  //       });
  //     }
      
  //     select();
  //   }, [gclientId, getValues("division"), getValues("name"), getValues("classification")]
  // )

  const selectGTests = async ({ gclientId, itemOrCertificationName, name, division, classification }) => {
    const paramClassification = classification ? classification : getValues("classification");
    const paramItemOrCertificationName = itemOrCertificationName ? itemOrCertificationName : getValues("itemOrCertificationName");
    const paramName = name ? name : getValues("name");
    const paramDivision = division ? division : getValues("division");
    let paramClassifications = [];
    if (paramClassification) {
      const selectedClassificationTypes = classificationTypes.filter(c => c.indexOf(paramClassification) >= 0);
      paramClassifications = selectedClassificationTypes.length > 0 ? selectedClassificationTypes : [paramClassification];
    }

    console.log({
      gclientId,
      division: paramDivision,
      itemOrCertificationName: paramItemOrCertificationName,
      name: paramName,
      classification: paramClassification,
      classificationTypes,
      classifications: paramClassifications,
    })

    await selectAllByQuery({
      gclientId,
      division: paramDivision,
      itemOrCertificationName: paramItemOrCertificationName,
      name: paramName,
      classifications: paramClassifications,
    });
  }

  // 거래선명으로 검색
  const handleChangeGClient = (e, value) => {
    const { id: gclientId } = value;
    selectGTests({ gclientId });
    setGClientId(gclientId);
  }

  // 디비전(원자재/가공부자재/시공부자재/공정)으로 검색
  const handleChangeDivision = (e) => {
    const { value: division } = e.target;
    selectGTests({ gclientId, division });
    setValue("division", division);
  }
  
  // 원부자재 및 공정의 이름으로 검색
  const handleChangeItemOrCertificationName = async (e) => {
    const itemOrCertificationName = e.target.value;
    selectGTests({ gclientId, itemOrCertificationName });
  };

  // 제품명(더 정확히는 성적서 등록시 사용한 이름)으로 검색
  const handleChangeName = async (e) => {
    const name = e.target.value;
    selectGTests({ gclientId, name });
  };

  // 종류·등급·호칭 또는 모델로 검색
  const handleChangeClassification = async (e) => {
    const name = e.target.value;
    selectGTests({ gclientId, classification: name });
  };

  // 검색버튼
  const handleClickSearch = async () => {
    setLoaded(false);
    await selectGTests({ gclientId });
    setTimeout(() => setLoaded(true), 300);
  };

  useEffect(
    () => {
      if (classifications.length > 0) {
        // console.log(classifications)
        const types = mode.indexOf('java') > 0 ? classifications.map(c1 => [...c1.map(c2 => c2.type)]) : classifications.map(c1 => [...c1.classifications.map(c2 => c2.type)]);
        // console.log(types)
        const distincts = types.reduce((acc, cur) => acc.concat(cur));
        setClassificationTypes([...new Set(distincts)]);
      }
    }, [classifications]
  )

  const handleClickAttach = (from) => {
    console.log(from)
    setOpenFileDialog(true);
    // console.log(params.row)
    // const { id, code, selectedClassifications, sample, gcomponentItemId, g04docuGCertificationId, g04docuGCertificationName, division, name, gclientId, documentPath, validYN, startDate, endDate, gstandardId, gstandardNo, gstandardName, guideLink } = params.row;
    
    // setSelectedGComponentItemId(gcomponentItemId);
    // setSelectedGComponentItemType(division);
    
    // if (type === 'attachDocument') {
    //   setSelectedRowGTest({
    //     division,
    //     g04docuGTestId : id,
    //     g04docuGTestName : name,
    //     code,
    //     selectedClassifications,
    //     sample,
    //     g04docuGCertificationId,
    //     g04docuGCertificationName,
    //     documentPath,
    //     validYN,
    //     startDate,
    //     endDate,
    //     gstandardId,
    //     gstandardNo,
    //     gstandardName,
    //     guideLink,
    //   });
    //   setSelectedDocuFileType('TEST');      
    //   setOpen(true);
    // }
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <G04docuDialogGTest
          open={open}
          setOpen={setOpen}
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          selectedRow={selectedRow}
          selectedGComponentItemName={selectedGComponentItemName}
          gstandardId={gstandardId}
          // gclientId={selectedGClientId}
          // gclient={selectedGClient}
          // refresh={selectAllByGComponentItemOrProcessByQuery}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {
                title && (
                  <Typography variant="h5">
                    {title}
                  </Typography>
                )
              }
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center" sx={{ display }}>
              <FormInputAutoComplete
                control={control}
                label={"거래선명"}
                options={[{ label: "전체", id: "NONE" }].concat(gclients.map(gclient => ({ label: gclient.name, id: gclient.id })))}
                setValue={setValue}
                onCustomChange={handleChangeGClient}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center" sx={{ display }}>
              <FormInputText
                select
                name={"division"}
                control={control}
                label={"종류"}
                options={[
                  { label: "전체", value: "NONE" },
                  { label: "원자재", value: "RAW_MATERIAL" },
                  { label: "가공부자재", value: "SUB_MATERIAL_PROCESS" },
                  { label: "시공부자재", value: "SUB_MATERIAL_BUILD" },
                  { label: "공정", value: "PROCESS" },
                ]}
                onChange={handleChangeDivision}
                // value={details && details.length > i && details[i]}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center" sx={{ display }}>
              <FormInputText
                name={"itemOrCertificationName"}
                control={control}
                label={"이름"}
                onCustomChange={handleChangeItemOrCertificationName}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center">
              <FormInputText
                name={"name"}
                control={control}
                label={"제품명"}
                onCustomChange={handleChangeName}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center">
              <FormInputText
                name={"classification"}
                control={control}
                label={"종류·등급·호칭 또는 모델"}
                onCustomChange={handleChangeClassification}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center" sx={{ mb: 2 }}>
              {/* {
                from?.params && (
                  <Button
                    variant="contained"
                    startIcon={<Attachment />}
                    onClick={handleClickAttach}
                    sx={{ mr: 1 }}
                  >
                    {"직접첨부"}
                  </Button>
                )
              } */}
              <Button
                variant="contained"
                startIcon={<Search />}
                onClick={handleClickSearch}
              >
                {"검색"}
              </Button>
            </Grid>
          </Grid>
          <div style={{ height: from?.params ? 500 : 700, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              columnHeaderHeight={38}
              rowHeight={34}
              sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              loading={!loaded}
              rows={rows}
              columns={columns}
              // pageSize={5}
              // rowsPerPageOptions={[5]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[1, 10, 20, 50, 100]}
              onPageChange={handlePageChange}
              page={page}
              // rowCount={rowCount}
              pagination
              onRowDoubleClick={(params) => handleSelect({ type: 'detail', params })}
              // checkboxSelection
              // disableSelectionOnClick
              // onRowSelectionModelChange={(ids) => {
              //   const selectedIDs = new Set(ids);
              //   const selectedRows = rows.filter((row) =>
              //     selectedIDs.has(row.id),
              //   );
      
              //   setSelectedRows(selectedRows);
              // }}
            />
          </div>
        </Box>
        {/* <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog> */}
      </Container>
    </ThemeProvider>
  );
};

export default G04docuGTestManagement;
