import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Skeleton,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  SliderThumb,
  Stack,
} from '@mui/material';
import {
  Add,
  Assignment,
  Attachment,
  Business,
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
  Remove,
  Search,
} from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';  // 한국어 로케일 추가
import { v4 as uuidv4 } from 'uuid';
import usePrevious from "../hook/usePrevious";
import {
  FormInputText,
  FormInputMultipleSelect
} from "../form";
import TextMaskCustom from "../../components/form/inputProps/TextMaskCustom"
import {
  ConfirmDialog,
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
  changeArrayOrder,
} from "../../utils";
import GClientTypeManagement from "../GClientType/GClientTypeManagement";
import * as gclientTypeActions from "../../store/gclientType";
import * as gclientType04docuActions from "../../store/gclientType04docu";
import * as gclientActions from "../../store/gclient";
import * as userActions from "../../store/user";
import * as gcomponentItemActions from "../../store/gcomponentItem";
import * as gsubItemActions from "../../store/gsubItem";
import * as gclientGComponentItemMapActions from "../../store/gclientGComponentItemMap";
import * as gclientG04docuMapActions from "../../store/gclientG04docuMap";
// import * as g04docuActions from "../../store/g04docu";

import { G04docuFileManagement } from "../G04docuFile";

import { PARTNERSHIP_TYPES, PARTNERSHIP_TYPE_LABELS } from "../../constants/gclientTypes";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  gclientTypes: [],
  gclientType04docus: [],
  bizRegNumber: "",
  name: "",
  ceo: "",
  // code: "",
  phone: "",
  fax: "",
  address: "",
  email: "",
  systemUrl: "",
  // comments: "",
  products:"",
  productName: "", // 거래선 다이얼로그가 아닌 거래선 내부의 자재 다이얼로그에서 사용
  partnership: "",
  joinDate: "",
  createdAt: "",
};

// const ITEM_HEIGHT = 48;
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const GClientDialog = ({
  crudMode,
  setCrudMode,
  open,
  setOpen,
  selectedRow,
  refresh,
}) => {
  // for(let i=0; i<10; i++) {
  //   console.log(uuidv4());
  // }
  const refType04docus = React.useRef(); // 자식 함수 호출하기 위함

  const [errors, setErrors] = useState([]);
  const [types, setTypes] = useState([]);
  const [type04docus, setType04docus] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [tabValueForFind, setTabValueForFind] = useState(0);
  const [drawerState, setDrawerState] = useState({
    // top: false,
    // left: false,
    // bottom: false,
    // right: false,
  });
  const [loaded, setLoaded] = useState(false);
  const [loadedUser, setLoadedUser] = useState(false);
  const [tabGClientTypes, setTabGClientTypes] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [pageSizeUser, setPageSizeUser] = useState(100);
  const [pageSizeForFind, setPageSizeForFind] = useState(100);
  const [show, setShow] = useState(true);
  const [showUser, setShowUser] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  // const [showTabs, setShowTabs] = useState(true);
  const [openFind, setOpenFind] = useState(false);
  const [loadedForFind, setLoadedForFind] = useState(false);
  const [showGridForFind, setShowGridForFind] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openItemDialog, setOpenItemDialog] = useState(false);
  const [crudModeForItem, setCrudModeForItem] = useState('');
  const [removeObject, setRemoveObject] = useState({});
  const [paramsItemDialog, setParamsItemDialog] = useState({});
  const [confirmOpenDelete, setConfirmOpenDelete] = useState(false);
  // const [loadedForG04docu, setLoadedForG04docu] = useState(false);
  // const [openG04docuDialog, setOpenG04docuDialog] = useState(false);
  // const [pageSizeForG04docu, setPageSizeForG04docu] = useState(100);
  // const [showGridForG04docu, setShowGridForG04docu] = useState(false);
  const [selectedGComponentItemId, setSelectedGComponentItemId] = useState("");
  const [selectedG04docuGCertificationId, setSelectedG04docuGCertificationId] = useState("");
  const [selectedGComponentItemType, setSelectedGComponentItemType] = useState("");
  // const [inCharges, setInCharges] = useState([]);
  const [users, setUsers] = useState([]);
  const [phoneMask, setPhoneMask] = useState("000-0000-0000");

  const prevDrawerState = usePrevious(drawerState);

  const handleSelect = async ({ selectType, params, e }) => {
    console.log(params)
    const { id, gclientId, gcomponentItemType } = params.row;
    if (selectType === "detail") {
      await selectMine(id, gclientId, gcomponentItemType);
      setCrudModeForItem('R');
      setOpenItemDialog(true);
    } else if (selectType === "edit") {
      await selectMine(id, gclientId, gcomponentItemType);
      setCrudModeForItem('U');
      setOpenItemDialog(true);
    } else if (selectType === "delete") {
      setRemoveObject({ id, gcomponentItemType, gclientId });
      setParamsItemDialog(params);
      setConfirmOpenDelete(true);
    } else if (selectType === "g04docu") {
      console.log(params.row)
      const { id, g04docuGCertificationId, gcomponentItemType } = params.row;
      setSelectedGComponentItemId(id);
      setSelectedG04docuGCertificationId(g04docuGCertificationId);
      setSelectedGComponentItemType(gcomponentItemType);

      // // setCrudModeForItem('R');
      // setOpenG04docuDialog(true);

      // setShowGridForG04docu(false);
      // setLoadedForG04docu(false);
      
      // // 해당 제품의 규격(인증서/성적서) 조회
      // // await selectNotMinesGSubItems(g04docuId);
      // await selectAllByGClientByG04docu(gclientId, g04docuId);

      // setTimeout(async () => {
      //   await hideWatermark();
      //   setShowGridForG04docu(true);
      //   setTimeout(() => setLoadedForG04docu(true), 300);
      // }, 300);
      setTimeout(async () => {
        setShowGrid(false);
        setShow(false);
        setShowUser(false);
      
        toggleDrawer("G04DOCU_FILE", true)(e);  
      }, 100);
    }
  }

  const remove = (removeObject) => {
    console.log(removeObject);
    const { id, gclientId, gcomponentItemType } = removeObject;
    dispatch(gclientGComponentItemMapActions.remove(id, gclientId, gcomponentItemType))
    .then(res => setItemType(tabValue)) // 자재탭 refresh
    .catch(async (res) => {
      const data = await res.json();
      if (data && data.errors) setErrors(data.errors);
    });
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ selectType: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelect({ selectType: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ selectType: 'delete', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Assignment />}
        label={"성적서"}
        onClick={(e) => handleSelect({ selectType: 'g04docu', params, e })}
        showInMenu
      />,
    ];

    return arrActions;
  }

  const columnsBasic = [
    {
      field: 'name',
      headerName: '이름',
      width: 200,
      // editable: true,
    },
    {
      field: 'itemProductName',
      headerName: '세부제품명',
      width: 200,
      // editable: true,
    },
    {
      field: 'code',
      headerName: '코드',
      width: 200,
      // editable: true,
      hide: true,
    },
    {
      field: 'gName',
      headerName: '분류',
      width: 150,
      // editable: true,
    },
    // {
    //   field: 'g04docuName',
    //   headerName: '규격',
    //   width: 200,
    //   // editable: true,
    // },
    // {
    //   field: 'gstandardNo',
    //   headerName: '표준번호',
    //   width: 150,
    //   // editable: true,
    // },
    // {
    //   field: 'gstandardName',
    //   headerName: '표준명(한글)',
    //   width: 200,
    //   // editable: true,
    // },
    {
      field: 'g04docuCertificationId',
      headerName: '규격 아이디',
      width: 200,
      hide: true,
    },
    {
      field: 'g04docuGCertification',
      headerName: '규격',
      width: 300,
      // editable: true,
      // valueGetter: (params) => {
      //   if (params.row.g04docuName && params.row.gstandardNo) {
      //     return `${params.row.g04docuName} ( ${params.row.gstandardNo} )`
      //   }

      //   return "";
      // },
      renderCell: (params) => {
        if (params.row.g04docuGCertificationName && params.row.gstandardNo) {
          const { g04docuGCertificationName, gstandardNo } = params.row;
          return <><Chip label={gstandardNo} size="small" sx={{ mr: 1 }}></Chip>{g04docuGCertificationName}</>;
        } else {
          return "";
        }
      }
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    // {
    //   field: 'createdAt',
    //   headerName: '생성일시',
    //   width: 160,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => dateFormat(params.value),
    // },
    // {
    //   field: 'updatedAt',
    //   headerName: '수정일시',
    //   width: 160,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => dateFormat(params.value),
    // },
  ];

  const columnsMines = (crudMode !== 'U') ? columnsBasic : columnsBasic.concat({
    field: 'actions',
    headerName: <Tooltip title={"상세/수정/삭제/성적서"} followCursor><Box>{"기능"}</Box></Tooltip>,
    width: 70,
    type: 'actions',
    getActions: (params) => generateActions(params),
  });

  const columns = [
    {
      field: 'name',
      headerName: '이름',
      width: 200,
      // editable: true,
    },
    {
      field: 'code',
      headerName: '코드',
      width: 200,
      // editable: true,
    },
    {
      field: 'gName',
      headerName: '분류',
      width: 150,
      // editable: true,
    },
    // {
    //   field: 'g04docuName',
    //   headerName: '규격',
    //   width: 200,
    //   // editable: true,
    // },
    // {
    //   field: 'gstandardNo',
    //   headerName: '표준번호',
    //   width: 150,
    //   // editable: true,
    // },
    // {
    //   field: 'gstandardName',
    //   headerName: '표준명(한글)',
    //   width: 200,
    //   // editable: true,
    // },
    {
      field: 'g04docu',
      headerName: '규격',
      width: 300,
      // editable: true,
      // valueGetter: (params) => {
      //   if (params.row.g04docuName && params.row.gstandardNo) {
      //     return `${params.row.g04docuName} ( ${params.row.gstandardNo} )`
      //   }

      //   return "";
      // },
      renderCell: (params) => {
        if (params.row.g04docuName && params.row.gstandardNo) {
          const { g04docuName, gstandardNo } = params.row;
          return <><Chip label={gstandardNo} size="small" sx={{ mr: 1 }}></Chip>{g04docuName}</>;
        } else {
          return "";
        }
      }
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
  ];
  
  const generateActionsForG04docu = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<Attachment />}
        label={"첨부"}
        // onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
    ];

    return (params.row.type === 'CERTIFICATION' || params.row.type === 'TEST') ? arrActions : [];
  }

  const columnsForG04docu = [
    {
      field: 'type',
      headerName: '구분',
      width: 150,
      // hide: true,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        if (params.value === "CERTIFICATION") {
          return "인증서";
        } else if (params.value === "TEST") {
          return "성적서";
        }
      },
    },
    {
      field: 'code',
      headerName: '코드',
      width: 200,
      // editable: true,
      hide: true,
    },
    {
      field: 'gstandardNo',
      headerName: '표준번호',
      width: 200,
      // editable: true,
    },
    {
      field: 'gstandardName',
      headerName: '표준명(한글)',
      width: 200,
      // editable: true,
    },
    // {
    //   field: 'comments',
    //   headerName: '설명',
    //   width: 280,
    //   // editable: true,
    // },
    {
      field: 'documents',
      headerName: '자재승인서류',
      width: 280,
      // editable: true,
    },
    {
      field: 'pid',
      headerName: '부모아이디',
      width: 100,
      hide: true,
      // editable: true,
    },
    // {
    //   field: 'createdAt',
    //   headerName: '생성일시',
    //   width: 160,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => dateFormat(params.value),
    // },
    // {
    //   field: 'updatedAt',
    //   headerName: '수정일시',
    //   width: 160,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => dateFormat(params.value),
    // },
    {
      field: 'actions',
      headerName: <Tooltip title={"첨부"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      type: 'actions',
      getActions: (params) => generateActionsForG04docu(params),
    },
  ];
  
  // TODO : 데이터 연결하기
  // const rowsGComponentItemsByItemType = useSelector((state) => state.gcomponentItem.gcomponentItemsByItemType);
  // const rowsSubMaterialBuild = useSelector((state) => state.gsubItem.gsubItems);
  const gclientGComponentItemMap = useSelector((state) => state.gclientGComponentItemMap.gclientGComponentItemMap);
  const rowsGClientGComponentItemMaps = useSelector((state) => state.gclientGComponentItemMap.gclientGComponentItemMaps);
  const rowsGComponentItemsNotMinesByItemType = useSelector((state) => state.gcomponentItem.gcomponentItemsNotMinesByItemType);
  // const rowsSubItemsNotMinesMaterialBuild = useSelector((state) => state.gsubItem.gsubItemsNotMines);
  const rowsForG04docu = useSelector((state) => state.gclientG04docuMap.gclientG04docuMaps);
  // const g04docus = useSelector((state) => state.g04docu.g04docus);

  const handleDialogClose = () => {
    setOpen(false);
    setCrudMode('');
    
    initDialog();
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    setErrors([]);
    setTabGClientTypes([]);
    setTypes([]); // 대분류 초기화
    setType04docus([]); // 세분류 초기화. // TODO : 검토 필요 => 단, 대분류 초기화(setTypes([]))는 FormInputMultipleSelect 설정방법이 달라 (여기서???) 하지 않음
    // setInCharges([]);
  }

  const handleItemDialogClose = () => {
    setOpenItemDialog(false);
  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, getValues, setValue } = useForm({ defaultValues: defaultValues });

  const dispatch = useDispatch();

  const gclientTypes = useSelector((state) => state.gclientType.gclientTypes);
  const gclientType04docus = useSelector((state) => state.gclientType04docu.gclientType04docus);
  
  // 데이터 관리
  const addGClient = ({ id, gclientTypes, gclientType04docus, bizRegNumber, name, /*code, */phone, address, email, inCharges, systemUrl/*, comments*/, ceo, fax, partnership, joinDate, createdAt, products }) => dispatch(gclientActions.create({ id, gclientTypes, gclientType04docus, bizRegNumber, name, /*code, */phone, address, email, inCharges, systemUrl/*, comments*/, ceo, fax, partnership, joinDate, createdAt, products }))
  const modifyGClient = ({ id, gclientTypes, gclientType04docus, name, phone, address, email, inCharges, systemUrl, ceo, fax/*, comments*/, partnership, joinDate, createdAt, products }) => dispatch(gclientActions.modify({ id, gclientTypes, gclientType04docus, name, phone, address, email, inCharges, systemUrl/*, comments*/, ceo, fax, partnership, joinDate, createdAt, products }))
  const selectAllGclientType = () => dispatch(gclientTypeActions.selectAll());
  const selectAllGclientType04docuDirect = () => gclientType04docuActions.selectAllDirect()
  const selectAllGclientType04docuByGClientTypes = (gclientTypeIds) => dispatch(gclientType04docuActions.selectAllByGClientTypesByQuery(gclientTypeIds));
  // const selectAllByItemTypeByQuery = (itemType) => dispatch(gcomponentItemActions.selectAllByItemTypeByQuery(itemType))
  // const selectAllGSubItems = () => dispatch(gsubItemActions.selectAllByQuery())
  const selectNotMinesByItemTypeByQuery = (gclientId, itemType) => dispatch(gcomponentItemActions.selectNotMinesByItemTypeByQuery(gclientId, itemType))
  const selectNotMinesGSubItems = (gclientId) => dispatch(gsubItemActions.selectNotMinesByQuery(gclientId))
  const createGClientGComponentItemMaps = (gclientGComponentItemMaps) => gclientGComponentItemMapActions.createMapsDirect(gclientGComponentItemMaps)
  const selectAllByGClientByItemTypeByQuery = (gclientId, itemType) => dispatch(gclientGComponentItemMapActions.selectAllByGClientByItemTypeByQuery(gclientId, itemType))
  const selectMine = (gId, gclientId, gcomponentItemType) => dispatch(gclientGComponentItemMapActions.select(gId, gclientId, gcomponentItemType))
  const modifyProductName = ({ gcomponentItemId, gcomponentItemType, gclientId, productName }) => dispatch(gclientGComponentItemMapActions.modifyProductName({ gcomponentItemId, gcomponentItemType, gclientId, productName }))
  // const selectAllByGClientByG04docu = (g04docuId) => dispatch(gclientG04docuMapActions.selectAllByGClientByG04docu(g04docuId))
  const selectAllByGClient = (gclientId) => dispatch(gclientG04docuMapActions.selectAllByGClient(gclientId))
  // const selectAllG04docus = () => dispatch(g04docuActions.selectAll())
  const selectAllByGClientDirect = (gclientId) => userActions.selectAllByGClientDirect(gclientId)

  const onSubmit = ({ id, gclientTypes, gclientType04docus, bizRegNumber, name, phone, address, email, systemUrl/*, comments*/, ceo, fax, partnership, joinDate, createdAt, products }) => {
    setErrors([]);
    
    // console.log({ id, gclientTypes, gclientType04docus: type04docus, bizRegNumber, name, /*code, */phone, address, email, inCharges, systemUrl/*, comments*/ })
    // return

    let func;
    if (crudMode === 'U') {
      func = modifyGClient;
    } else if (crudMode === 'C') {
      func = addGClient
    }
    
    // const validatedInCharges = inCharges.filter(inCharge => inCharge.inChargeName || inCharge.inChargePhone || inCharge.inChargeEmail);
    func({ id, gclientTypes, gclientType04docus: type04docus, bizRegNumber, name, phone, address, email, inCharges: /*validatedInCharges*/[], systemUrl, ceo, fax/*, comments*/, partnership, joinDate, createdAt, products })
      .then (res => {
        handleDialogClose();
        refresh();
      })
      .catch(error => {
        console.log(error.serverResponse);
        setErrors([error.serverResponse.message]);
      });

  }

  const saveProductName = () => {
    const {id, gclientId, gcomponentItemType } = gclientGComponentItemMap;
    const productName = getValues("productName");
    console.log({ id, gclientId, gcomponentItemType, productName });

    modifyProductName({ gcomponentItemId: id, gclientId, gcomponentItemType, productName })
      .then (res => {
        handleItemDialogClose();
        setItemType(tabValue); // 자재탭 refresh
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });

  }

  useEffect(
    () => {
      setValue("productName", gclientGComponentItemMap?.itemProductName);
    }, [gclientGComponentItemMap, setValue]
  );

  useEffect(
    async () => {
      await selectAllGclientType(); // await를 하지 않으면 다이얼로그 나타날 때 아이템이 구성되지 않았다가 뒤늦게 추가되므로 await 사용
      // await selectAllGclientType04docu();
      // await selectAllG04docus();
    }, [dispatch]
  );

  useEffect(
    async () => {
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          setValue(item, selectedRow[item] || value);
        }

        // 그외 설정할 것들은 여기서 한다.
        if (selectedRow["gclientTypes"]) {
          const arr = selectedRow["gclientTypes"].map(row => row.code); // TODO : id or code 추후 검토
          const selected = arr.map(arr => gclientTypes.find(gclientType => gclientType.code === arr));
          // console.log(selected);
          setTypes(selected);
          
          if (selected.length > 0) {
            /*await */selectAllGclientType04docuByGClientTypes(selected.map(item => item.id)); // 대분류에 따른 세분류 목록 설정
            /**
             * selectedRow에 값을 쓰는게 내키지 않으나 목록에서 다이얼로그 띄울 때 해당 거래선의 값을 설정할 때만 useEffect([gclientType04docus])가 
             * 한번 호출되고 대분류 목록을 변경시는 useEffect([gclientType04docus])가 호출되지 않게 하기 위해 사용함
             * (참고) 위의 await가 있으면 useEffect([gclientType04docus])가 먼저 호출되어 아래 selectedRow["once"] = true; 코드가 더 늦게 실행됨
             */
            selectedRow["once"] = true;
          }
        } else {
          setTypes([]); // 수정한 후 다른 row 선택 후 수정 다이얼로그 띄우면 이전 선택 타입 남아 있는 문제 해결
        }
        
        if (!selectedRow["gclientType04docus"]) {
          setType04docus([]); // 수정한 후 다른 row 선택 후 수정 다이얼로그 띄우면 이전 선택 타입 남아 있는 문제 해결
          setTabGClientTypes([]);
        }


        setLoadedUser(false);
        const incharges = await selectAllByGClientDirect(selectedRow.id);
        setUsers(incharges);

        setTimeout(async () => {
          await hideWatermark();
          setShowUser(true);
          setTimeout(() => setLoadedUser(true), 300);
        }, 1000);

        setTabValue(0); // 이 코드가 없다면 전에 띄웠던 다이얼로그에서 탭을 선택하고 닫은 경우 그 탭 번호가 새로 띄운 다이얼로그의 탭 개수보다 많은 경우 문제 발생
      }


    }, [selectedRow]
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // console.log({ ...state, [anchor]: open });
    setDrawerState({ ...drawerState, [anchor]: open });

    if (!open) {
      setShow(true);
      setShowGrid(true);
      setShowUser(true);
      // setShowTabs(true);
      anchor === "G04DOCU_FILE" && setSelectedG04docuGCertificationId("");
    }
  };

  const actions = [
    { icon: <Business />, name: '거래선 구분 관리', code: "GCLIENT_TYPE", component: <GClientTypeManagement title={"거래선 구분"} /> },
    // { icon: <Icon baseClassName="fas" className="fa-building" /*color="primary"*/ fontSize="small" />, name: '거래처 관리', code: "GCLIENT", component: <GClientManagement /> },
  ];

  // 아래 두가지 방식 검토
  const handleSpeedDialActionClick = (action) => (e) => {
  // const handleSpeedDialActionClick = (e, action) => {
    // setOpen(false);
    // TODO : setShow로 다이얼로그를 숨겨도 그 안의 탭과 그리드는 왜 보이는가? 이 이유로 우선은 탭과 그리드를 숨기도록 했다. 그래도 탭 선택표시는 뒤늦게 사라짐
    // setShowTabs(false);

    setTimeout(async () => {
      setShowGrid(false);
      setShow(false);
      setShowUser(false);
    
      toggleDrawer(action.code, true)(e);  
    }, 100);
  }

  useEffect(
    () => {
      if (crudMode === 'C') {
        setValue("id", uuidv4());
        // setInCharges([{
        //   inChargeName: "",
        //   inChargePhone: "",
        //   inChargeEmail: "",
        // }]);
      }
    }, [crudMode]
  );

  useEffect(
    async () => {
      // console.log(prevDrawerState);
      // console.log(drawerState);
      if (prevDrawerState?.GCLIENT_TYPE === true && drawerState?.GCLIENT_TYPE === false ) {
        await selectAllGclientType();
        // await selectAllGclientType04docu();
      }
    }, [drawerState]
  )

  // useEffect(
  //   async () => {
  //     console.log("useEffect[getValues('gclientTypes')]");
  //     // console.log(drawerState);
  //   }, [getValues("gclientTypes")]
  // )

  useEffect(
    async () => {
      // console.log("useEffect[getValues('gclientType04docus')]");
      console.log(gclientType04docus)
      if (open && selectedRow && selectedRow["gclientType04docus"] && selectedRow["once"]) {
        const codes = selectedRow["gclientType04docus"].map(row => row.code); // TODO : id or code 추후 검토
        const selected04docu = codes.map(code => gclientType04docus.find(gclientType04docu => gclientType04docu.code === code));
        console.log(selected04docu)
        
        setType04docus(selected04docu);
      }
      
      // if (selectedRow["once"] === false) {
      //   alert("111")
      //   setType04docus([]);
      // }
      
      selectedRow && (selectedRow["once"] = false);
    }, [gclientType04docus]
  )
  
  // useEffect(
  //   async () => {
  //     console.log(types)
  //     if (types.length > 0) {
  //       await selectAllGclientType04docuByGClientTypes(types.map(type => type.id)); // 대분류에 따른 세분류 설정
  //     }
  //   }, [types]
  // )

  useEffect(
    async () => {
      console.log('useEffect[type04docus]')
      // console.log(getValues("gclientType04docus"))
      // console.log(selectedRow["gclientType04docus"])
      console.log(type04docus)
      
      const manufacturer = type04docus?.filter(type => type.code === 'MANUFACTURER_RAW_MATERIAL' || type.code === 'MANUFACTURER_SUB_MATERIAL_PROCESS' || type.code === 'MANUFACTURER_SUB_MATERIAL_BUILD');
      if (manufacturer?.length > 0) {
        // TODO : setTabGClientTypes 전에 호출하면 제조사가 아닌 경우 이전에 제조사를 조회했다면 자재정보가 그대로 남아 있게 된다. 왜???
        // if (selectedRow) {
        //   const manufacturerCode = manufacturer[0].code;
        //   const itemType = manufacturerCode.replace("MANUFACTURER_", "");
          
        //   await selectAllByGClientByItemTypeByQuery(selectedRow.id, itemType);
        // }

        setTabGClientTypes(manufacturer);

        // setShowGrid(false); // TODO : 심한 크기 변화 발생
        setLoaded(false);

        // TODO : 공정도 포함해야 함. HEAT_STRENGTHENED, TEMPERED, LAMINATED, INSULATED_GLASS_UNIT

        if (selectedRow) {
          const manufacturerCode = manufacturer[0].code;
          const itemType = manufacturerCode.replace("MANUFACTURER_", "");
          
          await selectAllByGClientByItemTypeByQuery(selectedRow.id, itemType);
        }

        setTimeout(async () => {
          await hideWatermark();
          setShowGrid(true);
          setTimeout(() => setLoaded(true), 300);
        }, 300);
      } else {
        setTabGClientTypes([]);
      }
    }, [type04docus]
  )

  const handleChangeItemType = (event, newValue) => {
    setItemType(newValue);
  };

  const setItemType = async (value) => {
    // setShowGrid(false); // TODO : 심한 크기 변화 발생
    setLoaded(false);

    const { code } = tabGClientTypes[value];
    const itemType = code.replace("MANUFACTURER_", "");

    // if (itemType === 'RAW_MATERIAL' || itemType === 'SUB_MATERIAL_PROCESS' ) {
    //   await selectAllByItemTypeByQuery(itemType);
    // } else if (itemType === 'SUB_MATERIAL_BUILD') {
    //   await selectAllGSubItems();
    // }
    await selectAllByGClientByItemTypeByQuery(selectedRow.id, itemType);

    setTabValue(value); // 탭선택

    setTimeout(async () => {
      await hideWatermark();
      setShowGrid(true);
      setTimeout(() => setLoaded(true), 300);
    }, 300);
    // setLoaded(true);
  }

  const handleChangeItemTypeForFind = (event, newValue) => {
    setItemTypeForFind(newValue);
  };

  const setItemTypeForFind = async (value) => {
    setShowGridForFind(false);
    setLoadedForFind(false);

    const { code } = tabGClientTypes[value];
    const itemType = code.replace('MANUFACTURER_', '');
    // if (itemType === 'RAW_MATERIAL' || itemType === 'SUB_MATERIAL_PROCESS' ) {
      await selectNotMinesByItemTypeByQuery(selectedRow?.id, itemType);
    // } else if (itemType === 'SUB_MATERIAL_BUILD') {
    //   await selectNotMinesGSubItems(selectedRow?.id);
    // }

    setTabValueForFind(value); // 탭선택

    setTimeout(async () => {
      await hideWatermark();
      setShowGridForFind(true);
      setTimeout(() => setLoadedForFind(true), 300);
    }, 300);
  }

  const handleClickSearch = () => {
    setOpenFind(true);

    setTabValueForFind(tabValue);

    setItemTypeForFind(tabValue);
  }

  const handleSelectFind = async () => {
    console.log(selectionModel);
    console.log(tabValueForFind);

    const selectedItems = selectionModel.map(selection => {
      return {
        gcomponentItemId: selection,
        gcomponentItemType: tabGClientTypes[tabValueForFind].code.replace("MANUFACTURER_", ""),
        gclientId: selectedRow.id,
        gclient: selectedRow,
        itemProductName: null,
      }
    });

    console.log(selectedItems);

    const result = await createGClientGComponentItemMaps(selectedItems);
    console.log(result);
    setSelectionModel([]);

    setOpenFind(false);

    setItemType(tabValue);
  }

  const handleFindDialogClose = () => {
    setOpenFind(false);
  }

  const makeTabs = (tabValue, handleChange) => {
    return (
      <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" /*sx={{ visibility: showTabs ? 'visible' : 'hidden' }}*/>
        {
          tabGClientTypes.map((type, idx) => {
            let label = "";
            if (type.code === 'MANUFACTURER_RAW_MATERIAL') {
              label = "원자재";
            } else if (type.code === 'MANUFACTURER_SUB_MATERIAL_PROCESS') {
              label = "가공부자재";
            } else if (type.code === 'MANUFACTURER_SUB_MATERIAL_BUILD') {
              label = "시공부자재";
            } else if (type.code === 'HEAT_STRENGTHENED') { // TODO : 공정도 포함해야 함. HEAT_STRENGTHENED, TEMPERED, LAMINATED, INSULATED_GLASS_UNIT
              label = type.name;
            } else if (type.code === 'TEMPERED') {
              label = type.name;
            } else if (type.code === 'LAMINATED') {
              label = type.name;
            } else if (type.code === 'INSULATED_GLASS_UNIT') {
              label = type.name;
            }
            
            return <Tab label={label} {...a11yProps(idx)} sx={{ fontWeight: 'bold' }} />
          })
        }
      </Tabs>
    );
  }

  // const handleG04DialogClose = () => {
  //   setOpenG04docuDialog(false);
  // }

  const handleClickG04docu = async (e) => {
    setSelectedGComponentItemId("");

    setShow(false);

    setTimeout(async () => {
      // setShowGrid(false);
      // setShow(false);
      // setShowUser(false);
    
      toggleDrawer("G04DOCU_FILE", true)(e);  
    }, 100);
  }

  // 아래는 useEffect([types])를 통해 처리했었으나 setTypes와 연결된 FormInputMultipleSelect의 setMultiselectValue에서 []으로 설정하므로 문제 발생하여 다시 아래 함수에서 처리
  const handleChangeMultiGClientTypeItem = async (value) => {
    // typeof value === object
    const gclientTypeIds = value.map(item => JSON.parse(item).id); // typeof item === string(json)
    // console.log(gclientTypeIds)

    if (gclientTypeIds.length > 0) {
      await selectAllGclientType04docuByGClientTypes(gclientTypeIds);
    }

    // type04docus 중에서 gclientTypeIds 에 없는 경우는 제거할 것 => 대분류 해제시 그 하위의 세분류 선택사항도 해제 의미
    const newType04docus = type04docus.filter(type04docu => gclientTypeIds.includes(type04docu.gclientTypeId));
    console.log({ type04docus, newType04docus })
    setType04docus(newType04docus);

    if (newType04docus.length === 0) {
      /**
       * FormInputMultipleSelect 두 개가 있고 하나의 컴포넌트(FormInputMultipleSelect)의 선택에 따라 다른 컴포넌트의 목록이 바뀌는 종속 관계가 있다고 하자.
       * 여기서는 쉽게 설명하기 위하여 대분류, 소분류로 직접 예를 든다.
       * 소분류를 가지고 있는 대분류를 선택하면 소분류 목록이 변경된다.
       * 이때 소분류 목록에서 선택하고 대분류 목록으로 가서 해제하면 소분류 목록은 사라지는데 대분류 재설정시 새로 구성된 소분류 목록을 보면 그 설정값은 유지되어 있다.
       * 소분류 목록이 하나라도 있으면 그렇지 않지만 하나도 없는 경우가 그런 현상이 발생한다. 그럴 경우 아래처럼 강제로 목록 설정값을 지워준다.
       */
      refType04docus.current.setItemsEmpty();
    }
  }

  const handleChangePhone = (e, type) => {
    const { value } = e.target;
    const fieldName = type === 'fax' ? 'fax' : 'phone';

    const areaCode02 = "02";
    const compareCode = value.substring(0, 2);
    if (compareCode === areaCode02) {
      if (value.length < 12) {
        setPhoneMask("00-000-00000"); // 한자리 여분을 두어야 함. 00-000-0000하면 더 이상 입력이 안됨
      } else {
        setPhoneMask("00-0000-0000");
      }
    } else {
      if(value.length < 13) {
        setPhoneMask("000-000-00000"); // 한자리 여분을 두어야 함
      } else {
        setPhoneMask("000-0000-0000");
      }
    }
    
    setValue(fieldName, value);
  }
  
  const columnsUser = [
    {
      field: 'userId',
      headerName: '이메일 아이디',
      width: 250,
    },
    {
      field: 'name',
      headerName: '이름',
      width: 120,
    },
    {
      field: 'mobile',
      headerName: '모바일',
      width: 150,
    },
    {
      field: 'role',
      headerName: '사용자 유형',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        // console.log(params)
        const { value } = params;
        if (value === 'ADMIN_ROLE') {
          return "관리자";
        } else if (value === 'USER_ROLE') {
          return "일반사용자";
        } else {
          return "일반사용자"; // TODO : 다른 role이 생기면 그때 정의
        }
      },
    },
    {
      field: 'status',
      headerName: '상태',
      width: 120,
    },
  ];

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose} // 여기를 주석처리하면 다이얼로그 바깥을 눌렀을 때 닫히지 않음
        PaperComponent={PaperComponent} // The component used to render the body of the dialog.
        // TODO : Dialog의 aria-labelledby="draggable-dialog-title"가 있는 경우 모바일(?)에서는 닫기 안됨
        // 주석처리하거나 draggable-dialog-title의 이름을 변경할 경우는 닫기는 동작하나 drag 동작이 사라짐
        // 참고로 draggable-dialog-title는 PaperComponent안의 Draggable에 설정되어 있고 아래 DialogTitleClose의 id는 영향이 없음(PaperComponent안의 Draggable에는 handle #인데)
        // 추후 모바일(?)에서는 드래그가 필수는 아니므로 드래그를 막고 닫기가 되도록 하는 것이 좋겠다. 혹은 상단의 닫기버튼을 삭제하여 사용자로 하여금 눌러도 반응이 없어 오류로 인식하는 문제를 해결하는 것이 좋겠다.
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
        sx={{ visibility: show ? 'visible' : 'hidden' }}
        scroll="body"
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          style={{ cursor: 'move' }}
        >
          {crudMode === 'C' && "거래선 등록"}
          {crudMode === 'R' && "거래선 상세"}
          {crudMode === 'U' && "거래선 수정"}
          <SpeedDial
            ariaLabel="SpeedDial basic example" // TODO : ariaLabel 바꿀 것
            sx={{ position: 'absolute', top: 61, right: 24 }}
            icon={<SpeedDialIcon />}
            FabProps={{ size: "small" }}
            direction="left"
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={handleSpeedDialActionClick(action)}
                // onClick={(e) => handleSpeedDialActionClick(e, action)}
              />
            ))}
          </SpeedDial>
        </DialogTitleClose>
        <DialogContent dividers>
          <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              {/* TODO : 대분류 선택에 따라 세분류 변하도록 해야 함. 또한 세분류는 대분류에 속하므로 세분류로 선택한 내용이 있다면 다른 대분류 선택시 조치필요 */}
              <FormInputMultipleSelect
                id="multiple-type-select"
                name="gclientTypes"
                label={"대분류"}
                control={control}
                setValue={setValue}
                value={types}
                setMultiselectValue={setTypes}
                options={gclientTypes}
                onChangeItem={handleChangeMultiGClientTypeItem}
                chipSize={"small"}
                inputProps={
                  { readOnly: crudMode === 'R' }
                }
              />
            </Grid>
            {/* TODO : 상위의 대분류 설정 해제시 세분류 속성 초기화 필요 => value, setMultiselectValue를 사용하면 안됨 */}
            <Grid item xs={12}>
              <FormInputMultipleSelect
                ref={refType04docus}
                id="multiple-type04docu-select"
                name="gclientType04docus"
                label={"세분류"}
                control={control}
                setValue={setValue}
                // value={type04docus}
                // setMultiselectValue={setType04docus}
                dynamicItemsBasic={type04docus}
                onChangeItem={(value) => setType04docus(value.map(i => ({ ...JSON.parse(i) })))}
                options={gclientType04docus}
                chipSize={"small"}
                inputProps={
                  { readOnly: crudMode === 'R' }
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'none' }}>
              <FormInputText
                name={"id"}
                control={control}
                label={"아이디"}
                inputProps={
                  { readOnly: true, }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <FormInputText
                    // margin="normal"
                    autoComplete="bizRegNumber"
                    name={"bizRegNumber"}
                    control={control}
                    required
                    // fullWidth
                    label={"사업자등록번호"}
                    // autoFocus
                    // size={"medium"}
                    InputProps={{
                      inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                      inputProps: {
                        style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                          mask: "000-00-00000",
                          definitions: {
                            '#': /[1-9]/,
                          },
                        },
                        readOnly: crudMode === 'R' || crudMode === 'U',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormInputText
                    name={"name"}
                    control={control}
                    label={"거래선명"}
                    inputProps={
                      { readOnly: crudMode === 'R', }
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormInputText
                    name={"ceo"}
                    control={control}
                    label={"대표자"}
                    inputProps={
                      { readOnly: crudMode === 'R', }
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormInputText
                    name="partnership"
                    select
                    control={control}
                    label={"파트너쉽"}
                    setValue={setValue}
                    options={PARTNERSHIP_TYPES.map(type => ({
                      value: type,
                      label: PARTNERSHIP_TYPE_LABELS[type]
                    }))}
                    inputProps={
                      { readOnly: crudMode === 'R', }
                    }
                    InputProps={{
                      inputComponent: TextMaskCustom,  
                      inputProps: {
                        style: {
                          mask: phoneMask,
                        },
                      }
                    }}
                    //onChange={(e) => setValue('partnership', e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <FormInputText
                    name={"phone"}
                    control={control}
                    label={"대표전화번호"}
                    inputProps={
                      { readOnly: crudMode === 'R', }
                    }
                    InputProps={{
                      inputComponent: TextMaskCustom,  
                      inputProps: {
                        style: {
                          mask: phoneMask,
                        },
                      }
                    }}
                    onChange={(e) => handleChangePhone(e, 'phone')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormInputText
                    name={"email"}
                    control={control}
                    label={"회사대표메일"}
                    inputProps={
                      { readOnly: crudMode === 'R', }
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormInputText
                    name={"fax"}
                    control={control}
                    label={"대표팩스번호"}
                    inputProps={
                      { readOnly: crudMode === 'R', }
                    }
                    InputProps={{
                      inputComponent: TextMaskCustom,  
                      inputProps: {
                        style: {
                          mask: phoneMask,
                        },
                      }
                    }}
                    onChange={(e) => handleChangePhone(e, 'fax')}
                  />
                </Grid>
                
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                    <DatePicker
                      value={getValues("joinDate") ? dayjs(getValues("joinDate")) : null}
                      onChange={(newValue) => {
                        // ISO 형식의 날짜를 원하는 포맷으로 변환
                        const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD HH:mm:ss') : null;
                        setValue("joinDate", formattedDate);
                      }}
                      name={"joinDate"}
                      control={control}
                      label={"유리협회 가입일"}
                      format="YYYY-MM-DD"
                      minDate={dayjs('2023-01-01')}
                      maxDate={dayjs()}
                      closeOnSelect={true}  // 날짜 선택 시 자동으로 닫힘
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          InputProps: {
                            readOnly: crudMode === 'R'
                          }
                        },
                        
                      }}
                    />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                    <DatePicker
                      value={getValues("createdAt") ? dayjs(getValues("createdAt")) : null}
                      onChange={(newValue) => {
                        const formattedDate = newValue ? dayjs(newValue).format('YYYY-MM-DD HH:mm:ss') : null;
                        setValue("createdAt", formattedDate);
                      }}
                      name={"createdAt"}
                      control={control}
                      label={"공사다큐 가입일"}
                      format="YYYY-MM-DD"
                      minDate={dayjs('2023-01-01')}
                      maxDate={dayjs()}
                      closeOnSelect={true}  // 날짜 선택 시 자동으로 닫힘
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          InputProps: {
                            readOnly: crudMode === 'R'
                          }
                        },
                      }}
                    />
                    </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"address"}
                control={control}
                label={"주소"}
                inputProps={
                  { readOnly: crudMode === 'R', }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"products"}
                control={control}
                label={"상품 설명"}
                inputProps={
                  { readOnly: crudMode === 'R', }
                }
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormInputText
                name={"systemUrl"}
                control={control}
                label={"시스템 URL"}
                inputProps={
                  { readOnly: crudMode === 'R', }
                }
              />
            </Grid> */}
            {/* <Grid item xs={12}>
              <Grid container spacing={2}>
              {
                inCharges?.map((inCharge, idx) => {
                  const { inChargeName, inChargePhone, inChargeEmail } = inCharge;
                  return (
                    <>
                      <Grid item xs={3}>
                        <FormInputText
                          name={"inChargeName"}
                          control={control}
                          label={"담당자명"}
                          inputProps={
                            { readOnly: crudMode === 'R', }
                          }
                          value={inChargeName}
                          onChange={(e) => handleChangeInChargeName(e, idx)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormInputText
                          // name={"inChargePhone"}
                          control={control}
                          label={"담당자연락처"}
                          inputProps={
                            { readOnly: crudMode === 'R', }
                          }
                          value={inChargePhone}
                          onChange={(e) => handleChangeInChargePhone(e, idx)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormInputText
                          // name={"inChargeEmail"}
                          control={control}
                          label={"E-mail 주소"}
                          inputProps={
                            { readOnly: crudMode === 'R', }
                          }
                          value={inChargeEmail}
                          onChange={(e) => handleChangeInChargeEmail(e, idx)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        {
                          crudMode !== 'R' && (
                            <Stack direction="row" spacing={1}>
                              <IconButton
                                size="small"
                                // color="primary"
                                onClick={() => handleClickAddInCharge(idx)}
                              >
                                <Add/>
                              </IconButton>
                              <IconButton
                                size="small"
                                // color="secondary"
                                onClick={() => handleClickRemoveInCharge(idx)} disabled={inCharges.length <= 1 ? true : false}
                              >
                                <Remove/>
                              </IconButton>
                              <IconButton
                                size="small"
                                // color="success"
                                onClick={() => handleClickUpDownInCharge('up', idx)}
                              >
                                <KeyboardArrowUp/>
                              </IconButton>
                              <IconButton
                                size="small"
                                // color="info"
                                onClick={() => handleClickUpDownInCharge('down', idx)}
                              >
                                <KeyboardArrowDown/>
                              </IconButton>
                            </Stack>
                          )
                        }
                      </Grid>
                    </>
                  )
                })
              }
              </Grid>
            </Grid> */}
            {
              crudMode !== 'C' && (
                <>
                  <Grid item xs={12}>
                    <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                      {"담당자"}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ height: 200, width: '100%' }}>
                      {/* <Skeleton variant="rounded" height={showUser ? 0 : 200} /> */}
                      <DataGridPro
                        localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                        sx={{ /*visibility: showUser ? 'visible' : 'hidden', */cursor: 'pointer', fontSize: '0.85em' }}
                        initialState={{ pinnedColumns: { right: ['actions'] } }}
                        slots={{
                          noRowsOverlay: CustomNoRowsOverlay,
                          loadingOverlay: LinearProgress,
                        }}
                        columnHeaderHeight={38}
                        rowHeight={34}
                        loading={!loadedUser}
                        rows={users}
                        columns={columnsUser}
                        pageSize={pageSizeUser}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        pagination
                        // headerHeight={50}
                        // rowHeight={46}
                        onRowSelectionModelChange={async (ids) => {
                        }}
                        // onRowDoubleClick={(params) => handleSelect({ type: 'detail', params })}
                      />
                    </div>
                  </Grid>
                </>
              )
            }
            {
              crudMode !== 'C' && (
              <Grid item xs={9} sm={9}>
                {makeTabs(tabValue, handleChangeItemType)}
              </Grid>
              )
            }
            <Grid item xs={3} sm={3} display="flex" justifyContent={"flex-end"} alignItems={"flex-start"}>
              {
                crudMode !== 'C' && crudMode !== 'R' && tabGClientTypes.length > 0 && (
                  <Button
                    variant="contained"
                    sx={{ mt: 1 }}
                    startIcon={<Search />}
                    onClick={handleClickSearch}
                  >
                    {"자재 찾기"}
                  </Button>
                )
              }
              {
                crudMode !== 'C' && crudMode !== 'R' && (
                  <Button
                    variant="contained"
                    sx={{ mt: 1, ml: 1 }}
                    startIcon={<Assignment />}
                    onClick={handleClickG04docu}
                  >
                    {"자재승인서류"}
                  </Button>
                )
              }
            </Grid>
            {
              crudMode !== 'C' && (
                <Grid item xs={12}>
                  {
                    tabGClientTypes.map((type, idx) => {
                      return (
                        <TabPanel value={tabValue} index={idx}>
                          {/* TODO : DataGridPro의 sx의 visibility 만으로는 숨지 않는 element가 있음. (행 선택 메시지) */}
                          <div style={{ height: 400, width: '100%', display: showGrid ? '' : 'none' }}>
                            <DataGridPro
                              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                              sx={{ /*visibility: showGrid ? 'visible' : 'hidden', */cursor: 'pointer', fontSize: '0.85em' }}
                              initialState={{ pinnedColumns: { right: ['actions'] } }}
                              slots={{
                                noRowsOverlay: CustomNoRowsOverlay,
                                loadingOverlay: LinearProgress,
                              }}
                              columnHeaderHeight={38}
                              rowHeight={34}
                              loading={!loaded}
                              rows={rowsGClientGComponentItemMaps}
                              columns={columnsMines}
                              pageSize={pageSize}
                              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                              rowsPerPageOptions={[5, 10, 20, 50, 100]}
                              pagination
                              onRowDoubleClick={(params) => handleSelect({ selectType: 'edit', params })}
                            />
                          </div>
                        </TabPanel>
                      )
                    })
                  }
                </Grid>
              )
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          {
            crudMode === 'C' && (
              <>
                <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
                <Button onClick={() => reset()}>{"초기화"}</Button>
              </>
            )
          }
          {
            crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button>
          }
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      {
        actions.map((action) => (
          <Drawer
            anchor={"bottom"} // TODO : 추후 사용자가 환경설정에서 위치 설정하면 전체 반영하는 방법 강구
            open={drawerState[action.code]}
            PaperProps={{
              sx: { width: "100%" },
            }}
            onClose={toggleDrawer(action.code, false)}
          >
            <Grid display="flex" justifyContent={"center"} alignItems="center" sx={{ backgroundColor: "#f3f3f3" }}>
              <Tooltip title={"닫기"}>
                <IconButton aria-label="close drawer" size="small" component="span" onClick={toggleDrawer(action.code, false)}>
                  <KeyboardArrowDown />
                </IconButton>
              </Tooltip>
            </Grid>
            {action.component}
          </Drawer>
        ))
      }
      <Dialog
        open={openFind}
        onClose={handleFindDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        // 아래 두 요소(fullWidth, maxWidth)를 다 설정해야 다이얼로그 크기가 반영됨. // TODO : input 계열이 없는 경우, 아래처럼 grid, tab 만 있으면 fullwidth 없으면 다이얼로그 폭이 작음
        fullWidth={true}
        maxWidth="lg"
        sx={{ visibility: show ? 'visible' : 'hidden' }}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleFindDialogClose}
          style={{ cursor: 'move' }}
        >
          {"자재 목록"}
        </DialogTitleClose>
        <DialogContent dividers>
          <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              {makeTabs(tabValueForFind, handleChangeItemTypeForFind)}
            </Grid>
            <Grid item xs={12}>
              {
                tabGClientTypes.map((type, idx) => {
                  let rows;
                  // if (type.code === 'MANUFACTURER_RAW_MATERIAL' || type.code === 'MANUFACTURER_SUB_MATERIAL_PROCESS') {
                    rows = rowsGComponentItemsNotMinesByItemType;
                  // } else if (type.code === 'MANUFACTURER_SUB_MATERIAL_BUILD') {
                  //   rows = rowsSubItemsNotMinesMaterialBuild;
                  // }

                  return (
                    <TabPanel value={tabValueForFind} index={idx}>
                      <div style={{ height: 800, width: '100%' }}>
                        <DataGridPro
                          localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                          sx={{ /*visibility: showGridForFind ? 'visible' : 'hidden', */cursor: 'pointer', fontSize: '0.85em' }}
                          initialState={{ pinnedColumns: { right: ['actions'] } }}
                          slots={{
                            noRowsOverlay: CustomNoRowsOverlay,
                            loadingOverlay: LinearProgress,
                          }}
                          columnHeaderHeight={38}
                          rowHeight={34}
                          checkboxSelection
                          onRowSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                          }}
                          selectionModel={selectionModel}
                          loading={!loadedForFind}
                          rows={rows}
                          columns={columns}
                          pageSize={pageSizeForFind}
                          onPageSizeChange={(newPageSize) => setPageSizeForFind(newPageSize)}
                          rowsPerPageOptions={[10, 20, 50, 100]}
                          pagination
                        />
                      </div>
                    </TabPanel>
                  )
                })
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSelectFind}>{"선택"}</Button>
          <Button onClick={handleFindDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openItemDialog}
        onClose={handleItemDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
        sx={{ visibility: show ? 'visible' : 'hidden' }}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleItemDialogClose}
          style={{ cursor: 'move' }}
        >
          {crudModeForItem === 'R' ? "생산 자재 상세" : "생산 자재명 수정"}
        </DialogTitleClose>
        <DialogContent dividers>
          <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul>
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
            </Grid> */}
            <Grid item xs={12} sx={{ display: 'none' }}>
              <FormInputText
                control={control}
                label={"아이디"}
                value={gclientGComponentItemMap?.id}
                inputProps={
                  { readOnly: true }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                control={control}
                label={"이름"}
                value={gclientGComponentItemMap?.name}
                inputProps={
                  { readOnly: true }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"productName"}
                control={control}
                label={"세부제품명"}
                // value={gclientGComponentItemMap?.itemProductName}
                inputProps={
                  { readOnly: crudModeForItem === 'R', }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                control={control}
                label={"코드"}
                value={gclientGComponentItemMap?.code}
                inputProps={
                  { readOnly: true }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                control={control}
                label={"분류"}
                value={gclientGComponentItemMap?.gName}
                inputProps={
                  { readOnly: true }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                select
                control={control}
                label={"규격"}
                value={gclientGComponentItemMap?.g04docuGCertificationId}
                inputProps={
                  { readOnly: true }
                }
                options={
                  [{ label: <><Chip label={gclientGComponentItemMap?.gstandardNo} size="small" sx={{ mr: 1 }}></Chip>{gclientGComponentItemMap?.g04docuGCertificationName}</>, value: gclientGComponentItemMap?.g04docuGCertificationId }]
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                control={control}
                label={"설명"}
                value={gclientGComponentItemMap?.comments}
                inputProps={
                  { readOnly: true }
                }
                multiline
                maxRows={5}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {crudModeForItem === 'U' && <Button onClick={saveProductName}>{"세부제품명 수정"}</Button>}
          <Button onClick={handleItemDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        removeId={removeObject}
        title={"삭제"}
        open={confirmOpenDelete}
        setOpen={setConfirmOpenDelete}
        onConfirm={remove}
        onCancel={() => {}}
      >
        {`"${paramsItemDialog && paramsItemDialog.row && paramsItemDialog.row.name || ""}(세부제품명 : ${paramsItemDialog && paramsItemDialog.row && paramsItemDialog.row.itemProductName  || ""})"를 삭제하시겠습니까?`}
      </ConfirmDialog>
      <Drawer
        anchor={"bottom"} // TODO : 추후 사용자가 환경설정에서 위치 설정하면 전체 반영하는 방법 강구
        open={drawerState["G04DOCU_FILE"]}
        PaperProps={{
          sx: { width: "100%" },
        }}
        onClose={toggleDrawer("G04DOCU_FILE", false)}
      >
        <Grid display="flex" justifyContent={"center"} alignItems="center" sx={{ backgroundColor: "#f3f3f3" }}>
          <Tooltip title={"닫기"}>
            <IconButton aria-label="close drawer" size="small" component="span" onClick={toggleDrawer("G04DOCU_FILE", false)}>
              <KeyboardArrowDown />
            </IconButton>
          </Tooltip>
        </Grid>
        <G04docuFileManagement
          title={`자재승인서류관리`}
          from={
            {
              source: "GClientDialog",
              params: {
                gclientId: getValues('id'),
                g04docuGCertificationId: selectedG04docuGCertificationId,
                gcomponentItemId: selectedGComponentItemId,
                gcomponentItemType: selectedGComponentItemType,
              }
            }
          }
        />
      </Drawer>
      {/* <Dialog
        open={openG04docuDialog}
        onClose={handleG04DialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        // 아래 두 요소(fullWidth, maxWidth)를 다 설정해야 다이얼로그 크기가 반영됨. // TODO : input 계열이 없는 경우, 아래처럼 grid, tab 만 있으면 fullwidth 없으면 다이얼로그 폭이 작음
        fullWidth={true}
        maxWidth="lg"
        sx={{ visibility: show ? 'visible' : 'hidden' }}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleG04DialogClose}
          style={{ cursor: 'move' }}
        >
          {"자재승인서류 관리"}
        </DialogTitleClose>
        <DialogContent dividers>
          <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <div style={{ height: 400, width: '100%' }}>
              <DataGridPro
                sx={{ visibility: showGridForG04docu ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
                treeData
                groupingColDef={{ headerName : "이름 (하위요소 개수)", width: 300 }}
                getTreeDataPath={(row) => row.path}
                initialState={{ pinnedColumns: { right: ['actions'] } }}
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                  loadingOverlay: LinearProgress,
                }}
                loading={!loadedForG04docu}
                rows={rowsForG04docu}
                columns={columnsForG04docu}
                pageSize={pageSizeForG04docu}
                onPageSizeChange={(newPageSize) => setPageSizeForG04docu(newPageSize)}
                rowsPerPageOptions={[10, 20, 50, 100]}
                pagination
                headerHeight={50}
                rowHeight={46}
                // onRowSelectionModelChange={(ids) => {
                //   const selectedIDs = new Set(ids);
                //   const selectedRows = rows.filter((row) =>
                //     selectedIDs.has(row.id),
                //   );
        
                //   setSelectedRows(selectedRows);

                //   // 현재는 단일 선택 기준임
                //   if (selectedRows.length === 1) {
                //     setLoadedChild(false);
                //     selectByPid(selectedRows[0].id);
                //   }
                // }}
                // onRowDoubleClick={(params) => handleSelect({ type: 'detail', params })}
              />
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleG04DialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default GClientDialog;
