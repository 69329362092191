import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { DataGridPro, koKR } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Typography,
  useScrollTrigger
} from '@mui/material';
import  {
  Preview,
} from '@mui/icons-material';
import {
  FormInputText,
} from "../form";
import {
  CustomNoRowsOverlay,
} from "../datagrid";
import { StyledTooltip } from "../tooltip";
import {
  dateFormat,
} from "../../utils";
import { uploadFilePath, fileServerUrl } from '../../config';
import * as gprojectActions from "../../store/gproject";
import * as gdomesticAuthMapActions from "../../store/gdomesticAuthMap";

const theme = createTheme();

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const defaultValues = {
  gclient: {},
};

const DomesticCertification = ({ gclients }) => {
  const [loadedGDomesticAuthProject, setLoadedGDomesticAuthProject] = useState(false);
  const [pageGDomesticAuthProject, setPageGDomesticAuthProject] = useState(0);
  const [pageSizeGDomesticAuthProject, setPageSizeGDomesticAuthProject] = useState(100);
  const [loadedGDomesticAuthMap, setLoadedGDomesticAuthMap] = useState(true);
  const [pageGDomesticAuthMap, setPageGDomesticAuthMap] = useState(0);
  const [pageSizeGDomesticAuthMap, setPageSizeGDomesticAuthMap] = useState(100);

  const { control, getValues } = useForm({ defaultValues });

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const rowsGDomesticAuthProject = useSelector((state) => state.gproject.gprojects);
  const rowsGDomesticAuthMap = useSelector((state) => state.gdomesticAuthMap.gdomesticAuthMaps);

  const dispatch = useDispatch();

  const selectAllProjectsOr4docuProjects = (docu04, searchName, searchSite) => dispatch(gprojectActions.selectAllProjectsOr4docuProjects(docu04, searchName, searchSite))
  const selectProjectsOr4docuProjectsByOwner = (docu04, gclientId, searchName, searchSite) => dispatch(gprojectActions.selectProjectsOr4docuProjects(docu04, gclientId, searchName, searchSite))
  const selectGDomestciAuthByGProject = (gprojectId) => dispatch(gdomesticAuthMapActions.selectByGProjectByQuery(gprojectId))

  const columnsGDomesticAuthProject = [
    {
      field: 'gdomesticAuths',
      headerName: '사전인증 발급현황',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const { value } = params;
        if (value && Array.isArray(value) && value.length > 0) {
          return `${value.filter(i => i?.authYN === 'Y').length}/${value.length}`;
        } else {
          return "-";
        }
      },
    },
    {
      field: 'owner',
      headerName: '공급업체',
      width: 200,
      valueGetter: (params) => params.row?.owner?.name,
    },
    {
      field: 'site',
      headerName: '현장명',
      width: 200,
    },
    {
      field: 'siteAddress',
      headerName: '현장주소',
      width: 200,
    },
    {
      field: 'constructionCompanyName',
      headerName: '건설사명',
      width: 160,
    },
    {
      field: 'completionDate',
      headerName: '준공일',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value && dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'startDate',
      headerName: '시작',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'endDate',
      headerName: '마감',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
  ];

  const columnsGDomestciAuthMap = [
    {
      field: 'no',
      headerName: '품번',
      width: 120,
    },
    {
      field: 'specification',
      headerName: '사양',
      width: 300,
      valueGetter: (params) => params.value && params.value.replaceAll("|", " ").replaceAll("+", " + "),
    },
    {
      field: 'gdomesticAuthSerialNo',
      headerName: '인증번호',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'authDocumentPath',
      headerName: '인증서',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { value } = params;
        if (value) {
          return (
            <StyledTooltip
              title={<Stack direction="row" display="flex" alignItems="center">
                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                  {`미리보기`}
                </Box>
                <Typography variant="subtitle2">{value?.split("/")[value?.split("/")?.length-1]}</Typography>
              </Stack>}
              placement="right"
              sx={tooltipTop}
            >
              <IconButton
                color="primary"
                aria-label="file"
                onClick={() => handleClickViewEachDoc(value)}
                sx={{ color: "#1976D2" }}
              >
                <Preview />
              </IconButton>
            </StyledTooltip>
          );
        }
      }
    },
    {
      field: 'processGClientIds',
      headerName: '가공업체',
      width: 240,
      renderCell: (params) => {
        const processGClients = [];
        const { value } = params;
        if (value) {
          const processGClientIds = value.split(",");
          processGClientIds.forEach(processGClientId => {
            const find = gclients.find(gclient => gclient.id === processGClientId);
            if (find) {
              if (processGClients.length === 0) {
                processGClients.push(<Chip label={find.name} size="small" color="primary" variant="outlined"></Chip>);
              } else {
                processGClients.push(<Chip label={find.name} size="small" color="primary" variant="outlined" sx={{ ml: 1 }}></Chip>);
              }
            }
          });
          return processGClients;
        }
      },
    },
    {
      field: 'status',
      headerName: '상태',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        let result = "";
        const { value } = params;
        if (value === 'C') {
          result = "신규발급"  
        } else if (value === 'D') {
          result = "폐기"  
        }
        return result;
      },
    },
    {
      field: 'createdAt',
      headerName: '발급일',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value && dateFormat(params.value, 'yyyy-MM-dd'),
    },
  ];

  // const handleClickViewEachDoc = (documentPath) => {
  //   const path = documentPath.replace(uploadFilePath, fileServerUrl);

  //   // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
  //   const randNumber = Math.floor(Math.random()*99);

  //   window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  // }
  const handleClickViewEachDoc = (documentPath) => {
    const pathParts = documentPath.replace(uploadFilePath, '').split('/');
    
    // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
    const randNumber = Math.floor(Math.random()*99);
    
    // 경로를 '/'로 분리하고 각 부분을 개별적으로 인코딩
    const encodedPath = pathParts.map(part => encodeURIComponent(part)).join('/');

    const path = `${fileServerUrl}${encodedPath}?q=cat&${randNumber}`;

    window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  }

  useEffect(
    async () => {
      const { id, type } = sessionUser;
      
      const docu04 = true;
      if (type === 'ADMIN') { // 시스템 관리자
        await selectAllProjectsOr4docuProjects(docu04);
      } else { // 회원사 관리자나 회원사 사용자 모두 회원사 소속이면 데이터 검색이 가능 (같은 회원사의 사용자인 경우 A 사용자 자재승인서 발급 정보에 B 사용자가 접근이 가능)
        await selectProjectsOr4docuProjectsByOwner(docu04, id);
      }
      setLoadedGDomesticAuthProject(true);
    }, [dispatch]
  );

  const handlePageChangeGDomesticAuthProject = (newPage) => {
    setPageGDomesticAuthProject(newPage);
  }

  const handlePageChangeGDomesticAuthMap = (newPage) => {
    setPageGDomesticAuthMap(newPage);
  }

  var timer;
  
  const handleChangeSearchGDomesticAuth = async (e) => {
    const searchName = getValues("searchNameGDomesticAuth");
    const searchSite = getValues("searchSiteGDomesticAuth");
    // // 과도한 API 호출 방지를 위한 debouncing 코드. TODO : 라이브러리 활용 검토
    if (timer) {
      clearTimeout(timer);
    }
    
    const { id, type } = sessionUser;
    // 타이머 설정
    timer = setTimeout(() => {
      const docu04 = true;
      if (type === 'ADMIN') { // 시스템 관리자
        selectAllProjectsOr4docuProjects(docu04, searchName, searchSite);
      } else { // 회원사 관리자나 회원사 사용자 모두 회원사 소속이면 데이터 검색이 가능 (같은 회원사의 사용자인 경우 A 사용자 자재승인서 발급 정보에 B 사용자가 접근이 가능)
        selectProjectsOr4docuProjectsByOwner(docu04, id, searchName, searchSite);
      }
    }, 500);
  };

  return (
    <Paper sx={{ p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
            <Grid container>
              <Grid item xs={12} sm={12} display="flex" alignItems="center">
              {
                console.log(rowsGDomesticAuthProject)
              }
                {
                  `국내가공유리제품 사전 인증서 발급 현황 (발급건수 : ${rowsGDomesticAuthProject.map(p => {
                      const { gdomesticAuths } = p;
                      if (gdomesticAuths && Array.isArray(gdomesticAuths)) {
                        return p.gdomesticAuths?.filter(g => g?.authYN === 'Y').length;
                      }
                      return 0;
                    }).reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0,
                  )})`
                }
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <FormInputText
                  name={"searchNameGDomesticAuth"}
                  control={control}
                  label={"공급업체 또는 건설사명으로 검색하세요."}
                  onCustomChange={handleChangeSearchGDomesticAuth}
                  sx={{
                    '& .MuiInputBase-root': {
                      background: "#E3EEFA"
                    },
                    '& .MuiFormLabel-root' : {
                      color: '#2196f3',
                      fontSize: '0.85rem',
                    },
                    input: { color: '#2196f3', fontSize: '0.85rem' }
                  }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputText
                name={"searchSiteGDomesticAuth"}
                control={control}
                label={"현장명 또는 현장주소로 검색하세요."}
                onCustomChange={handleChangeSearchGDomesticAuth}
                sx={{
                  '& .MuiInputBase-root': {
                    background: "#E3EEFA"
                  },
                  '& .MuiFormLabel-root' : {
                    color: '#2196f3',
                    fontSize: '0.85rem',
                  },
                  input: { color: '#2196f3', fontSize: '0.85rem' }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ height: 400, width: '100%' }}>
                <DataGridPro
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                  // density="standard"
                  sx={{ cursor: 'pointer', fontSize: '0.85em', mt: 0.1 }}
                  initialState={{ pinnedColumns: { right: ['actions'] } }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: LinearProgress,
                  }}
                  columnHeaderHeight={38}
                  rowHeight={34}
                  loading={!loadedGDomesticAuthProject}
                  rows={rowsGDomesticAuthProject}
                  columns={columnsGDomesticAuthProject}
                  pageSize={pageSizeGDomesticAuthProject}
                  onPageSizeChange={(newPageSize) => setPageSizeGDomesticAuthProject(newPageSize)}
                  rowsPerPageOptions={[1, 10, 20, 50, 100]}
                  onPageChange={handlePageChangeGDomesticAuthProject}
                  page={pageGDomesticAuthProject}
                  pagination
                  disableColumnMenu
                  disableMultipleRowSelection={true}
                  onRowSelectionModelChange={async ids => {
                    if (ids.length === 1) {
                      // 프로젝트별 국내가공유리제품 사전 인증서 발급 현황
                      const gprojectId = ids[0];
                      setLoadedGDomesticAuthMap(false);
                      await selectGDomestciAuthByGProject(gprojectId);
                      setLoadedGDomesticAuthMap(true);
                    }
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ height: 445.5, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              // density="standard"
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loadedGDomesticAuthMap}
              rows={rowsGDomesticAuthMap}
              columns={columnsGDomestciAuthMap}
              pageSize={pageSizeGDomesticAuthMap}
              onPageSizeChange={(newPageSize) => setPageSizeGDomesticAuthMap(newPageSize)}
              rowsPerPageOptions={[1, 10, 20, 50, 100]}
              onPageChange={handlePageChangeGDomesticAuthMap}
              page={pageGDomesticAuthMap}
              pagination
              disableColumnMenu
            />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DomesticCertification;