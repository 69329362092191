import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Badge,
  Chip,
  CircularProgress,
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  LinearProgress,
  styled,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import  {
  Attachment,
  Search,
  Delete,
  OpenInNew,
  PictureAsPdf,
  Image,
  Download,
  Downloading,
} from '@mui/icons-material';
import {
  FormInputAutoComplete,
  FormInputText
} from "../form";
import {
  AlertDialog,
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import { CustomNoRowsOverlay } from "../datagrid";
import { mode } from "../../config";
import { dateFormat } from "../../utils";
import * as gclientActions from "../../store/gclient";
import * as g04docuFileActions from "../../store/g04docuFile";
import * as gsendG04docuActions from "../../store/gsendG04docu";
import GSendG04docuDialog from "./GSendG04docuDialog";
import { getGridHeight } from "../../constants/gridHeights";
const GRID_HEIGHT = getGridHeight('ONE_LINE_LAYER', 107); // 0px 추가 여백

const theme = createTheme();

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 1,
    top: 13,
    // border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: "white",
    backgroundColor: "#9e9e9e",
  },
}));

const defaultValues = {
  // gclientId: "",
  // division: '',
  type: '',
  searchWord: "",
  name: "",
  classification: "",
};

const GSendG04docuManagement = ({
  title,
  from,
}) => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const [gclientIdFrom, setGClientIdFrom] = useState("");
  const [gclientIdTo, setGClientIdTo] = useState("");
  const [gclients, setGClients] = useState([]);
  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [hideGClientNameColumn, setHideGClientNameColumn] = useState(true);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [alertInfo, setAlertInfo] = useState({});

  const [attachFiles, setAttachFiles ] = useState([]);

  // const openFunctionMenu = Boolean(anchorEl);
  
  // 기능버튼 메뉴
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  
  const handleSelect = async ({ type, params }) => {
    // setAnchorEl(null);
    console.log(params)
    
    const { id, row } = params;

    setOpenBackdrop(true);

    if (type === "detail") {
      setSelectedRow(row);
      setCrudMode('R');
      setOpen(true);
    } else if (type === "delete") {
      setRemoveId(id);
      setConfirmOpen(true);
    }

    setOpenBackdrop(false);
  }

  const remove = async (removeId) => {
    if (removeId) {
      dispatch(gsendG04docuActions.remove(removeId))
        .then(res => {
          // setSelectionModel([]);
          selectGSendG04docus({ gclientIdFrom, gclientIdTo });
        })
        .catch(async (res) => {
          console.log(res)
          const data = await res.json();
          if (data && data.errors) setErrors(data.errors);
        });
    } else {
      // console.log(selectionModel)
      await removeSelectedDirect(selectionModel);
      selectGSendG04docus({ gclientIdFrom, gclientIdTo });
    }
  }

  const handleClickRemoveSelected = async () => {
    setRemoveId("");
    setConfirmOpen(true);
  }

  // const options = [
  //   {
  //     text: '수정',
  //     icon: <Edit fontSize="small" />,
  //     type: 'edit',
  //   },
  //   {
  //     text: '삭제',
  //     icon: <Delete fontSize="small" />,
  //     type: 'delete',
  //   },
  // ];
  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
    ];

    return arrActions;
  }

  const handleClickAttachment = (e, attachments) => {
    // console.log(attachments)
    e.stopPropagation();

    setAttachFiles(attachments);

    setTimeout(() => setOpenFile(true), 500);
  }

  const handleFileDialogClose = () => {
    setOpenFile(false);
  }

  const columns = [
    // {
    //   field: 'gclientIdFrom',
    //   headerName: '보낸 거래선 아이디',
    //   width: 200,
    //   // editable: true,
    // },
    {
      field: 'fromGClient',
      headerName: '보낸 거래선',
      width: 150,
      // editable: true,
    },
    // {
    //   field: 'gclientFrom',
    //   headerName: '보낸 거래선',
    //   width: 200,
    //   // editable: true,
    // },
    // {
    //   field: 'gclientIdTo',
    //   headerName: '받은 거래선 아이디',
    //   width: 200,
    //   // editable: true,
    // },
    // {
    //   field: 'gclientTo',
    //   headerName: '받은 거래선',
    //   width: 200,
    //   // editable: true,
    // },
    {
      field: 'toGClient',
      headerName: '받은 거래선',
      width: 150,
      // editable: true,
    },
    {
      field: 'mailFrom',
      headerName: '보낸 사람',
      width: 200,
      // editable: true,
      hide: true,
    },
    {
      field: 'mailTo',
      headerName: '받은 사람',
      width: 230,
      // editable: true,
      renderCell: (params) => (params.row.mailTo?.map(to => (<Chip label={to.name} size="small" sx={{ mr: 1 }}></Chip>))),
    },
    {
      field: 'mailCc',
      headerName: '참조',
      width: 200,
      // editable: true,
      hide: true,
      renderCell: (params) => (params.row.mailCc?.map(cc => (<Chip label={cc.name} size="small" sx={{ mr: 1 }}></Chip>))),
    },
    {
      field: 'mailCcInternal',
      headerName: '내부참조',
      width: 200,
      // editable: true,
      hide: true,
      renderCell: (params) => (params.row.mailCcInternal?.map(ccInternal => (<Chip label={ccInternal.name} size="small" sx={{ mr: 1 }}></Chip>))),
    },
    {
      field: 'subject',
      headerName: '제목',
      width: 250,
      // editable: true,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <Box>{params.value}</Box>
          </Tooltip>
        )
      },
    },
    {
      field: 'html',
      headerName: '메시지',
      width: 400,
      // editable: true,
      hide: true,
      renderCell: (params) => {
        return (
          <Tooltip title={params.value}>
            <Box>{params.value}</Box>
          </Tooltip>
        )
      },
    },
    {
      field: 'attachments',
      headerName: '첨부파일',
      width: 90,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { attachments } = params.row;
        if (attachments && Array.isArray(attachments) && attachments.length > 0) {
          return (
            <Tooltip title={"첨부파일 보기"}>
              <IconButton onClick={(e) => handleClickAttachment(e, attachments)}>
                <Attachment />
              </IconButton>
            </Tooltip>
          )
        }
      }
    },
    {
      field: 'sendMailResults',
      headerName: '전송 메일 결과',
      width: 200,
      // editable: true,
      hide: true,
      renderCell: (params) => {
        if (mode.indexOf('java') > 0) {
          console.log(params)
          const { sendMailResults } = params.row;
          if (sendMailResults && sendMailResults.result === 'OK') {
            return "전송";
          } else if(sendMailResults && sendMailResults.accepted && Array.isArray(sendMailResults.accepted) && sendMailResults.accepted.length > 0) {
            return "전송";
          } else {
            return "미전송";
          }
        } else {
          return (
            <>
              <StyledBadge
                badgeContent={params.row.sendMailResults?.accepted.length ? params.row.sendMailResults?.accepted.length : "0"} // 숫자형태의 0이면 나타나지 않음 
                sx={{ mr: 2 }}
              >
                <Chip label={`전송`} />
              </StyledBadge>
              <StyledBadge
                badgeContent={params.row.sendMailResults?.rejected.length ? params.row.sendMailResults?.rejected.length : "0"} // 숫자형태의 0이면 나타나지 않음
              >
                <Chip label={`미전송`} />
              </StyledBadge>
            </>
          )
        }
      },
    },
    {
      field: 'results',
      headerName: '결과',
      width: 200,
      hide: true,
      // editable: true,
    },
    {
      field: 'createdAt',
      headerName: '보낸시간',
      width: 160,
      headerAlign: 'center',
      align: 'right',
      valueGetter: (params) => {
        const today = dateFormat(new Date(), 'yyyy-MM-dd');
        const value = dateFormat(params.value, 'yyyy-MM-dd HH:mm');
        return value.replace(`${today}`, "오늘");
      },
    },
    // {
    //   field: 'createdAt',
    //   headerName: '생성일시',
    //   width: 160,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => dateFormat(params.value),
    // },
    // {
    //   field: 'updatedAt',
    //   headerName: '수정일시',
    //   width: 160,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => dateFormat(params.value),
    // },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];

  const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const sessionUser = useSelector(state => state.session.sessionUser);
  const rowsGSendG04docu = useSelector((state) => state.gsendG04docu.gsendG04docus);
  
  // 데이터 관리
  const dispatch = useDispatch();
  
  const selectAllByQuery = ({ gclientIdFrom, gclientIdTo, searchWord }) => dispatch(gsendG04docuActions.selectAllByQuery({ gclientIdFrom, gclientIdTo, searchWord }))
  const removeSelectedDirect = (ids) => gsendG04docuActions.removeSelectedDirect(ids)
  const selectAllGClientDirect = () => gclientActions.selectValidAllDirect()
  const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)

  const queryPaging = useMemo(
    () => ({
      page,
      pageSize,
    }), [page]
  );

  console.log("queryPaging")
  console.log(queryPaging)

  useEffect(
    async () => {
      await selectGSendG04docus({ gclientIdFrom, gclientIdTo });

      const resGClients = await selectAllGClientDirect();
      setGClients(resGClients);

      const { id: gclientId, type } = sessionUser;
      if (type === 'ADMIN') {
        setHideGClientNameColumn(false);
      } else {
        // selectGTests({ gclientId });
      }
      
      setTimeout(() => setLoaded(true), 300);
    }, [dispatch]
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  }

  const selectGSendG04docus = async ({ gclientIdFrom, gclientIdTo, searchWord }) => {
    const paramGClientIdFrom = sessionUser.type === 'ADMIN' ? gclientIdFrom : sessionUser.id; // sessionUser.id로 검색한다는 것은 회원사 사용자별로 검색하는 것이 아니라 회원사 전체로 검색한다는 의미임
    const paramGClientIdTo = gclientIdTo;
    const paramSearchWord = searchWord ? searchWord : getValues("searchWord");

    await selectAllByQuery({
      gclientIdFrom: paramGClientIdFrom,
      gclientIdTo: paramGClientIdTo,
      searchWord: paramSearchWord,
    });
  }

  // 거래선명으로 검색
  const handleChangeGClientFrom = (e, value) => {
    const { id: gclientIdFrom } = value;
    selectGSendG04docus({ gclientIdFrom, gclientIdTo });
    setGClientIdFrom(gclientIdFrom);
  }

  const handleChangeGClientTo = (e, value) => {
    const { id: gclientIdTo } = value;
    selectGSendG04docus({ gclientIdFrom, gclientIdTo });
    setGClientIdTo(gclientIdTo);
  }
  
  var timer;
  // 원부자재 및 공정의 이름으로 검색
  const handleChangeSearchWord = async (e) => {
    // 과도한 API 호출 방지를 위한 debouncing 코드. TODO : 라이브러리 활용 검토
    if (timer) {
      clearTimeout(timer);
    }
    // 타이머 설정
    timer = setTimeout(() => {
      const searchWord = e.target.value;
      selectGSendG04docus({ gclientIdFrom, gclientIdTo, searchWord });
    }, 500);
  };

  // 검색버튼
  const handleClickSearch = async () => {
    selectGSendG04docus({ gclientIdFrom, gclientIdTo });
  };

  // const handleClickDownloadAttachFile = async (e, documentPath) => {
  //   e.stopPropagation();

  //   const arr = documentPath.split("/");
  //   const fileName = arr[arr.length - 1];

  //   const res = await downloadG04docuFile(documentPath);
  //   const blob = await res.blob(); // res.blob는 promise를 리턴함???
    
  //   // 2. Create blob link to download
  //   const url = window.URL.createObjectURL(new Blob([blob]));
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', fileName);
  //   // 3. Append to html page
  //   document.body.appendChild(link);
  //   // 4. Force download
  //   link.click();
  //   // 5. Clean up and remove the link
  //   link.parentNode.removeChild(link);
  // }
  const handleClickDownloadAttachFile = async (e, documentPath, idx) => {
    e.stopPropagation();
    
    const newAttachFiles = attachFiles.map((file, i) => (i === idx ? { ...file, downloading: true } : file));

    setAttachFiles(newAttachFiles);
    const arr = documentPath.split("/");
    const fileName = arr[arr.length - 1];

    const res = await downloadG04docuFile(documentPath);
    const blob = await res.blob(); // res.blob는 promise를 리턴함???
    
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
    
    setTimeout(() => {
      setAttachFiles(newAttachFiles.map((file, i) => (i === idx ? { ...file, downloading: false } : file)));
    }, 1000);
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Container component="main" maxWidth="lg"> */}
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <GSendG04docuDialog
          open={open}
          setOpen={setOpen}
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          selectedRow={selectedRow}
          handleClickDownloadAttachFile={handleClickDownloadAttachFile}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {
                title && (
                  <Typography variant="h5">
                    {title}
                  </Typography>
                )
              }
            </Grid>
            {
              sessionUser.type === 'ADMIN' && (
                <>
                  <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center"/* sx={{ display }}*/>
                    <FormInputAutoComplete
                      control={control}
                      label={"보낸 거래선"}
                      // TODO : 우신복층유리 1차 오픈 위한 코드
                      // options={[{ label: "전체", id: "NONE" }].concat(gclients.map(gclient => ({ label: gclient.name, id: gclient.id })))}
                      options={gclients.map(gclient => ({ label: gclient.name, id: gclient.id }))}
                      setValue={setValue}
                      onCustomChange={handleChangeGClientFrom}
                    />
                  </Grid>
                </>
              )
            }
            <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center">
              <FormInputAutoComplete
                control={control}
                label={"받은 거래선"}
                options={[{ label: "전체", id: "NONE" }].concat(gclients.map(gclient => ({ label: gclient.name, id: gclient.id })))}
                setValue={setValue}
                onCustomChange={handleChangeGClientTo}
              />
            </Grid>
            <Grid item xs={10} display="flex" justifyContent="flex-start" alignItems="center">
              <FormInputText
                name={"searchWord"}
                control={control}
                label={"검색"}
                onCustomChange={handleChangeSearchWord}
                sx={{ mr: 1 }}
                // disabled={gclientIdFrom ? false : true} // TODO : 우신복층유리 1차 오픈
              />
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                variant="contained"
                startIcon={<Search />}
                onClick={handleClickSearch}
                sx={{ mr: 1 }}
              >
                {"검색"}
              </Button>
              <Button
                variant="contained"
                startIcon={<Delete />}
                onClick={handleClickRemoveSelected}
                disabled={selectedRows.length > 0 ? false : true}
              >
                {"삭제"}
              </Button>
            </Grid>
          </Grid>
          <div style={{ height: from?.params ? 500 : GRID_HEIGHT.REDUCED, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ cursor: 'pointer', mt: 2, fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={rowsGSendG04docu}
              columns={columns}
              // pageSize={5}
              // rowsPerPageOptions={[5]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[1, 10, 20, 50, 100]}
              onPageChange={handlePageChange}
              page={page}
              // rowCount={rowCount}
              pagination
              onRowDoubleClick={(params) => handleSelect({ type: 'detail', params })}
              checkboxSelection
              // checkboxSelection
              // disableSelectionOnClick
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                // console.log(selectedIDs)
                const selected = rowsGSendG04docu.filter((row) =>
                  selectedIDs.has(row.id),
                );
      
                setSelectionModel(ids);

                console.log(selected)
                setSelectedRows(selected);
              }}
              selectionModel={selectionModel}
            />
          </div>
        </Box>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {"선택하신 메일을 삭제하시겠습니까?"}
        </ConfirmDialog>
        <AlertDialog
          alertInfo={alertInfo}
          setAlertInfo={setAlertInfo}
        />
      </Container>
      <Dialog
        open={openFile}
        onClose={handleFileDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        maxWidth="sm"
        // onConfirm={sendOrder}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleFileDialogClose}
          style={{ cursor: 'move' }}
        >
          {"첨부파일"}
        </DialogTitleClose>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <List>
                {
                  attachFiles.map((file, idx) => {
                    const { filename, path } = file;
                    const pos = filename.lastIndexOf(".");
                    let ext = "";
                    if (pos > -1) {
                      ext = filename.substring(pos+1);
                    }

                    return (
                      <ListItem disablePadding>
                        {/* <Tooltip title={"다운로드"}>
                        <ListItemButton onClick={(e) => handleClickDownloadAttachFile(e, path)}>
                          <ListItemIcon>
                            { ext.toLowerCase() === 'pdf' ? <PictureAsPdf /> : <Image /> }
                          </ListItemIcon>
                          <ListItemText primary={filename} />
                        </ListItemButton>
                        </Tooltip> */}
                        <ListItemButton>
                          <ListItemIcon>
                            { ext.toLowerCase() === 'pdf' ? <PictureAsPdf /> : <Image /> }
                          </ListItemIcon>
                          <ListItemText primary={filename} />
                          <IconButton onClick={(e) => handleClickDownloadAttachFile(e, path, idx)}>
                            { file.downloading ? <Downloading /> : <Download /> }
                          </IconButton>
                        </ListItemButton>
                      </ListItem>
                    )
                  })
                }                
                {/* <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <PictureAsPdf />
                    </ListItemIcon>
                    <ListItemText primary="Drafts" />
                  </ListItemButton>
                </ListItem> */}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFileDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default GSendG04docuManagement;
