import React from 'react';
import { Route, Routes } from "react-router-dom";
import { GProjectManagement } from "../components/GProject";

import RequireAuth from './RequireAuth';
import DashboardRoute from './DashboardRoute';
import ProfileRoute from './ProfileRoute';
import IndexRoute from './IndexRoute';
import SettingsRoute from './SettingsRoute';
import MaterialApproveRoute from './MaterialApproveRoute';
import SupplyConfirmRoute from './SupplyConfirmRoute';
import PublishRoute from './PublishRoute';
import AssociationRoute from './AssociationRoute';

export const AppRoutes = ({ sessionUser, isLoaded }) => (
    <>
    <Routes>
      {/* 대시보드 */}
      {DashboardRoute({sessionUser})}

      {/* Intro, Login, Signup */}
      {IndexRoute()}

      {/* Profile */}
      {ProfileRoute({sessionUser})}
      
      {/* 설정 */}
      {SettingsRoute({sessionUser})}
      
      {/* 자재승인서 */}
      {MaterialApproveRoute({sessionUser})}

      {/* 납품확인서 */}
      {SupplyConfirmRoute({sessionUser})}
      
      <Route path="/projects" element={
        <RequireAuth sessionUser={sessionUser}>
          <GProjectManagement />
        </RequireAuth>
      } />

      <Route path="/g04docuProjects" element={
        <RequireAuth sessionUser={sessionUser}>
          <GProjectManagement />
        </RequireAuth>
      } />

      {/* 별도발행 */}
      {PublishRoute({sessionUser})}

      {/* 유리협회 */}
      {AssociationRoute({sessionUser})}
    </Routes>
    </>
);

export default AppRoutes;