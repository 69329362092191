import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { FormInputText, FormInputSwitch, FormInputDate } from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  AlertDialog,
} from "../dialog";
import { format } from 'date-fns'; 
import * as gclientPaymentsActions from "../../store/gclientPayments";
import { SERVICE_AMOUNTS } from '../../constants/serviceAmounts';
import { PARTNERSHIP_TYPE, MEMBERSHIP_TYPE, MEMBERSHIP_TYPE_LABELS } from '../../constants/gclientTypes';

const defaultValues = {
  seq: "",
  amount: "",
  membership: "", 
  startDate: "", 
  endDate: "",
  comment: "",
};

const GClientServiceDialogPop = ({ 
  popOpen, 
  setPopOpen, 
  selectedRow,
  params,
  onSuccess,
}) => {

  const { handleSubmit, reset, control, getValues, setValue, formState: { errors } } = useForm({ 
    defaultValues: defaultValues 
  });
  const [loaded, setLoaded] = useState(true);
  const [alertInfo, setAlertInfo] = useState({ open: false, titleAlert: '오류', messageAlert: '' });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dispatch = useDispatch();

  // 날짜 문자열을 Date 객체로 변환하는 함수
  const parseDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  };

  useEffect(() => {
    if (popOpen && selectedRow) {
      // 날짜 필드 특별 처리
      const formattedData = {
        ...selectedRow,
        startDate: parseDate(selectedRow.startDate),
        endDate: parseDate(selectedRow.endDate),
      };

      // 폼 데이터 설정
      Object.entries(defaultValues).forEach(([key, defaultValue]) => {
        const value = formattedData[key];
        if (key === 'amount' && value) {
          // 금액 처리
          setValue(key, value.toLocaleString());
        } else {
          setValue(key, value || defaultValue);
        }
      });
    }
  }, [popOpen, selectedRow, setValue]);

  const handleClose = () => {
    setPopOpen(false);
    reset(defaultValues);
  };

  useEffect(() => {
    const errorMessages = Object.values(errors).map(error => error.message);
    if (errorMessages.length > 0) {
      setAlertInfo({
        open: true,
        titleAlert: '필수사항 입력오류',
        messageAlert: (
          <span>
            {errorMessages.map((message, index) => (
              <React.Fragment key={index}>
                <span style={{ paddingRight: '50px' }}>{message}</span>
                {index < errorMessages.length - 1 && <br />}
              </React.Fragment>
            ))}
          </span>
        )
      });
    }
  }, [errors]);

  const onSubmit = async (data) => {
    console.log('onSubmit => ', data);

    const confirmed = window.confirm('수정 하시겠습니까?');
    if (!confirmed) {
      return;
    }

    // 콤마 제거
    const amount = data.amount.replace(/,/g, '');

    // 날짜 형식 변환
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return format(date, 'yyyy-MM-dd') + ' 00:00:00';
    };

    const startDate = formatDate(data.startDate);
    const endDate = formatDate(data.endDate);

    const formattedData = {
      ...data,
      amount,
      startDate,
      endDate,
    };

    console.log('onSubmit formattedData => ', formattedData);

    setLoaded(false);
    try {
      const response = await dispatch(gclientPaymentsActions.modify(formattedData));

      if (response.ok) {
        handleClose();
        if (onSuccess) {
          onSuccess();
        }
      } else {
        //setErrors(errorData.errors || ['수정에 실패했습니다.']);
      }
    } catch (error) {
      console.error('서비스 저장 실패:', error);
      const message = error.serverResponse?.message || '서비스 저장에 실패했습니다.';
      setAlertInfo({
        open: true,
        titleAlert: '서비스 오류',
        messageAlert: message
      });
    } finally {
      setLoaded(true);
    }
  };

  const handleMembershipChange = (event) => {
    const selectedValue = event.target.value;
    const partnership = params?.row?.partnership || PARTNERSHIP_TYPE.비회원사;  // 기본값 설정

    console.log("selectedValue", selectedValue);
    let amount = 0;
    if (partnership === PARTNERSHIP_TYPE.비회원사) {
      if (selectedValue === MEMBERSHIP_TYPE.DEFAULT) {
        amount = SERVICE_AMOUNTS.NON_MEMBER_BASIC;
      } else {
        amount = SERVICE_AMOUNTS.NON_MEMBER_OPTION01;
      }
    } else {
      if (selectedValue === MEMBERSHIP_TYPE.DEFAULT) {
        amount = SERVICE_AMOUNTS.MEMBER_BASIC;
      } else {
        amount = SERVICE_AMOUNTS.MEMBER_OPTION01;
      }
    }

    setValue('amount', amount.toLocaleString());
  };

  const handleChangeStartDate = (value) => {
    setValue('startDate', value);
    setStartDate(value);

    // value에 365일을 추가
    const endDate = new Date(value);
    endDate.setFullYear(endDate.getFullYear() + 1);
    //endDate.setDate(endDate.getDate() - 1);
    
    handleChangeEndDate(endDate);
    
  }

  const handleChangeEndDate = (value) => {
    setValue('endDate', value);
    setEndDate(value);
  }

  return (
    <>
      <Dialog
          open={popOpen}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          fullWidth
          maxWidth={false}  // 기본 maxWidth 제한 해제
          sx={{
            '& .MuiDialog-paper': {
              maxWidth: '750px',
              width: '100%'
            }
          }}
        >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleClose}
          style={{ cursor: 'move' }}
        >
          서비스 정보 수정 ({params?.row?.partnership || ''})
        </DialogTitleClose>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <FormControl component="fieldset">
                <Controller 
                  control={control}
                  name="membership"
                  onCustomChange={handleMembershipChange}
                  render={({ field }) => (
                    <RadioGroup {...field} row>
                      <FormControlLabel
                        value={MEMBERSHIP_TYPE.DEFAULT}
                        control={<Radio />}
                        label="자재승인서"
                      />
                      <FormControlLabel
                        value={MEMBERSHIP_TYPE.OPTION01}
                        control={<Radio />}
                        label="자재승인서+납품확인서"
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <FormInputDate
                  name="startDate"
                  control={control}
                  value={startDate}
                  label={"과금 시작일"}
                  onChangePeriodValue={handleChangeStartDate}
                  getValues={getValues}
                  setValue={setValue}
                  inputHeight={37}
                  fontSize={14}
                  clearable={true}
                  customWidth={175} 
                  iconSize={"small"}
                />
                <FormInputDate
                  name="endDate"
                  control={control}
                  value={endDate}
                  label={"과금 종료일"}
                  onChangePeriodValue={handleChangeEndDate}
                  getValues={getValues}
                  setValue={setValue}
                  inputHeight={37}
                  fontSize={14}
                  clearable={true}
                  customWidth={175} 
                  iconSize={"small"}
                />
                <FormInputText
                  name="amount"
                  control={control}
                  label={"과금액"}
                  onCustomChange={e=>setValue('amount', e.target.value)}
                  sx={{
                    width: 90,
                    '& .MuiFormLabel-root' : {
                      fontSize: '0.85rem',
                    },
                    input: { fontSize: '0.85rem' }
                  }}
                  
                />
                <FormInputText
                  name="comment"
                  control={control}
                  label={"비고"}
                  sx={{
                    width: 200,
                    '& .MuiFormLabel-root' : {
                      fontSize: '0.85rem',
                    },
                    input: { fontSize: '0.85rem' }
                  }}
                />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button>
          <Button onClick={handleClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
    </>
  );
};

export default GClientServiceDialogPop;