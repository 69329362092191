// Snow 테마 설정 (기본 테마) theme={snowThemeConfig.theme}
export const snowThemeConfig = {
    theme: "snow",
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['link'],
        ['clean']
      ]
    },
    style: {
      '.ql-container': {
        borderRadius: '0 0 4px 4px',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderTop: 0
      },
      '.ql-toolbar': {
        borderRadius: '4px 4px 0 0',
        border: '1px solid rgba(0, 0, 0, 0.23)'
      }
    }
  };
  
  // Bubble 테마 설정 (미니멀 테마) theme={bubbleThemeConfig.theme}
  export const bubbleThemeConfig = {
    theme: "bubble",
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'color': [] }],
        [{ 'align': [] }],
        ['link']
      ]
    },
    style: {
      '.ql-container': {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px'
      },
      '.ql-tooltip': {
        zIndex: 1000
      }
    }
  };

// Quill 에디터의 기본 설정
export const defaultModules = {
    
    toolbar: [
      [{ 'header': [
        { '1': '제목 1' },
        { '2': '제목 2' },
        { '3': '제목 3' },
        { false: '본문' }
      ] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false // 붙여넣기 시 서식 유지
    }
  };
  
  // 툴바 한글화
  const toolbarOptions = {
    'bold': '굵게',
    'italic': '기울임',
    'underline': '밑줄',
    'strike': '취소선',
    'color': '글자색',
    'background': '배경색',
    'align': '정렬',
    'list': '목록',
    'link': '링크',
    'clean': '서식 지우기',
    'ordered': '번호 매기기',
    'bullet': '글머리 기호'
  };
  
  // Quill 에디터 스타일 설정
  export const defaultStyle = {
    '.ql-container': {
      minHeight: '150px',
      fontSize: '16px',
      borderRadius: '0 0 4px 4px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderTop: 0
    },
    '.ql-editor': {
      minHeight: '150px',
      '&.ql-blank::before': {
        content: 'attr(data-placeholder)',
        color: 'rgba(0, 0, 0, 0.6)',
        fontStyle: 'normal',
        left: '12px',                      // 왼쪽 패딩 맞춤
        right: '12px',                     // 오른쪽 패딩 맞춤
        position: 'absolute'    
      }
    },
    '.ql-toolbar': {
      borderRadius: '4px 4px 0 0',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      //height: '40px',
      //minHeight: '40px',
      padding: '4px 8px',
      backgroundColor: '#f5f5f5'  // 툴바 배경색
    }
  };


    
  // 서명용 스타일
  export const signatureStyle = {
    '& .ql-container': {
      height: '200px',
      backgroundColor: '#ffffff'
    }
  };
  
  // 서명용 Quill 설정
  export const signatureModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'color': [] }],
      [{ 'align': [] }],
      ['link'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    }
  };

  // 이미지 업로드가 필요한 경우의 설정
export const imageModules = {
    ...defaultModules,
    toolbar: [
      ...defaultModules.toolbar,
      ['image']
    ],
    imageUploader: {
      upload: (file) => {
        return new Promise((resolve, reject) => {
          // 이미지 업로드 로직
        });
      }
    }
  };
  
  // 커스텀 폰트가 필요한 경우의 설정
//   export const fontModules = {
//     ...defaultModules,
//     toolbar: [
//       [{ 'font': ['Arial', 'Courier', '나눔고딕', '맑은고딕'] }],
//       ...defaultModules.toolbar
//     ]
//   };

export const fontModules = {
    ...defaultModules,
    toolbar: [
      [{ 
        'font': [
          '맑은 고딕',
          '나눔고딕',
          '나눔명조',
          '돋움체',
          '굴림체',
          '바탕체'
        //   'Arial',
        //   'Courier New'
        ] 
      }],
    //   [{ 
    //     'size': [
    //       '8px',
    //       '10px',
    //       '12px',
    //       '14px',
    //       '16px',
    //       '18px',
    //       '20px'
    //     ]
    //   }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    //   ['link'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    }
  };
  
  // 폰트 관련 포맷
  export const fontFormats = [
    'font',
    'size',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'align',
    'list', 'bullet',
    'link'
  ];
  
  // 폰트 스타일 설정
  export const fontStyle = {
    ...defaultStyle,
    '.ql-snow .ql-picker.ql-font': {
      '& .ql-picker-label::before': {
        content: '"글꼴"'
      },
      '& .ql-picker-item::before': {
        content: 'attr(data-value)'
      }
    },
    '.ql-snow .ql-picker.ql-size': {
      '& .ql-picker-label::before': {
        content: '"크기"'
      },
      '& .ql-picker-item::before': {
        content: 'attr(data-value)'
      }
    },
    // 폰트 패밀리 적용
    '.ql-font-맑은고딕': {
      fontFamily: '"맑은 고딕", "Malgun Gothic", sans-serif'
    },
    '.ql-font-나눔고딕': {
      fontFamily: '"나눔고딕", "NanumGothic", sans-serif'
    },
    '.ql-font-나눔명조': {
      fontFamily: '"나눔명조", "NanumMyeongjo", serif'
    },
    '.ql-font-돋움체': {
      fontFamily: '"돋움체", "DotumChe", sans-serif'
    },
    '.ql-font-굴림체': {
      fontFamily: '"굴림체", "GulimChe", sans-serif'
    },
    '.ql-font-바탕체': {
      fontFamily: '"바탕체", "BatangChe", serif'
    },
    // '.ql-font-arial': {
    //   fontFamily: 'Arial, sans-serif'
    // },
    // '.ql-font-courier-new': {
    //   fontFamily: '"Courier New", Courier, monospace'
    // }
  };
  
  // Quill에 폰트 등록
  export const registerFonts = (Quill) => {
    const Font = Quill.import('formats/font');
    Font.whitelist = [
      '맑은고딕',
      '바탕체',
      '굴림체',
      '돋움체',
      '나눔고딕',
      '나눔명조',
    //   'arial',
    //   'courier-new'
    ];
    Quill.register(Font, true);
  };

    // 서명용 작은 스타일 (fontStyle 상속)
export const smallStyle = {
    ...fontStyle,
    '& .ql-container': {
      ...defaultStyle['& .ql-container'],
      height: '130px',      // 높이만 변경
      minHeight: '100px'    // 최소 높이도 변경
    },
    '& .ql-editor': {
      ...defaultStyle['& .ql-editor'],
      height: '130px',      // 높이만 변경
      minHeight: '100px'    // 최소 높이도 변경
    }
  };