import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  DialogTitleClose,
} from "../dialog";

const theme = createTheme();

const border = '1px solid rgba(224, 224, 224, 1)';
const summaryTableHeader = {
  backgroundColor: '#f5f5f5',
  border,
};

const GSupplyConfirmSummaryDialog = ({
  open, 
  setOpen,
  summaryData,
}) => {
  const handleDialogClose = () => {
    setOpen(false);

    initDialog();
  };

  const initDialog = () => {
    
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog
          fullScreen={true}
          open={open}
          onClose={handleDialogClose}
          aria-labelledby="draggable-dialog-title"
          maxWidth="lg"
          scroll="body"
          disableEscapeKeyDown={true}
        >
          <DialogTitleClose
            id="draggable-dialog-title"
            onClose={handleDialogClose}
            fullScreen={true}
            color="white"
            style={{ backgroundColor: "#1976d2" }}
          >
            <div id="dialog-position">
              {"납품확인서 요청하기"}
            </div>
          </DialogTitleClose>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <TableContainer sx={{
                  '& .MuiTableCell-root': {
                    padding: '12px 16px',
                  }
                }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" sx={{ ...summaryTableHeader, width: '60px' }}>순번</TableCell>
                        <TableCell align="center" colSpan={2} sx={{ ...summaryTableHeader }}>구분</TableCell>
                        <TableCell align="center" sx={{ ...summaryTableHeader }}>업체명</TableCell>
                        <TableCell align="center" sx={{ ...summaryTableHeader }}>제품명</TableCell>
                        {/* <TableCell align="center" sx={{ ...summaryTableHeader, width: '160px' }}>제품코드</TableCell> */}
                        <TableCell align="center" sx={{ ...summaryTableHeader, width: '160px' }}>적용부위</TableCell>
                        <TableCell align="center" sx={{ ...summaryTableHeader, width: '120px' }}>수량</TableCell>
                        {/* <TableCell align="center" sx={{ ...summaryTableHeader, width: '70px'}}>단위</TableCell> */}
                        <TableCell align="center" sx={{ ...summaryTableHeader }}>납품일자</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(() => {
                        return summaryData.length > 0 && 
                          summaryData.map((row, index) => {
                            return (
                              <TableRow key={`${row.category}-${index}`} sx={{ height: '30px' }}>
                                <TableCell align="right" sx={{ border }}>{index + 1}</TableCell>
                                <TableCell align="center" sx={{ border }}>{row.category}</TableCell>
                                <TableCell align="left" sx={{ border }}>{row.subCategory}</TableCell>
                                <TableCell align="left" sx={{ border }}>{row.company}</TableCell>
                                <TableCell align="left" sx={{ border }}>{row.product}</TableCell>
                                {/* <TableCell align="center" sx={{ border }}>{row.itemCode}</TableCell> */}
                                <TableCell align="center" sx={{ border }}>{row.appliedArea}</TableCell>
                                <TableCell align="right" sx={{ border }}>
                                  {
                                    row.supplySize ? (<>
                                      {Number(row.supplySize).toLocaleString()}{' '}
                                      <Typography component="span" sx={{ fontStyle: 'italic', fontSize: '0.7rem' }}>{row.supplyUnit}</Typography>
                                    </>) : ''
                                  } 
                                </TableCell> 
                                {/* <TableCell align="center" sx={{ border }}>{row.supplyUnit}</TableCell> */}
                                <TableCell align="center" sx={{ border }}>{row.supplyDate}</TableCell>
                              </TableRow>
                            );
                          });
                      })()}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>{"닫기"}</Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default GSupplyConfirmSummaryDialog;
